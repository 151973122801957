import React from 'react';
import { Icon } from './Icon';
import { useT } from '../hooks/useT';
import { useNavigate } from "react-router-dom";

export const Backlink = ({ className, style }) => {
  const {t} = useT();
  const navigate = useNavigate();

  return (
    <span onClick={() => navigate(-1)} className={`link ${className}`} style={style}>
      {t('generic.link.go_back')} <Icon i={'read-more'} />
    </span>
  )
}
