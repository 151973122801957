import React, { useState } from "react";
import { useToast } from "@/hooks/useToast";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "react-query";
import { programme } from "@/queries";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import { Button, Stack } from "@mui/material";
import { FormRichTextField } from "@/base/form/mui/FormRichTextField";

export const VisionForm = ({ programmeData, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();
  const schema = yup.object().shape({
    vision: yup.string().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      vision: programmeData?.vision || "",
    },
  });

  const { handleSubmit, reset } = formData;

  const updateVision = useMutation(programme.editVision, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Vision updated successfully");
      onUpdate?.();
      setIsEditing(false);
    },
    onError: (error) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(`Error updating vision. ${error?.response?.data?.message}`);
    },
  });

  return (
    <FormDataProvider formKey="programme.editDesignForm.fields" {...formData}>
      <form
        onSubmit={handleSubmit((data) => {
          updateVision.mutate({
            id: programmeData.id,
            data: {
              vision: data.vision,
            },
          });
        })}
      >
        <Stack spacing={3}>
          <FormRichTextField
            name="vision"
            readOnly={!isEditing}
            editable={!isEditing}
            onEditClick={() => setIsEditing(true)}
            labelStyle={{ fontWeight: "700", lineHeight: "24px" }}
          />
          {isEditing && (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ width: "100%", mt: 2 }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
    </FormDataProvider>
  );
};
