import React, { useMemo, useEffect } from "react";
import { useParams, useMatch } from "react-router-dom";
import { is, isEmpty, isNil, path } from "ramda";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { project } from "../../queries";
import { useQ } from "../../hooks/useQ";
import { CheckpointReport } from "./components/CheckpointReport";
import { HighlightReport } from "./components/HighlightReport";
import { clientUrl } from "../../util/routing";
import { uris } from "../../config/nav";
import { HighlightReportPdf } from "./HighlightReportPdf";
import { Container } from "@mui/material";
import { useFlag } from "@/hooks/useFlag";

export const Progress = () => {
  const { id = "", reportId = "", type } = useParams();
  const { t } = useT();
  const useNewCheckpoint = useFlag("checkpoints.useRestApiV1");
  const useOptimizedReports = useFlag("project.optimize.reports");
  const { isLoading: isLoadingProject, data = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );
  const {
    isLoading: isLoadingReports,
    data: reportData = [],
    refetch,
  } = useQ(
    `project-${id}-report-highlights`,
    () => project.report.highlights({ id }),
    { enabled: !useOptimizedReports },
  );

  const {
    isLoading: isLoadingReportList,
    data: reportObject = {},
    refetch: refetchReportList,
  } = useQ(
    useOptimizedReports
      ? `project-${id}-report-${reportId}`
      : `project-${id}-report-single`,
    () => project.report.single({ id, type, reportId }),
    { enabled: !!useOptimizedReports },
  );
  const { data: projectLessonsData = {}, refetch: refetchProjectLessons } =
    useQ(`project-lessons`, () => project.lessons());

  const lessons = projectLessonsData.lessons ?? [];

  useEffect(() => {
    if (useOptimizedReports) {
      refetchReportList();
    }
  }, [id, reportId, type, refetchReportList, useOptimizedReports]);

  const { isLoading: isLoadingPlans, data: projectPlanData = [] } = useQ(
    `project-${id}-plans`,
    () => project.plans({ id }),
  );

  const report = useMemo(() => {
    if (!useOptimizedReports) {
      const reports =
        type === "checkpoint"
          ? useNewCheckpoint
            ? reportData.filter((report) => report.type === "checkpoint")
            : reportData
          : type === "highlight" || isEmpty(type)
            ? reportData.filter(
                (report) =>
                  !report.endProject &&
                  isNil(report.stage) &&
                  report.type !== "checkpoint",
              )
            : type === "endStage"
              ? reportData.filter(
                  (report) =>
                    !isNil(report.stage) &&
                    !report.endProject &&
                    report.type !== "checkpoint",
                )
              : type === "endProject"
                ? reportData.filter(
                    (report) =>
                      !!report.endProject && report.type !== "checkpoint",
                  )
                : [];
      let _report = null;
      if (reportId) {
        if (reportId !== "draft") {
          _report = reports.find((item) => item.id === reportId);
        } else {
          _report = reports.find((item) => !!item.isDraft);
        }
      } else {
        _report = reports[0];
      }
      return _report
        ? {
            ..._report,
            summary: path(["summary", "content"], _report),
            value: path(["summary", "status"], _report),
          }
        : null;
    } else {
      return reportObject && reportObject.id
        ? {
            ...reportObject,
            summary: path(["summary", "content"], reportObject),
            value: path(["summary", "status"], reportObject),
          }
        : null;
    }
  }, [
    reportData,
    reportObject,
    reportId,
    type,
    useNewCheckpoint,
    useOptimizedReports,
  ]);

  const { title } = data;

  const isFullscreen = useMatch(
    clientUrl(uris.project.progressFullscreen, {
      id,
      type: "highlight",
      reportId,
    }),
  );

  const isLoading =
    isLoadingProject ||
    isLoadingReports ||
    isLoadingPlans ||
    isLoadingReportList;

  useEffect(() => {
    if (isFullscreen) {
      document.body.style.background = "none";
    }
  }, [isFullscreen]);

  const renderContent = () => {
    return (
      <>
        {useMatch(
          clientUrl(uris.project.progressFull, {
            id,
            type: "checkpoint",
            reportId,
          }),
        ) &&
          (useNewCheckpoint ? (
            <HighlightReport
              initiative={data}
              report={report}
              type="checkpoint"
            />
          ) : (
            <CheckpointReport projectId={id} reportId={reportId} />
          ))}

        {useMatch(
          clientUrl(uris.project.progressFull, {
            id,
            type: "highlight",
            reportId,
          }),
        ) && <HighlightReport initiative={data} report={report} />}
        {useMatch(
          clientUrl(uris.project.progressFullscreen, {
            id,
            type: "highlight",
            reportId,
          }),
        ) && <HighlightReport initiative={data} report={report} />}
        {useMatch(
          clientUrl(uris.project.progressFull, {
            id,
            type: "endStage",
            reportId,
          }),
        ) && (
          <HighlightReport
            initiative={data}
            report={report}
            type={type}
            stages={projectPlanData.stages ?? []}
            lessons={lessons}
          />
        )}
        {useMatch(
          clientUrl(uris.project.progressFull, {
            id,
            type: "endProject",
            reportId,
          }),
        ) && (
          <HighlightReport
            initiative={data}
            report={report}
            type={type}
            lessons={lessons}
          />
        )}
        {useMatch(
          clientUrl(uris.project.progressReportIdPdf, {
            id,
            reportId,
          }),
        ) && (
          <HighlightReportPdf
            projectId={id}
            reportId={reportId}
            type={type}
            stages={projectPlanData.stages ?? []}
          />
        )}
      </>
    );
  };

  if (isFullscreen) {
    return <Container sx={{ py: 4 }}>{renderContent()}</Container>;
  } else {
    return (
      <Page isLoading={isLoading} title={title}>
        <Section title={t("project.progress.title")}>{renderContent()}</Section>
      </Page>
    );
  }
};
