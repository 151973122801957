import React, { createContext, useState } from 'react';

export const ErrorRecovery = createContext({ rerender: () => {} });

export const ErrorRecoveryProvider = ({ children }) => {
  const [key, setKey] = useState(42);

  return (
    <ErrorRecovery.Provider value={{ rerender: () => setKey(Math.random()) }}>
      <React.Fragment key={key}>
        {children}
      </React.Fragment>
    </ErrorRecovery.Provider>
  );
};
