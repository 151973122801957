import React from "react";
import { EntityLink } from "./EntityLink";

export const InitiativeLink = ({ title, id, type, style }) => {
  return (
    <EntityLink
      entity={type}
      id={id}
      style={style || { color: "var(--shade-01)" }}
    >
      {title}
    </EntityLink>
  );
};
