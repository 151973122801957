import React from "react";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { Button } from "@mui/material";
import { uris } from "../../config/nav";
import { useT } from "../../hooks/useT";
import { useParams } from "react-router-dom";
import { clientUrl } from "@/util/routing";
import { portfolio } from "../../queries";
import { useQ } from "../../hooks/useQ";

export const TypeSelect = () => {
  const { t } = useT();
  const { portfolioId } = useParams();

  const { data: portfolios = [] } = useQ(`portfolios`, () => portfolio.list(), {
    enabled: portfolioId === undefined,
  });

  return (
    <>
      <Section title={t("page.home.new_project.title")}>
        <p>{t("page.home.new_project.description")}</p>
        <Button
          sx={{ float: "right" }}
          variant="contained"
          color="primary"
          href={clientUrl(uris.project.new, {
            portfolioId: portfolioId ?? portfolios[0]?.id ?? "noId",
          })}
        >
          {t("page.home.new_project.link")}
        </Button>
      </Section>

      <Section title={t("page.home.new_programme.title")}>
        <p>{t("page.home.new_programme.description")}</p>
        <Button
          sx={{ float: "right" }}
          variant="contained"
          color="primary"
          href={clientUrl(uris.programme.new, {
            portfolioId: portfolioId ?? portfolios[0]?.id ?? "noId",
          })}
        >
          {t("page.home.new_programme.link")}
        </Button>
      </Section>

      <Section title={t("page.home.new_product.title")}>
        <p>{t("page.home.new_product.description")}</p>
        <Button
          sx={{ float: "right" }}
          variant="contained"
          color="primary"
          href={clientUrl(uris.product.new, {
            portfolioId: portfolioId ?? portfolios[0]?.id ?? "noId",
          })}
        >
          {t("page.home.new_product.link")}
        </Button>
      </Section>
    </>
  );
};

export const TypeSelectPage = () => {
  return (
    <Page>
      <TypeSelect />
    </Page>
  );
};
