import React from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { project } from "../../queries";
import { useSteps } from "../../hooks/useSteps";
import { HighlightProgressReportForm } from "./forms/HighlightReportForm";
import { clientUrl } from "../../util/routing";
import { useQ } from "../../hooks/useQ";
import { uris } from "../../config/nav";

export const ProgressEditForm = () => {
  const { id = "", reportId = "", type = "" } = useParams();
  const { t } = useT();
  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );

  const { title, level } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.progress.title")}>
        <HighlightProgressReportForm
          projectId={id}
          reportId={reportId}
          next={() => {}}
          level={level}
          state="edit"
          type={type}
        />
      </Section>
    </Page>
  );
};
