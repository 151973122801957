import React, { useMemo } from "react";
import { Page } from "../../layout/Page";
import { useT } from "../../hooks/useT";
import { Section } from "../../layout/Section";
import { Link, useParams } from "react-router-dom";
import { useQ } from "../../hooks/useQ";
import { home } from "../../queries";
import { Typography } from "@mui/material";
import { CustomAccordion } from "../../layout/CustomAccordion";
import { uris } from "../../config/nav";
import { clientUrl } from "../../util/routing";

export const SearchResults = () => {
  const { t } = useT();
  const { query } = useParams();

  const { isLoading, data } = useQ(`search-${query}`, () =>
    home.search({ query }),
  );

  const results = useMemo(() => (data ? data : []), [data]);

  return (
    <Page isLoading={isLoading}>
      <Section title={'Search Results For "' + query + '"'}>
        {!!results.length ? (
          <CustomAccordion
            header={{
              columns: [
                { title: "Identifier", flex: 1 },
                { title: "Name", flex: 2 },
                { title: "Portfolio", flex: 2 },
              ],
            }}
            rows={results.map((result) => ({
              id: result.id,
              columns: [
                { title: result.identifier, flex: 1 },
                {
                  content: (
                    <Link
                      to={
                        result.type === "project"
                          ? clientUrl(uris.project.single, { id: result.id })
                          : result.type === "product"
                          ? clientUrl(uris.product.single, { id: result.id })
                          : clientUrl(uris.programme.single, { id: result.id })
                      }
                    >
                      {result.name}
                    </Link>
                  ),
                  flex: 2,
                },
                {
                  content: result.portfolio?.name ? (
                    <Link
                      to={clientUrl(uris.portfolio.single, {
                        id: result.portfolio.id,
                      })}
                    >
                      {result.portfolio.name}
                    </Link>
                  ) : null,
                  flex: 2,
                },
              ],
            }))}
          />
        ) : (
          <Typography>There is no match to your search criteria</Typography>
        )}
      </Section>
    </Page>
  );
};
