import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import { project } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { HtmlReadMoreTypography } from "../../../base/typography/HtmlReadMoreTypography";
import qualityApproachClip from "../../../assets/cliparts/qualityApproach.svg";
import { useToast } from "../../../hooks/useToast";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { DisplayContent } from "../../../base/content/DisplayContent";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";

const ProjectControlsForm = ({ data, projectId, onClose, onSuccess }) => {
  const { t } = useT();
  const toast = useToast();

  const schema = yup.object().shape({
    projectControls: yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      projectControls: data?.projectControls ?? "",
    },
  });

  const { handleSubmit, reset } = formData;

  const updateControls = useMutation(project.updateControls, {
    onSuccess: (data) => {
      toast.success(t("project.plans.approach.successfulUpdate"));
    },
    onError: () => {
      toast.error(t("project.plans.approach.updateFailed"));
    },
  });

  const handleCreateOrUpdateApproach = useMemo(
    () =>
      handleSubmit((formData) => {
        updateControls.mutate(
          {
            projectId,
            controls: formData,
          },
          {
            onSuccess: () => {
              onClose();
              onSuccess();
            },
          },
        );
      }),
    [handleSubmit, projectId, updateControls, data],
  );

  useEffect(() => {
    reset({
      projectControls: data?.projectControls ?? "",
    });
  }, [data, reset]);

  return (
    <FormDataProvider formKey="project.plans.controls.fields" {...formData}>
      <form onSubmit={handleCreateOrUpdateApproach}>
        <Stack spacing={2}>
          <FormRichTextField name="projectControls" />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="outlined" onClick={onClose}>
              {t("generic.button.cancel")}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {t("project.plans.controls.actions.update")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormDataProvider>
  );
};

export const PlansControls = () => {
  const { t } = useT();
  const { id = "" } = useParams();

  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );
  const {
    isLoading: isLoadingControls,
    data: controlsData = {},
    refetch: refetchControls,
  } = useQ(`project-${id}-controls`, () => project.controls({ id }));

  const { title } = data;

  const [displayForm, showForm] = useState(false);

  const controls = controlsData?.projectControls ?? null;

  return (
    <Page isLoading={isLoading || isLoadingControls} title={title}>
      <Section title={t("project.plans.title")}>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Box flex={3}>
            <Typography variant="h2">
              {t("project.plans.controls.title")}
            </Typography>
            <HtmlReadMoreTypography>
              {t("project.plans.controls.description")}
            </HtmlReadMoreTypography>
          </Box>
          <Box flex={1} textAlign="right">
            <img
              src={qualityApproachClip}
              alt={"Project Controls Method Clipart"}
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Stack>
        {displayForm ? (
          <ProjectControlsForm
            data={controls}
            projectId={id}
            onClose={() => showForm(false)}
            onSuccess={() => refetchControls()}
          />
        ) : !!controls ? (
          <Stack spacing={2}>
            <Button color="secondary" onClick={() => showForm(true)}>
              {t("generic.button.edit")}
            </Button>
            <DisplayContent
              area="plans.controls.fields"
              data={controls}
              fields={["projectControls"]}
            />
          </Stack>
        ) : (
          <Paper sx={{ p: 3 }}>
            <Stack spacing={2}>
              <Typography>{t("project.plans.controls.noContent")}</Typography>
              <Button
                onClick={() => showForm((v) => !v)}
                color="secondary"
                endIcon={<ArrowCircleRightOutlinedIcon />}
              >
                {t("project.plans.controls.actions.createNew")}
              </Button>
            </Stack>
          </Paper>
        )}
      </Section>
    </Page>
  );
};
