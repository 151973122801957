import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const Security = () => (
  <Section id="6">
    <Typography variant="h2">6. Security of processing</Typography>
    <ol>
      <li>
        Article 32 GDPR stipulates that, taking into account the state of the
        art, the costs of implementation and the nature, scope, context and
        purposes of processing as well as the risk of varying likelihood and
        severity for the rights and freedoms of natural persons, the data
        controller and data processor shall implement appropriate technical and
        organisational measures to ensure a level of security appropriate to the
        risk.
        <br />
        The data controller shall evaluate the risks to the rights and freedoms
        of natural persons inherent in the processing and implement measures to
        miti-gate those risks. Depending on their relevance, the measures may
        include the following:
        <ol type="a">
          <li>Pseudonymisation and encryption of personal data;</li>

          <li>
            the ability to ensure ongoing confidentiality, integrity,
            availability and re-silience of processing systems and services;
          </li>

          <li>
            the ability to restore the availability and access to personal data
            in a timely manner in the event of a physical or technical incident;
          </li>

          <li>
            a process for regularly testing, assessing and evaluating the
            effective-ness of technical and organisational measures for ensuring
            the security of the processing.
          </li>
        </ol>
      </li>

      <li>
        According to Article 32 GDPR, the data processor shall also -
        independently from the data controller - evaluate the risks to the
        rights and freedoms of natural persons inherent in the processing and
        implement measures to miti-gate those risks. To this effect, the data
        controller shall provide the data pro-cessor with all information
        necessary to identify and evaluate such risks.
      </li>

      <li>
        Furthermore, the data processor shall assist the data controller in
        ensuring compliance with the data controller's obligations pursuant to
        Articles 32 GDPR, by inter alia providing the data controller with
        information concerning the technical and organisational measures already
        implemented by the data processor pursuant to Article 32 GDPR along with
        all other information nec-essary for the data controller to comply with
        the data controller's obligation under Article 32 GDPR.
        <br />
        If subsequently - in the assessment of the data controller - mitigation
        of the identified risks require further measures to be implemented by
        the data pro-cessor, than those already implemented by the data
        processor pursuant to Article 32 GDPR, the data controller shall specify
        these additional measures to be implemented in Appendix C.
      </li>
    </ol>
  </Section>
);
