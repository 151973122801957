import React from 'react';
import { usePrevious } from '../../hooks/usePrevious';
import { useStyleParams } from '../../hooks/useStyleParams';

export const Step = ({ children, stepper, step, ...rest }) => {
  const {className, style} = useStyleParams(rest);

  const isActive = stepper.active === step;
  const activeStep = stepper.active;
  const prevStep = usePrevious(activeStep);


  let animate = '';
  if(prevStep > activeStep) {
    if(isActive) {
      animate = 'fadeInLeft';
    } else {
      animate = 'fadeOutRight';
    }
  }
  if(prevStep < activeStep) {
    if(isActive) {
      animate = 'fadeInRight';
    } else {
      animate = 'fadeOutLeft';
    }
  }


  return (
    <div className={`step step--${isActive ? 'active' : 'inactive'} ${animate} ${className}`} style={style}>
      {children}
    </div>
  )
}
