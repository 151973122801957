import React from "react";
import { useMatch, useParams } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import { clientUrl } from "../../../util/routing";
import { uris } from "../../../config/nav";
import { useQ } from "../../../hooks/useQ";
import { release } from "../../../queries";
import { RiskRegister } from "../../projects/components/RiskRegister";

export const ReleaseRisk = () => {
  const { id = "", riskId } = useParams();
  const { t } = useT();

  const { isLoading, data = {} } = useQ(`release-${id}`, () =>
    release.single({ id }),
  );

  const { title } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.risk.title")}>
        <RiskRegister type="release" releaseId={id} riskId={riskId} />
      </Section>
    </Page>
  );
};
