import { useT } from "@/hooks/useT";
import { useToast } from "@/hooks/useToast";
import { useMutation } from "react-query";
import { programme } from "@/queries";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { Paper, Stack, Typography } from "@mui/material";
import { FormTable } from "@/base/table/FormTable";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { FormTextField } from "../../../base/form/mui/FormTextField";

export const BenefitsForm = ({ programmeData, onUpdate }) => {
  const { t } = useT();
  const toast = useToast();

  const addMeasurableBenefit = useMutation(programme.addMeasurableBenefit, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Measurable benefit added successfully");
      onUpdate?.();
    },
    onError: (error) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(
        `Error adding measurable benefit. ${error?.response?.data?.message}`,
      );
    },
  });

  const editMeasurableBenefit = useMutation(programme.editMeasurableBenefit, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Measurable benefit updated successfully");
      onUpdate?.();
    },
    onError: ({ error }) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(
        `Error updating measurable benefit. ${error?.response?.data?.message}`,
      );
    },
  });

  const deleteMeasurableBenefit = useMutation(
    programme.deleteMeasurableBenefit,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success(
          data?.message ?? "Measurable benefit deleted successfully",
        );
        onUpdate?.();
      },
      onError: ({ error }) => {
        console.error(
          `Received error: ${error?.message ?? "-"} ${
            error?.response?.data?.message ?? "-"
          }`,
        );
        toast.error(
          `Error deleting measurable benefit. ${error?.response?.data?.message}`,
        );
      },
    },
  );

  const schema = yup.object().shape({
    benefits: yup.array().optional(),
    title: yup.string().optional(),
    accountable: yup.string().optional(),
    measurement: yup.string().optional(),
    actions: yup.string().optional(),
    baseline: yup.string().optional(),
    goal: yup.string().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      benefits: programmeData?.measurableBenefits || [],
      title: "",
      accountable: "",
      measurement: "",
      actions: "",
      baseline: "",
      goal: "",
    },
  });

  const { setValue, control } = formData;

  const [benefits] = useWatch({
    control,
    name: ["benefits"],
  });
  const benefitRef = useRef(benefits);
  benefitRef.current = benefits;

  useEffect(() => {
    setValue("benefits", programmeData?.measurableBenefits ?? []);
  }, [programmeData, setValue]);

  return (
    <FormTable
      label={t("programme.design.benefit")}
      addButtonLabel={t("project.businessCase.addEditForm.addEntry")}
      formKey="project.businessCase.addEditForm.fields"
      items={benefits}
      schema={schema}
      formData={formData}
      handleAdd={(data) => {
        addMeasurableBenefit.mutate({
          id: programmeData.id,
          data: {
            measurableBenefit: { id: uuidV4(), ...data },
          },
        });
      }}
      handleEdit={(data) => {
        editMeasurableBenefit.mutate({
          id: programmeData.id,
          data: {
            measurableBenefit: { ...data },
          },
        });
      }}
      handleDelete={(data, index) => {
        deleteMeasurableBenefit.mutate({
          id: programmeData.id,
          measurableBenefitId: data.id,
        });
      }}
      headerColumns={[
        {
          title: "ID",
          flex: 1,
          sx: { marginRight: -2 },
        },
        {
          title: t(
            "project.businessCase.addEditForm.fields.benefits.title.title",
          ),
          flex: 2,
        },
        {
          title: t(
            "project.businessCase.addEditForm.fields.benefits.baseline.title",
          ),
          flex: 3,
        },
        {
          title: t(
            "project.businessCase.addEditForm.fields.benefits.goal.title",
          ),
          flex: 4,
        },
        {
          title: t(
            "project.businessCase.addEditForm.fields.benefits.accountable.title",
          ),
          flex: 5,
        },
      ]}
      rowColumns={(item, index) => [
        {
          content: <Typography sx={{ mr: 1 }}>{index + 1}</Typography>,
          width: "50px",
        },
        {
          content: <Typography>{item.title}</Typography>,
          flex: 2,
          sx: { marginRight: 10 },
        },
        {
          content: <Typography>{item.baseline}</Typography>,
          flex: 3,
        },
        {
          content: <Typography>{item.goal}</Typography>,
          flex: 4,
        },
        {
          content: <Typography>{item.accountable}</Typography>,
          flex: 5,
          sx: { marginLeft: 5 },
        },
      ]}
      addForm={<BenefitForm />}
      details={(item, index) => (
        <BenefitDetails item={item} index={index} t={t} />
      )}
    />
  );
};

export const BenefitDetails = ({ item, index, t }) => {
  return (
    <Stack spacing={2} direction="column">
      <Stack>
        <Typography
          sx={{
            fontSize: "16px !important",
            color: "#111827 !important",
          }}
        >
          {t(`project.businessCase.addEditForm.fields.benefits.title.title`)}
        </Typography>
        <Typography>{item?.title}</Typography>
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: "16px !important",
            color: "#111827 !important",
          }}
        >
          {t(
            `project.businessCase.addEditForm.fields.benefits.accountable.title`,
          )}
        </Typography>
        <Typography>{item?.accountable}</Typography>
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: "16px !important",
            color: "#111827 !important",
          }}
        >
          {t(
            `project.businessCase.addEditForm.fields.benefits.measurement.title`,
          )}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{
            __html: item?.measurement,
          }}
        />
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: "16px !important",
            color: "#111827 !important",
          }}
        >
          {t(`project.businessCase.addEditForm.fields.benefits.actions.title`)}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{
            __html: item?.actions,
          }}
        />
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: "16px !important",
            color: "#111827 !important",
          }}
        >
          {t(`project.businessCase.addEditForm.fields.benefits.baseline.title`)}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{
            __html: item?.baseline,
          }}
        />
      </Stack>
      <Stack>
        <Typography
          sx={{
            fontSize: "16px !important",
            color: "#111827 !important",
          }}
        >
          {t(`project.businessCase.addEditForm.fields.benefits.goal.title`)}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{
            __html: item?.goal,
          }}
        />
      </Stack>
    </Stack>
  );
};

export const BenefitForm = () => {
  return (
    <Stack spacing={3}>
      <Stack spacing={2} direction="row">
        <FormTextField
          name={`title`}
          fieldKey="benefits.title"
          help={false}
          fullWidth
        />
        <FormTextField
          name={`accountable`}
          fieldKey="benefits.accountable"
          fullWidth
        />
      </Stack>
      <FormRichTextField name={`measurement`} fieldKey="benefits.measurement" />
      <FormRichTextField
        name={`actions`}
        fieldKey="benefits.actions"
        fullWidth
      />
      <Stack spacing={2} direction="row">
        <FormTextField
          name={`baseline`}
          fieldKey="benefits.baseline"
          fullWidth
        />
        <FormTextField name={`goal`} fieldKey="benefits.goal" fullWidth />
      </Stack>
    </Stack>
  );
};
