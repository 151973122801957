import React from "react";
import { Paper } from "@mui/material";

export const SummaryCard = ({ children, sx = {}, ...rest }) => {
  return (
    <Paper sx={{ py: 3, px: 6, borderColor: "#e5e7db", flex: 1, ...sx }}>
      {children}
    </Paper>
  );
};
