import * as React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { equals } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';

export const usePersist = (formik, name) => {
  const saveForm = useDebouncedCallback((data) => {
    window.localStorage.setItem(name, JSON.stringify(data))
  }, 1000)


  useEffect(() => {
    const maybeState = window.localStorage.getItem(name);
    if (maybeState) {
      formik.setValues(JSON.parse(maybeState));
    }
  }, []);

  useEffect(() => {
    saveForm(formik.values);
  }, [formik.values]);
};
