import { useFlag as useUnleashFlag } from "@unleash/proxy-client-react";

export const useFlag = (flag, id) => {
  try {
    const isEnabled = useUnleashFlag(flag);
    const isEnabled2 = useUnleashFlag(flag + "." + id);

    return isEnabled || (id && isEnabled2);
  } catch (error) {
    //    console.log('[ERROR] not able to get flag, got',error)
    return false;
  }
};
