import React from "react";
import { useParams } from "react-router-dom";
import { programme } from "../../queries";
import { useQ } from "../../hooks/useQ";
import { RiskApproach } from "../projects/risk/RiskApproach";

export const ProgrammeRiskApproach = () => {
  const { id = "" } = useParams();

  const { isLoading, data = {} } = useQ(`programme-${id}`, () =>
    programme.single({ id }),
  );
  const {
    isLoading: isLoadingApproach,
    data: approachData = {},
    refetch: refetchApproach,
  } = useQ(`programme-${id}-risk-approach`, () =>
    programme.riskApproach({ id }),
  );

  const { title } = data;

  const approach = approachData?.approach;

  return (
    <RiskApproach
      isLoading={isLoading || isLoadingApproach}
      title={title}
      type="programme"
      programmeId={id}
      approach={approach}
      refetch={refetchApproach}
    />
  );
};
