import React, { useEffect, useState } from "react";
import { useT } from "@/hooks/useT";
import { Stat } from "@/base/Stat";
import { statusDef } from "@/util/constants";
import { GaugeChart } from "@/base/charts/GaugeChart";
import { QualityTableForm } from "../forms/QualityTableForm";
import { HtmlReadMoreTypography } from "@/base/typography/HtmlReadMoreTypography";
import { useQ } from "@/hooks/useQ";
import { isEmpty, prop, propOr } from "ramda";
import { stripHtml } from "@/util/stripHtml";
import { project } from "@/queries";
import { QualityTableItemDetailForm } from "../forms/QualityTableItemDetailForm";
import { Stack, Typography } from "@mui/material";

const getLeafNodes = (products) =>
  products.reduce(
    (leafNodes, product) =>
      product.products && product.products.length > 0
        ? [...leafNodes, ...getLeafNodes(product.products)]
        : [...leafNodes, product],
    [],
  );

function getStatistics(products) {
  const leafNodes = getLeafNodes(products);
  return {
    done: leafNodes.filter((item) => item.status === "done").length,
    inProgress: leafNodes.filter((item) => item.status === "inProgress").length,
    overdue: leafNodes.filter((item) => item.status === "overdue").length,
    notStarted: leafNodes.filter(
      (item) => !["done", "inProgress", "overdue"].includes(item.status),
    ).length,
  };
}

const QualityStatusStatistics = ({ statistics }) => {
  const { t } = useT();

  return (
    <Stack sx={{ minWidth: 200 }} spacing={1}>
      {["done", "inProgress", "overdue", "notStarted"].map((item) => (
        <Stack key={item} direction="row" spacing={1} alignItems="center">
          <Stat size={"md"} className={"pd-r-sm"} status={statusDef[item]} />{" "}
          <Typography>
            {t("project.overview.activities." + item)}: {statistics[item]}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
function percentCompleted(statistics) {
  const sum =
    statistics.done +
    statistics.inProgress +
    statistics.overdue +
    statistics.notStarted;
  return ((sum > 0 ? statistics.done / sum : 0) * 100).toFixed(0) / 100;
}

const QualityRegisterHeader = ({ products }) => {
  const { t } = useT();
  const statistics = getStatistics(products);
  return (
    <>
      <Stack direction="row">
        <Stack
          spacing={2}
          alignItems="flex-start"
          justifyContent="flex-start"
          sx={{ minWidth: 450 }}
        >
          <Typography variant="h2">
            {t("project.quality.register.title")}
          </Typography>
          <HtmlReadMoreTypography>
            {t("project.quality.register.description")}
          </HtmlReadMoreTypography>
        </Stack>
        <GaugeChart
          data={{
            percent: percentCompleted(statistics),
          }}
        />
        <QualityStatusStatistics statistics={statistics} />
      </Stack>
    </>
  );
};

const transformApiData = (data) => {
  return data.map((item) => {
    return {
      ...item,
      id: prop("_id", item) ?? prop("id", item),
      ident: prop("id", item),
      status:
        item.products && item.products.length > 0
          ? percentCompleted(getStatistics([item])) * 100
          : propOr("notStarted", "status", item),

      children: transformApiData(item.products || item.outputs || []),
      producer: stripHtml(propOr("", "producer", item)),
      approver: stripHtml(propOr("", "approver", item)),
      child: (data, config, controls) => (
        <QualityTableItemDetailForm data={data} controls={controls} />
      ),
    };
  });
};

export const QualityRegister = ({ data: projectData }) => {
  const { t } = useT();
  const { id } = projectData;

  const {
    isLoading,
    data = [],
    refetch,
  } = useQ(`project-${id}-products`, () => project.products({ id }));
  const [products, setProducts] = useState([]);

  useEffect(() => {
    console.log("[EFFECT] Loaded data on " + id, data);
    if (data) {
      const transformed = transformApiData(data);
      setProducts(transformed);
    }
  }, [data]);

  const onSort = (ordering) => {
    setProducts((data) => simpleDataTableSort(data, ordering));
  };

  return (
    <>
      <QualityRegisterHeader products={products} />
      <QualityTableForm
        id={id}
        products={products}
        onSort={onSort}
        onUpdate={() => refetch()}
        scopes={projectData.projectScope}
      />
    </>
  );
};
