import React from "react";
import { isNil, propOr } from "ramda";
import moment from "moment";
import { DatePretty } from "../DatePretty";
import TippyHeadless from "@tippyjs/react/headless";

const LabelToday = ({ children, labelPos = "top", label }) => {
  return (
    <TippyHeadless
      placement={labelPos}
      render={(attrs) => {
        return (
          <div {...attrs}>
            {label}
            <DatePretty format={"ll"} date={new Date()} />
          </div>
        );
      }}
      visible={true}
    >
      {children}
    </TippyHeadless>
  );
};

const Delimiter = ({ isToday, className, labelPos }) => {
  if (isToday) {
    return (
      <LabelToday labelPos={labelPos}>
        <div className={`delimiter ${className} delimiter--is-today`}></div>
      </LabelToday>
    );
  }

  return <div className={`delimiter ${className}`}></div>;
};

export const TimelineChart = ({ data, labels }) => {
  const {
    startDate = new Date(),
    endDate,
    toleranceDate,
    // -- optional declarations
    length,
    tolerance = 0,
  } = data;
  // Convention is that Uppercased VariableNames are verified or calculated and can be w/o checks.

  const Today = moment(new Date());
  const Start = 0;
  const StartDate = moment(startDate);
  const End = isNil(endDate) ? length : moment(endDate).diff(StartDate, "days");
  const EndDate = moment(StartDate).add(End, "days");
  const Tolerance = Math.max(
    isNil(toleranceDate)
      ? tolerance
      : moment(toleranceDate).diff(EndDate, "days"),
    0,
  );
  const ToleranceEndDate = moment(EndDate).add(Tolerance, "days");
  const Overdue = Math.max(Today.diff(ToleranceEndDate, "days"), 0);
  const SinceStart = Math.max(Today.diff(StartDate, "days"), 0);
  const SinceTolerance = Math.max(Today.diff(EndDate, "days"), 0);

  const hasTolerance = Tolerance > 0;

  const isFuture = Today.isBefore(StartDate, "days");
  const isOverdue = Today.isAfter(ToleranceEndDate, "days");

  const isStartDate = Today.isSame(StartDate, "day");
  const isEndDate = Today.isSame(EndDate, "day");
  const isToleranceEndDate = Today.isSame(ToleranceEndDate, "day");

  const todayInRange = Today.isBetween(StartDate, EndDate, "days", "()");
  const todayInTolerance = Today.isBetween(
    EndDate,
    moment(EndDate).add(Tolerance, "days"),
    "days",
    "()",
  );

  console.log({ Start, End, Tolerance, Overdue });

  return (
    <>
      <div className={`timeline-chart`}>
        <div className={"chart"}>
          {isFuture && (
            <div className={"future-indicator"}>
              <LabelToday labelPos={"top-start"}>
                <div className={"today"}></div>
              </LabelToday>
            </div>
          )}

          <Delimiter
            isToday={isStartDate}
            labelPos={"top-start"}
            className={"delimiter__start"}
          />

          <div style={{ flex: End }} className={`timeline timeline__planned`}>
            {todayInRange && (
              <LabelToday>
                <div
                  style={{ marginLeft: `${(SinceStart / End) * 100}%` }}
                  className={"today"}
                ></div>
              </LabelToday>
            )}
          </div>

          <Delimiter
            isToday={isEndDate}
            labelPos={isEndDate && !hasTolerance ? "top-end" : "top"}
            className={"delimiter__end"}
          />

          {hasTolerance && (
            <>
              <div
                style={{ flex: Tolerance }}
                className={`timeline timeline__tolerance`}
              >
                {todayInTolerance && (
                  <LabelToday>
                    <div
                      style={{ marginLeft: `${(SinceTolerance / End) * 100}%` }}
                      className={"today"}
                    ></div>
                  </LabelToday>
                )}
              </div>
              <Delimiter
                labelPos={isToleranceEndDate ? "top-end" : "top"}
                isToday={isToleranceEndDate}
                className={"delimiter__tolerance"}
              />
            </>
          )}
          {isOverdue && (
            <div
              style={{ flex: Overdue }}
              className={`timeline timeline__overdue`}
            >
              <LabelToday labelPos={"top-end"}>
                <div className={`today`}></div>
              </LabelToday>
            </div>
          )}
        </div>
        <div className={"labels"}>
          {isFuture && <div className={"label label__future-indicator"}></div>}
          <div style={{ flex: End }} className={"label label__end"}>
            <div className={`left ${isStartDate ? "txt-b" : ""}`}>
              {propOr("", "start", labels)}
              <DatePretty format={"ll"} date={StartDate} />
            </div>
            <div className={`right ${isEndDate ? "txt-b" : ""}`}>
              {propOr("", "end", labels)}
              <DatePretty format={"ll"} date={EndDate} />
            </div>
          </div>
          {hasTolerance && (
            <div
              style={{ flex: Tolerance }}
              className={"label label__tolerance"}
            >
              <div className={`${isToleranceEndDate ? "txt-b" : ""}`}>
                {propOr("", "tolerance", labels)}
                <DatePretty format={"ll"} date={ToleranceEndDate} />
              </div>
            </div>
          )}
          {isOverdue && (
            <div className={"label label__overdue"} style={{ flex: Overdue }}>
              <div className={"txt-b"}>
                {propOr("", "overdue", labels)}
                <DatePretty format={"ll"} date={Today} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

/** Debugger
 {debug && <span style={{backgroundColor: '#ccc', marginTop: '50px', display: 'block'}}>
        TodayInRange: {todayInRange.toString()} <br/>
        Start: {Start} <br/>
        End: {End} <br/>
        Tolerance: {Tolerance} <br/>
        OverdueDays: {Overdue} <br/>
        Overdue: {isOverdue.toString()} <br/>
        </span>}

 **/
