import React, { useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import { programme, project } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { HtmlReadMoreTypography } from "../../../base/typography/HtmlReadMoreTypography";
import qualityApproachClip from "../../../assets/cliparts/qualityApproach.svg";
import { useToast } from "../../../hooks/useToast";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { DisplayContent } from "../../../base/content/DisplayContent";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";

const ChangeApproachForm = ({ data, id, isProject, onClose, onSuccess }) => {
  const { t } = useT();
  const toast = useToast();

  const schema = yup.object().shape({
    process: yup.string().trim(),
    reporting: yup.string().trim(),
    roles: yup.string().trim(),
    scales: yup.string().trim(),
    timing: yup.string().trim(),
    tools: yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      process: data?.process ?? "",
      reporting: data?.reporting ?? "",
      roles: data?.roles ?? "",
      scales: data?.scales ?? "",
      timing: data?.timing ?? "",
      tools: data?.tools ?? "",
    },
  });

  const { handleSubmit, reset } = formData;

  useEffect(() => {
    reset({
      process: data?.process ?? "",
      reporting: data?.reporting ?? "",
      roles: data?.roles ?? "",
      scales: data?.scales ?? "",
      timing: data?.timing ?? "",
      tools: data?.tools ?? "",
    });
  }, [data, reset]);

  const updateApproach = useMutation(
    isProject ? project.updateChangeApproach : programme.updateChangeApproach,
    {
      onSuccess: (data) => {
        toast.success(t("project.change.approach.successfulUpdate"));
      },
      onError: () => {
        toast.error(t("project.change.approach.updateFailed"));
      },
    },
  );

  const handleCreateOrUpdateApproach = useMemo(
    () =>
      handleSubmit((formData) => {
        updateApproach.mutate(
          {
            projectId: id,
            programmeId: id,
            approach: formData,
          },
          {
            onSuccess: () => {
              onClose();
              onSuccess();
            },
          },
        );
      }),
    [handleSubmit, id, updateApproach, data],
  );

  return (
    <FormDataProvider formKey="project.change.approach.fields" {...formData}>
      <form onSubmit={handleCreateOrUpdateApproach}>
        <Stack spacing={2}>
          {["process", "tools", "reporting", "timing", "roles", "scales"].map(
            (fieldName) => (
              <FormRichTextField name={fieldName} />
            ),
          )}
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="outlined" onClick={onClose}>
              {t("project.change.approach.actions.cancel")}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {t(
                !data
                  ? "project.change.approach.actions.createNew"
                  : "project.change.approach.actions.update",
              )}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormDataProvider>
  );
};

export const ChangeApproach = () => {
  const { t } = useT();
  const { id = "" } = useParams();
  const location = useLocation();
  const isProject = location.pathname.includes("/projects/");

  const { isLoading, data: projectData = {} } = useQ(
    `project-${id}`,
    () => project.single({ id }),
    { enabled: isProject },
  );
  const {
    isLoading: isLoadingApproach,
    data: projectApproachData = {},
    refetch: refetchApproach,
  } = useQ(
    `project-${id}-change-approach`,
    () => project.changeApproach({ id }),
    { enabled: isProject },
  );
  const { isLoading: isLoadingProgramme, data: programmeData = {} } = useQ(
    `project-${id}`,
    () => programme.single({ id }),
    { enabled: !isProject },
  );
  const {
    isLoading: isLoadingApproachProgramme,
    data: programmeApproachData = {},
    refetch: refetchApproachProgramme,
  } = useQ(
    `project-${id}-change-approach`,
    () => programme.changeApproach({ id }),
    { enabled: !isProject },
  );

  const { title } = projectData;

  const [displayForm, showForm] = useState(false);

  const approach = isProject
    ? projectApproachData?.approach
    : programmeApproachData?.approach;

  return (
    <Page isLoading={isLoading || isLoadingApproach} title={title}>
      <Section title={t("project.change.title")}>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Box flex={3}>
            <Typography variant="h2">
              {t("project.change.approach.title")}
            </Typography>
            <Typography
              dangerouslySetInnerHTML={{
                __html: t("project.change.approach.description"),
              }}
            />
          </Box>
          <Box flex={1} textAlign="right">
            <img
              src={qualityApproachClip}
              alt={"Project Approach Method Clipart"}
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Stack>
        {displayForm ? (
          <ChangeApproachForm
            data={approach}
            id={id}
            isProject={isProject}
            onClose={() => showForm(false)}
            onSuccess={() =>
              isProject ? refetchApproach() : refetchApproachProgramme()
            }
          />
        ) : !!approach ? (
          <Stack spacing={2}>
            <Button color="secondary" onClick={() => showForm(true)}>
              {t("generic.button.edit")}
            </Button>
            <DisplayContent
              area="change.approach.fields"
              data={approach ?? {}}
              fields={[
                "process",
                "reporting",
                "roles",
                "scales",
                "timing",
                "tools",
              ]}
            />
          </Stack>
        ) : (
          <Paper sx={{ p: 3 }}>
            <Stack spacing={2}>
              <Typography>{t("project.change.approach.noContent")}</Typography>
              <Button
                onClick={() => showForm((v) => !v)}
                color="secondary"
                endIcon={<ArrowCircleRightOutlinedIcon />}
              >
                {t("project.change.approach.actions.createNew")}
              </Button>
            </Stack>
          </Paper>
        )}
      </Section>
    </Page>
  );
};
