import React from "react";
import { Stack } from "@mui/material";

export const BadgeGroup = ({ children }) => {
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: "#f9fafb",
        borderRadius: "20px",
        p: "4px",
      }}
    >
      {children}
    </Stack>
  );
};
