import { BlockChart } from "@/base/charts/BlockChart";
import { Divider, Link, Popover, Stack, Typography } from "@mui/material";
import React, { Ref, useCallback, useRef, useState } from "react";
import { useT } from "@/hooks/useT";
import { useNavigate } from "react-router-dom";
import { clientUrl } from "@/util/routing";
import { uris } from "@/config/nav";

type RiskThreatsBlockChartProps = {
  id: string;
  type: string;
  threats: any[];
};

export const RiskThreatsBlockChart = ({
  id,
  type,
  threats,
}: RiskThreatsBlockChartProps) => {
  const { t } = useT();
  const navigate = useNavigate();

  const [blockChartPopoverOpen, setBlockChartPopoverOpen] = useState(false);
  const [blockChartItems, setBlockChartItems] = useState<any[]>([]);
  const anchorEl = useRef<HTMLElement | null>(null);

  const handleBlockSelected = useCallback(
    (index: number, ref: HTMLElement, cellItems: any[]) => {
      anchorEl.current = ref;
      setBlockChartItems(cellItems);
      setBlockChartPopoverOpen(true);
    },
    [],
  );

  const handleOpenRisk = useCallback(
    (riskId: string) => {
      navigate(
        // @ts-ignore
        clientUrl(uris[type].riskDetails, {
          id: id,
          riskId,
        }),
      );
    },
    [navigate, id],
  );

  return (
    <>
      <BlockChart
        data={threats}
        yLabel={t("project.risksAndIssues.probability")}
        xLabel={t("project.risksAndIssues.impact")}
        onBlockSelected={handleBlockSelected}
        hideTooltips
      />
      <Popover
        onClose={() => {
          setBlockChartPopoverOpen(false);
          anchorEl.current = null;
        }}
        open={blockChartPopoverOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack
          sx={{
            p: 1,
          }}
        >
          {blockChartItems.map((risk) => (
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                key={risk.no}
              >
                <Link
                  onClick={() => {
                    setBlockChartPopoverOpen(false);
                    anchorEl.current = null;
                    handleOpenRisk(risk.id);
                  }}
                  variant="body2"
                >
                  {risk.no}
                </Link>
                <Typography variant="body2">{`${risk.title} ${
                  risk.proximity && "(" + risk.proximity + ")"
                }`}</Typography>
              </Stack>
              <Divider />
            </Stack>
          ))}
        </Stack>
      </Popover>
    </>
  );
};
