import React from "react";
import { useParams, useMatch, useNavigate } from "react-router-dom";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { useT } from "@/hooks/useT";
import { project } from "@/queries";
import { QualityManagementApproach } from "./components/QualityManagementApproach";
import { QualityRegister } from "./components/QualityRegister";
import { useQ } from "@/hooks/useQ";
import { clientUrl } from "@/util/routing";
import { uris } from "@/config/nav";

const QualityContent = ({ data }) => {
  const { t } = useT();
  const id = data.id;

  const isQuality = useMatch(clientUrl(uris.project.quality, { id }));
  const isQualityOutputDetails = useMatch(
    clientUrl(uris.project.qualityOutputDetails, { id, outputId: "*" }),
  );
  const isQualityProductDescription = useMatch(
    clientUrl(uris.project.quality + "/productDescription", { id }),
  );
  const isQualityManagementApproach = useMatch(
    clientUrl(uris.project.quality + "/approach", { id }),
  );

  return (
    <>
      <Section title={t("project.quality.title")}>
        {(isQuality || isQualityOutputDetails) && (
          <QualityRegister data={data} />
        )}
        {isQualityProductDescription && (
          <Section>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam
            delectus earum eius fugiat numquam odit perferendis perspiciatis
            quae quam! Aperiam consequuntur doloribus illo nihil placeat
            provident quidem voluptatem! Dicta, velit.
          </Section>
        )}
        {isQualityManagementApproach && (
          <QualityManagementApproach projectId={data.id} />
        )}
      </Section>
    </>
  );
};

export const Quality = () => {
  const { id = "" } = useParams();

  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );

  const { title } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <QualityContent data={data} />
    </Page>
  );
};
