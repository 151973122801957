import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const Assistance = () => {
  const competentSA = "Datatilsynet"; //[PLEASE INDICATE THE COMPETENT SA]
  return (
    <Section id="9">
      <Typography variant="h2">
        {" "}
        9. Assistance to the data controller
      </Typography>
      <ol>
        <li>
          Taking into account the nature of the processing, the data processor
          shall assist the data controller by appropriate technical and
          organisational measures, insofar as this is possible, in the
          fulfilment of the data controller's obligations to respond to requests
          for exercising the data subject's rights laid down in Chapter III
          GDPR.
          <br />
          This entails that the data processor shall, insofar as this is
          possible, assist the data controller in the data controller's
          compliance with:
          <ol type="a">
            <li>
              the right to be informed when collecting personal data from the
              data subject
            </li>
            <li>
              the right to be informed when personal data have not been obtained
              from the data subject
            </li>
            <li>the right of access by the data subject</li>
            <li>the right to rectification</li>
            <li>the right to erasure (‘the right to be forgotten')</li>
            <li>the right to restriction of processing</li>
            <li>
              notification obligation regarding rectification or erasure of
              personal data or restriction of processing
            </li>
            <li>the right to data portability</li>
            <li>the right to object </li>
            <li>
              the right not to be subject to a decision based solely on
              automated processing, including profiling
            </li>
          </ol>
        </li>

        <li>
          In addition to the data processor's obligation to assist the data
          controller pursuant to Clause 6.3., the data processor shall
          furthermore, taking into ac-count the nature of the processing and the
          information available to the data processor, assist the data
          controller in ensuring compliance with:
          <ol type="a">
            <li>
              The data controller's obligation to without undue delay and, where
              feasible, not later than 72 hours after having become aware of it,
              no-tify the personal data breach to the competent supervisory
              authority, "{competentSA}", unless the personal da-ta breach is
              unlikely to result in a risk to the rights and freedoms of natural
              persons;
            </li>

            <li>
              the data controller's obligation to without undue delay
              communicate the personal data breach to the data subject, when the
              personal da-ta breach is likely to result in a high risk to the
              rights and freedoms of natural persons;
            </li>

            <li>
              the data controller's obligation to carry out an assessment of the
              im-pact of the envisaged processing operations on the protection
              of personal data (a data protection impact assessment);
            </li>

            <li>
              the data controller's obligation to consult the competent
              supervisory authority, "{competentSA}", prior to pro-cessing where
              a data protection impact assessment indicates that the processing
              would result in a high risk in the absence of measures taken by
              the data controller to mitigate the risk.
            </li>
          </ol>
        </li>

        <li>
          The parties shall define in Appendix C the appropriate technical and
          organi-sational measures by which the data processor is required to
          assist the data controller as well as the scope and the extent of the
          assistance required. This applies to the obligations foreseen in
          Clause 9.1. and 9.2.
        </li>
      </ol>
    </Section>
  );
};
