import React from 'react';


export const F = ({onSubmit, children, className, style}) => {
  return (
    <form onSubmit={onSubmit} className={className} style={style}>
      {children}
    </form>
  )
};
