import { useContext } from 'react';
import { isNil } from 'ramda';
import { ScreenSizeContext } from '../ScreenSizeProvider';

export const useScreen = () => {

  const {screenSize, override} = useContext(ScreenSizeContext);

  return !isNil(override) ? override : screenSize;
};
