import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const Preamble = () => {
  const nameOfService = "Project Link";
  return (
    <Section id="2">
      <Typography variant="h2">2. Preamble</Typography>
      <ol>
        <li>
          These Contractual Clauses (the Clauses) set out the rights and
          obligations of the data controller and the data processor, when
          processing personal da-ta on behalf of the data controller.
        </li>
        <li>
          The Clauses have been designed to ensure the parties' compliance with
          Ar-ticle 28(3) of Regulation 2016/679 of the European Parliament and
          of the Council of 27 April 2016 on the protection of natural persons
          with regard to the processing of personal data and on the free
          movement of such data and repealing Directive 95/46/EC (General Data
          Protection Regulation).
        </li>

        <li>
          In the context of the provision of "{nameOfService}", the data
          processor will process personal data on behalf of the data controller
          in accordance with the Clauses.
        </li>

        <li>
          The Clauses shall take priority over any similar provisions contained
          in other agreements between the parties.
        </li>

        <li>
          Four appendices are attached to the Clauses and form an integral part
          of the Clauses.
        </li>

        <li>
          Appendix A contains details about the processing of personal data,
          includ-ing the purpose and nature of the processing, type of personal
          data, cate-gories of data subject and duration of the processing.
        </li>

        <li>
          Appendix B contains the data controller's conditions for the data
          processor's use of sub-processors and a list of sub-processors
          authorised by the data controller.
        </li>

        <li>
          Appendix C contains the data controller's instructions with regards to
          the processing of personal data, the minimum security measures to be
          imple-mented by the data processor and how audits of the data
          processor and any sub-processors are to be performed.
        </li>

        <li>
          Appendix D contains provisions for other activities which are not
          covered by the Clauses.{" "}
        </li>

        <li>
          The Clauses along with appendices shall be retained in writing,
          including electronically, by both parties.
        </li>

        <li>
          The Clauses shall not exempt the data processor from obligations to
          which the data processor is subject pursuant to the General Data
          Protection Regu-lation (the GDPR) or other legislation.
        </li>
      </ol>
    </Section>
  );
};
