
import React from 'react';

export const Profile = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.75 9.3125C8.75 9.68184 8.82275 10.0476 8.96409 10.3888C9.10543 10.73 9.3126 11.0401 9.57376 11.3012C9.83493 11.5624 10.145 11.7696 10.4862 11.9109C10.8274 12.0523 11.1932 12.125 11.5625 12.125C11.9318 12.125 12.2976 12.0523 12.6388 11.9109C12.98 11.7696 13.2901 11.5624 13.5512 11.3012C13.8124 11.0401 14.0196 10.73 14.1609 10.3888C14.3023 10.0476 14.375 9.68184 14.375 9.3125C14.375 8.94316 14.3023 8.57743 14.1609 8.2362C14.0196 7.89497 13.8124 7.58493 13.5512 7.32376C13.2901 7.0626 12.98 6.85543 12.6388 6.71409C12.2976 6.57275 11.9318 6.5 11.5625 6.5C11.1932 6.5 10.8274 6.57275 10.4862 6.71409C10.145 6.85543 9.83493 7.0626 9.57376 7.32376C9.3126 7.58493 9.10543 7.89497 8.96409 8.2362C8.82275 8.57743 8.75 8.94316 8.75 9.3125V9.3125Z" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.5 3.6875C15.5 4.13505 15.6778 4.56427 15.9943 4.88074C16.3107 5.19721 16.7399 5.375 17.1875 5.375C17.6351 5.375 18.0643 5.19721 18.3807 4.88074C18.6972 4.56427 18.875 4.13505 18.875 3.6875C18.875 3.23995 18.6972 2.81072 18.3807 2.49426C18.0643 2.17779 17.6351 2 17.1875 2C16.7399 2 16.3107 2.17779 15.9943 2.49426C15.6778 2.81072 15.5 3.23995 15.5 3.6875V3.6875Z" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.5 13.25C15.5 13.6976 15.6778 14.1268 15.9943 14.4432C16.3107 14.7597 16.7399 14.9375 17.1875 14.9375C17.6351 14.9375 18.0643 14.7597 18.3807 14.4432C18.6972 14.1268 18.875 13.6976 18.875 13.25C18.875 12.8024 18.6972 12.3732 18.3807 12.0568C18.0643 11.7403 17.6351 11.5625 17.1875 11.5625C16.7399 11.5625 16.3107 11.7403 15.9943 12.0568C15.6778 12.3732 15.5 12.8024 15.5 13.25V13.25Z" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.875 17.1875C9.875 17.6351 10.0528 18.0643 10.3693 18.3807C10.6857 18.6972 11.1149 18.875 11.5625 18.875C12.0101 18.875 12.4393 18.6972 12.7557 18.3807C13.0722 18.0643 13.25 17.6351 13.25 17.1875C13.25 16.7399 13.0722 16.3107 12.7557 15.9943C12.4393 15.6778 12.0101 15.5 11.5625 15.5C11.1149 15.5 10.6857 15.6778 10.3693 15.9943C10.0528 16.3107 9.875 16.7399 9.875 17.1875Z" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2 17.1875C2 17.4091 2.04365 17.6285 2.12845 17.8333C2.21326 18.038 2.33756 18.224 2.49426 18.3807C2.65096 18.5374 2.83698 18.6617 3.04172 18.7465C3.24646 18.8314 3.46589 18.875 3.6875 18.875C3.90911 18.875 4.12854 18.8314 4.33328 18.7465C4.53802 18.6617 4.72404 18.5374 4.88074 18.3807C5.03744 18.224 5.16174 18.038 5.24655 17.8333C5.33135 17.6285 5.375 17.4091 5.375 17.1875C5.375 16.9659 5.33135 16.7465 5.24655 16.5417C5.16174 16.337 5.03744 16.151 4.88074 15.9943C4.72404 15.8376 4.53802 15.7133 4.33328 15.6285C4.12854 15.5436 3.90911 15.5 3.6875 15.5C3.46589 15.5 3.24646 15.5436 3.04172 15.6285C2.83698 15.7133 2.65096 15.8376 2.49426 15.9943C2.33756 16.151 2.21326 16.337 2.12845 16.5417C2.04365 16.7465 2 16.9659 2 17.1875V17.1875Z" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.5625 5.375C2.5625 5.82255 2.74029 6.25178 3.05676 6.56824C3.37322 6.88471 3.80245 7.0625 4.25 7.0625C4.69755 7.0625 5.12677 6.88471 5.44324 6.56824C5.75971 6.25178 5.9375 5.82255 5.9375 5.375C5.9375 4.92745 5.75971 4.49823 5.44324 4.18176C5.12677 3.86529 4.69755 3.6875 4.25 3.6875C3.80245 3.6875 3.37322 3.86529 3.05676 4.18176C2.74029 4.49823 2.5625 4.92745 2.5625 5.375V5.375Z" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.5508 7.32513L15.9958 4.88013" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.0332 10.658L15.747 12.3717" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.5625 12.125V15.5" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.57661 11.2992L4.88086 15.9949" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.93778 8.30084L5.64453 6.32458" stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

