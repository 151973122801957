import { Section } from "@/layout/Section";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useQ } from "@/hooks/useQ";
import { users } from "@/queries";

const Signature = () => {
  const { isLoading, data } = useQ(`users-me`, users.me);
  return (
    <Stack spacing={3}>
      <Typography>On behalf of the data controller</Typography>
      <Typography>
        Name {data?.name}
        <br />
        Position Administrator
        <br />
        Date {new Date().toISOString().slice(0, 10)}
        <br />
        Signature (sign)
      </Typography>
      <Typography>On behalf of the data processor</Typography>
      <Typography>
        Name Olav Loen
        <br /> Position CEO
        <br /> Date {new Date().toISOString().slice(0, 10)}
        <br />
        Signature (sign)
      </Typography>
    </Stack>
  );
};

export const Commencement = () => (
  <Section id="14">
    <Typography variant="h2">14. Commencement and termination</Typography>
    <ol>
      <li>
        The Clauses shall become effective on the date of both parties'
        signature.
      </li>

      <li>
        Both parties shall be entitled to require the Clauses renegotiated if
        changes to the law or inexpediency of the Clauses should give rise to
        such renegotia-tion.
      </li>

      <li>
        The Clauses shall apply for the duration of the provision of personal
        data processing services. For the duration of the provision of personal
        data pro-cessing services, the Clauses cannot be terminated unless other
        Clauses governing the provision of personal data processing services
        have been agreed between the parties.
      </li>

      <li>
        If the provision of personal data processing services is terminated, and
        the personal data is deleted or returned to the data controller pursuant
        to Clause 11.1. and Appendix C.4., the Clauses may be terminated by
        written notice by either party.
      </li>

      <li>
        Signature
        <Signature />
      </li>
    </ol>
  </Section>
);
