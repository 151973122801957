import { useMediaQuery } from 'react-responsive'

export const useDetermineScreen = () => {

  const isLg = useMediaQuery({query: '(min-width: 1920px)'});
  const isMd = useMediaQuery({query: '(min-width: 1440px) and (max-width: 1919px)'});
  const isNm = useMediaQuery({query: '(min-width: 1024px) and (max-width: 1439px)'});
  const isSm = useMediaQuery({query: '(max-width: 1023px)'});

  return `${isLg ? 'lg' : ''}${isMd ? 'md' : ''}${isNm ? 'nm' : ''}${isSm ? 'sm' : ''}`
};
