import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const Confidentiality = () => (
  <Section id="5">
    <Typography variant="h2">5. Confidentiality</Typography>
    <ol>
      <li>
        The data processor shall only grant access to the personal data being
        processed on behalf of the data controller to persons under the data
        processor's authority who have committed themselves to confidentiality
        or are under an appropriate statutory obligation of confidentiality and
        only on a need to know basis. The list of persons to whom access has
        been granted shall be kept under periodic review. On the basis of this
        review, such access to per-sonal data can be withdrawn, if access is no
        longer necessary, and personal data shall consequently not be accessible
        anymore to those persons.
      </li>
      <li>
        The data processor shall at the request of the data controller
        demonstrate that the concerned persons under the data processor's
        authority are subject to the abovementioned confidentiality.
      </li>
    </ol>
  </Section>
);
