import React from 'react';

export const Plus = ({ className, style, onClick }) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.90625 6.34375V13.4688" stroke="#111827" strokeWidth="1.1875"
            strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.34375 9.90625H13.4688" stroke="#111827" strokeWidth="1.1875"
            strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M1 9.90625C1 12.2683 1.93833 14.5337 3.60858 16.2039C5.27883 17.8742 7.54417 18.8125 9.90625 18.8125C12.2683 18.8125 14.5337 17.8742 16.2039 16.2039C17.8742 14.5337 18.8125 12.2683 18.8125 9.90625C18.8125 7.54417 17.8742 5.27883 16.2039 3.60858C14.5337 1.93833 12.2683 1 9.90625 1C7.54417 1 5.27883 1.93833 3.60858 3.60858C1.93833 5.27883 1 7.54417 1 9.90625V9.90625Z"
        stroke="#111827" strokeWidth="1.1875" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

