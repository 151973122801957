import React from "react";
import { home } from "../../queries";
import { Spinner } from "../../base/Spinner";
import { StartOutParagraphs } from "./StartOutParagraphs";
import { Favs } from "./Favs";
import { isEmpty } from "ramda";
import { useQ } from "../../hooks/useQ";

export const Home = () => {
  const { isLoading, data } = useQ(`favorites`, () => home.favorites());

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmpty(data)) {
    return <StartOutParagraphs />;
  }

  return <Favs />;
};
