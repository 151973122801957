import React from "react";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import { project } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import { isNil, isEmpty } from "ramda";
import { DisplayContent } from "../../../base/content/DisplayContent";
import { SummaryCard } from "../../../base/project/SummaryCard";
import { DatePretty } from "../../../base/DatePretty";

export const CheckpointReport = ({ projectId, reportId }) => {
  const { t } = useT();
  console.log("[INFO] Project: " + projectId);
  const { data: reportData = [] } = useQ(
    `project-${projectId}-report-checkpoints`,
    () => project.report.checkpoints({ id: projectId }),
  );
  const report = reportData.filter(
    (item, index) =>
      item.id === reportId ||
      isNil(reportId) ||
      (isEmpty(reportId) && index === 0),
  )[0];
  console.log(
    "Reports: " +
      reportData.map((item) => item.id).join(", ") +
      ": " +
      JSON.stringify(report) +
      " from " +
      reportId,
  );
  return report !== undefined ? (
    <Section
      title={
        <>
          {t("project.progress.checkpointReport.title")}{" "}
          {report.isDraft ? "- Draft" : ""}{" "}
          <DatePretty format={"YYYY-MM-DD"} date={report.to || report.date} />
        </>
      }
    >
      <DisplayContent
        area="progress.checkpointReport.fields"
        data={report}
        fields={[
          "followUps",
          "thisPeriod",
          "nextPeriod",
          "workPackage",
          "issues",
        ]}
        titleAdditions={{
          thisPeriod: (
            <span>
              {" "}
              ({t("project.progress.report.from")}{" "}
              <DatePretty format={"YYYY-MM-DD"} date={report.from} />)
            </span>
          ),
        }}
      />
    </Section>
  ) : (
    <Section>
      <SummaryCard className={"pd-lg"}>
        No report exists. Reports are written on...
      </SummaryCard>
    </Section>
  );
};
