import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const Audit = () => (
  <Section id="12">
    <Typography variant="h2">12. Audit and inspection</Typography>
    <ol>
      <li>
        The data processor shall make available to the data controller all
        information necessary to demonstrate compliance with the obligations
        laid down in Arti-cle 28 and the Clauses and allow for and contribute to
        audits, including in-spections, conducted by the data controller or
        another auditor mandated by the data controller.
      </li>

      <li>
        Procedures applicable to the data controller's audits, including
        inspections, of the data processor and sub-processors are specified in
        appendices C.7. and C.8.
      </li>

      <li>
        The data processor shall be required to provide the supervisory
        authorities, which pursuant to applicable legislation have access to the
        data controller's and data processor's facilities, or representatives
        acting on behalf of such supervisory authorities, with access to the
        data processor's physical facilities on presentation of appropriate
        identification.
      </li>
    </ol>
  </Section>
);
