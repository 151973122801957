import React, { useEffect, useMemo } from "react";
import { useT } from "../../hooks/useT";
import { Page } from "../../layout/Page";
import { useParams } from "react-router-dom";
import { product } from "../../queries";
import { useQ } from "../../hooks/useQ";
import { Section } from "../../layout/Section";
import { Stack, Button } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRichTextField } from "../../base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { useToast } from "../../hooks/useToast";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";

export const ProductDefinitionForm = () => {
  const { t } = useT();
  const { id = "" } = useParams();
  const {
    isLoading,
    data: productData,
    refetch: refetchProduct,
  } = useQ(`product-${id}`, () => product.single({ id }));

  const schema = yup.object().shape({
    description: yup.string().trim().optional(),
    background: yup.string().trim().optional(),
    bigPicture: yup.string().trim().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: productData?.description ?? "",
      background: productData?.background ?? "",
      vision: productData?.vision ?? "",
    },
  });

  const { handleSubmit, reset } = formData;

  useEffect(() => {
    reset({
      description: productData?.description ?? "",
      background: productData?.background ?? "",
      vision: productData?.vision ?? "",
    });
  }, [reset, productData]);

  const toast = useToast();

  const updateProductDefinition = useMutation(product.updateDefinition, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Product definiton updated successfully");
      refetchProduct();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating product definition");
    },
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        updateProductDefinition.mutate({
          id,
          data,
        });
      }),
    [handleSubmit, id],
  );

  return (
    <Page isLoading={isLoading} title={t("product.editDefinition")}>
      <Section title={t("product.editDefinition") + ": " + productData?.name}>
        <FormDataProvider {...formData} formKey="product.addEditForm.fields">
          <form onSubmit={onSubmit}>
            <Stack spacing={3}>
              <FormRichTextField name="description" />
              <FormRichTextField name="background" />
              <FormRichTextField name="vision" />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ width: "100%", mt: 2 }}
            >
              <Button type="submit" variant="contained">
                Update definition
              </Button>
            </Stack>
          </form>
        </FormDataProvider>
      </Section>
    </Page>
  );
};
