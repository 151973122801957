import { prop } from 'ramda';
import React from 'react';
import { Cell, Pie, PieChart as PC, ResponsiveContainer } from 'recharts';

const colors = [
  'var(--yellow-pastel)',
  'var(--red-shaded)',
  'var(--shade-05)',
  'var(--green)',
];

export const PieChart = ({ data, center, label, onSliceClick }) => {

  return (
    <ResponsiveContainer>
      <PC>
        <Pie
          dataKey={'value'}
          nameKey={'name'}
          data={data}
          label={label}
          onClick={onSliceClick}
          cx={prop('cx', center)}
          cy={prop('cy', center)}>
          {
            data.map((entry, index) => <Cell key={index} fill={colors[index % colors.length]}/>)
          }
        </Pie>
      </PC>
    </ResponsiveContainer>
  );
};
