import React, { useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { useT } from "../../../hooks/useT";
import { useQ } from "../../../hooks/useQ";
import { release, product, project } from "../../../queries";
import { StageDetails, StageForm } from "../../projects/Plans";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useMutation } from "react-query";
import { useToast } from "../../../hooks/useToast";

export const ReleasePlan = () => {
  const { id = "" } = useParams();
  const { t } = useT();
  const toast = useToast();

  const [editing, setEditing] = useState(false);

  const {
    isLoading: isLoadingRelease,
    data: releaseData = {},
    refetch,
  } = useQ(`release-${id}`, () => release.single({ id }));
  const { isLoading: isLoadingProduct, data: productData = {} } = useQ(
    `product-${releaseData.productId}`,
    () => product.single({ id: releaseData?.product?.id }),
    {
      enabled: !!releaseData?.product?.id,
    },
  );

  const updateRelease = useMutation(product.updateRelease, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Stage updated successfully");
      refetch();
      setEditing(false);
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating stage");
    },
  });

  const handleUpdateRelease = useCallback(
    async (data) => {
      await updateRelease.mutate({
        productId: productData.id,
        release: {
          ...releaseData,
          ...data,
          period: data.period.from
            ? `${moment(data.period.from).format("YYYY-MM-DD")} to ${moment(
                data.period.to,
              ).format("YYYY-MM-DD")}`
            : "",
        },
      });
    },
    [productData, releaseData],
  );

  const startReleaseWithMethod = useMutation(product.startReleaseWithMethod, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      refetch();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error starting release");
    },
  });

  const handleMakeReleaseActive = useCallback(
    async (releaseId, method, projectId) => {
      await startReleaseWithMethod.mutate({
        productId: productData.id,
        releaseId,
        method,
        projectId,
      });
      toast.success("Release activated successfully");
    },
    [id],
  );

  const { title } = releaseData;

  return (
    <Page isLoading={isLoadingRelease || isLoadingProduct} title={title}>
      <Box mt={3}>
        <StageDetails
          product={productData}
          type="product"
          stage={releaseData}
          onEdit={() => setEditing(true)}
          onMakeReleaseActive={handleMakeReleaseActive}
          exceptionReports={[]}
        />
        <StageForm
          open={editing}
          stage={releaseData}
          type="product"
          onCancel={() => setEditing(false)}
          onSubmit={handleUpdateRelease}
        />
      </Box>
    </Page>
  );
};
