import React from "react";
import { Link } from "react-router-dom";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { Stack, Typography } from "@mui/material";
import { useQ } from "@/hooks/useQ";
import { users } from "@/queries";
import { Table } from "rsuite";
import { StandardContractualClauses } from "@/pages/home/AccountSettings/DataProtectionAgreement/StandardContractualClauses";
import { TableOfContent } from "@/pages/home/AccountSettings/DataProtectionAgreement/TableOfContent";
import { Preamble } from "@/pages/home/AccountSettings/DataProtectionAgreement/Preamble";
import { DataControllerRights } from "@/pages/home/AccountSettings/DataProtectionAgreement/DataControllerRights";
import { ProcessorInstructions } from "@/pages/home/AccountSettings/DataProtectionAgreement/ProcessorInstructions";
import { Confidentiality } from "@/pages/home/AccountSettings/DataProtectionAgreement/Confidentiality";
import { Security } from "@/pages/home/AccountSettings/DataProtectionAgreement/Security";
import { SubProcessors } from "@/pages/home/AccountSettings/DataProtectionAgreement/SubProcessors";
import { Transfer } from "@/pages/home/AccountSettings/DataProtectionAgreement/Transfer";
import { Assistance } from "@/pages/home/AccountSettings/DataProtectionAgreement/Assistance";
import { DataBreach } from "@/pages/home/AccountSettings/DataProtectionAgreement/DataBreach";
import { ErasureOfData } from "@/pages/home/AccountSettings/DataProtectionAgreement/ErasureOfData";
import { Audit } from "@/pages/home/AccountSettings/DataProtectionAgreement/Audit";
import { OtherTerms } from "@/pages/home/AccountSettings/DataProtectionAgreement/OtherTerms";
import { Commencement } from "@/pages/home/AccountSettings/DataProtectionAgreement/Commencement";
import { Contacts } from "@/pages/home/AccountSettings/DataProtectionAgreement/Contacts";
import { ApprovedSubProcessors } from "@/pages/home/AccountSettings/DataProtectionAgreement/ApprovedSubProcessors";
import { Processing } from "@/pages/home/AccountSettings/DataProtectionAgreement/Processing";
import { PersonalData } from "@/pages/home/AccountSettings/DataProtectionAgreement/PersonalData";
import { TermsOfAgreement } from "@/pages/home/AccountSettings/DataProtectionAgreement/TermsOfAgreement";

export const DataProtectionAgreement = () => (
  <Page>
    <Section title="Data Protection Agreement">
      <Stack spacing={4}>
        <StandardContractualClauses />
        <TableOfContent />
        <Preamble />
        <DataControllerRights />
        <ProcessorInstructions />
        <Confidentiality />
        <Security />
        <SubProcessors />
        <Transfer />
        <Assistance />
        <DataBreach />
        <ErasureOfData />
        <Audit />
        <OtherTerms />
        <Commencement />
        <Contacts />
        <Processing />
        <ApprovedSubProcessors />
        {/*

        <PersonalData />
        <TermsOfAgreement />
           */}
      </Stack>
    </Section>
  </Page>
);
