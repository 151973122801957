import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

var stringToColorHsl = (string, saturation = 80, lightness = 40) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

function stringToColorHex(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name, sx) {
  return {
    sx: {
      bgcolor: stringToColorHsl(name),
      ...sx,
    },
    children: `${name.split(" ")[0][0]}${
      name.split(" ")[1] !== undefined ? name.split(" ")[1][0] : ""
    }`,
  };
}

export const ProfileIcon = ({ name, sx, ...props }) => {
  //code based on https://mui.com/material-ui/react-avatar/ in the "Letter avatars" section
  return <Avatar {...props} {...stringAvatar(name, sx)} />;
};
