import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useStyleParams } from '../../hooks/useStyleParams';

export const Input = ({ name, onChange = () => {}, value, placeholder, type = 'text', ...rest}) => {
  const {className, style} = useStyleParams(rest);

  const [val, setVal] = useState(value);

  const debounced = useDebouncedCallback(
    (v) => {
      onChange(v)
    }, 300)

  return (
    <input
      name={name}
      value={val}
      onChange={(e) => {
        setVal(e.target.value);
        debounced(e);
      }}
      type={type}
      onBlur={onChange}
      placeholder={placeholder}
      className={`form-input ${className}`}
      style={style} />
  )
}
