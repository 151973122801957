import React, { createContext, useEffect, useState } from 'react';
import { useDetermineScreen } from './hooks/useDetermineScreen';


export const ScreenSizeContext = createContext({ screenSize: 'lg', override: null });

export const ScreenSizeProvider = ({children}) => {
  const size = useDetermineScreen();
  const [override, setOverride] = useState(null);

  useEffect(() => {
    if(size !== override) {
      setOverride(null);
    }
  }, [size])

  return (
    <ScreenSizeContext.Provider value={{screenSize: size, override: override, setOverride: setOverride}}>
      {children}
    </ScreenSizeContext.Provider>
  )
};
