import React from "react";
import { Stack } from "@mui/material";

export const Badge = ({ children, variant = "default", light, sx = {} }) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      px={2}
      py="2px"
      borderRadius="20px"
      alignItems="center"
      sx={{
        whiteSpace: "nowrap",
        backgroundColor: light
          ? undefined
          : variant === "default"
          ? "#ededed"
          : variant === "green"
          ? "success.main"
          : variant === "yellow"
          ? "warning.main"
          : variant === "red"
          ? "error.main"
          : "#ededed",
        color:
          variant === "default" || light
            ? "black"
            : variant === "green"
            ? "success.contrastText"
            : variant === "yellow"
            ? "warning.contrastText"
            : variant === "red"
            ? "error.contrastText"
            : "black",
        "& .MuiSvgIcon-root, .MuiTypography-root": {
          color:
            variant === "default"
              ? "black"
              : variant === "green"
              ? light
                ? "success.main"
                : "success.contrastText"
              : variant === "yellow"
              ? light
                ? "warning.main"
                : "warning.contrastText"
              : variant === "red"
              ? light
                ? "error.main"
                : "error.contrastText"
              : "black",
        },
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};
