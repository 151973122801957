import React, { useMemo } from "react";
import { useMatch, useParams } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { Section } from "../../../layout/Section";
import { useQ } from "../../../hooks/useQ";
import { release } from "../../../queries";
import { clientUrl } from "../../../util/routing";
import { uris } from "../../../config/nav";
import { IssueRegister } from "../../projects/components/IssueRegister";
import { useT } from "../../../hooks/useT";

export const ReleaseChange = () => {
  const { t } = useT();
  const { id = "", issueId } = useParams();

  const { isLoading, data = {} } = useQ(`programme-${id}`, () =>
    release.single({ id }),
  );

  const { title } = data;

  return (
    <Page isLoading={false} title={""}>
      <Section title={t("project.navigation.change.title")}>
        <IssueRegister releaseId={id} type={"release"} issueId={issueId} />
      </Section>
    </Page>
  );
};
