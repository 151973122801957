import React from "react";
import { Icon } from "./Icon";

export const InitiativeIcon = ({
  initiativeType,
}: {
  initiativeType: "programme" | "release" | "project" | "product";
}) => {
  let ico: string | null = "";
  switch (initiativeType) {
    case "programme":
      ico = "programme";
      break;

    case "release":
      ico = "release";
      break;

    case "project":
      ico = "project";
      break;

    case "product":
      ico = "product";
      break;

    default:
      ico = null;
      break;
  }

  if (ico === null) {
    return null;
  }

  return <Icon i={ico} size={"nm"} />;
};
