import React, { useState } from "react";
import { useT } from "../../../hooks/useT";
import { Section } from "../../../layout/Section";
import { useMutation } from "react-query";
import { project } from "../../../queries";
import * as Yup from "yup";
import { useFormik } from "formik";
import { formErrorsToTooltip, prepareFormValues } from "../../../util/form";
import { usePersist } from "../../../hooks/usePersist";
import { Label } from "../../../base/form/Label";
import { Textarea } from "../../../base/form/Textarea";
import { isEmpty, propOr } from "ramda";
import { useToast } from "../../../hooks/useToast";
import { ControlSection } from "../../../layout/ControlSection";
import {
  ButtonGroup,
  LightButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../base/Button";
import { F } from "../../../base/form/Form";
import { TableChildWrapper } from "../../../base/table/DataTable";
import { Grid } from "../../../layout/Grid";
import { P } from "../../../base/typography/P";
import { Title } from "../../../base/typography/Title";
import { Input } from "../../../base/form/Input";
import { stripHtml } from "../../../util/stripHtml";

const InlineForm = ({ data, next, onClose }) => {
  const { t } = useT();
  const toast = useToast();

  const updateConstraints = useMutation(project.updateConstraints, {
    onSuccess: (data) => {
      toast.success(
        t("projectForm.messages.success.updatedQualityRegisterProjects"),
      );
      next();
    },
    onError: () => {
      toast.success(t("projectForm.messages.error.generic"));
    },
  });

  const updateQualityRegister = Yup.object().shape({
    id: Yup.string().trim(),
    title: Yup.string().trim(),
    purpose: Yup.string().trim(),
    composition: Yup.string().trim(),
    format: Yup.string().trim(),
    diversion: Yup.string().trim(),
    necessarySkills: Yup.string().trim(),
    qualityCriteria: Yup.string().trim(),
    qualityMethod: Yup.string().trim(),
    producer: Yup.string().trim(),
    approver: Yup.string().trim(),
    results: Yup.string().trim(),
  });

  const updateQUalityRegisterForm = useFormik({
    validationSchema: updateQualityRegister,
    initialValues: {
      assumptions: propOr("", "assumptions", data),
      constraints: propOr("", "constraints", data),
    },
    onSubmit: (v) => {
      const data = prepareFormValues(v);
      updateConstraints.mutate(data);
    },
  });

  const persist = usePersist(
    updateQUalityRegisterForm,
    "update-constraints-form",
  );

  return (
    <F onSubmit={updateQUalityRegisterForm.handleSubmit}>
      <Section>
        <Label
          htmlFor={"constraints"}
          title={t("qualityRegisterForm.fields.constraints.title")}
          error={updateQUalityRegisterForm.errors.constraints}
          touched={updateQUalityRegisterForm.touched}
        >
          <Textarea
            plain={false}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t(
              "qualityRegisterForm.fields.constraints.placeholder",
            )}
            value={updateQUalityRegisterForm.values.constraints}
            name={"constraints"}
          />
        </Label>
      </Section>

      <Section>
        <Label
          htmlFor={"id"}
          title={t("qualityRegisterForm.fields.id.title")}
          error={updateQUalityRegisterForm.errors.id}
          touched={updateQUalityRegisterForm.touched}
        >
          <Input
            value={updateQUalityRegisterForm.values.id}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t("qualityRegisterForm.fields.id.placeholder")}
            name={"id"}
          />
        </Label>

        <Label
          htmlFor={"title"}
          title={t("qualityRegisterForm.fields.title.title")}
          error={updateQUalityRegisterForm.errors.title}
          touched={updateQUalityRegisterForm.touched}
        >
          <Input
            value={updateQUalityRegisterForm.values.title}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t("qualityRegisterForm.fields.title.placeholder")}
            name={"title"}
          />
        </Label>

        <Label
          htmlFor={"purpose"}
          title={t("qualityRegisterForm.fields.purpose.title")}
          error={updateQUalityRegisterForm.errors.purpose}
          touched={updateQUalityRegisterForm.touched}
        >
          <Input
            value={updateQUalityRegisterForm.values.purpose}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t("qualityRegisterForm.fields.purpose.placeholder")}
            name={"purpose"}
          />
        </Label>
      </Section>

      <Section>
        <Label
          htmlFor={"composition"}
          title={t("qualityRegisterForm.fields.composition.title")}
          error={updateQUalityRegisterForm.errors.composition}
          touched={updateQUalityRegisterForm.touched}
        >
          <Textarea
            plain={false}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t(
              "qualityRegisterForm.fields.composition.placeholder",
            )}
            value={updateQUalityRegisterForm.values.composition}
            name={"composition"}
          />
        </Label>

        <Label
          htmlFor={"format"}
          title={t("qualityRegisterForm.fields.format.title")}
          error={updateQUalityRegisterForm.errors.format}
          touched={updateQUalityRegisterForm.touched}
        >
          <Input
            value={updateQUalityRegisterForm.values.format}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t("qualityRegisterForm.fields.format.placeholder")}
            name={"format"}
          />
        </Label>
      </Section>

      <Section>
        <Label
          htmlFor={"diversion"}
          title={t("qualityRegisterForm.fields.diversion.title")}
          error={updateQUalityRegisterForm.errors.diversion}
          touched={updateQUalityRegisterForm.touched}
        >
          <Textarea
            plain={false}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t("qualityRegisterForm.fields.diversion.placeholder")}
            value={updateQUalityRegisterForm.values.diversion}
            name={"diversion"}
          />
        </Label>

        <Label
          htmlFor={"necessarySkills"}
          title={t("qualityRegisterForm.fields.necessarySkills.title")}
          error={updateQUalityRegisterForm.errors.necessarySkills}
          touched={updateQUalityRegisterForm.touched}
        >
          <Input
            value={updateQUalityRegisterForm.values.necessarySkills}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t(
              "qualityRegisterForm.fields.necessarySkills.placeholder",
            )}
            name={"necessarySkills"}
          />
        </Label>
      </Section>

      <Section>
        <Label
          htmlFor={"qualityCriteria"}
          title={t("qualityRegisterForm.fields.qualityCriteria.title")}
          error={updateQUalityRegisterForm.errors.qualityCriteria}
          touched={updateQUalityRegisterForm.touched}
        >
          <Input
            value={updateQUalityRegisterForm.values.qualityCriteria}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t(
              "qualityRegisterForm.fields.qualityCriteria.placeholder",
            )}
            name={"qualityCriteria"}
          />
        </Label>

        <Label
          htmlFor={"qualityMethod"}
          title={t("qualityRegisterForm.fields.qualityMethod.title")}
          error={updateQUalityRegisterForm.errors.qualityMethod}
          touched={updateQUalityRegisterForm.touched}
        >
          <Textarea
            plain={false}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t(
              "qualityRegisterForm.fields.qualityMethod.placeholder",
            )}
            value={updateQUalityRegisterForm.values.qualityMethod}
            name={"qualityMethod"}
          />
        </Label>
      </Section>

      <Section>
        <Label
          htmlFor={"producer"}
          title={t("qualityRegisterForm.fields.producer.title")}
          error={updateQUalityRegisterForm.errors.producer}
          touched={updateQUalityRegisterForm.touched}
        >
          <Input
            value={updateQUalityRegisterForm.values.producer}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t("qualityRegisterForm.fields.producer.placeholder")}
            name={"producer"}
          />
        </Label>
        <Label
          htmlFor={"approver"}
          title={t("qualityRegisterForm.fields.approver.title")}
          error={updateQUalityRegisterForm.errors.approver}
          touched={updateQUalityRegisterForm.touched}
        >
          <Input
            value={updateQUalityRegisterForm.values.approver}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t("qualityRegisterForm.fields.approver.placeholder")}
            name={"approver"}
          />
        </Label>
        <Label
          htmlFor={"results"}
          title={t("qualityRegisterForm.fields.results.title")}
          error={updateQUalityRegisterForm.errors.results}
          touched={updateQUalityRegisterForm.touched}
        >
          <Input
            value={updateQUalityRegisterForm.values.results}
            onChange={updateQUalityRegisterForm.handleChange}
            placeholder={t("qualityRegisterForm.fields.results.placeholder")}
            name={"results"}
          />
        </Label>
      </Section>

      <ControlSection>
        <SecondaryButton onClick={onClose}>
          {t("generic.button.close")}
        </SecondaryButton>
        <PrimaryButton
          disabledTitle={formErrorsToTooltip(updateQUalityRegisterForm.errors)}
          disabled={!isEmpty(updateQUalityRegisterForm.errors)}
          className={"mg-l-nm"}
          type={"submit"}
        >
          {t("projectForm.submit.updateDefinition")}
        </PrimaryButton>
      </ControlSection>
    </F>
  );
};

function hasValue(content) {
  return (
    content !== undefined &&
    content !== null &&
    content.replace(/(<([^>]+)>)/gi, "").trim().length > 0
  );
}

const HTMLContent = ({ value }) => (
  <span dangerouslySetInnerHTML={{ __html: value }}></span>
);

const DisplayField = ({ name, data }) => {
  const { t } = useT();
  return hasValue(data[name]) ? (
    <P>
      <Title size={"md"}>{t("project.quality." + name)}</Title> <br />
      <HTMLContent value={data[name]} />
    </P>
  ) : null;
};

const Details = ({ data, onEditClick }) => {
  const { t } = useT();

  return (
    <div>
      <Section>
        <ButtonGroup split={true} className={"d-f f-jc-fe"}>
          <LightButton>{t("generic.button.pdf")}</LightButton>
          <LightButton>{t("generic.button.copy")}</LightButton>
          <LightButton>{t("generic.button.add")}</LightButton>
          <LightButton onClick={onEditClick}>
            {t("generic.button.edit")}
          </LightButton>
        </ButtonGroup>
      </Section>
      <Section>
        <Grid cols={"1fr 1fr"}>
          <Title size={"lg"}>{stripHtml(data.title)}</Title>
        </Grid>
        <DisplayField name="purpose" data={data} />
        <DisplayField name="composition" data={data} />
        <DisplayField name="derivation" data={data} />
        <DisplayField name="format" data={data} />
        <DisplayField name="qualityExpectations" data={data} />
        <DisplayField name="acceptanceCriterias" data={data} />
        <DisplayField name="acceptanceMethod" data={data} />
        <DisplayField name="acceptanceResponsible" data={data} />
        <DisplayField name="qualityCriteria" data={data} />
        <DisplayField name="qualityMethod" data={data} />
        <DisplayField name="skills" data={data} />
        <DisplayField name="producer" data={data} />
        <DisplayField name="approver" data={data} />
      </Section>
    </div>
  );
};

export const QualityTableItemDetailForm = ({ data, controls }) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <TableChildWrapper>
      {showEdit ? (
        <InlineForm
          data={data}
          next={() => setShowEdit((v) => !v)}
          onClose={() => controls.setShowChild(false)}
        />
      ) : (
        <Details data={data} onEditClick={() => setShowEdit((v) => !v)} />
      )}
    </TableChildWrapper>
  );
};
