import React from "react";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { Button } from "@mui/material";
import { uris } from "../../config/nav";
import { useT } from "../../hooks/useT";
import { Hide } from "../../base/responsive/Hide";
import { NoMobileView } from "../../base/responsive/NoMobileView";
import { TypeSelect } from "./TypeSelect";
export const StartOutParagraphs = () => {
  const { t } = useT();
  return (
    <Page>
      <NoMobileView show={"sm"} />
      <Hide hide={"sm"}>
        <Section title={t("page.home.welcome.title")}>
          <p>{t("page.home.welcome.description")}</p>
        </Section>

        <TypeSelect />
      </Hide>
    </Page>
  );
};
