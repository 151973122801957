import { useScreen } from './useScreen';
import { equals, has, includes, intersection, is, keys } from 'ramda';

export const useStyleParams = (params) => {
  const screen = useScreen();
  
  const responsiveStyles = keys(params)
    .filter((k) => k !== 'hide')
    .reduce((acc, curr) => {
        const value = params[curr];
        if (is(Object, value) &&
          (has('sm', value) || has('nm', value) || has('md', value) || has('lg', value))) {
          // Seems to be a prop, that has Responsive-Switched values

          if (has(screen, value)) {
            return { ...acc, [curr]: value[screen] };
          }

          if (has('default', value)) {
            return { ...acc, [curr]: value['default'] };
          }
        }

        // Return Boolean Values if prop='sm'

        if (['sm', 'nm', 'md', 'lg'].includes(value)) {
          return { ...acc, [curr]: value, [`is${curr[0].toUpperCase()}${curr.slice(1)}`]: value === screen };
        }
        // or prop=['sm', 'lg']
        if (is(Array, value)) {
          if (equals(intersection(value, ['sm', 'nm', 'md', 'lg']), value)) {
            return { ...acc, [`is${curr[0].toUpperCase()}${curr.slice(1)}`]: value.includes(screen), [curr]: value };
          }
        }

        return { ...acc, [curr]: value };
      },
      {});

  responsiveStyles.hide = params['hide'] === screen || (is(Array, params['hide']) && includes(screen, params['hide']));

  // Take the opportunity to remove unnessecary "undefined" strings out of the dom
  // that get put in there by template strings
  if (!has('className', responsiveStyles)) {
    responsiveStyles.className = '';
  }

  return responsiveStyles;
};
