import { stripHtml } from "@/util/stripHtml";
import { Box, Stack, Typography } from "@mui/material";
import { Section } from "@/layout/Section";
import React, { ReactNode } from "react";

export type KeyValueDisplayViewProps = {
  title?: string;
  properties: {
    name: string;
    content?: ReactNode;
    text?: string;
    html?: string;
  }[];
};

export const KeyValueDisplayView = ({
  title,
  properties,
}: KeyValueDisplayViewProps) => {
  const renderContent = () => {
    return (
      <Stack spacing={2}>
        {properties.map(({ name, content, text, html }) => (
          <Box key={name}>
            <Typography variant="h3" fontWeight="bold">
              {name}
            </Typography>
            {!!content ? (
              content
            ) : text ? (
              <Typography>{text}</Typography>
            ) : html ? (
              <Typography dangerouslySetInnerHTML={{ __html: html }} />
            ) : null}
          </Box>
        ))}
      </Stack>
    );
  };

  return title ? (
    <Section title={title} sx={{ mb: 0 }}>
      {renderContent()}
    </Section>
  ) : (
    renderContent()
  );
};
