import { useStyleParams } from '../../hooks/useStyleParams';
import React from 'react';

export const Title = ({children, ...rest}) => {
  const {className, style, size = 'nm'} = useStyleParams(rest)

  return (
    <span className={`title title--${size} ${className}`} style={style}>
      {children}
    </span>
  )
}
