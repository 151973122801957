import React from "react";
import { MainRouter } from "./MainRouter";
import { RouterProvider } from "./RouterProvider";
import "./i18n";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthenticationProvider } from "./auth/AuthenticationProvider";
import { ScreenSizeProvider } from "./ScreenSizeProvider";
import { ToastProvider } from "./ToastProvider";
import { UseQueryProvider } from "./UseQueryProvider";
import { DragAndDropProvider } from "./DragAndDropProvider";
import { ErrorRecoveryProvider } from "./ErrorRecoveryProvider";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import "rsuite/dist/rsuite.min.css";
import "./styles/design3.css";

export const App = () => {
  return (
    <ErrorRecoveryProvider>
      <ScreenSizeProvider>
        <AuthenticationProvider>
          <ThemeProvider theme={theme}>
            <UseQueryProvider>
              <DragAndDropProvider>
                <RouterProvider>
                  <MainRouter />
                </RouterProvider>
              </DragAndDropProvider>
              <ToastProvider />
              <ReactQueryDevtools initialIsOpen={false} />
            </UseQueryProvider>
          </ThemeProvider>
        </AuthenticationProvider>
      </ScreenSizeProvider>
    </ErrorRecoveryProvider>
  );
};

export default App;
