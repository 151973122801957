import { useT } from "../hooks/useT";
import React, { useState } from "react";
import { Button, ButtonGroup, Stack } from "@mui/material";

const Languages = [
  { code: "en_uk", name: "English (UK)" },
  { code: "en_us", name: "English (US)" },
  { code: "no", name: "Norsk" },
];

export const LanguageSwitcher = () => {
  const { t, i18n } = useT();

  const lng = i18n.language;
  const [loading, setLoading] = useState(false);

  const switchLang = (to) => {
    setLoading(true);
    i18n.changeLanguage(to).then(() => {
      setLoading(false);
    });
  };

  return (
    <Stack direction={"row"} spacing={1}>
      <ButtonGroup>
        {Languages.map((lang) => {
          return (
            <Button
              key={lang.code}
              onClick={() => switchLang(lang.code)}
              disabled={loading}
              variant={lng === lang.code ? "contained" : "outlined"}
            >
              {lang.name}
            </Button>
          );
        })}
      </ButtonGroup>
    </Stack>
  );
};
