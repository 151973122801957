import React, { useState, useEffect } from "react";
import {
  Stack,
  Dialog,
  DialogContent,
  IconButton,
  useScrollTrigger,
  Fade,
  Fab,
  DialogTitle,
} from "@mui/material";
import { ArrowDownward, Close } from "@mui/icons-material";

/**
 * CustomDialog is a reusable component for rendering a dialog with a close icon and an indication if the dialog is scrollable.
 * @param scrollDependencyArray
 * @param {Object} props - The props for the component.
 * @param {boolean} props.isOpen - A boolean indicating whether the dialog should be open or not.
 * @param {function} props.onClose - A function to be called when the dialog is closed.
 * @param {Array} scrollDependencyArray - An array of values to be used as dependencies for the scroll trigger.
 * @param {boolean} isContentDirty - A boolean indicating whether the content of the dialog is dirty or not. If set to true, a warning will be displayed when the user tries to close the page.
 * @param {ReactNode} props.children - The content to be displayed in the dialog.
 * @param {string} title - The title of the dialog.
 */

export const CustomDialog = ({
  isOpen,
  onClose,
  scrollDependencyArray = [],
  children,
  isContentDirty = false,
  title,
  ...props
}) => {
  const [scrollTarget, setScrollTarget] = useState(undefined);
  const [isScrollable, setIsScrollable] = useState(false);
  const [threshold, setThreshold] = useState(
    scrollTarget?.scrollHeight - scrollTarget?.clientHeight - 20,
  );

  const scrollTrigger = useScrollTrigger({
    target: scrollTarget,
    threshold: threshold,
  });

  useEffect(() => {
    setIsScrollable(scrollTarget?.scrollHeight !== scrollTarget?.clientHeight),
      setThreshold(
        scrollTarget?.scrollHeight - scrollTarget?.clientHeight - 20,
      );
  }, [scrollTarget, ...scrollDependencyArray]);

  useEffect(() => {
    window.onbeforeunload = function (event) {
      if (isContentDirty) {
        const message = "Sure you want to leave?";
        if (typeof event == "undefined") {
          event = window.event;
        }
        if (event) {
          event.returnValue = message;
        }
        return message;
      }
      return undefined;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isContentDirty]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
      open={isOpen}
      fullWidth
      maxWidth="lg"
      {...props}
    >
      <DialogTitle>
        {title ? (
          <Stack direction="row" justifyContent="space-between">
            {title}
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent
        sx={{ padding: 2 }}
        ref={(node) => {
          if (node) {
            setScrollTarget(node);
          }
        }}
      >
        <Stack spacing={2}>{children}</Stack>
        {isScrollable && (
          <Fade
            sx={{
              position: "fixed",
              bottom: 50,
              left: 0,
              transform: "translate(calc(50vw - 50%))",
            }}
            in={!scrollTrigger}
          >
            <Fab
              size="small"
              onClick={() =>
                scrollTarget.scrollTo({
                  top: scrollTarget.clientHeight + 1000,
                  behavior: "smooth",
                })
              }
            >
              <ArrowDownward />
            </Fab>
          </Fade>
        )}
      </DialogContent>
    </Dialog>
  );
};
