import React from "react";
import useConfirmDialog, {
  UseConfirmDialogProps,
} from "@/hooks/useConfirmDialog";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const DeleteButton = ({
  onConfirm,
  onCancel,
  content,
  cancelText,
  confirmText,
  iconMode,
  disabled,
}: UseConfirmDialogProps & {
  iconMode?: boolean;
  disabled?: boolean;
}) => {
  const { open, show, dialog } = useConfirmDialog({
    onConfirm,
    onCancel,
    content,
    cancelText,
    confirmText,
  });

  return (
    <>
      {!!iconMode ? (
        <IconButton disabled={disabled} onClick={show} color="error">
          <DeleteIcon />
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          startIcon={<DeleteIcon />}
          onClick={show}
          variant="contained"
          color="error"
        >
          Delete
        </Button>
      )}
      {dialog}
    </>
  );
};
