import { CustomAccordion } from "@/layout/CustomAccordion";
import { Section } from "@/layout/Section";
import { Page } from "@/layout/Page";
import {
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useQ } from "@/hooks/useQ";
import { phase, projectType } from "@/queries";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteButton } from "@/base/DeleteButton";
import { CustomDialog } from "@/layout/CustomDialog";

const PhaseForm = ({ phase, onSave, onClose, open, state, ...props }) => {
  const schema = yup.object().shape({
    text: yup.string().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text: phase?.text || "",
      description: phase?.description || "",
      link: phase?.link || "",
    },
  });

  const { handleSubmit, reset } = formData;

  return (
    <CustomDialog isOpen={open} onClose={onClose}>
      <FormDataProvider
        formKey="accountSetting.lifecycles.fields"
        {...formData}
      >
        <form
          onSubmit={handleSubmit((data) => {
            onSave({
              id: phase?.id || undefined,
              text: data.text,
              description: data.description,
              link: data.link,
            });
            state === "add" ? reset() : null;
          })}
        >
          <FormTextField name={"text"} fullWidth />
          <Box sx={{ width: "100%", paddingTop: "40px" }}>
            <FormTextField name={"description"} rows="5" multiline fullWidth />
          </Box>
          <Box sx={{ width: "100%", paddingTop: "40px" }}>
            <FormTextField name={"link"} fullWidth />
          </Box>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Stack>
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

export const Lifecycles = () => {
  const { data: projectTypes = [], refetch: refetchprojectTypes } = useQ(
    `projectTypes`,
    () => projectType.list(),
  );

  const toast = useToast();

  const createPhases = useMutation(phase.create, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Phase created successfully");
      refetchprojectTypes();
      setShowNewItemForms((editItemIds) => editItemIds.map(() => false));
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating phase");
    },
  });

  const updatePhases = useMutation(phase.update, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Phase updated successfully");
      refetchprojectTypes();
      handleShowEditItemForm(null);
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating phase");
    },
  });

  const [showNewItemForms, setShowNewItemForms] = useState(
    (projectTypes ?? []).map(() => false),
  );
  const [editItemIds, setEditItemIds] = useState(
    (projectTypes ?? []).map(() => null),
  );

  const handleShowEditItemForm = useCallback((index, itemId) => {
    setShowNewItemForms((editItemIds) => [
      ...editItemIds.slice(0, index),
      false,
      ...editItemIds.slice(index + 1),
    ]);
    setEditItemIds((editItemIds) => [
      ...editItemIds.slice(0, index),
      itemId,
      ...editItemIds.slice(index + 1),
    ]);
  }, []);

  return (
    <Page>
      <Section title="Lifecycle Management">
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography>
            A lifecycle can be defined for every Project Type. A project life
            cycle is the predefined sequence of phases that a project goes
            through.
            <br />
            There can be one or multiple stages in a phase.
          </Typography>
        </Stack>
        {projectTypes.map((projectType, index) => (
          <Box sx={{ width: "100%", mt: 5 }} key={projectType.id}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h2">{`Lifecycle for "${projectType.name}"`}</Typography>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  setShowNewItemForms((editItemIds) => [
                    ...editItemIds.slice(0, index),
                    !editItemIds[index],
                    ...editItemIds.slice(index + 1),
                  ]);
                  setEditItemIds((editItemIds) => [
                    ...editItemIds.slice(0, index),
                    null,
                    ...editItemIds.slice(index + 1),
                  ]);
                }}
              >
                Add a Phase
              </Button>
            </Stack>
            <Box sx={{ mt: "20px" }}>
              <CustomAccordion
                tableView
                header={{
                  columns: [
                    { title: "Phase", flex: 1 },
                    { title: "Description", flex: 4 },
                    { title: "", width: 80 },
                    { title: "", width: 40 },
                    { title: "", width: 40 },
                  ],
                  expanded: false,
                  details: (
                    <PhaseForm
                      onSave={(data) =>
                        createPhases.mutate({
                          projectTypeId: projectType.id,
                          ...data,
                        })
                      }
                      state={"add"}
                      open={showNewItemForms[index]}
                      onClose={() => {
                        setShowNewItemForms((editItemIds) => [
                          ...editItemIds.slice(0, index),
                          false,
                          ...editItemIds.slice(index + 1),
                        ]);
                      }}
                    />
                  ),
                }}
                rows={(projectType.phases ?? []).map((phase) => ({
                  id: phase.id,
                  columns: [
                    {
                      content: (
                        <Typography sx={{ fontWeight: "bold" }}>
                          {phase.text}
                        </Typography>
                      ),
                      flex: 1,
                      sx: { alignSelf: "flex-start" },
                    },
                    {
                      title:
                        phase.description?.length > 200
                          ? phase.description?.substr(0, 200) + "..."
                          : phase.description,
                      flex: 4,
                    },
                    {
                      content: !!phase.link ? (
                        <Link href={phase.link} target="_blank">
                          <IconButton>
                            <OpenInNewIcon />
                          </IconButton>
                        </Link>
                      ) : null,
                      sx: { textAlign: "center" },
                      width: 80,
                    },
                    {
                      content: (
                        <DeleteButton
                          onConfirm={() => null}
                          content="Not implemented yet"
                          confirmText="Close"
                          iconMode
                        />
                      ),
                      sx: { textAlign: "center" },
                      width: 40,
                    },
                    {
                      content: (
                        <IconButton
                          onClick={() =>
                            handleShowEditItemForm(index, phase.id)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      ),
                      sx: { textAlign: "center" },
                      width: 40,
                    },
                  ],
                  expanded: false,
                  expandDisabled: true,
                  details: (
                    <PhaseForm
                      open={editItemIds[index] === phase.id}
                      phase={phase}
                      onClose={() => {
                        handleShowEditItemForm(index, null);
                      }}
                      onSave={(data) => {
                        updatePhases.mutate({
                          projectTypeId: projectType.id,
                          ...data,
                        });
                      }}
                    />
                  ),
                }))}
              />
            </Box>
          </Box>
        ))}
      </Section>
    </Page>
  );
};
