import React, { memo, useMemo } from "react";
import {
  Stack,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { accessObjectByDotSeparatedKeys } from "./util";
import { useFormDataContext } from "../data-context/FormDataContext";
import { TitleWithHelper } from "../../TitleWithHelper";
import { useT } from "../../../hooks/useT";
import { useFormStrings } from "../../../hooks/useFormStrings";

/*
export type FormSelectProps = {
  options: {
    value: number | string;
    label: number | string;
  }[];
  label?: string;
  name?: string;
  nullValue?: string;
  selectProps?: SelectProps;
} & FormControlProps;
*/

export const FormSelect = memo(
  ({
    options = [],
    label,
    placeholder,
    help,
    name = "",
    fieldKey,
    nullValue,
    selectProps,
    disableStrings = false,
    required,
    ...props
  }) => {
    const { t } = useT();
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const { formKey } = useFormDataContext();
    const inputStrings = useFormStrings({
      name,
      label,
      placeholder,
      help,
      fieldKey,
    });

    //console.log(accessObjectByDotSeparatedKeys(errors, name));

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, name, ref } }) => (
          <Stack sx={{ width: "100%" }} {...props}>
            {!disableStrings ? (
              inputStrings.help ? (
                <TitleWithHelper
                  title={inputStrings.label}
                  help={inputStrings.help}
                  required={required}
                />
              ) : (
                <Typography
                  sx={{
                    fontSize: "20px !important",
                    color: "#111827 !important",
                    mb: "12px !important",
                  }}
                >
                  {inputStrings.label}
                  {required && (
                    <Typography
                      component="span"
                      color="error"
                      fontSize="1.3em"
                      lineHeight="1em"
                    >
                      {" "}
                      *
                    </Typography>
                  )}
                </Typography>
              )
            ) : null}
            <Select
              value={nullValue ? value ?? nullValue : value}
              onChange={(ev) =>
                onChange(
                  nullValue && ev.target.value === nullValue
                    ? null
                    : ev.target.value,
                )
              }
              variant="outlined"
              name={name}
              renderValue={(selected) => {
                let currentOption = options.find(
                  (item) => item.value === selected,
                );
                if (currentOption) {
                  return currentOption.label;
                } else if (
                  selected === "" &&
                  (placeholder || inputStrings.placeholder)
                ) {
                  return (
                    <span style={{ opacity: "0.42" }}>
                      {placeholder || inputStrings.placeholder}
                    </span>
                  );
                } else {
                  return (
                    <em style={{ color: "red" }}>
                      {t("generic.unknownValue")}:{" "}
                      {JSON.stringify(
                        selected
                          ? selected
                          : selected === undefined
                            ? "_undefined_"
                            : selected === null
                              ? "_null_"
                              : selected,
                      )}
                    </em>
                  );
                }
              }}
              error={!!accessObjectByDotSeparatedKeys(errors, name)}
              {...selectProps}
              displayEmpty
            >
              {options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {!!accessObjectByDotSeparatedKeys(errors, name) && (
              <FormHelperText
                error={!!accessObjectByDotSeparatedKeys(errors, name)}
              >
                {accessObjectByDotSeparatedKeys(errors, name)?.message}
              </FormHelperText>
            )}
          </Stack>
        )}
      />
    );
  },
);
FormSelect.displayName = "FormSelect";
