import React from 'react';

export const NavtoggleOpen = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M29.5081 4.0072C29.8425 3.67279 29.8425 3.13062 29.5081
      2.79621C29.1737 2.46181 28.6315 2.46181 28.2971 2.79621L16.0582 15.0352L3.81921 2.79621C3.48481 2.46181
      2.94263 2.46181 2.60823 2.79621C2.27382 3.13062 2.27382 3.67279 2.60823 4.0072L14.8472 16.2461L2.60823
      28.4851C2.27382 28.8195 2.27382 29.3617 2.60823 29.6961C2.94263 30.0305 3.48481 30.0305 3.81921 29.6961L16.0582
      17.4571L28.2971 29.6961C28.6315 30.0305 29.1737 30.0305 29.5081 29.6961C29.8425 29.3617 29.8425 28.8195 29.5081
      28.4851L17.2691 16.2461L29.5081 4.0072Z" fill="white"/>

    </svg>
  );
};
