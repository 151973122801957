import React from 'react';

export const Project = ({className, style, onClick}) => (
    <svg className={className} style={style} onClick={onClick}
      width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0508 1.24609H1.55078V18.7461H19.0508V1.24609Z" stroke="#9A9EB1" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.0508 6.24609H1.55078" stroke="#9A9EB1" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.55078 6.24609V18.7461" stroke="#9A9EB1" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
