import React from "react";
import { Typography } from "@mui/material";

export const HtmlTypography = ({ children, ...props }) => {
  return (
    <Typography
      className="ql-editor"
      sx={{ minHeight: "1em" }}
      dangerouslySetInnerHTML={{ __html: children }}
      {...props}
    />
  );
};
