import React from "react";
import { useT } from "../../../hooks/useT";
import qualityApproachClip from "../../../assets/cliparts/qualityApproach.svg";
import { HtmlReadMoreTypography } from "../../../base/typography/HtmlReadMoreTypography";
import { Box, Stack, Typography } from "@mui/material";

export const QMAInformation = () => {
  const { t } = useT();
  return (
    <Stack direction="row" justifyContent="space-between" mb={3}>
      <Box flex={3}>
        <Typography variant="h2">
          {t("project.quality.approach.title")}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{
            __html: t("project.quality.approach.description"),
          }}
        />
      </Box>
      <Box flex={1} textAlign="right">
        <img
          src={qualityApproachClip}
          alt={"Quality Method Approach Clipart"}
          style={{ maxWidth: "100%" }}
        />
      </Box>
    </Stack>
  );
};
