import React from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../../../layout/Page";
import { release } from "../../../../queries";
import { HighlightReleaseProgressReportForm } from "../../../projects/forms/HighlightReportForm";
import { useQ } from "../../../../hooks/useQ";

export const ReleaseProgressCreateForm = () => {
  const { id = "", type = "" } = useParams();
  const { isLoading, data = {} } = useQ(`release-${id}`, () =>
    release.single({ id }),
  );

  const { title, level } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <HighlightReleaseProgressReportForm
        releaseId={id}
        level={level}
        type={type}
      />
    </Page>
  );
};
