import React from 'react';
import { useStyleParams } from '../hooks/useStyleParams';

export const CenterH = ({ children, ...rest }) => {
  const {className, style} = useStyleParams(rest);
  
  return (
    <div className={`center-h ${className}`} style={style}>
      {children}
    </div>
  )
}
