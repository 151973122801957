import React from "react";
import { isNil } from "ramda";
import { ChevronDown } from "./icons/chevron-down";
import { ChevronUp } from "./icons/chevron-up";
import { ChevronRight } from "./icons/chevron-right";
import { Danger } from "./icons/danger";
import { Folder } from "./icons/folder";
import { Search } from "./icons/search";
import { Home } from "./icons/home";
import { Spinner } from "./icons/spinner";
import { Success } from "./icons/success";
import { Warn } from "./icons/warn";
import { Tooltip } from "./icons/tooltip";
import { TooltipActive } from "./icons/tooltip-active";
import { GreenUp } from "./icons/green-up";
import { NavtoggleClosed } from "./icons/navtoggle-closed";
import { NavtoggleOpen } from "./icons/navtoggle-open";
import { ThreeDotsV } from "./icons/three-dots-v";
import { useStyleParams } from "../hooks/useStyleParams";
import { Edit } from "./icons/edit";
import { Checkmark } from "./icons/checkmark";
import { ReadMore } from "./icons/read-more";
import { Circle } from "./icons/circle";
import { Profile } from "./icons/profile";
import { SignOut } from "./icons/sign-out";
import { MyProfile } from "./icons/my-profile";
import { Plus } from "./icons/plus";
import { Minus } from "./icons/minus";
import { Dropzone } from "./icons/dropzone";
import { DeleteIcon } from "./icons/deleteIcon";
import { UserOn } from "./icons/user-on";
import { UserOff } from "./icons/user-off";
import { Arrows } from "./icons/arrows";

import { Release } from "./icons/release";
import { Project } from "./icons/project";
import { Programme } from "./icons/programme";
import { Product } from "./icons/product";

import { FinishFlag } from "./icons/finishFlag";
import { StageFlag } from "./icons/stageFlag";

import { FavoriteSelected } from "./icons/favorite-selected";
import { Favorite } from "./icons/favorite";

export const icons = (props) => ({
  "chevron-right": <ChevronRight {...props} />,
  "chevron-up": <ChevronUp {...props} />,
  "chevron-down": <ChevronDown {...props} />,
  folder: <Folder {...props} />,
  search: <Search {...props} />,
  home: <Home {...props} />,
  spinner: <Spinner {...props} />,
  success: <Success {...props} />,
  danger: <Danger {...props} />,
  warn: <Warn {...props} />,
  "read-more": <ReadMore {...props} />,
  tooltip: <Tooltip {...props} />,
  "tooltip-active": <TooltipActive {...props} />,
  "green-up": <GreenUp {...props} />,
  "navtoggle-closed": <NavtoggleClosed {...props} />,
  "navtoggle-open": <NavtoggleOpen {...props} />,
  "three-dots-v": <ThreeDotsV {...props} />,
  edit: <Edit {...props} />,
  checkmark: <Checkmark {...props} />,
  circle: <Circle {...props} />,
  profile: <Profile {...props} />,
  "sign-out": <SignOut {...props} />,
  "my-profile": <MyProfile {...props} />,
  plus: <Plus {...props} />,
  minus: <Minus {...props} />,
  dropzone: <Dropzone {...props} />,

  release: <Release {...props} />,
  project: <Project {...props} />,
  programme: <Programme {...props} />,
  product: <Product {...props} />,

  "flag-finish": <FinishFlag {...props} />,
  "flag-stage": <StageFlag {...props} />,

  "favorite-selected": <FavoriteSelected {...props} />,
  favorite: <Favorite {...props} />,
  "delete-icon": <DeleteIcon {...props} />,
  "user-on": <UserOn {...props} />,
  "user-off": <UserOff {...props} />,
  arrows: <Arrows {...props} />,
});

export const Icon = ({
  i,
  size = "nm",
  flip = false,
  onClick = () => null,
  color = null,
  disabled = false,
  ...rest
}) => {
  const { className, style } = useStyleParams(rest);
  if (isNil(i)) {
    return null;
  }

  let negOffset = "-3px";
  switch (size) {
    case "nm":
      negOffset = "-3px";
      break;
    case "md":
      negOffset = "-6px";
      break;
    case "lg":
      negOffset = "-12px";
      break;
    default:
      break;
  }

  const props = {
    onClick: disabled ? () => null : onClick,
    className: `icon bs-${size} ${flip ? "icon--flip" : ""} ${className}`,
    // Baselining -4px because that is usually a good value if icons are inline with txt
    style: { marginBottom: negOffset, ...style },
    color: color,
  };

  let file = null;

  const icon = icons(props)[i];

  return icon !== undefined ? icon : <Search {...props} />;

  /*
  switch (i) {
    case 'chevron-right':
      return <ChevronRight {...props} />
    case 'chevron-up':
      return <ChevronUp {...props} />
    case 'chevron-down':
      return <ChevronDown {...props} />
    case 'folder':
      return <Folder {...props} />
    case 'search':
      return <Search {...props} />
    case 'home':
      return <Home {...props} />
    case 'spinner':
      return <Spinner {...props} />
    case 'success':
      return <Success {...props} />
    case 'danger':
      return <Danger {...props} />
    case 'warn':
      return <Warn {...props} />
    case 'read-more':
      return <ReadMore {...props} />
    case 'tooltip':
      return <Tooltip {...props} />
    case 'tooltip-active':
      return <TooltipActive {...props} />
    case 'green-up':
      return <GreenUp {...props} />
    case 'navtoggle-closed':
      return <NavtoggleClosed {...props} />
    case 'navtoggle-open':
      return <NavtoggleOpen {...props} />
    case 'three-dots-v':
      return <ThreeDotsV {...props} />
    case 'edit':
      return <Edit {...props} />
    case 'checkmark':
      return <Checkmark {...props} />
    case 'circle':
      return <Circle {...props} />
    case 'profile':
      return <Profile {...props} />
    case 'sign-out':
      return <SignOut {...props} />
    case 'my-profile':
      return <MyProfile {...props} />
    case 'plus':
      return <Plus {...props} />
    case 'minus':
      return <Minus {...props} />
    case 'dropzone':
      return <Dropzone {...props} />


    case 'release':
      return <Release {...props} />
    case 'project':
      return <Project {...props} />
    case 'programme':
      return <Programme {...props} />
    case 'product':
      return <Product {...props} />

    case 'flag-finish':
      return <FinishFlag {...props} />
    case 'flag-stage':
      return <StageFlag {...props} />


    default:
      return <Search {...props} />
  }
*/
};
