
export const statusDef = {
  notStarted: 'notStarted',
  inProgress: 'inProgress',
  overdue: 'overdue',
  done: 'done'
}

export const reportStatus = {
  green: 'green',
  yellow: 'yellow',
  red: 'red',
}
