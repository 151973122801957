import React, { ReactNode, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export type UseConfirmDialogProps = {
  onConfirm: () => void;
  onCancel?: () => void;
  content: ReactNode;
  cancelText?: string;
  confirmText?: string;
};

const useConfirmDialog = ({
  onConfirm,
  onCancel,
  content,
  cancelText,
  confirmText,
}: UseConfirmDialogProps) => {
  const [open, setOpen] = useState(false);

  const show = useCallback(() => {
    setOpen(true);
  }, []);

  const onConfirmInternal = useCallback(() => {
    setOpen(false);
    onConfirm();
  }, [onConfirm]);

  const onCancelInternal = useCallback(() => {
    setOpen(false);
    onCancel?.();
  }, [onCancel]);

  return {
    open,
    show,
    dialog: (
      <Dialog
        open={open}
        onClose={onCancelInternal}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: 300,
          },
        }}
      >
        <DialogContent>
          <Typography variant="h2">{content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onCancelInternal}>
            {cancelText ?? "Cancel"}
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            color="error"
            variant="contained"
            onClick={onConfirmInternal}
          >
            {confirmText ?? "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    ),
  };
};

export default useConfirmDialog;
