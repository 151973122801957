import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const OtherTerms = () => (
  <Section id="13">
    <Typography variant="h2">
      13. The parties' agreement on other terms
    </Typography>
    <ol>
      <li>
        The parties may agree other clauses concerning the provision of the
        per-sonal data processing service specifying e.g. liability, as long as
        they do not contradict directly or indirectly the Clauses or prejudice
        the fundamental rights or freedoms of the data subject and the
        protection afforded by the GDPR.
      </li>
    </ol>
  </Section>
);
