import React, { useContext } from 'react';
import { ErrorRecovery } from '../ErrorRecoveryProvider';
import { SecondaryButton } from '../base/Button';
import { isDev } from '../util/isDev';

export const ReRenderButton = () => {
  const { rerender } = useContext(ErrorRecovery);

  return isDev() ? <SecondaryButton onClick={rerender}>Re-Render</SecondaryButton> : null;
};

export class GenericErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log('Cought Error in GenericErrorHandler boundary:');
    console.error(error);
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (<>
          <h2>An Error occured. Please try again.</h2>
          <p><ReRenderButton/></p>
        </>
      );
    }
    return children;
  }

}
