import React from 'react';

export const SignOut = ({ className, style, onClick }) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 10.3121H13.4688" stroke="#111827" strokeWidth="1.1875" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path d="M10.5 13.2809L13.4688 10.3121L10.5 7.34338" stroke="#111827" strokeWidth="1.1875"
            strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M3.02344 13.9507C3.84047 15.6291 5.19871 16.9837 6.87937 17.7963C8.56002 18.6088 10.4653 18.832 12.2882 18.4298C14.1111 18.0277 15.7457 17.0236 16.9285 15.5794C18.1113 14.1352 18.7735 12.3349 18.8085 10.4684C18.8435 8.60197 18.2493 6.77808 17.1215 5.2905C15.9937 3.80293 14.398 2.73828 12.5914 2.26805C10.7848 1.79782 8.87254 1.94937 7.16259 2.6983C5.45264 3.44723 4.04456 4.74993 3.16515 6.39658"
        stroke="#111827" strokeWidth="1.1875" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

