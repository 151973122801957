import React from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { programme } from "../../../queries";
import { HighlightKnowledgeReportForm } from "../../projects/forms/HighlightReportForm";
import { useQ } from "../../../hooks/useQ";

export const KnowledgeEditForm = () => {
  const { id = "", reportId = "", type } = useParams();
  const { isLoading, data = {} } = useQ(`programme-${id}`, () =>
    programme.single({ id }),
  );

  const { title, level } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <HighlightKnowledgeReportForm
        state={"edit"}
        programmeId={id}
        level={level}
        reportId={reportId}
        type={type}
      />
    </Page>
  );
};
