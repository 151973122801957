import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import { project, programme } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { HtmlReadMoreTypography } from "../../../base/typography/HtmlReadMoreTypography";
import qualityApproachClip from "../../../assets/cliparts/qualityApproach.svg";
import { useToast } from "../../../hooks/useToast";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { DisplayContent } from "../../../base/content/DisplayContent";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";

export const RiskApproachForm = ({
  data,
  type = "project",
  projectId,
  programmeId,
  onClose,
  onSuccess,
}) => {
  const { t } = useT();
  const toast = useToast();

  const schema = yup.object().shape({
    process: yup.string().trim(),
    tools: yup.string().trim(),
    reporting: yup.string().trim(),
    timing: yup.string().trim(),
    roles: yup.string().trim(),
    tolerance: yup.string().trim(),
    budget: yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      process: data?.process ?? "",
      reporting: data?.reporting ?? "",
      roles: data?.roles ?? "",
      timing: data?.timing ?? "",
      tools: data?.tools ?? "",
      tolerance: data?.tolerance ?? "",
      budget: data?.budget ?? "",
    },
  });

  const { handleSubmit, reset } = formData;

  useEffect(() => {
    reset({
      process: data?.process ?? "",
      reporting: data?.reporting ?? "",
      roles: data?.roles ?? "",
      timing: data?.timing ?? "",
      tools: data?.tools ?? "",
      tolerance: data?.tolerance ?? "",
      budget: data?.budget ?? "",
    });
  }, [data, reset]);

  const updateApproach = useMutation(
    type === "project"
      ? project.updateRiskApproach
      : programme.updateRiskApproach,
    {
      onSuccess: (data) => {
        toast.success(t("project.risk.approach.successfulUpdate"));
      },
      onError: () => {
        toast.error(t("project.risk.approach.updateFailed"));
      },
    },
  );

  const handleCreateOrUpdateApproach = useMemo(
    () =>
      handleSubmit((formData) => {
        updateApproach.mutate(
          {
            projectId,
            programmeId,
            approach: formData,
          },
          {
            onSuccess: () => {
              onClose();
              onSuccess();
            },
          },
        );
      }),
    [handleSubmit, projectId, updateApproach, data],
  );

  return (
    <FormDataProvider formKey="project.risk.approach.fields" {...formData}>
      <form onSubmit={handleCreateOrUpdateApproach}>
        <Stack spacing={2}>
          {[
            "process",
            "tools",
            "reporting",
            "timing",
            "roles",
            "tolerance",
            "budget",
          ].map((fieldName) => (
            <FormRichTextField name={fieldName} />
          ))}
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="outlined" onClick={onClose}>
              {t("project.risk.approach.actions.cancel")}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {t(
                !data
                  ? "project.risk.approach.actions.createNew"
                  : "project.risk.approach.actions.update",
              )}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormDataProvider>
  );
};

export const RiskApproach = ({
  type = "project",
  projectId,
  programmeId,
  isLoading,
  title,
  approach,
  refetch,
}) => {
  const { t } = useT();

  const [displayForm, showForm] = useState(false);

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.risk.title")}>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Box flex={3}>
            <Typography variant="h2">
              {t("project.risk.approach.title")}
            </Typography>
            <Typography
              dangerouslySetInnerHTML={{
                __html: t("project.risk.approach.description"),
              }}
            />
          </Box>
          <Box flex={1} textAlign="right">
            <img
              src={qualityApproachClip}
              alt={"Project Approach Method Clipart"}
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Stack>
        {displayForm ? (
          <RiskApproachForm
            data={approach}
            type={type}
            projectId={projectId}
            programmeId={programmeId}
            onClose={() => showForm(false)}
            onSuccess={() => refetch()}
          />
        ) : !!approach ? (
          <Stack spacing={2}>
            <Button color="secondary" onClick={() => showForm(true)}>
              {t("generic.button.edit")}
            </Button>
            <DisplayContent
              area="risk.approach.fields"
              data={approach ?? {}}
              fields={[
                "process",
                "tools",
                "reporting",
                "timing",
                "roles",
                "tolerance",
                "budget",
              ]}
            />
          </Stack>
        ) : (
          <Paper sx={{ p: 3 }}>
            <Stack spacing={2}>
              <Typography>{t("project.risk.approach.noContent")}</Typography>
              <Button
                onClick={() => showForm((v) => !v)}
                color="secondary"
                endIcon={<ArrowCircleRightOutlinedIcon />}
              >
                {t("project.risk.approach.actions.createNew")}
              </Button>
            </Stack>
          </Paper>
        )}
      </Section>
    </Page>
  );
};

export const ProjectRiskApproach = () => {
  const { t } = useT();
  const { id = "" } = useParams();

  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );
  const {
    isLoading: isLoadingApproach,
    data: approachData = {},
    refetch: refetchApproach,
  } = useQ(`project-${id}-risk-approach`, () => project.riskApproach({ id }));

  const { title } = data;

  const approach = approachData?.approach;

  return (
    <RiskApproach
      isLoading={isLoading || isLoadingApproach}
      title={title}
      projectId={id}
      approach={approach}
      refetch={refetchApproach}
    />
  );
};
