import React, { useContext } from 'react';
import { GenericErrorHandler } from './GenericErrorHandler';
import { SimpleLayout } from './SimpleLayout';
import { AuthenticationContext } from '../auth/AuthenticationProvider';
import { AuthenticatedLayout } from './AuthenticatedLayout';


// Page switches layout depending on if the user is authenticated
export const DynamicPage = ({ children }) => {
  const [auth, _] = useContext(AuthenticationContext);
  const Layout = auth.authenticated ? AuthenticatedLayout : SimpleLayout;
  return (
    <Layout>
      <div className={'page'}>
        <GenericErrorHandler>
          {children}
        </GenericErrorHandler>
      </div>
    </Layout>
  );
};
