import React, { createContext, useContext } from "react";
import { FormProvider } from "react-hook-form";

export const FormDataContext = createContext();

export const FormDataProvider = ({ formKey, children, ...formData }) => {
  return (
    <FormProvider {...formData}>
      <FormDataContext.Provider value={{ formKey }}>
        {children}
      </FormDataContext.Provider>
    </FormProvider>
  );
};

export const useFormDataContext = () => {
  const formDataContext = useContext(FormDataContext);

  return (
    formDataContext ?? {
      formKey: null,
    }
  );
};
