import React from "react";
import { Section } from "../../../layout/Section";
import { isNil } from "ramda";
import { useState, useCallback } from "react";
import { OrganizationForm } from "../forms/OrganizationForm";
import { OrganizationDropDown } from "./OrganizationDropDown";
import { project, programme, product } from "../../../queries";
import {
  Box,
  IconButton,
  Typography,
  Collapse,
  Chip,
  Stack,
  Button,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomAccordion } from "../../../layout/CustomAccordion";
import { useT } from "../../../hooks/useT";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { CustomDialog } from "@/layout/CustomDialog";

function updateMembers(existingMembers, member) {
  const members = isNil(existingMembers) ? [] : existingMembers;
  console.log(
    "Update " + JSON.stringify(members) + " with " + JSON.stringify(member),
  );
  return members.concat(member);
}

const CategoryTitle = ({ sectionTitle, help, warning }) => {
  const [helpText, setHelpText] = useState(false);

  return (
    <Section
      title={
        <Stack spacing={!!helpText ? 2 : 0}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ width: "100%" }}
            spacing={2}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                gap: "15px",
                flex: "none",
                order: "0",
                flexGrow: "0",
              }}
            >
              <Typography variant="h1">{sectionTitle}</Typography>
              {!!warning ? (
                <Chip
                  avatar={<InfoOutlinedIcon />}
                  color="warning"
                  label={warning}
                />
              ) : null}
            </Box>

            {!isNil(help) ? (
              <Stack alignItems="flex-end">
                <IconButton onClick={() => setHelpText(!helpText)}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Stack>
            ) : null}
          </Stack>
          <Collapse
            in={helpText}
            style={{
              border: "solid 1px darkgrey",
              borderRadius: 5,
            }}
          >
            <Typography
              sx={{
                fontStyle: "italic",
                color: "grey",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "16px",
                padding: 2,
              }}
            >
              {help}
            </Typography>
          </Collapse>
        </Stack>
      }
    />
  );
};

export const OrganizationCategory = ({
  sectionTitle,
  roles,
  addProjectMemberText,
  help,
  validRoles,
  category,
  warning,
  onChange,
  refetch,
  type = "project",
  tKey = "project.organization",
}) => {
  const { t } = useT();
  const members = isNil(roles)
    ? []
    : roles.filter((item) => validRoles.includes(item.role));
  if (members !== undefined) {
    members.sort((a, b) => a.name.localeCompare(b.name));
    members.sort(function (a, b) {
      return validRoles.indexOf(a.role) - validRoles.indexOf(b.role);
    });
  }
  const [showNewItemForms, setShowNewItemForms] = useState(
    (members ?? []).map(() => false),
  );
  const [editItemIds, setEditItemIds] = useState(
    (members ?? []).map(() => null),
  );

  const handleShowEditItemForm = useCallback((index, itemId) => {
    setShowNewItemForms((editItemIds) => [
      ...editItemIds.slice(0, index),
      false,
      ...editItemIds.slice(index + 1),
    ]);
    setEditItemIds((editItemIds) => [
      ...editItemIds.slice(0, index),
      itemId,
      ...editItemIds.slice(index + 1),
    ]);
  }, []);

  return (
    <React.Fragment>
      {
        <Box sx={{ width: "100%", mb: 8 }}>
          <CategoryTitle
            sectionTitle={sectionTitle}
            help={help}
            warning={warning}
          />
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Button
              variant="text"
              startIcon={<AddCircleOutlineOutlined />}
              onClick={() => {
                setShowNewItemForms((editItemIds) => [
                  ...editItemIds.slice(0, 0),
                  !editItemIds[0],
                  ...editItemIds.slice(0 + 1),
                ]);
                setEditItemIds((editItemIds) => [
                  ...editItemIds.slice(0, 0),
                  null,
                  ...editItemIds.slice(0 + 1),
                ]);
              }}
            >
              {!showNewItemForms[0]
                ? addProjectMemberText
                : t("generic.button.close")}
            </Button>
          </Stack>
          <Box sx={{ mt: "5px" }}>
            <CustomAccordion
              tableView
              header={{
                columns: [
                  {
                    title: t("project.organization.headers.role"),
                    flex: 1,
                  },
                  { title: t("project.organization.headers.name"), flex: 2 },
                  { title: t("project.organization.headers.email"), flex: 2 },
                ],
                expanded: false,
                details: (
                  <OrganizationForm
                    isOpen={showNewItemForms[0] ? true : false}
                    formMethod={
                      type === "project"
                        ? project.addRole
                        : type === "programme"
                          ? programme.addRole
                          : product.addRole
                    }
                    isEdit={false}
                    toggle={() =>
                      setShowNewItemForms((editItemIds) => [
                        ...editItemIds.slice(0, 0),
                        !editItemIds[0],
                        ...editItemIds.slice(0 + 1),
                      ])
                    }
                    validRoles={validRoles}
                    category={category}
                    onSubmit={(contact, details) => {
                      onChange(
                        updateMembers(members, {
                          name: contact.name,
                          email: contact.email,
                          role: contact.role,
                          customRole: contact.customRole,
                          details: contact.details,
                        }),
                      );
                      setShowNewItemForms();
                    }}
                    refetch={refetch}
                    tKey={tKey}
                  />
                ),
              }}
              rows={(members ?? []).map((element) => ({
                onClick: () =>
                  editItemIds[0] !== element.role + element.name
                    ? handleShowEditItemForm(0, element.role + element.name)
                    : handleShowEditItemForm(0, null),
                id: element.id,
                columns: [
                  {
                    title:
                      element.role != undefined
                        ? !element.role.includes("Custom-")
                          ? t(
                              tKey +
                                "." +
                                category +
                                ".roles." +
                                element.role +
                                ".title",
                            )
                          : element.role.replace("Custom-", "")
                        : "",
                    flex: 1,
                  },
                  { title: element.name, flex: 2 },
                  { title: element.email, flex: 2 },
                ],
                expanded: editItemIds[0] === element.role + element.name,
                details: (
                  <OrganizationDropDown
                    name={element.name}
                    email={element.email}
                    role={element.role}
                    toggle={() => handleShowEditItemForm(0, null)}
                    validRoles={validRoles}
                    category={category}
                    onSubmit={(contact, roles, details) => {
                      () => handleShowEditItemForm(0, null);
                    }}
                    customRole={
                      element.role.includes("Custom-")
                        ? element.role.replace("Custom-", "")
                        : ""
                    }
                    details={element.details}
                    refetch={refetch}
                    type={type}
                    tKey={tKey}
                  />
                ),
              }))}
            />
          </Box>
        </Box>
      }
    </React.Fragment>
  );
};
