import React from "react";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { Button } from "../../base/Button";
import { uris } from "../../config/nav";
import { DynamicPage } from "../../layout/DynamicPage";

export const E401 = () => {
  const { t } = useT();
  return (
    <DynamicPage>
      <Section title={t("page.401.title")}>
        <p>{t("page.401.body")}</p>
        <p>&nbsp;</p>
        <p>
          <a className="btn btn-primary" href={uris.login}>
            {t("page.401.goto_login")}
          </a>
        </p>
      </Section>
    </DynamicPage>
  );
};
