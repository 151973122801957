import { CustomAccordion } from "../../../../layout/CustomAccordion";
import { Section } from "../../../../layout/Section";
import { Page } from "../../../../layout/Page";
import {
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { FormDataProvider } from "../../../../base/form/data-context/FormDataContext";

import { useQ } from "../../../../hooks/useQ";
import { tailoring, category } from "../../../../queries";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect } from "../../../../base/form/mui/FormSelect";
import { useMutation } from "react-query";
import { useToast } from "../../../../hooks/useToast";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const maturityLevels = [
  "Level 1: Only Organization theme, no highlight reports and no guidance on starting up activities",
  "Level 2: Without Plan, Business Case and Quality themes",
  "Standard: All PRINCE2 themes are available",
];

function maturityDescription(level) {
  return level !== undefined
    ? maturityLevels[level - 1]
    : maturityLevels[3 - 1];
}

const TailoringForm = ({ phase, onSave, onClose, open, state, ...props }) => {
  const schema = yup.object().shape({
    level: yup.number().required(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      level: phase?.level || 3,
    },
  });

  const { handleSubmit, reset } = formData;

  const options = useMemo(
    () =>
      maturityLevels
        .map((item, index) => ({
          label: item,
          value: index + 1,
        }))
        .reverse(),
    [],
  );

  return (
    <Paper
      sx={{
        padding: "30px 20px",
        display: open ? "block" : "none",
      }}
      {...props}
    >
      <FormDataProvider formKey="accountSettings.tailoring" {...formData}>
        <form
          onSubmit={handleSubmit((data) => {
            onSave({
              level: data.level,
            });
            state === "add" ? reset() : null;
          })}
        >
          <FormSelect
            name={"level"}
            label={"Maturity Level"}
            help=""
            options={options}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Stack>
        </form>
      </FormDataProvider>
    </Paper>
  );
};

export const Tailoring = () => {
  const { data: categories = [], refetch: refetchCategories } = useQ(
    `projectTypes`,
    () => category.list(),
  );

  const toast = useToast();

  const tailorCategory = useMutation(tailoring.category, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Category tailored successfully");
      refetchCategories();
      handleShowEditItemForm(null);
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error tailor category");
    },
  });

  const [editItemIds, setEditItemIds] = useState(
    (categories ?? []).map(() => false),
  );

  useEffect(() => {
    setEditItemIds((categories ?? []).map(() => false));
  }, [categories]);

  const handleShowEditItemForm = useCallback((index) => {
    setEditItemIds((editItemIds) => [
      ...editItemIds.slice(0, index),
      !editItemIds[index],
      ...editItemIds.slice(index + 1),
    ]);
  }, []);

  return (
    <Page>
      <Section title="Tailoring">
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography>
            By default projects in all categories have all PRINCE2 themes
            available. Tailoring is the process of adjusting themes and
            processes to the enviroment and maturity level of the organization.
          </Typography>
        </Stack>
        {categories.map((category, index) => (
          <Box sx={{ width: "100%", mt: 5 }} key={category.id}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h2">{`Tailoring for "${category.name}"`}</Typography>
            </Stack>
            <Box sx={{ mt: "20px" }}>
              <CustomAccordion
                tableView
                header={{
                  columns: [
                    { title: "Type", flex: 1 },
                    { title: "Description", flex: 4 },
                  ],
                }}
                rows={(category.level
                  ? [
                      {
                        text: "Maturity level",
                        description: maturityDescription(category.level),
                        level: category.level,
                      },
                    ]
                  : [
                      {
                        text: "Maturity level",
                        description: maturityLevels[3 - 1],
                        level: 3,
                      },
                    ]
                ).map((phase) => ({
                  id: phase.id,
                  columns: [
                    {
                      content: (
                        <Typography sx={{ fontWeight: "bold" }}>
                          {phase.text}
                        </Typography>
                      ),
                      flex: 1,
                      sx: { alignSelf: "flex-start" },
                    },
                    {
                      title:
                        phase.description?.length > 200
                          ? phase.description?.substr(0, 200) + "..."
                          : phase.description,
                      flex: 4,
                    },
                  ],
                  expanded: !!editItemIds[index],
                  onClick: () =>
                    editItemIds[index] !== phase.id
                      ? handleShowEditItemForm(index)
                      : handleShowEditItemForm(index),
                  details: (
                    <TailoringForm
                      open
                      phase={phase}
                      onClose={() => {
                        handleShowEditItemForm(index);
                      }}
                      onSave={(data) => {
                        tailorCategory.mutate({
                          id: category.id,
                          ...data,
                        });
                      }}
                    />
                  ),
                }))}
              />
            </Box>
          </Box>
        ))}
      </Section>
    </Page>
  );
};
