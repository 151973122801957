import { useT } from "./useT";
import { useFormDataContext } from "../base/form/data-context/FormDataContext";
import { useCallback, useMemo } from "react";

/**
 * This function simplifies the handling of translations for form input strings.
 *  It takes name, label, help, placeholder, and fieldKey, which are used to generate translations.
 *  To generate these translations, the function uses two hooks: useT and useFormDataContext. useT is used to get the translation function, while useFormDataContext is used to get the form data.
 *  The translationKey hook is then used to generate a translation key, which is based on the formKey, fieldKey, and name properties from the input object. This key is then used to check whether a translation exists for the specific string type, such as title, placeholder, or help. If fieldKey is provided, it is used instead of name.
 *  checkTranslation function takes a string type and checks whether a translation exists for the specific string type key. If a translation exists, it returns the translation; otherwise, it returns null.
 *  If a custom label, placeholder, or help string is provided, it is used instead of the translation.
 *  The function returns an object of input strings, including label, placeholder, and help strings.
 *  @param {Object} inputObject - An object that has properties like name, label, help, placeholder, and fieldKey.
 *  @returns {Object} An object of input strings, including label, placeholder, and help strings.
 */
export const useFormStrings = ({
  name,
  label,
  help,
  placeholder,
  fieldKey,
}) => {
  const { t } = useT();
  const { formKey } = useFormDataContext();

  const translationKey = useMemo(
    () => (formKey ? `${formKey}.${fieldKey ?? name}` : null),
    [formKey, fieldKey, name],
  );

  const checkTranslation = useCallback(
    (stringType) =>
      translationKey
        ? t(`${translationKey}.${stringType}`)?.length
          ? t(`${translationKey}.${stringType}`)
          : null
        : null,
    [t, translationKey],
  );

  const inputStrings = useMemo(
    () => ({
      label: label ?? checkTranslation("title"),
      placeholder: placeholder ?? checkTranslation("description"),
      help: help ?? checkTranslation("help"),
    }),
    [t],
  );

  return inputStrings;
};
