import React from 'react';

export const Tooltip = ({ className, style, onClick }) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6875 12.375H10.125C9.82663 12.375 9.54048 12.2565 9.3295 12.0455C9.11853 11.8345 9 11.5484 9 11.25V8.4375C9 8.28832 8.94074 8.14524 8.83525 8.03975C8.72976 7.93426 8.58668 7.875 8.4375 7.875H7.875"
        stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M8.71875 5.0625C8.66312 5.0625 8.60875 5.079 8.5625 5.1099C8.51624 5.1408 8.4802 5.18473 8.45891 5.23612C8.43762 5.28751 8.43205 5.34406 8.4429 5.39862C8.45376 5.45318 8.48054 5.50329 8.51988 5.54262C8.55921 5.58196 8.60932 5.60874 8.66388 5.6196C8.71844 5.63045 8.77499 5.62488 8.82638 5.60359C8.87777 5.5823 8.9217 5.54626 8.9526 5.5C8.9835 5.45375 9 5.39938 9 5.34375C9 5.26916 8.97037 5.19762 8.91762 5.14488C8.86488 5.09213 8.79334 5.0625 8.71875 5.0625Z"
        stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M0.5625 9C0.5625 11.2378 1.45145 13.3839 3.03379 14.9662C4.61612 16.5486 6.76224 17.4375 9 17.4375C11.2378 17.4375 13.3839 16.5486 14.9662 14.9662C16.5486 13.3839 17.4375 11.2378 17.4375 9C17.4375 6.76224 16.5486 4.61612 14.9662 3.03379C13.3839 1.45145 11.2378 0.5625 9 0.5625C6.76224 0.5625 4.61612 1.45145 3.03379 3.03379C1.45145 4.61612 0.5625 6.76224 0.5625 9V9Z"
        stroke="#111827" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};
