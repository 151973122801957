import {
  Box,
  ButtonGroup,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircleIcon from "@mui/icons-material/Circle";
import { Stat } from "@/base/Stat";
import {
  AddCircleOutlineOutlined,
  ChevronRight,
  ExpandMore,
  Preview,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { CustomAccordion } from "@/layout/CustomAccordion";
import React, { useCallback, useState } from "react";
import {
  OutputDetails,
  ProductFormDialog,
} from "@/pages/projects/forms/QualityTableForm";
import { useT } from "@/hooks/useT";

export type ProductsTableProps = {
  products: any[];
  openProductId: string;
  handleRowClick: (item: any) => void;
  onUpdate: ({ newProduct }: { newProduct: boolean }) => void;
  id: string;
  scopes: any[];
  handleDrop?: () => any;
  viewOnly?: boolean;
};

export const ProductsTable = ({
  products,
  openProductId,
  handleRowClick,
  onUpdate,
  id,
  scopes,
  handleDrop,
  viewOnly = false,
}: ProductsTableProps) => {
  const { t } = useT();

  const [showContent, setShowContent] = useState("some");
  const [showNewProductForm, setShowNewProductForm] = useState(false);

  const getOutputById = useCallback(
    (id) => products.find((item) => item.id === id),
    [products],
  );
  const [editId, setEditId] = useState(null);

  return (
    <CustomAccordion
      tableView
      draggable={!!handleDrop}
      droppable={!!handleDrop}
      onDrop={handleDrop}
      header={{
        columns: [
          {
            title: t("generic.titles.id"),
            width: "150px",
          },
          {
            title: t("generic.titles.name"),
            flex: 1,
          },
          {
            title: t("generic.titles.status"),
            flex: 1,
          },
          {
            title: t("project.quality.table.signOffDate"),
            flex: 1,
          },
          {
            title: t("project.quality.table.producer"),
            flex: 1,
          },
          {
            title: t("project.quality.table.approver"),
            flex: 1,
          },
        ],
      }}
      rows={products.map((item, index) => ({
        id: item.id,
        expanded: item.id === openProductId,
        onClick: () => handleRowClick(item),
        columns: [
          {
            content: (
              <Stack
                direction="row"
                alignItems="center"
                spacing="4px"
                sx={{
                  "& .MuiTypography-root": {
                    fontWeight: item.id === openProductId ? "bold" : undefined,
                  },
                }}
              >
                {item.position.map((_p, index2) =>
                  index2 > 0 && index2 === item.position.length - 1 ? (
                    <MoreVertIcon sx={{ ml: "-7px" }} />
                  ) : index2 > 0 ? (
                    <CircleIcon sx={{ width: "8px" }} />
                  ) : null,
                )}
                <Typography>{item.position.join(".")}</Typography>
              </Stack>
            ),
            width: "150px",
          },
          {
            content: (
              <Box>
                <Typography dangerouslySetInnerHTML={{ __html: item.title }} />
              </Box>
            ),
            flex: 1,
          },
          {
            content:
              typeof item.status === "number" ? (
                <Box>
                  <Typography>{Math.round(item.status)}%</Typography>
                </Box>
              ) : (
                <Box>
                  <Stat status={item.status} />
                </Box>
              ),
            flex: 1,
          },
          {
            title: item.signOffDate ?? "-",
            flex: 1,
          },
          {
            title: item.producer ?? "-",
            flex: 1,
          },
          {
            title: item.id === openProductId ? null : item.approver ?? "-",
            content: item.id === openProductId && (
              <Stack direction="row">
                <ButtonGroup>
                  {!viewOnly && showContent === "none" && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowNewProductForm(true);
                      }}
                    >
                      <AddCircleOutlineOutlined
                        sx={{
                          color: "rgb(170, 170, 170)",
                        }}
                      />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowContent("some");
                    }}
                  >
                    <Preview
                      sx={{
                        color:
                          showContent !== "some" ? "rgb(170, 170, 170)" : "",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowContent("none");
                    }}
                  >
                    <VisibilityOff
                      sx={{
                        color:
                          showContent !== "none" ? "rgb(170, 170, 170)" : "",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowContent("all");
                    }}
                  >
                    <Visibility
                      sx={{
                        color:
                          showContent !== "all" ? "rgb(170, 170, 170)" : "",
                      }}
                    />
                  </IconButton>
                </ButtonGroup>
                <Box onClick={(e) => e.stopPropagation()}>
                  <ProductFormDialog
                    output={null}
                    parent={item}
                    grandParent={getOutputById(item?.parentId)}
                    showNewProductForm={showNewProductForm}
                    onClose={() => {
                      setShowNewProductForm(false);
                    }}
                    onUpdate={() => {
                      onUpdate({
                        newProduct: true,
                      });
                      setShowNewProductForm(false);
                    }}
                    scopeId={item.scopeId || item.parentId}
                    state={"add"}
                    scopes={scopes}
                    setEditId={setEditId}
                    editId={editId}
                  />
                </Box>
              </Stack>
            ),
            flex: 1,
          },
        ],
        details: (
          <OutputDetails
            id={id}
            output={item}
            parent={getOutputById(item.parentId)}
            grandParent={getOutputById(getOutputById(item.parentId)?.parentId)}
            expandable={item.children.length > 0}
            onUpdate={() =>
              onUpdate({
                newProduct: false,
              })
            }
            scopeId={item.scopeId || item.parentId}
            showContent={showContent}
            scopes={scopes}
            viewOnly={viewOnly}
            setEditId={setEditId}
            editId={editId}
          />
        ),
      }))}
    />
  );
};
