import { useQ } from "@/hooks/useQ";
import { users } from "@/queries";
import { Section } from "@/layout/Section";
import { Stack, Typography } from "@mui/material";
import React from "react";

export const Contacts = () => {
  const { isLoading, data } = useQ(`users-me`, users.me);

  return (
    <Section id="15">
      <Typography variant="h2">
        15. Data controller and data processor contacts/contact points
      </Typography>
      <ol>
        <li>
          The parties may contact each other using the following
          contacts/contact points:
        </li>

        <li>
          The parties shall be under obligation continuously to inform each
          other of changes to contacts/contact points.
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography>
              Name {data?.name}
              <br />
              Position Administrator
              <br />
              E-mail <a href="mailto:{data?.email}">{data?.email}</a>
            </Typography>
            <Typography>
              Name Olav Loen
              <br />
              Position CEO
              <br />
              E-mail{" "}
              <a href="mailto:support@progreto.com">support@progreto.com</a>
            </Typography>
          </Stack>
        </li>
      </ol>
    </Section>
  );
};
