import React from 'react';

// Meant for controls (submit button etc). Buttons pull right in here
export const ControlSection = ({children}) => {
  return (
    <div className={'control-section'}>
      {children}
    </div>
  )
};
