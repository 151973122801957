import React from "react";
import { useT } from "@/hooks/useT";
import { path, pathOr } from "ramda";
import { SummaryCard } from "@/base/project/SummaryCard";
import { Stat } from "@/base/Stat";
import { DatePretty } from "@/base/DatePretty";
import { RiskSection, IssueSection } from "./RiskAndIssueSections";

import { Section } from "@/layout/Section";
import { Typography, Stack } from "@mui/material";
import { CustomAccordion } from "@/layout/CustomAccordion";
import { Link } from "react-router-dom";
import { clientUrl } from "@/util/routing";
import { uris } from "@/config/nav";
import { HtmlTypography } from "@/base/typography/HtmlTypography";
import { RelatedLesson } from "./IssueRegister";

export const StatusTable = ({ report }) => {
  const { t } = useT();

  const names = ["benefits", "time", "cost", "quality", "scope", "risk"];

  const data = names.map((name) => {
    return {
      aspect: t("project.progress.highlightReport.fields." + name + ".title"),
      status: path([name, "status"], report),
      description: path([name, "content"], report),
    };
  });
  const cols = "200px 150px 1fr ";
  return (
    <CustomAccordion
      tableView
      header={{
        columns: [
          {
            title: t("project.progress.highlightReport.fields.aspect.title"),
            flex: 4,
          },
          {
            title: t("project.progress.highlightReport.fields.status.title"),
            flex: 3,
          },
          {
            title: t(
              "project.progress.highlightReport.fields.description.title",
            ),
            flex: 15,
          },
        ],
      }}
      rows={data.map((item) => {
        return {
          expanded: false,
          expandDisabled: true,
          columns: [
            {
              content: item.aspect,
              flex: 4,
            },
            {
              content: <Stat size={"md"} status={item.status} />,
              flex: 3,
            },
            {
              content: <HtmlTypography>{item.description}</HtmlTypography>,
              flex: 15,
            },
          ],
        };
      })}
    />
  );
};

export const HighlightReport = ({
  initiative,
  report,
  type,
  stages,
  lessons,
}) => {
  const { t } = useT();
  console.log("report", report);
  if (!report) {
    return (
      <Section>
        <SummaryCard className={"pd-lg"}>
          {t("project.progress.highlightReport.messages.noReport.title")}{" "}
          <Link
            to={clientUrl(uris.project.create_progress, {
              id: initiative.id,
              type: type ?? "highlight",
            })}
          >
            {t("project.progress.highlightReport.messages.noReport.link")}
          </Link>
        </SummaryCard>
      </Section>
    );
  }

  const stageName =
    report?.stage?.id === initiative?.stage?.id
      ? initiative?.stage?.name
      : stages &&
        stages.filter((stage) => stage?.id === report?.stage?.id)[0]?.name;

  return (
    <>
      <Typography variant={"h1"} sx={{ textAlign: "center", mb: 1 }}>
        {t(
          "project.progress." +
            (type !== undefined ? type : "highlightReport") +
            ".title",
        )}
        {type === "endStage" && stageName ? " - " + stageName : ""}
      </Typography>
      <Typography variant={"h2"} sx={{ textAlign: "center", mb: 1 }}>
        {initiative.name} -{" "}
        <DatePretty format={"YYYY-MM-DD"} date={report.to || report.date} />
      </Typography>
      {!!pathOr(initiative.projectManager, ["manager", "name"], report) && (
        <Typography variant={"h3"} sx={{ textAlign: "center" }}>
          Managed by:{" "}
          {pathOr(initiative.projectManager, ["manager", "name"], report)}
        </Typography>
      )}
      {report?.summary && (
        <>
          <Section
            title={
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant={"h1"}>
                  {t("project.progress.highlightReport.fields.summary.title")}
                </Typography>
                <Stat status={path(["value"], report)} size={"lg"} />
              </Stack>
            }
          ></Section>
          <HtmlTypography>{report?.summary}</HtmlTypography>
        </>
      )}
      {type === "checkpoint" && report?.followUps && (
        <Section
          title={t(`project.progress.highlightReport.fields.followUps.title`)}
        >
          <HtmlTypography>{report?.followUps}</HtmlTypography>
        </Section>
      )}
      {type !== "endStage" ? (
        <>
          <Section
            title={t(
              `project.progress.${
                type === "endProject" ? "endProjectReport" : "highlightReport"
              }.fields.performedAchievements.title`,
            )}
          >
            <Typography>
              ({t("project.progress.report.from")}{" "}
              <DatePretty format={"YYYY-MM-DD"} date={report.from} />)
            </Typography>
            <HtmlTypography>{report?.performedAchivements}</HtmlTypography>
          </Section>

          {type !== "endProject" && report?.plannedAchivements && (
            <Section
              title={t(
                "project.progress.highlightReport.fields.plannedAchievements.title",
              )}
            >
              <HtmlTypography>{report?.plannedAchivements}</HtmlTypography>
            </Section>
          )}
        </>
      ) : null}
      {type !== "checkpoint" && <StatusTable report={report} />}
      {type === "endStage" ? (
        <>
          {report?.teamPerformance && (
            <Section
              title={t(
                "project.progress.highlightReport.fields.teamPerformance.title",
              )}
            >
              <HtmlTypography>{report?.teamPerformance}</HtmlTypography>
            </Section>
          )}
          {(report?.lessonsDescription || report?.lessons) && (
            <Section
              title={t(
                "project.progress.highlightReport.fields.lessonsDescription.title",
              )}
            >
              <HtmlTypography>{report?.lessonsDescription}</HtmlTypography>
              <Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  width={"100%"}
                >
                  {report?.lessons.map((lessonId) => (
                    <RelatedLesson
                      lesson={lessons?.find((lesson) => lesson.id === lessonId)}
                      projectData={initiative}
                    />
                  ))}
                </Stack>
              </Typography>
            </Section>
          )}
          {report?.performedAchivements && (
            <Section
              title={t(
                "project.progress.highlightReport.fields.deliveredThisStage.title",
              )}
            >
              <HtmlTypography>{report?.performedAchivements}</HtmlTypography>
            </Section>
          )}
        </>
      ) : null}
      {type === "endProject" && (
        <>
          {report?.teamPerformance && (
            <Section
              title={t(
                `project.progress.${
                  type === "endProject" ? "endProjectReport" : "highlightReport"
                }.fields.teamPerformance.title`,
              )}
            >
              <HtmlTypography>{report?.teamPerformance}</HtmlTypography>
            </Section>
          )}
          {(report?.lessonsDescription || report?.lessons) && (
            <Section
              title={t(
                `project.progress.${
                  type === "endProject" ? "endProjectReport" : "highlightReport"
                }.fields.lessonsDescription.title`,
              )}
            >
              <HtmlTypography>{report?.lessonsDescription}</HtmlTypography>
              <Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  width={"100%"}
                >
                  {report?.lessons.map((lessonId) => (
                    <RelatedLesson
                      lesson={lessons?.find((lesson) => lesson.id === lessonId)}
                      projectData={initiative}
                    />
                  ))}
                </Stack>
              </Typography>
            </Section>
          )}
        </>
      )}
      {type === "checkpoint" && report?.workPackage && (
        <Section
          title={t(`project.progress.highlightReport.fields.workPackage.title`)}
        >
          <HtmlTypography>{report?.workPackage}</HtmlTypography>
        </Section>
      )}
      {!!report?.risk?.items?.length && <RiskSection report={report} />}
      {!!report?.change?.items?.length && <IssueSection report={report} />}
    </>
  );
};
