import { Paper, Stack } from "@mui/material";
import React from "react";

export const ColoredCardSize = {
  SMALL: 14,
  MEDIUM: 16,
};

export const ColoredCard = ({ color, text, size = ColoredCardSize.MEDIUM }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFF",
        backgroundColor: color,
        borderRadius: 4,
        fontSize: size,
        px: 3,
        py: {
          [ColoredCardSize.SMALL]: 1,
          [ColoredCardSize.MEDIUM]: 2,
        }[size],
        fontWeight: {
          [ColoredCardSize.SMALL]: 400,
          [ColoredCardSize.MEDIUM]: 700,
        }[size],
        minWidth: 250,
        maxWidth: 250,
      }}
    >
      {text}
    </Paper>
  );
};
