import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const ProcessorInstructions = () => (
  <Section id="4">
    <Typography variant="h2">
      4. The data processor acts according to instructions
    </Typography>
    <ol>
      <li>
        The data processor shall process personal data only on documented
        instruc-tions from the data controller, unless required to do so by
        Union or Member State law to which the processor is subject. Such
        instructions shall be speci-fied in appendices A and C. Subsequent
        instructions can also be given by the data controller throughout the
        duration of the processing of personal da-ta, but such instructions
        shall always be documented and kept in writing, in-cluding
        electronically, in connection with the Clauses.{" "}
      </li>

      <li>
        The data processor shall immediately inform the data controller if
        instructions given by the data controller, in the opinion of the data
        processor, contravene the GDPR or the applicable EU or Member State data
        protection provisions.
      </li>
    </ol>
  </Section>
);
