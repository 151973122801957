import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { useQ } from "../../hooks/useQ";
import { project, programme, release, product } from "../../queries";
import { useT } from "../../hooks/useT";
import { isEmpty, isNil, path, pathOr } from "ramda";
import { Box, Button, Stack } from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { useFlag } from "@/hooks/useFlag";
import { HighlightReportPdfDocument } from "@/pages/projects/components/HighlightReportPdfDocument";

export const HighlightReportPdf = ({
  projectId,
  programmeId,
  releaseId,
  productId,
  reportId,
  reportType = "project",
  type = "highlight",
  stages,
}) => {
  const { t } = useT();
  const useOptimizedReports = useFlag("project.optimize.reports");
  const { data: projectData = [] } = useQ(
    `project-${projectId}`,
    () => project.single({ id: projectId }),
    {
      enabled: reportType === "project",
    },
  );
  const { data: programmeData = [] } = useQ(
    `programme-${programmeId}`,
    () => programme.single({ id: programmeId }),
    {
      enabled: reportType === "programme",
    },
  );
  const { data: releaseData = [] } = useQ(
    `release-${releaseId}`,
    () => release.single({ id: releaseId }),
    {
      enabled: reportType === "release",
    },
  );
  const { data: productData = [] } = useQ(
    `product-${productId}`,
    () => product.single({ id: productId }),
    {
      enabled: reportType === "product",
    },
  );

  const { data: reportProjectData = [] } = useQ(
    `project-${projectId}-report-highlights`,
    () => project.report.highlights({ id: projectId }),
    {
      enabled: reportType === "project" && !useOptimizedReports,
    },
  );

  const { data: reportProjectList = [] } = useQ(
    `project-${projectId}-report-list`,
    () => project.report.list({ id: projectId }),
    {
      enabled: reportType === "project" && !!useOptimizedReports,
    },
  );
  const { data: reportProgrammeData = [] } = useQ(
    `programme-${programmeId}-report-highlights`,
    () => programme.report.highlights({ id: programmeId }),
    {
      enabled: reportType === "programme",
    },
  );
  const { data: reportReleaseData = [] } = useQ(
    `release-${programmeId}-report-highlights`,
    () => release.report.highlights({ id: releaseId }),
    {
      enabled: reportType === "release",
    },
  );
  const { data: reportProductData = [] } = useQ(
    `product-${productId}-report-highlights`,
    () => product.report.highlights({ id: productId }),
    {
      enabled: reportType === "product",
    },
  );
  const reportData =
    reportType === "project"
      ? useOptimizedReports
        ? reportProjectList
        : reportProjectData
      : reportType === "programme"
        ? reportProgrammeData
        : reportType === "product"
          ? reportProductData
          : reportReleaseData;
  const report = reportData
    .filter(
      (item, index) =>
        item.id === reportId ||
        isNil(reportId) ||
        reportId === "latest" ||
        (isEmpty(reportId) && index === 0 && type === "highlight"),
    )
    .map((item) => ({
      ...item,
      summary: path(["summary", "content"], item),
      value: path(["summary", "status"], item),
    }))[0];

  const data =
    reportType === "project"
      ? projectData
      : reportType === "programme"
        ? programmeData
        : reportType === "product"
          ? productData
          : releaseData;

  return (
    <Stack py={2} height="100%" spacing={2}>
      <PDFDownloadLink
        document={
          <HighlightReportPdfDocument
            t={t}
            project={data}
            report={report}
            type={report?.stage?.id ? "endStage" : "highlightReport"}
            stages={stages}
          />
        }
        fileName={`highlightReport_${data.name.replace(/ /g, "")}_${moment(
          report?.to || report?.date,
        ).format("YYYY-MM-DD")}`}
      >
        <Button variant="contained" endIcon={<DownloadIcon />}>
          Download PDF
        </Button>
      </PDFDownloadLink>
      <PDFViewer
        style={{
          width: "100%",
          height: "800px",
        }}
        showToolbar={false}
      >
        <HighlightReportPdfDocument
          t={t}
          project={data}
          report={report}
          type={report?.stage?.id ? "endStage" : "highlightReport"}
          stages={stages}
        />
      </PDFViewer>
    </Stack>
  );
};
