import React, { useContext, useEffect } from "react";
import { Label } from "../../base/form/Label";
import { Input } from "../../base/form/Input";
import { PrimaryButton, SecondaryButton } from "../../base/Button";
import { F } from "../../base/form/Form";
import { AuthenticationContext } from "../../auth/AuthenticationProvider";
import { uris } from "../../config/nav";
import { useSteps } from "../../hooks/useSteps";
import { Steps } from "../../base/stepper/Steps";
import { Step } from "../../base/stepper/Step";
import * as Yup from "yup";
import { useFormik } from "formik";
import { prepareFormValues } from "../../util/form";
import { useT } from "../../hooks/useT";
import { isEmpty } from "ramda";
import { useMutation } from "react-query";
import { login, users } from "../../queries";
import { useNavigate } from "react-router-dom";

import { useUnleashContext } from "@unleash/proxy-client-react";

const RequestEmailForm = ({ next }) => {
  const { t } = useT();
  const requestLink = useMutation(login.requestLink, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      if (data.source !== undefined) {
        console.log("Redirect to " + data.source);
        window.location = data.source;
      } else {
        next();
      }
    },
  });

  const requestEmail = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(t("loginForm.fields.email.requiredError")),
  });

  const loginEmailForm = useFormik({
    validationSchema: requestEmail,
    initialValues: {
      email: "",
    },
    onSubmit: (v) => {
      const data = prepareFormValues(v);
      requestLink.mutate(data);
    },
  });

  return (
    <F onSubmit={loginEmailForm.handleSubmit}>
      <Label
        htmlFor={"email"}
        error={loginEmailForm.errors.email}
        touched={loginEmailForm.touched}
        title={t("loginForm.fields.email.title")}
      >
        <Input
          value={loginEmailForm.values.email}
          onChange={loginEmailForm.handleChange}
          placeholder={t("loginForm.fields.email.placeholder")}
          className={"input__login"}
          name={"email"}
        />
      </Label>

      <br />
      <br />
      <PrimaryButton type={"submit"} disabled={!isEmpty(loginEmailForm.errors)}>
        Next
      </PrimaryButton>
    </F>
  );
};

const ValidateEmailCodeForm = ({ last }) => {
  const { t } = useT();
  const navigate = useNavigate();
  const [auth, dispatch] = useContext(AuthenticationContext);

  const requestAuthentication = useMutation(login.requestAuth, {
    onSuccess: ({ data }) => {
      dispatch({ type: "LOGIN", payload: data });
      navigate(uris.home);
    },
  });

  const loginCode = Yup.object().shape({
    code: Yup.string()
      .trim()
      .required(t("loginForm.fields.code.requiredError")),
  });

  const loginCodeForm = useFormik({
    validationSchema: loginCode,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      code: "",
    },
    onSubmit: (v) => {
      const data = prepareFormValues(v);
      requestAuthentication.mutate({ code: v.code });
    },
  });

  return (
    <F onSubmit={loginCodeForm.handleSubmit}>
      <Label
        htmlFor={"code"}
        error={loginCodeForm.errors.code}
        touched={loginCodeForm.touched}
        title={t("loginForm.fields.code.title")}
      >
        <Input
          value={loginCodeForm.values.code}
          onChange={loginCodeForm.handleChange}
          name={"code"}
          className={"input__login"}
          placeholder={t("loginForm.fields.code.placeholder")}
        />
      </Label>
      <br />
      <br />
      <SecondaryButton onClick={last}>
        {t("generic.button.back")}
      </SecondaryButton>
      <PrimaryButton className={"mg-l-nm"} type={"submit"}>
        {t("loginForm.submit")}
      </PrimaryButton>
    </F>
  );
};

export const LinkLoginForm = () => {
  const [auth, dispatch] = useContext(AuthenticationContext);
  const stepper = useSteps({ steps: [{ title: "Login" }, { title: "Code" }] });
  const navigate = useNavigate();

  const updateContext = useUnleashContext();

  useEffect(() => {
    if (auth.authenticated) {
      console.log(
        "[LoginForm] redirect to home if user information is available",
      );
      users
        .me()
        .then((result) => {
          console.log(
            "[INFO] Retrieved user information, login user: " +
              JSON.stringify(result),
          );
          dispatch({ type: "LOGIN", payload: result });
          navigate(uris.home);
          updateContext({
            userId: result.id,
            hostName: window.location.hostname,
            account: result.currentTenant,
          });
        })
        .catch((error) => {
          console.log(
            "[WARN] Not able to access user information, log out",
            error,
          );
          dispatch({ type: "LOGOUT" });
        });
    }
  }, [auth]);

  return (
    <div style={{ width: "100%", height: "100%" }} className={"d-f"}>
      <Steps>
        <Step stepper={stepper} step={0}>
          <div className={"d-f f-1 f-ai-fe"}>
            <RequestEmailForm next={stepper.next} />
          </div>
        </Step>
        <Step stepper={stepper} step={1}>
          <div className={"d-f f-1 f-ai-fe"}>
            <ValidateEmailCodeForm last={stepper.last} />
          </div>
        </Step>
      </Steps>
    </div>
  );
};
