import React, { useContext, useEffect } from "react";
import { Home } from "./pages/home/Home";
import { E404 } from "./pages/errors/E404";
import { uris } from "./config/nav";
import { NewProject } from "./pages/projects/NewProject";
import { Project } from "./pages/projects/Project";
import { AuthenticationContext } from "./auth/AuthenticationProvider";
import { E401 } from "./pages/errors/E401";
import { Login } from "./pages/login/Login";
import { Signup } from "./pages/signup/Signup";
import { LinkLoginLander } from "./pages/login/LinkLoginLander";
import { Responsive } from "./pages/dev/Responsive";
import { Quality } from "./pages/projects/Quality";
import { Details } from "./pages/portfolio/Details";
import { Progress } from "./pages/projects/Progress";
import { E500 } from "./pages/errors/E500";
import { Plans } from "./pages/projects/Plans";
import { PlansApproach } from "./pages/projects/plans/Approach";
import { BusinessCase } from "./pages/projects/BusinessCase";
import { Change } from "./pages/projects/Change";
import { Organization } from "./pages/projects/Organization";
import { ProgressEditForm } from "./pages/projects/ProgressEditForm";
import { ProgressCreateForm } from "./pages/projects/ProgressCreateForm";
import { users } from "./queries";
import { EditLinks } from "./pages/projects/EditLinks";
import { UserAdministration } from "./pages/home/AccountSettings/UserAdministration";
import {
  useNavigate,
  Route,
  Routes,
  Outlet,
  useLocation,
} from "react-router-dom";

import { BulkImport } from "./pages/trial/BulkImport";

import { useUnleashContext } from "@unleash/proxy-client-react";
import { Properties } from "./pages/home/AccountSettings/Properties/Properties";
import { ProjectSettings as TenantProjectSettings } from "./pages/home/AccountSettings/ProjectSettings/ProjectSettings";
import { Portfolios } from "./pages/home/AccountSettings/Portfolios/Portfolios";
import { Lifecycles } from "./pages/home/AccountSettings/Lifecycles/Lifecycles";
import { Tailoring } from "./pages/home/AccountSettings/Tailoring/Tailoring";
import { DataProtectionAgreement } from "./pages/home/AccountSettings/DataProtectionAgreement";
import { Product } from "./pages/products/Product";
import { ProjectProductDescription } from "./pages/projects/ProjectProductDescription";
import { TypeSelectPage } from "./pages/home/TypeSelect";
import { Lessons } from "./pages/projects/Lessons";
import { BriefPdf } from "./pages/projects/ProjectBriefAndInitiationDoc/PDF/BriefPdf";
import { CombinedProjectReports } from "./pages/projects/ProjectBriefAndInitiationDoc/ProjectBriefAndInitiationDoc";
import { PlansControls } from "./pages/projects/plans/Controls";
import { OrganizationApproach } from "./pages/projects/organization/OrganizationApproach";
import { ProjectRiskApproach } from "./pages/projects/risk/RiskApproach";
import { ChangeApproach } from "./pages/projects/change/ChangeApproach";
import { Programme } from "./pages/programmes/Programme";
import { ProgrammeDefinitionForm } from "./pages/programmes/ProgrammeDefinitionForm";
import { PIDPdf } from "./pages/projects/ProjectBriefAndInitiationDoc/PDF/PIDPdf";
import { Release } from "./pages/products/releases";
import { EditProperties } from "./pages/projects/EditProperties";
import { NewProgramme } from "./pages/programmes/NewProgramme";
import { NewProduct } from "./pages/products/NewProduct";
import { Knowledge } from "./pages/programmes/Knowledge/Knowledge";
import { KnowledgeCreateForm } from "./pages/programmes/Knowledge/KnowledgeCreateForm";
import { KnowledgeEditForm } from "./pages/programmes/Knowledge/KnowledgeEditForm";
import { Decisions } from "./pages/programmes/Decisions";
import { MyProfile } from "./pages/home/MyProfile";
import { ProgrammeRisk } from "./pages/programmes/ProgrammeRisk";
import { ProgrammeRiskApproach } from "./pages/programmes/ProgrammeRiskApproach";
import { ReleaseRisk } from "./pages/products/releases/ReleaseRisk";
import { Risk } from "./pages/projects/Risk";
import { ReleasePlan } from "./pages/products/releases/ReleasePlan";
import { ReleaseChange } from "./pages/products/releases/Change";
import { ReleaseProgressCreateForm } from "./pages/products/releases/progress/ReleaseProgressCreateForm";
import { ReleaseProgressEditForm } from "./pages/products/releases/progress/ReleaseProgressEditForm";
import { ReleaseProgress } from "./pages/products/releases/progress/ReleaseProgress";
import { ProductProgress } from "./pages/products/ProductProgress";
import { ProductProgressCreateForm } from "./pages/products/ProductProgressCreateForm";
import { ProductDefinitionForm } from "./pages/products/ProductDefinitionForm";
import { SearchResults } from "./pages/home/SearchResults";
import { StakeholderAnalysis } from "./pages/projects/organization/StakeholderAnalysis";
import { ProductRisk } from "./pages/products/ProductRisk";
import { ProductChange } from "./pages/products/Change";
import { EventLog } from "./pages/projects/EventLog";
import { LessonsArchive } from "./pages/home/LessonsArchive";
import { ProjectSettings } from "./pages/projects/ProjectSettings";
import { EditProgrammeProperties } from "./pages/programmes/EditProgrammeProperties";
import { Design } from "./pages/programmes/Design";
import { EditProductProperties } from "@/pages/products/EditProductProperties";

export const AuthRoute = ({ ...props }) => {
  const [auth, dispatch] = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const location = useLocation();

  const updateContext = useUnleashContext();
  useEffect(() => {
    if (!auth.authenticated) {
      users
        .me()
        .then((result) => {
          console.log("[INFO] Retrieved user information");
          dispatch({ type: "LOGIN", payload: result });
          updateContext({
            userId: result.id,
            hostName: window.location.hostname,
            account: result.currentTenant,
          });
        })
        .catch((error) => {
          console.log(
            "[WARN] Not able to login user, change to 401 error page",
          );
          navigate(uris.e401);
        });
      //    return null;
    } else {
      updateContext({
        userId: auth.user.id,
        hostName: window.location.hostname,
        account: auth.user.currentTenant,
      });
    }
    //    updateContext({ userId })
  }, []);
  return auth.authenticated ? <Outlet /> : navigate(uris.login);
};

export const MainRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<AuthRoute />}>
        <Route exact path="/bulkImport" element={<BulkImport />} />
        <Route exact path={uris.home} element={<Home />} />
        <Route exact path={uris.myProfile} element={<MyProfile />} />
        <Route exact path={uris.lessons} element={<LessonsArchive />} />
        <Route exact path={uris.searchResults} element={<SearchResults />} />
        <Route
          exact
          path={uris.project.newWithPortfolioIdAndProgrammeId}
          element={<NewProject />}
        />
        <Route exact path={uris.new} element={<TypeSelectPage />} />
        <Route exact path={uris.project.new} element={<NewProject />} />
        <Route
          exact
          path={uris.project.newWithPortfolioId}
          element={<NewProject />}
        />
        <Route exact path={uris.programme.new} element={<NewProgramme />} />
        <Route
          exact
          path={uris.programme.newWithPortfolioId}
          element={<NewProgramme />}
        />
        <Route exact path={uris.programme.log} element={<EventLog />} />
        <Route exact path={uris.programme.risk} element={<ProgrammeRisk />} />
        <Route
          exact
          path={uris.programme.riskDetails}
          element={<ProgrammeRisk />}
        />
        <Route
          exact
          path={uris.programme.riskApproach}
          element={<ProgrammeRiskApproach />}
        />
        <Route exact path={uris.project.single} element={<Project />} />
        <Route
          exact
          path={uris.project.editProperties}
          element={<EditProperties />}
        />
        <Route exact path={uris.project.quality} element={<Quality />} />
        <Route
          exact
          path={uris.project.qualityOutputDetails}
          element={<Quality />}
        />
        <Route
          exact
          path={uris.project.quality + "/productDescription"}
          element={<ProjectProductDescription />}
        />
        <Route
          exact
          path={uris.project.quality + "/approach"}
          element={<Quality />}
        />
        <Route exact path={uris.project.plans} element={<Plans />} />
        <Route
          exact
          path={uris.project.plansStageDetails}
          element={<Plans />}
        />
        <Route exact path={uris.project.plansLessons} element={<Lessons />} />
        <Route
          exact
          path={uris.project.plansApproach}
          element={<PlansApproach />}
        />
        <Route
          exact
          path={uris.project.plansControls}
          element={<PlansControls />}
        />
        <Route
          exact
          path={uris.project.businessCase}
          element={<BusinessCase />}
        />
        <Route exact path={uris.project.risk} element={<Risk />} />
        <Route exact path={uris.project.riskDetails} element={<Risk />} />
        <Route
          exact
          path={uris.project.riskApproach}
          element={<ProjectRiskApproach />}
        />
        <Route exact path={uris.project.change} element={<Change />} />
        <Route exact path={uris.project.changeDetails} element={<Change />} />
        <Route
          exact
          path={uris.project.changeApproach}
          element={<ChangeApproach />}
        />
        <Route
          exact
          path={uris.project.organization}
          element={<Organization />}
        />
        <Route
          exact
          path={uris.project.organizationApproach}
          element={<OrganizationApproach />}
        />
        <Route
          exact
          path={uris.project.organizationStakeholderAnalysis}
          element={<StakeholderAnalysis />}
        />
        <Route
          exact
          path={uris.project.edit_progress}
          element={<ProgressEditForm />}
        />
        <Route
          exact
          path={uris.project.create_progress}
          element={<ProgressCreateForm />}
        />
        <Route
          exact
          path={uris.project.progressSingle}
          element={<Progress />}
        />
        <Route exact path={uris.project.progressType} element={<Progress />} />
        <Route
          exact
          path={uris.project.progressReportId}
          element={<Progress />}
        />
        <Route
          exact
          path={uris.project.progressReportIdPdf}
          element={<Progress />}
        />
        <Route exact path={uris.project.progressFull} element={<Progress />} />
        <Route
          exact
          path={uris.project.progressFullscreen}
          element={<Progress />}
        />
        <Route exact path={uris.portfolio.single} element={<Details />} />
        <Route exact path={uris.project.editLinks} element={<EditLinks />} />
        <Route exact path={uris.product.editLinks} element={<EditLinks />} />
        <Route exact path={uris.product.new} element={<NewProduct />} />
        <Route exact path={uris.product.roadmap} element={<Plans />} />
        <Route
          exact
          path={uris.product.roadmapReleaseDetails}
          element={<Plans />}
        />
        <Route
          exact
          path={uris.product.editProperties}
          element={<EditProductProperties />}
        />
        <Route exact path={uris.product.log} element={<EventLog />} />
        <Route exact path={uris.project.log} element={<EventLog />} />
        <Route exact path={uris.release.log} element={<EventLog />} />
        <Route
          exact
          path={uris.project.settings}
          element={<ProjectSettings />}
        />
        <Route
          exact
          path={uris.product.createProgress}
          element={<ProductProgressCreateForm />}
        />
        <Route
          exact
          path={uris.product.editProgress}
          element={<ProductProgressCreateForm />}
        />
        <Route
          exact
          path={uris.product.progressReportIdPdf}
          element={<ProductProgress />}
        />
        <Route
          exact
          path={uris.product.progressFull}
          element={<ProductProgress />}
        />
        <Route
          exact
          path={uris.product.editDefinition}
          element={<ProductDefinitionForm />}
        />
        <Route exact path={uris.product.risk} element={<ProductRisk />} />
        <Route
          exact
          path={uris.product.riskDetails}
          element={<ProductRisk />}
        />
        <Route exact path={uris.product.change} element={<ProductChange />} />
        <Route
          exact
          path={uris.product.changeDetails}
          element={<ProductChange />}
        />
        <Route
          exact
          path={uris.product.organization}
          element={<Organization />}
        />
        <Route exact path={uris.release.single} element={<Release />} />
        <Route exact path={uris.release.risk} element={<ReleaseRisk />} />
        <Route
          exact
          path={uris.release.riskDetails}
          element={<ReleaseRisk />}
        />
        <Route exact path={uris.release.plan} element={<ReleasePlan />} />
        <Route exact path={uris.release.change} element={<ReleaseChange />} />
        <Route
          exact
          path={uris.release.changeDetails}
          element={<ReleaseChange />}
        />
        <Route
          exact
          path={uris.release.createProgress}
          element={<ReleaseProgressCreateForm />}
        />
        <Route
          exact
          path={uris.release.editProgress}
          element={<ReleaseProgressEditForm />}
        />
        <Route
          exact
          path={uris.release.progressReportIdPdf}
          element={<ReleaseProgress />}
        />
        <Route
          exact
          path={uris.release.progressFull}
          element={<ReleaseProgress />}
        />
        <Route
          exact
          path={"/accountsettings/users"}
          element={<UserAdministration />}
        />
        <Route
          exact
          path={"/accountsettings/properties"}
          element={<Properties />}
        />
        <Route
          exact
          path={"/accountsettings/projectsettings"}
          element={<TenantProjectSettings />}
        />
        <Route
          exact
          path={"/accountsettings/portfolios"}
          element={<Portfolios />}
        />
        <Route
          exact
          path={"/accountsettings/lifecycles"}
          element={<Lifecycles />}
        />
        <Route
          exact
          path={"/accountsettings/tailoring"}
          element={<Tailoring />}
        />
        <Route
          exact
          path={"/accountsettings/dpa"}
          element={<DataProtectionAgreement />}
        />
        <Route exact path={uris.project.briefPdf} element={<BriefPdf />} />
        <Route
          exact
          path={uris.project.briefPDFHistoric}
          element={<BriefPdf />}
        />
        {
          //<Route exact path={uris.project.brief} element={<ProjectBriefPage />} />
        }
        <Route path={uris.project.brief} element={<CombinedProjectReports />} />
        <Route
          path={uris.project.briefHistoric}
          element={<CombinedProjectReports />}
        />
        <Route
          path={uris.project.approveBrief}
          element={<CombinedProjectReports />}
        />
        <Route path={uris.project.pid} element={<CombinedProjectReports />} />
        <Route
          path={uris.project.pidHistoric}
          element={<CombinedProjectReports />}
        />
        <Route
          path={uris.project.approvePid}
          element={<CombinedProjectReports />}
        />
        <Route exact path={uris.project.pidPDf} element={<PIDPdf />} />
        <Route exact path={uris.project.pidPDFHistoric} element={<PIDPdf />} />
        <Route exact path={uris.programme.single} element={<Programme />} />
        <Route
          exact
          path={uris.programme.createKnowledge}
          element={<KnowledgeCreateForm />}
        />
        <Route
          exact
          path={uris.programme.editKnowledge}
          element={<KnowledgeEditForm />}
        />
        <Route
          exact
          path={uris.programme.knowledgeReportIdPdf}
          element={<Knowledge />}
        />
        <Route
          exact
          path={uris.programme.knowledgeFull}
          element={<Knowledge />}
        />
        <Route
          exact
          path={uris.programme.editDefinition}
          element={<ProgrammeDefinitionForm />}
        />
        <Route exact path={uris.programme.change} element={<Decisions />} />
        <Route
          exact
          path={uris.programme.changeDetails}
          element={<Decisions />}
        />
        <Route exact path={uris.programme.design} element={<Design />} />
        <Route
          exact
          path={uris.programme.changeApproach}
          element={<ChangeApproach />}
        />
        <Route
          exact
          path={uris.programme.organization}
          element={<Organization />}
        />
        <Route
          exact
          path={uris.programme.editProperties}
          element={<EditProgrammeProperties />}
        />
      </Route>
      <Route exact path={uris.product.single} element={<Product />} />
      <Route exact path={uris.login} element={<Login />} />
      <Route exact path={uris.signup} element={<Signup />} />
      <Route exact path={uris.loginCode} element={<LinkLoginLander />} />
      <Route path={"/dev/responsive"} element={<Responsive />} />
      <Route path={uris.errors.e500} element={<E500 />} />
      <Route path={uris.errors.e401} element={<E401 />} />
      <Route path={uris.errors.e404} element={<E404 />} />
      <Route path={uris.errors.e404_fallback} element={<E404 />} />
    </Routes>
  );
};
