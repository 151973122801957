import React from "react";
import { Box } from "@mui/material";

export const RiskStatusIcon = ({ type, status }) => {
  return (
    <Box
      sx={{
        width: 16,
        height: 16,
        borderRadius: "50%",
        backgroundColor: {
          success: "success.main",
          warn: "warning.main",
          danger: "error.main",
        }[status],
      }}
    />
  );
};
