import React, { useCallback, useState } from "react";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomAccordion } from "@/layout/CustomAccordion";
import { Button, Paper, Stack, Typography, IconButton } from "@mui/material";
import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
  EditOutlined,
  DeleteOutlined,
} from "@mui/icons-material";
import { useT } from "../../hooks/useT";
import { CustomDialog } from "@/layout/CustomDialog";

const DeletePanel = ({
  handleDelete,
  onClose,
  open,
  title,
  submitLabel,
  t,
  ...props
}) => {
  return (
    <Paper
      sx={{
        padding: "30px 20px",
        display: open ? "block" : "none",
      }}
      {...props}
    >
      <Typography variant="body1">
        {title ?? t("generic.deleteTitle")}
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ width: "100%", mt: 2 }}
      >
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t("generic.button.cancel")}
        </Button>
        <Button variant="contained" onClick={handleDelete}>
          {submitLabel ?? t("generic.button.delete")}
        </Button>
      </Stack>
    </Paper>
  );
};

const EditForm = ({
  handleEdit,
  onClose,
  editForm,
  defaultValues,
  schema,
  item,
  index,
  t,
  formKey,
  submitLabel,
  isOpen = false,
  ...props
}) => {
  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = formData;
  return (
    <CustomDialog isOpen={isOpen} onClose={onClose} isContentDirty={isDirty}>
      <FormDataProvider formKey={formKey} {...formData}>
        <form
          onSubmit={handleSubmit((data) => {
            console.log("data", data);
            handleEdit(data);
            onClose();
          })}
        >
          <Stack>
            {editForm}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ width: "100%", mt: 3 }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onClose()}
              >
                {t("generic.button.cancel")}
              </Button>
              <Button type="submit" variant="contained">
                {submitLabel ?? t("generic.button.save")}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

export const FormTable = ({
  label,
  addButtonLabel = "Add",
  headerColumns = [],
  rowColumns = (item) => [],
  items = [],
  addForm = <>addForm</>,
  editForm = null,
  handleAdd = null,
  handleEdit = null,
  handleDelete = null,
  details = () => <>details</>,
  formData = {
    handleSubmit: () => {},
    reset: () => {},
    formState: { isDirty: false },
  },
  schema,
  formKey,
  submitLabel,
  editSubmitLabel,
  deleteSubmitLabel,
  deleteTitle,
}) => {
  const { t } = useT();
  const [showItemIds, setShowItemIds] = useState((items ?? []).map(() => null));
  const [editItemIds, setEditItemIds] = useState((items ?? []).map(() => null));
  const [deleteItemIds, setDeleteItemIds] = useState(
    (items ?? []).map(() => null),
  );
  const [showNewForm, setShowNewForm] = useState(false);

  const handleShowItemForm = useCallback((index, itemId) => {
    setShowItemIds((showItemIds) => [
      ...showItemIds.slice(0, index),
      itemId,
      ...showItemIds.slice(index + 1),
    ]);
  }, []);
  const handleEditItemForm = useCallback((index, itemId) => {
    setDeleteItemIds((editItemIds) => [
      ...editItemIds.slice(0, index),
      null,
      ...editItemIds.slice(index + 1),
    ]);
    setEditItemIds((editItemIds) => [
      ...editItemIds.slice(0, index),
      itemId,
      ...editItemIds.slice(index + 1),
    ]);
  }, []);
  const handleDeleteItemForm = useCallback((index, itemId) => {
    setEditItemIds((editItemIds) => [
      ...editItemIds.slice(0, index),
      null,
      ...editItemIds.slice(index + 1),
    ]);
    setDeleteItemIds((deleteItemIds) => [
      ...deleteItemIds.slice(0, index),
      itemId,
      ...deleteItemIds.slice(index + 1),
    ]);
  }, []);

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formData;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" fontWeight="normal">
          {label}
        </Typography>
        {handleAdd !== null && (
          <Button
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => setShowNewForm(!showNewForm)}
          >
            {!showNewForm ? addButtonLabel : t("generic.button.cancel")}
          </Button>
        )}
      </Stack>

      <CustomAccordion
        tableView
        header={{
          expanded: showNewForm,
          columns: headerColumns,
          details: (
            <CustomDialog
              isOpen={showNewForm}
              onClose={() => setShowNewForm(!showNewForm)}
              isContentDirty={isDirty}
            >
              <FormDataProvider formKey={formKey} {...formData}>
                <form
                  onSubmit={handleSubmit((data) => {
                    handleAdd?.(data);
                    reset({});
                    setShowNewForm(!showNewForm);
                  })}
                >
                  <Stack>
                    {addForm}
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end"
                      sx={{ width: "100%", mt: 3 }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowNewForm(!showNewForm)}
                      >
                        {t("generic.button.cancel")}
                      </Button>
                      <Button type="submit" variant="contained">
                        {submitLabel ?? t("generic.button.save")}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </FormDataProvider>
            </CustomDialog>
          ),
        }}
        rows={[
          ...(items ?? []).map((item, index) => ({
            id: item.id,
            expanded: showItemIds[index] === item.id,
            onClick: () => {
              showItemIds[index] !== item.id
                ? handleShowItemForm(index, item.id)
                : handleShowItemForm(index, null);
            },
            columns: rowColumns(item, index),
            details:
              deleteItemIds[index] === item.id ? (
                <DeletePanel
                  handleDelete={() => handleDelete(item, index)}
                  onClose={() => handleDeleteItemForm(index, null)}
                  open={deleteItemIds[index] === item.id}
                  title={deleteTitle}
                  submitLabel={deleteSubmitLabel}
                  t={t}
                />
              ) : (
                <Paper sx={{ p: 2 }}>
                  <Stack spacing={3}>
                    <Stack
                      justifyContent="flex-end"
                      direction="row"
                      spacing={2}
                    >
                      {handleDelete !== null && (
                        <Button
                          variant="text"
                          color="secondary"
                          startIcon={<DeleteOutlined />}
                          onClick={() =>
                            deleteItemIds[index] !== item.id
                              ? handleDeleteItemForm(index, item.id)
                              : handleDeleteItemForm(index, null)
                          }
                        >
                          {t("generic.button.delete")}
                        </Button>
                      )}
                      {handleEdit !== null && (
                        <Button
                          variant="text"
                          color="secondary"
                          startIcon={<EditOutlined />}
                          onClick={() =>
                            editItemIds[index] !== item.id
                              ? handleEditItemForm(index, item.id)
                              : handleEditItemForm(index, null)
                          }
                        >
                          {t("generic.button.edit")}
                        </Button>
                      )}
                    </Stack>
                    {editItemIds[index] === item.id ? (
                      <EditForm
                        handleEdit={(data) => {
                          handleEdit(data);
                          handleShowItemForm(index, null);
                        }}
                        onClose={() => handleEditItemForm(index, null)}
                        editForm={editForm ? editForm : addForm}
                        formKey={formKey}
                        defaultValues={item}
                        schema={schema}
                        item={item}
                        index={index}
                        t={t}
                        submitLabel={editSubmitLabel}
                        isOpen={editItemIds[index] === item.id}
                      />
                    ) : (
                      details(item, index)
                    )}
                  </Stack>
                </Paper>
              ),
          })),
        ]}
      />
    </Stack>
  );
};
