import React, { Fragment, useCallback, useState } from "react";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import {
  Button,
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CustomAccordion } from "../../../layout/CustomAccordion";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "../../../base/form/mui/FormTextField";
import { DeleteButton } from "@/base/DeleteButton";
import { CustomDialog } from "@/layout/CustomDialog";

export const NewItemForm = ({ item, onSave, onClose, open, ...props }) => {
  const { t } = useT();
  const schema = yup.object().shape({
    name: yup.string().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: item?.name || "",
    },
  });

  const { handleSubmit, reset } = formData;

  return (
    <CustomDialog isOpen={open} onClose={onClose}>
      <FormProvider {...formData}>
        <form
          onSubmit={handleSubmit((data) => {
            onSave({
              id: item?.id || undefined,
              name: data.name,
            });
            reset();
          })}
        >
          <Box sx={{ width: "100%" }}>
            <FormTextField
              name={"name"}
              label={t("accountSetting.portfolio.fields.name.title")}
              required
              fullWidth
            />
          </Box>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="contained" color="secondary" onClick={onClose}>
              {t("generic.button.cancel")}
            </Button>
            <Button type="submit" variant="contained">
              {t("generic.button.save")}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </CustomDialog>
  );
};

const DeletePanel = ({ onDelete, onClose, open, ...props }) => {
  return (
    <Paper
      sx={{
        padding: "30px 20px",
        display: open ? "block" : "none",
      }}
      {...props}
    >
      <Typography variant="body1">Are you sure to delete this item?</Typography>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ width: "100%", mt: 2 }}
      >
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDelete}>
          Delete
        </Button>
      </Stack>
    </Paper>
  );
};

export const TextsArraySection = ({
  title,
  headerTitle = "Title",
  description,
  items,
  onNewItemSubmit,
  onEditItemSubmit,
  onDeleteItemSubmit,
  renderTitle = (item) => `${item.name} (${item.usageCount})`,
}) => {
  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const { t } = useT();

  const handleShowNewItemForm = useCallback(() => {
    setShowNewItemForm(true);
    setEditItemId(null);
  }, []);

  const handleShowEditItemForm = useCallback(
    (itemId) => {
      setShowNewItemForm(false);
      setEditItemId(itemId);
    },
    [items],
  );

  const handleShowDeleteItemForm = useCallback((itemId) => {
    setShowNewItemForm(false);
    setEditItemId(null);
  }, []);

  const renderContent = () => {
    return (
      <>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography>{description}</Typography>
          <Button
            variant="text"
            startIcon={<AddCircleOutlineIcon />}
            onClick={
              showNewItemForm
                ? () => setShowNewItemForm(false)
                : handleShowNewItemForm
            }
          >
            {showNewItemForm
              ? t("generic.button.cancel")
              : t("generic.button.add")}
          </Button>
        </Stack>
        <CustomAccordion
          tableView
          header={{
            expanded: false,
            columns: [
              {
                title: headerTitle,
                flex: 1,
              },
            ],
            details: (
              <NewItemForm
                open={showNewItemForm}
                onClose={() => setShowNewItemForm(false)}
                onSave={(data) => {
                  onNewItemSubmit(data);
                  setShowNewItemForm(false);
                }}
              />
            ),
          }}
          rows={items.map((item) => ({
            id: item.id,
            expandDisabled: true,
            columns: [
              {
                title: renderTitle(item),
                flex: 1,
              },
              {
                content: (
                  <DeleteButton
                    onConfirm={() => onDeleteItemSubmit(item)}
                    content="Are you sure to delete this item?"
                    disabled={item.usageCount}
                    iconMode
                  />
                ),
                width: 40,
              },
              {
                content: (
                  <IconButton onClick={() => handleShowEditItemForm(item.id)}>
                    <EditIcon />
                  </IconButton>
                ),
                width: 40,
              },
            ],
            expanded: editItemId === item.id,
            details: (
              <>
                <NewItemForm
                  item={item}
                  open={editItemId === item.id}
                  onClose={() => handleShowEditItemForm(null)}
                  onSave={(data) => {
                    onEditItemSubmit(data);
                    setEditItemId(null);
                  }}
                />
              </>
            ),
          }))}
        />
      </>
    );
  };

  if (title) {
    return <Section title={title}>{renderContent()}</Section>;
  } else {
    return <>{renderContent()}</>;
  }
};
