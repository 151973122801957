import React from 'react';

export const Release = ({className, style, onClick}) => (
    <svg className={className} style={style} onClick={onClick}
    width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9687 17.1211C13.5758 17.1211 15.1468 16.6445 16.483 15.7516C17.8192 14.8587 18.8606 13.5896 19.4755 12.1048C20.0904 10.62 20.2512 8.98623 19.9374 7.41007C19.6237 5.8339 18.8497 4.38617 17.7131 3.24997C16.5766 2.11377 15.1286 1.34013 13.5523 1.02691C11.9761 0.71369 10.3423 0.874949 8.85771 1.49029C7.37311 2.10564 6.10433 3.14743 5.21184 4.48391C4.31936 5.82039 3.84326 7.39152 3.84375 8.9986V9.6236" stroke="#62A4CA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.34375 7.12109L3.84375 9.62109L6.34375 7.12109" stroke="#62A4CA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.0938 8.99609C10.0938 9.49337 10.2913 9.97029 10.6429 10.3219C10.9946 10.6735 11.4715 10.8711 11.9687 10.8711C12.466 10.8711 12.9429 10.6735 13.2946 10.3219C13.6462 9.97029 13.8437 9.49337 13.8437 8.99609C13.8437 8.49881 13.6462 8.0219 13.2946 7.67027C12.9429 7.31864 12.466 7.12109 11.9687 7.12109C11.4715 7.12109 10.9946 7.31864 10.6429 7.67027C10.2913 8.0219 10.0938 8.49881 10.0938 8.99609V8.99609Z" stroke="#62A4CA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
