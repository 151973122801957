import React, { useEffect } from "react";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { PrimaryButton, SecondaryButton } from "../../base/Button";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";
import * as Yup from "yup";
import { useT } from "../../hooks/useT";
import { useMutation } from "react-query";
import { location, department, programme } from "../../queries";
import { uris } from "../../config/nav";
import { clientUrl } from "../../util/routing";
import { Hide } from "../../base/responsive/Hide";
import { NoMobileView } from "../../base/responsive/NoMobileView";
import { useQ } from "../../hooks/useQ";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "../../base/form/mui/FormTextField";
import { FormSelect } from "../../base/form/mui/FormSelect";
import { Stack, Grid } from "@mui/material";
import { useToast } from "../../hooks/useToast";

export const EditProgrammeProperties = () => {
  const { t } = useT();
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { isLoading, data: programmeData = {} } = useQ(`programme-${id}`, () =>
    programme.single({ id }),
  );
  const { data: portfolios = [], isLoading: isLoadingPortfolios } = useQ(
    `portfolios`,
    () => portfolio.list(),
  );
  const {
    data: locations = [],
    refetch: refetchLocations,
    isLoading: isLoadingLocations,
  } = useQ(`locations`, () => location.list());

  const {
    data: departments = [],
    refetch: refetchDepartments,
    isLoading: isLoadingDepartments,
  } = useQ(`departments`, () => department.list());

  function getOptions(array) {
    return array.map((e) => {
      return { label: e.name, value: e.id };
    });
  }

  const editProperties = useMutation(programme.editProperties, {
    onSuccess: (data) => {
      navigate(clientUrl(uris.programme.single, { id: programmeData.id }));
      toast.success(t("programme.editProperties.success"));
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  const schema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t("projectForm.fields.name.requiredError")),
    identifier: Yup.string().trim(),
    portfolio: Yup.string().trim(),
    location: Yup.string().trim(),
    department: Yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: programmeData?.name ?? "",
      identifier: programmeData?.identifier ?? "",
      portfolio: programmeData?.portfolio?.id ?? "",
      location:
        locations?.find(
          (location) => location.name === programmeData?.attributes?.location,
        )?.id ?? "",
      department:
        departments?.find(
          (department) =>
            department?.name === programmeData?.attributes?.department,
        )?.id ?? "",
    },
  });
  const { handleSubmit, reset, control, setValue } = formData;
  const [portfolio] = useWatch({
    control,
    name: ["portfolio"],
  });

  /*useEffect(() => {
    setValue("programme", "none");
  }, [portfolio]);
*/
  useEffect(() => {
    reset({
      name: programmeData?.name,
      identifier: programmeData?.identifier,
      portfolio: programmeData?.portfolio?.id,
      location: locations?.find(
        (location) => location.name === programmeData?.attributes?.location,
      )?.id,
      department: departments?.find(
        (department) =>
          department?.name === programmeData?.attributes?.department,
      )?.id,
    });
  }, [reset, programmeData, locations, departments]);

  console.log({ portfolios });

  return (
    <Page
      isLoading={
        isLoading ||
        isLoadingDepartments ||
        isLoadingLocations ||
        isLoadingPortfolios
      }
    >
      <NoMobileView show={"sm"} />

      <Hide hide={"sm"}>
        <Section title={t("programme.editProperties.title")}>
          <FormDataProvider
            formKey="initiative.addEditForm.fields"
            {...formData}
          >
            <form
              onSubmit={handleSubmit((data) => {
                console.log("submit data", data);
                editProperties.mutate({
                  id: programmeData.id,
                  data: {
                    ...data,
                  },
                });
              })}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormTextField name="name" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormTextField name="identifier" />
                </Grid>

                {portfolios.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="portfolio"
                      options={getOptions(portfolios)}
                    />
                  </Grid>
                ) : null}

                {locations.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="location"
                      options={getOptions(locations)}
                      nullValue={
                        locations?.find(
                          (location) =>
                            location.name ===
                            programmeData?.attributes?.location,
                        )?.id
                      }
                    />
                  </Grid>
                ) : null}

                {departments.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="department"
                      options={getOptions(departments)}
                      nullValue={
                        departments?.find(
                          (department) =>
                            department?.name ===
                            programmeData?.attributes?.department,
                        )?.id
                      }
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Stack mt={2} direction="row" justifyContent="flex-end">
                <SecondaryButton
                  onClick={() =>
                    navigate(
                      clientUrl(uris.programme.single, {
                        id: programmeData.id,
                      }),
                    )
                  }
                >
                  {t("generic.button.cancel")}
                </SecondaryButton>
                <PrimaryButton className={"mg-l-nm"} type={"submit"}>
                  {t("programme.editProperties.update")}
                </PrimaryButton>
              </Stack>
            </form>
          </FormDataProvider>
        </Section>
      </Hide>
    </Page>
  );
};
