import React, { useState } from "react";
import { isNil, path } from "ramda";
import { IconButton, Popover, Stack, Typography } from "@mui/material";
import { PopoutCard } from "./tooltip/Popout";
import SearchIcon from "@mui/icons-material/Search";
import { Stat } from "./Stat";
import { DatePretty } from "./DatePretty";

export const TableStat = ({ name, data, hide = ["sm"], color }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const status = path(
    isNil(name) ? ["status", "value"] : ["status", "details", name, "value"],
    data,
  );

  return (
    <>
      <Popover
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <PopoutCard
          title={
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ color: "#fff !important" }}>
                {data.name}
              </Typography>
              <SearchIcon />
            </Stack>
          }
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography fontWeight="bold">
              {isNil(name)
                ? "Status"
                : name.charAt(0).toUpperCase() + name.slice(1)}
            </Typography>
            :
            <Stat status={status} />
          </Stack>
          <Typography
            dangerouslySetInnerHTML={{
              __html: isNil(name)
                ? data?.status?.description
                : data?.status?.details?.[name]?.description,
            }}
          />
          <Typography>
            <DatePretty
              format={"YYYY-MM-DD"}
              date={path(["status", "date"], data)}
            />{" "}
            {data?.status?.reportedBy}
          </Typography>
        </PopoutCard>
      </Popover>

      <IconButton onClick={!isNil(status) ? handleClick : null}>
        <Stat status={status} hide={hide} />
      </IconButton>
    </>
  );
};
