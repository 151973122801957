import React from 'react';

export const FinishFlag = ({className, style, onClick}) => (
  <svg className={className} style={style} onClick={onClick}
    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2799 18.7953V19.6794C12.1172 19.8657 13.234 20.47 13.234 20.9125C13.234 21.4241 11.5593 22.2156 9.04676 22.2156C6.48792 22.2156 4.85956 21.4477 4.85956 20.9125C4.85956 20.4709 5.97633 19.8657 7.81363 19.6794L7.81454 18.7953C5.76728 19.0043 4 19.7258 4 20.9125C4 22.3319 6.53512 23.0988 9.04767 23.0988C11.5602 23.0988 14.0953 22.3546 14.0953 20.9125C14.1181 19.7258 12.3507 19.0043 10.2799 18.7953H10.2799Z" fill="#ABABAB"/>
    <path d="M19.4003 1.79056C15.911 0.837346 13.3984 2.60474 9.49016 1.95412V1.41891C9.49016 1.18628 9.30388 1 9.07125 1C8.83862 1 8.65234 1.18628 8.65234 1.41891V20.5639C8.65234 20.7966 8.83862 20.9828 9.07125 20.9828C9.30388 20.9828 9.49016 20.7966 9.49016 20.5639V9.53718C12.7006 10.1887 15.8172 8.56036 19.2366 9.30456C19.4228 9.35091 19.6091 9.21188 19.6091 9.00197V2.09332C19.6328 1.9543 19.5164 1.83797 19.4001 1.79073L19.4003 1.79056ZM19.0041 3.9078C18.3763 3.79148 18.05 3.76786 17.4221 3.74514V5.32716C18.05 5.32716 18.3763 5.35078 19.0041 5.48981V7.07183C18.3763 6.95552 18.05 6.93189 17.4221 6.90917V8.49119C16.7942 8.49119 16.468 8.51482 15.8401 8.58388V7.00186C15.2122 7.07183 14.886 7.11817 14.2581 7.21086V8.79287C13.6302 8.88556 13.304 8.93281 12.6761 9.00187V7.41985C12.0482 7.48982 11.722 7.51254 11.0941 7.51254V9.09456C10.4662 9.09456 10.1399 9.07093 9.51205 8.9319V7.34989C10.1399 7.4662 10.4662 7.48982 11.0941 7.48982V5.90781C10.4662 5.90781 10.1399 5.88418 9.51205 5.76787V4.18585C10.1399 4.30216 10.4662 4.32579 11.0941 4.32579V2.74377C11.722 2.74377 12.0482 2.72015 12.6761 2.65109V4.2331C13.304 4.16313 13.6302 4.11679 14.2581 4.02411V2.44209C14.886 2.3494 15.2122 2.30215 15.8401 2.23309V3.81511C16.468 3.74514 16.7942 3.72242 17.4221 3.72242V2.14041C18.05 2.14041 18.3763 2.16403 19.0041 2.30306V3.90778V3.9078Z" fill="#ABABAB"/>
    <path d="M12.6992 4.25665C12.0713 4.32662 11.7451 4.34934 11.1172 4.34934V5.93136C11.7451 5.93136 12.0713 5.90773 12.6992 5.83867V4.25665Z" fill="#ABABAB"/>
    <path d="M15.8398 5.42003V3.83801C15.2119 3.90798 14.8857 3.95432 14.2578 4.04701V5.62903C14.9093 5.53634 15.2119 5.49 15.8398 5.42003Z" fill="#ABABAB"/>
    <path d="M12.6992 5.83888V7.4209C13.3271 7.35093 13.6533 7.30459 14.2812 7.2119V5.62988C13.6297 5.72257 13.3271 5.76891 12.6992 5.83888Z" fill="#ABABAB"/>
    <path d="M15.8398 7.00191C16.4677 6.93194 16.794 6.90923 17.4219 6.90923V5.32721C16.794 5.32721 16.4677 5.35083 15.8398 5.4199V7.00191Z" fill="#ABABAB"/>
  </svg>
)
