import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { isDev } from "../util/isDev";
import React from "react";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { useScreen } from "../hooks/useScreen";

export const SimpleLayout = ({ children }) => {
  const screenSize = useScreen();
  return (
    <div className={`layout layout--simple ss--${screenSize}`}>
      <header className={"page-head"}>
        <div className={"logo"}>
          <Link to={"/"}>
            <img alt={"Logo"} src={logo} />
          </Link>
        </div>
        <div className={"search"}>{isDev() && <LanguageSwitcher />}</div>
        <div className={"profile"}></div>
      </header>
      <main className={"content"}>{children}</main>
    </div>
  );
};
