import React, { ReactNode } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

type AccordionItemProps = {
  title: string;
  subtitle?: string;
  open?: boolean;
  onClick?: () => void;
  children?: ReactNode;
} & Omit<AccordionProps, "children">;

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  subtitle,
  open,
  onClick,
  children,
  ...props
}) => {
  return (
    <Accordion expanded={open} {...props}>
      <AccordionSummary expandIcon={<ChevronRight />} onClick={onClick}>
        <Stack>
          <Typography variant="h2" sx={{ fontSize: "16px !important" }}>
            {title}
          </Typography>
          {subtitle && <Typography>{subtitle}</Typography>}
        </Stack>
      </AccordionSummary>
      {children && <AccordionDetails>{children}</AccordionDetails>}
    </Accordion>
  );
};
