import React, { memo, useMemo } from "react";
import {
  Stack,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  Autocomplete,
  TextField,
  Chip,
  Box,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { accessObjectByDotSeparatedKeys } from "./util";
import { useFormDataContext } from "../data-context/FormDataContext";
import { TitleWithHelper } from "../../TitleWithHelper";
import { useT } from "../../../hooks/useT";
import { useFormStrings } from "../../../hooks/useFormStrings";

export const FormMultiSelect = memo(
  ({
    options = [],
    label,
    placeholder,
    help,
    name = "",
    fieldKey,
    nullValue,
    selectProps,
    disableStrings = false,
    ...props
  }) => {
    const { t } = useT();
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const { formKey } = useFormDataContext();
    const inputStrings = useFormStrings({
      name,
      label,
      placeholder,
      help,
      fieldKey,
    });

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, name, ref } }) => {
          const chipsIds = value ?? [];
          return (
            <Stack sx={{ width: "100%" }} {...props}>
              {!disableStrings ? (
                inputStrings.help ? (
                  <TitleWithHelper
                    title={inputStrings.label}
                    help={inputStrings.help}
                  />
                ) : (
                  <Typography
                    sx={{
                      fontSize: "20px !important",
                      color: "#111827 !important",
                      mb: "12px !important",
                    }}
                  >
                    {inputStrings.label}
                  </Typography>
                )
              ) : null}
              <Autocomplete
                fullWidth
                onChange={(ev, chip) => {
                  if (chip) {
                    if (!chipsIds.includes(chip.id)) {
                      onChange([...chipsIds, chip.id]);
                    }
                  }
                }}
                options={options.filter((o) => !chipsIds.includes(o.id))}
                getOptionLabel={(option) => option.value}
                getOptionDisabled={(option) => option.disabled}
                renderOption={(params, option) => (
                  <MenuItem {...params}>{option.value}</MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        padding: "0 !important",
                      },
                    }}
                  />
                )}
              />
              <Stack direction={"row"} flexWrap={"wrap"}>
                {chipsIds.map((chipId, index) => {
                  const chip = options.find((chip) => chip.id === chipId);
                  if (!chip) {
                    return null;
                  }
                  return (
                    <Box key={chip.value}>
                      <Chip
                        label={chip.value}
                        onDelete={() => {
                          onChange([
                            ...value.slice(0, index),
                            ...value.slice(index + 1),
                          ]);
                        }}
                        sx={{
                          mr: 1,
                          mt: 1,
                        }}
                      />
                    </Box>
                  );
                })}
              </Stack>
              {!!accessObjectByDotSeparatedKeys(errors, name) && (
                <FormHelperText
                  error={!!accessObjectByDotSeparatedKeys(errors, name)}
                >
                  {accessObjectByDotSeparatedKeys(errors, name)?.message}
                </FormHelperText>
              )}
            </Stack>
          );
        }}
      />
    );
  },
);
FormMultiSelect.displayName = "FormMultiSelect";
