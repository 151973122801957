import React from 'react';

export const TooltipActive = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.3594 10C19.3594 12.3869 18.4112 14.6761 16.7233 16.364C15.0355 18.0518 12.7463 19 10.3594 19C7.97243 19 5.68324 18.0518 3.99541 16.364C2.30759 14.6761 1.35938 12.3869 1.35938 10C1.35938 7.61305 2.30759 5.32387 3.99541 3.63604C5.68324 1.94821 7.97243 1 10.3594 1C12.7463 1 15.0355 1.94821 16.7233 3.63604C18.4112 5.32387 19.3594 7.61305 19.3594 10ZM11.4844 5.5C11.4844 5.79837 11.3658 6.08452 11.1549 6.2955C10.9439 6.50647 10.6577 6.625 10.3594 6.625C10.061 6.625 9.77486 6.50647 9.56388 6.2955C9.3529 6.08452 9.23438 5.79837 9.23438 5.5C9.23438 5.20163 9.3529 4.91548 9.56388 4.7045C9.77486 4.49353 10.061 4.375 10.3594 4.375C10.6577 4.375 10.9439 4.49353 11.1549 4.7045C11.3658 4.91548 11.4844 5.20163 11.4844 5.5ZM9.23438 8.875C8.93601 8.875 8.64986 8.99353 8.43888 9.2045C8.2279 9.41548 8.10938 9.70163 8.10938 10C8.10938 10.2984 8.2279 10.5845 8.43888 10.7955C8.64986 11.0065 8.93601 11.125 9.23438 11.125V14.5C9.23438 14.7984 9.3529 15.0845 9.56388 15.2955C9.77486 15.5065 10.061 15.625 10.3594 15.625H11.4844C11.7827 15.625 12.0689 15.5065 12.2799 15.2955C12.4908 15.0845 12.6094 14.7984 12.6094 14.5C12.6094 14.2016 12.4908 13.9155 12.2799 13.7045C12.0689 13.4935 11.7827 13.375 11.4844 13.375V10C11.4844 9.70163 11.3658 9.41548 11.1549 9.2045C10.9439 8.99353 10.6577 8.875 10.3594 8.875H9.23438Z" fill="#111827"/>

    </svg>
  );
};
