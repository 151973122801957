import React, { useContext } from 'react';
import { AuthenticationContext } from '../../auth/AuthenticationProvider';
import { uris } from '../../config/nav';
import { access } from '../../queries';
import { useNavigate } from "react-router-dom";

export const LogoutForm = ({ children, className, style }) => {
  const [auth, dispatch] = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const logout = () => {
    console.log('LOGOUT with logout!')
    dispatch({type: 'LOGOUT', payload: null});
    access.logout();
    navigate(uris.login);
  }

  return (
    <div className={`logout-form ${className}`} style={style} onClick={logout}>
      {children}
    </div>
  );
};
