import React from 'react';
import { useStyleParams } from '../../hooks/useStyleParams';

export const Hide = ({children, ...props}) => {
  const {hide} = useStyleParams(props);
  if(hide) {
    return null;
  }

  return children;
}
