import { useT } from "../hooks/useT";
import React, { useState, memo } from "react";
import { Button, Paper, Stack, Typography } from "@mui/material";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { FormSelect } from "./form/mui/FormSelect";

export const TitleWithHelper = memo(
  ({ title, help, select, labelStyle, required }) => {
    const { t } = useT();
    const [isHelpOpen, setIsHelpOpen] = useState(false);

    return (
      <Stack mb="4px" spacing={1}>
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography
            sx={{
              fontSize: "20px !important",
              color: "#111827 !important",
              whiteSpace: "nowrap",
              ...labelStyle,
            }}
          >
            {title}
            {required && (
              <Typography
                component="span"
                color="error"
                fontSize="1.3em"
                lineHeight="1em"
              >
                {" "}
                *
              </Typography>
            )}
          </Typography>
          {select && (
            <FormSelect
              name={select.name}
              options={select.options}
              sx={{
                width: "300px",
              }}
              selectProps={{
                sx: {
                  width: "300px",
                  height: "30px",
                },
              }}
              disableStrings
            />
          )}
          {help && (
            <Button
              endIcon={
                isHelpOpen ? (
                  <ExpandLessOutlinedIcon />
                ) : (
                  <ExpandMoreOutlinedIcon />
                )
              }
              onClick={() => setIsHelpOpen((o) => !o)}
              sx={{
                height: "29px",
              }}
            >
              {isHelpOpen ? t("generic.hideHelp") : t("generic.showHelp")}
            </Button>
          )}
        </Stack>
        {!!help && isHelpOpen && (
          <Paper
            sx={{
              p: 2,
              backgroundColor: "#F9FAFB",
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{
                __html: help,
              }}
            />
          </Paper>
        )}
      </Stack>
    );
  },
);
