import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import { project } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import { useToast } from "../../../hooks/useToast";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";
import { CustomAccordion } from "../../../layout/CustomAccordion";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { FormTextField } from "../../../base/form/mui/FormTextField";
import { v4 as uuidV4 } from "uuid";
import { CustomDialog } from "@/layout/CustomDialog";
import { StepperSections } from "@/base/form/mui/StepperSections";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";

const StakeholderForm = ({
  stakeholder,
  projectId,
  onClose,
  isOpen,
  onSuccess,
}) => {
  const { t } = useT();
  const toast = useToast();
  const [activeStep, setActiveStep] = useState(0);
  const isCreating = !stakeholder;

  const schema = yup.object().shape({
    format: yup.string().trim(),
    frequency: yup.string().trim(),
    means: yup.string().trim(),
    message: yup.string().trim(),
    providedInformation: yup.string().trim(),
    requiredInformation: yup.string().trim(),
    name: yup.string().trim().required(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      format: stakeholder?.format ?? "",
      frequency: stakeholder?.frequency ?? "",
      means: stakeholder?.means ?? "",
      message: stakeholder?.message ?? "",
      providedInformation: stakeholder?.providedInformation ?? "",
      requiredInformation: stakeholder?.requiredInformation ?? "",
      name: stakeholder?.name ?? "",
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formData;

  useEffect(() => {
    reset({
      format: stakeholder?.format ?? "",
      frequency: stakeholder?.frequency ?? "",
      means: stakeholder?.means ?? "",
      message: stakeholder?.message ?? "",
      providedInformation: stakeholder?.providedInformation ?? "",
      requiredInformation: stakeholder?.requiredInformation ?? "",
      name: stakeholder?.name ?? "",
    });
    if (!stakeholder?.name || !stakeholder?.message) {
      setActiveStep(0);
    } else if (
      !stakeholder?.providedInformation ||
      !stakeholder?.requiredInformation
    ) {
      setActiveStep(1);
    } else {
      setActiveStep(2);
    }
  }, [stakeholder, reset]);

  const createStakeholder = useMutation(project.addStakeholder, {
    onSuccess: () => {
      toast.success(
        t("project.organization.stakeholder.addEditForm.successfulAdd"),
      );
    },
    onError: () => {
      toast.error(t("project.organization.stakeholder.addEditForm.addFailed"));
    },
  });

  const updateStakeholder = useMutation(project.updateStakeholder, {
    onSuccess: () => {
      toast.success(
        t("project.organization.stakeholder.addEditForm.successfulUpdate"),
      );
    },
    onError: () => {
      toast.error(
        t("project.organization.stakeholder.addEditForm.updateFailed"),
      );
    },
  });

  const handleCreateOrUpdateApproach = useMemo(
    () =>
      handleSubmit((formData) => {
        if (isDirty) {
          if (isCreating) {
            const id = uuidV4();
            createStakeholder.mutate(
              {
                id: projectId,
                data: {
                  stakeholder: {
                    id: id,
                    ...formData,
                  },
                },
              },
              {
                onSuccess: () => {
                  onClose();
                  onSuccess(id);
                },
              },
            );
          } else {
            updateStakeholder.mutate(
              {
                id: projectId,
                stakeholderId: stakeholder.id,
                data: {
                  stakeholder: {
                    id: stakeholder.id,
                    ...formData,
                  },
                },
              },
              {
                onSuccess: () => {
                  onSuccess();
                },
              },
            );
          }
          if (activeStep < 2) {
            setActiveStep((a) => a + 1);
          }
        } else {
          setActiveStep((a) => a + 1);
        }
      }),
    [
      handleSubmit,
      projectId,
      updateStakeholder,
      stakeholder,
      isCreating,
      isDirty,
      activeStep,
    ],
  );

  const getStepIndex = (step) =>
    ["stakeholder", "information", "communication"].indexOf(step);

  const submitButtonLabel = useMemo(() => {
    if (isCreating) {
      return t("project.organization.stakeholder.addEditForm.createNew");
    } else if (!isDirty) {
      if (activeStep === 2) {
        return false;
      }
      return t("generic.stepper.next");
    } else {
      if (activeStep === 2) {
        return t("project.organization.stakeholder.addEditForm.update");
      }
      return t("project.organization.stakeholder.addEditForm.updateAndNext");
    }
  }, [activeStep, isDirty]);

  const showPreviousButton = useMemo(() => {
    return activeStep > 0;
  }, [activeStep]);

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      isContentDirty={isDirty}
      scrollDependencyArray={[activeStep]}
    >
      <FormDataProvider
        formKey="project.organization.stakeholder.addEditForm.fields"
        {...formData}
      >
        <form onSubmit={handleCreateOrUpdateApproach}>
          <Stack spacing={2}>
            <StepperSections
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={["stakeholder", "information", "communication"]}
              formatLabel={(step) =>
                t(`project.organization.stakeholder.addEditForm.steps.${step}`)
              }
            />
            <Box sx={{ mt: 4 }}>
              {activeStep === getStepIndex("stakeholder") && (
                <Stack spacing={2}>
                  <FormTextField name="name" required />
                  <FormRichTextField name="message" />
                </Stack>
              )}
              {activeStep === getStepIndex("information") && (
                <Stack spacing={2}>
                  <FormRichTextField name="requiredInformation" />
                  <FormRichTextField name="providedInformation" />
                </Stack>
              )}
              {activeStep === getStepIndex("communication") && (
                <Stack spacing={2}>
                  <FormRichTextField name="frequency" />
                  <Stack direction="row" spacing={2}>
                    <FormTextField name="means" />
                    <FormTextField name="format" />
                  </Stack>
                </Stack>
              )}
            </Box>
            <StepperNavigator
              showPreviousButton={showPreviousButton}
              setActiveStep={setActiveStep}
              onCancel={onClose}
              isDirty={isDirty}
              submitButtonLabel={submitButtonLabel}
            />
          </Stack>
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

export const StakeholderAnalysis = () => {
  const { t } = useT();
  const { id = "" } = useParams();

  const {
    isLoading,
    data = {},
    refetch,
  } = useQ(`project-${id}`, () => project.single({ id }));
  const { title } = data;

  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [editStakeholderId, setEditStakeholderId] = useState(null);

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.organization.title")}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              {t("project.organization.stakeholder.title")}
            </Typography>
            <Button
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setShowNewItemForm((n) => !n)}
              data-cy="add-stage-button"
            >
              {t("project.organization.stakeholder.addEditForm.add")}
            </Button>
          </Stack>
          <CustomAccordion
            tableView
            header={{
              columns: [
                {
                  title: t(
                    "project.organization.stakeholder.addEditForm.header.name",
                  ),
                  flex: 1,
                },
                {
                  title: t(
                    "project.organization.stakeholder.addEditForm.header.keyMessages",
                  ),
                  flex: 1,
                },
                {
                  title: t(
                    "project.organization.stakeholder.addEditForm.header.informationRequired",
                  ),
                  flex: 1,
                },
                {
                  title: t(
                    "project.organization.stakeholder.addEditForm.header.informationProvided",
                  ),
                  flex: 1,
                },
                {
                  title: t(
                    "project.organization.stakeholder.addEditForm.header.frequency",
                  ),
                  flex: 1,
                },
              ],
              expanded: false,
              details: showNewItemForm && (
                <StakeholderForm
                  projectId={id}
                  onClose={() => setShowNewItemForm(false)}
                  onSuccess={(id) => {
                    refetch();
                    setEditStakeholderId(id);
                  }}
                  isOpen={showNewItemForm}
                />
              ),
            }}
            rows={(data?.stakeholderCommunication ?? [])?.map(
              (stakeholder) => ({
                id: stakeholder.id,
                onClick: () => setEditStakeholderId(stakeholder.id),
                columns: [
                  {
                    title: stakeholder.name,
                    flex: 1,
                  },
                  {
                    content: (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: stakeholder.message,
                        }}
                      />
                    ),
                    flex: 1,
                  },
                  {
                    content: (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: stakeholder.requiredInformation,
                        }}
                      />
                    ),
                    flex: 1,
                  },
                  {
                    content: (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: stakeholder.providedInformation,
                        }}
                      />
                    ),
                    flex: 1,
                  },
                  {
                    content: (
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: stakeholder.frequency,
                        }}
                      />
                    ),
                    flex: 1,
                  },
                ],
                expanded: false,
                details: editStakeholderId === stakeholder.id && (
                  <StakeholderForm
                    stakeholder={stakeholder}
                    projectId={id}
                    onClose={() => {
                      setEditStakeholderId(null);
                    }}
                    onSuccess={() => {
                      refetch();
                    }}
                    isOpen={editStakeholderId === stakeholder.id}
                  />
                ),
              }),
            )}
          />
        </Stack>
      </Section>
    </Page>
  );
};
