import React, { useState, useCallback, useEffect, useMemo } from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { project, product } from "../../queries";
import { useQ } from "../../hooks/useQ";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormTextField } from "../../base/form/mui/FormTextField";
import { CustomAccordion } from "../../layout/CustomAccordion";
import { useMutation } from "react-query";
import { useToast } from "../../hooks/useToast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";

const LinkForm = ({ link, onSave, open, onClose, ...props }) => {
  const schema = yup.object().shape({
    label: yup.string().required(),
    href: yup.string().required(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      label: link?.label || "",
      href: link?.href || "",
    },
  });

  const { handleSubmit, reset } = formData;

  useEffect(() => {
    reset({
      label: link?.label || "",
      href: link?.href || "",
    });
  }, [link, reset]);

  return (
    <Paper
      sx={{
        padding: "30px 20px",
        display: open ? "block" : "none",
      }}
      {...props}
    >
      <FormDataProvider formKey="projectForm.editLinks.fields" {...formData}>
        <form
          onSubmit={handleSubmit((data) => {
            onSave({
              id: link?.id || undefined,
              label: data.label,
              href: data.href,
            });
            reset();
          })}
        >
          <Box sx={{ width: "100%" }}>
            <FormTextField
              disabled={link?.type === "standard"}
              name={"label"}
              fullWidth
            />
          </Box>
          <Box sx={{ width: "100%", mt: 2 }}>
            <FormTextField
              disabled={link?.type === "standard"}
              name={"href"}
              fullWidth
            />
          </Box>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={link?.type === "standard"}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </Stack>
        </form>
      </FormDataProvider>
    </Paper>
  );
};

const DeletePanel = ({ onDelete, onClose, open, ...props }) => {
  return (
    <Paper
      sx={{
        padding: "30px 20px",
        display: open ? "block" : "none",
      }}
      {...props}
    >
      <Typography variant="body1">Are you sure to delete this item?</Typography>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ width: "100%", mt: 2 }}
      >
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDelete}>
          Delete
        </Button>
      </Stack>
    </Paper>
  );
};

export const EditLinks = () => {
  const { id = "", reportId = "" } = useParams();
  const { t } = useT();
  const loc = useLocation();
  const match = matchPath({ path: "/:type/:id", end: false }, loc.pathname);
  const type = match?.params?.type;
  const { isLoading: isLoadingProject, data: projectData = {} } = useQ(
    `project-${id}`,
    () => project.single({ id }),
    {
      enabled: type === "projects",
    },
  );
  const { isLoading: isLoadingProduct, data: productData = {} } = useQ(
    `product-${id}`,
    () => product.single({ id }),
    {
      enabled: type === "products",
    },
  );

  const isLoading = useMemo(
    () => (type === "projects" ? isLoadingProject : isLoadingProduct),
    [type, isLoadingProject, isLoadingProduct],
  );

  const data = useMemo(
    () => (type === "projects" ? projectData : productData),
    [type, projectData, productData],
  );

  const { title } = data;

  const { data: projectLinks = [], refetch: refetchProjectLinks } = useQ(
    `project-${id}-links`,
    () => project.links({ id }),
    {
      enabled: type === "projects",
    },
  );

  const { data: productLinks = [], refetch: refetchProductLinks } = useQ(
    `product-${id}-links`,
    () => product.links({ id }),
    {
      enabled: type === "products",
    },
  );

  const links = useMemo(
    () => (type === "projects" ? projectLinks : productLinks),
    [type, projectLinks, productLinks],
  );

  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const toast = useToast();

  const createLink = useMutation(
    type === "projects" ? project.addLink : product.addLink,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Link created successfully");
        type === "projects" ? refetchProjectLinks() : refetchProductLinks();
        setShowNewItemForm(false);
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error creating link");
      },
    },
  );

  const editLink = useMutation(
    type === "projects" ? project.editLink : product.editLink,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Link updated successfully");
        type === "projects" ? refetchProjectLinks() : refetchProductLinks();
        setEditItemId(null);
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error updating link");
      },
    },
  );

  const deleteLink = useMutation(
    type === "projects" ? project.deleteLink : product.deleteLink,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Link updated successfully");
        type === "projects" ? refetchProjectLinks() : refetchProductLinks();
        setDeleteItemId(null);
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error updating link");
      },
    },
  );

  const handleShowEditItemForm = useCallback((itemId) => {
    setShowNewItemForm(false);
    setEditItemId(itemId);
    setDeleteItemId(null);
  }, []);

  const handleShowDeleteItemForm = useCallback((itemId) => {
    setShowNewItemForm(false);
    setEditItemId(null);
    setDeleteItemId(itemId);
  }, []);

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.navigation.actions.editLinks")}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          mb={4}
        >
          <Button
            variant="text"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setShowNewItemForm((s) => !s)}
          >
            {showNewItemForm ? "Cancel" : "Add new"}
          </Button>
        </Stack>
        <CustomAccordion
          tableView
          header={{
            columns: [
              {
                title: "",
                width: 40,
              },
              {
                title: "Link to",
                flex: 1,
              },
            ],
            expanded: showNewItemForm,
            details: (
              <LinkForm
                open
                onSave={(data) =>
                  createLink.mutate({
                    id,
                    data,
                  })
                }
                onClose={() => setShowNewItemForm(false)}
              />
            ),
          }}
          rows={links.map((link, index) => ({
            id: link.id,
            expanded: editItemId === link.id || deleteItemId === link.id,
            columns: [
              {
                content: (
                  <Stack direction="row" alignItems="center">
                    {/*<MoreVertIcon />*/}
                    <Typography fontWeight="bold">{index + 1}</Typography>
                  </Stack>
                ),
                width: 40,
              },
              {
                content: <Typography>{link.label}</Typography>,
                flex: 1,
              },
              {
                content: (
                  <IconButton onClick={() => handleShowEditItemForm(link.id)}>
                    <EditIcon />
                  </IconButton>
                ),
                width: 40,
              },
              {
                content: (
                  <IconButton
                    disabled={link.type === "standard"}
                    onClick={() => handleShowDeleteItemForm(link.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                ),
                width: 40,
              },
            ],
            details: (
              <>
                <LinkForm
                  link={link}
                  open={editItemId === link.id}
                  onClose={() => handleShowEditItemForm(null)}
                  onSave={(data) => {
                    editLink.mutate({
                      id,
                      linkId: link.id,
                      data: {
                        label: data.label,
                        href: data.href,
                      },
                    });
                    setEditItemId(null);
                  }}
                />
                <DeletePanel
                  open={deleteItemId === link.id}
                  onClose={() => handleShowDeleteItemForm(null)}
                  link={link}
                  onDelete={() => {
                    deleteLink.mutate({
                      id,
                      linkId: link.id,
                    });
                    setDeleteItemId(null);
                  }}
                />
              </>
            ),
          }))}
        />
      </Section>
    </Page>
  );
};
