import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const ErasureOfData = () => (
  <Section id="11">
    <Typography variant="h2">11. Erasure and return of data</Typography>
    <ol>
      <li>
        On termination of the provision of personal data processing services,
        the data processor shall be under obligation to delete all personal data
        processed on behalf of the data controller and certify to the data
        controller that it has done so unless Union or Member State law
        re-quires storage of the personal data.
      </li>
    </ol>
  </Section>
);
