import React from "react";
import { Link } from "react-router-dom";
import { clientUrl } from "../util/routing";
import { uris } from "../config/nav";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { Stack, Typography } from "@mui/material";

export const EntityLink = ({
  children,
  title,
  id = "",
  icon = false,
  entity,
}) => {
  let to = "";
  switch (entity) {
    case "project":
      to = clientUrl(uris.project.single, { id });
      break;
    case "release":
      to = clientUrl(uris.release.single, { id });
      break;
    case "programme":
      to = clientUrl(uris.programme.single, { id });
      break;
    case "product":
      to = clientUrl(uris.product.single, { id });
      break;
    case "portfolio":
      to = clientUrl(uris.portfolio.single, { id });
      break;

    default:
      console.warn(
        `Entity with type: ${entity} not recognized in < EntityLink >`,
      );
      to = `/e404`;
      break;
  }

  return (
    <Link to={to} title={title} style={{ textDecoration: "none" }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
          sx={{
            color: "#9A9EB1 !important",
            fontSize: "14px !important",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={typeof children === "string" ? children : ""}
        >
          {children}
        </Typography>
        {icon && (
          <ArrowCircleRightOutlinedIcon sx={{ color: "#9A9EB1 !important" }} />
        )}
      </Stack>
    </Link>
  );
};
