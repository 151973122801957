import React, { useEffect } from "react";
import { path } from "ramda";
import { uris } from "@/config/nav";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";

export const useQ = (queryKey: string, queryFn: () => any, opts?: any) => {
  const { isError, error, data, ...rest } = useQuery(queryKey, queryFn, opts);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (isError) {
      if (path(["response", "status"], error) === 500) {
        navigate(uris.errors.e500);
      }
      if (path(["response", "status"], error) === 404) {
        navigate(uris.errors.e404);
      }
      if ([401, 403].includes(path(["response", "status"], error))) {
        console.log(
          "[TRACE] Current location: " +
            location.pathname +
            " !== " +
            uris.login +
            "?",
        );
        if (location.pathname !== uris.login) {
          navigate(uris.errors.e401);
        } else {
          console.log("[TRACE] Is already on loginpage, ignore rediret to 401");
        }
      }
    }
  }, [isError]);

  return { isError, error, data, ...rest };
};
