import { useQ } from "@/hooks/useQ";
import { users } from "@/queries";
import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { Section } from "@/layout/Section";

export const TableOfContent = () => (
  <Stack>
    <Typography variant="h2">1. Table of Contents</Typography>
    {[
      {
        id: 2,
        chapter: "2",
        title: "Preamble",
        href: "#2",
      },
      {
        id: 3,
        chapter: "3",
        title: "The rights and obligations of the data controller",
        href: "#3",
      },
      {
        id: 4,
        chapter: "4",
        title: "The data processor acts according to instructions",
        href: "#4",
      },
      {
        id: 5,
        chapter: "5",
        title: "Confidentiality",
        href: "#5",
      },
      {
        id: 6,
        chapter: "6",
        title: "Security of processing",
        href: "#6",
      },
      {
        id: 7,
        chapter: "7",
        title: "Use of sub-processors",
        href: "#7",
      },
      {
        id: 8,
        chapter: "8",
        title:
          "Transfer of data to third countries or international organisations",
        href: "#8",
      },
      {
        id: 9,
        chapter: "9",
        title: "Assistance to the data controller",
        href: "#9",
      },
      {
        id: 10,
        chapter: "10",
        title: "Notification of personal data breach",
        href: "#10",
      },
      {
        id: 11,
        chapter: "11",
        title: "Erasure and return of data",
        href: "#11",
      },
      {
        id: 12,
        chapter: "12",
        title: "Audit and inspection",
        href: "#12",
      },
      {
        id: 13,
        chapter: "13",
        title: "The parties' agreement on other terms",
        href: "#13",
      },
      {
        id: 14,
        chapter: "14",
        title: "Commencement and termination",
        href: "#14",
      },
      {
        id: 15,
        chapter: "15",
        title: "Data controller and data processor contacts/contact points",
        href: "#15",
      },
      {
        id: "appendixA",
        chapter: "Appendix A",
        title: "Information about the processing",
        href: "#appendixA",
      },
      {
        id: "appendixB",
        chapter: "Appendix B",
        title: "Authorised sub-processors ",
        href: "#appendixB",
      },
      /*{
        id: "appendixC",
        chapter: "Appendix C",
        title: "Instruction pertaining to the use of personal data",
        href: "#appendixC",
      },
      {
        id: "appendixD",
        chapter: "Appendix D",
        title: "The parties’ terms of agreement on other subjects",
        href: "#appendixD",
      },*/
    ].map(({ id, title, href, chapter }) => (
      <Link key={id} href={href} sx={{ ml: 2 }}>
        {chapter}. {title}
      </Link>
    ))}
  </Stack>
);
