import { Button, Stack } from "@mui/material";
import React from "react";
import { useT } from "@/hooks/useT";

type StepperNavigatorProps = {
  showPreviousButton: boolean;
  setActiveStep: (step: number | ((step: number) => number)) => void;
  onCancel?: () => void;
  isDirty: boolean;
  submitButtonLabel?: string;
  hideCancelButton?: boolean;
};

export const StepperNavigator = ({
  showPreviousButton,
  setActiveStep,
  onCancel,
  isDirty,
  submitButtonLabel,
  hideCancelButton,
}: StepperNavigatorProps) => {
  const { t } = useT();

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      sx={{ width: "100%", mt: 2 }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        width={"100%"}
        spacing={2}
      >
        {!!showPreviousButton && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setActiveStep((s) => s - 1)}
          >
            {t("generic.stepper.previous")}
          </Button>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        width={"100%"}
        spacing={2}
      >
        {!hideCancelButton && (
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {isDirty
              ? t("risk.registerForm.buttons.cancel")
              : t("generic.button.close")}
          </Button>
        )}
        {!!submitButtonLabel && (
          <Button
            type="submit"
            variant="contained"
            data-cy="save-update-stage-button"
          >
            {submitButtonLabel}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
