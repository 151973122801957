import { useT } from "@/hooks/useT";
import { useToast } from "@/hooks/useToast";
import { useMutation } from "react-query";
import { programme } from "@/queries";
import { Stack, Typography } from "@mui/material";
import { HtmlReadMoreTypography } from "@/base/typography/HtmlReadMoreTypography";
import { TextsArraySection } from "@/pages/home/AccountSettings/TextsArraySection";
import { v4 as uuidV4 } from "uuid";
import React from "react";

export const OutcomesForm = ({ programmeData, onUpdate }) => {
  const { t } = useT();
  const toast = useToast();

  const createOutcome = useMutation(programme.addOutcome, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Outcome added successfully");
      onUpdate?.();
    },
    onError: ({ error }) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(`Error adding Outcome. ${error?.response?.data?.message}`);
    },
  });

  const updateOutcome = useMutation(programme.editOutcome, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Outcome edited successfully");
      onUpdate?.();
    },
    onError: ({ error }) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(`Error editing Outcome. ${error?.response?.data?.message}`);
    },
  });

  const deleteOutcome = useMutation(programme.deleteOutcome, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Outcome deleted successfully");
      onUpdate?.();
    },
    onError: ({ error }) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(`Error deleting Outcome. ${error?.response?.data?.message}`);
    },
  });

  return (
    <Stack spacing={1}>
      <Typography variant="h2" fontWeight="normal">
        {t("programme.editDesignForm.outcomes.title")}
      </Typography>
      <HtmlReadMoreTypography>
        {t("programme.editDesignForm.outcomes.description")}
      </HtmlReadMoreTypography>
      <TextsArraySection
        items={programmeData?.outcomes ?? []}
        onNewItemSubmit={(data) => {
          createOutcome.mutate({
            id: programmeData.id,
            data: {
              outcome: {
                id: uuidV4(),
                name: data.name,
              },
            },
          });
        }}
        onEditItemSubmit={(data) => {
          updateOutcome.mutate({
            id: programmeData.id,
            data: {
              outcome: {
                id: data.id,
                name: data.name,
              },
            },
          });
        }}
        onDeleteItemSubmit={(data) => {
          deleteOutcome.mutate({
            id: programmeData.id,
            outcomeId: data.id,
          });
        }}
        renderTitle={(item) => item.name}
        headerTitle={t("generic.titles.name")}
      />
    </Stack>
  );
};
