import htmlToDraftjs from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { isNil, path, clone, has, pathOr, assocPath, is, isEmpty, dissoc } from 'ramda';

export const d2h = (draftState) => {
  try {
    return draftToHtml(convertToRaw(draftState.getCurrentContent()));
  } catch (e) {
    return '';
  }
};

export const h2d = (html) => {
  if(isNil(html)) {
    return EditorState.createEmpty();
  }
  const { contentBlocks, entityMap } = htmlToDraftjs(html);
  const cs = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(cs);
};


export const valueToEvent = (value, fieldName) => {
  return { target: {name: fieldName, value: value}}
}


export const preventEnterKey = (e) => {
  if(e.keyCode === 13) {
    e.preventDefault();
  }
}


export const blurOnEnterKey = (e) => {
  if(e.keyCode === 13) {
    e.target.blur();
    e.preventDefault();
  }
}

export const simpleDataTableSort = (data, ordering) => {
  const {currentOrder, currentParent, targetOrder, targetParent} = ordering;

  // console.log('from ', currentOrder, `(${currentParent})` , ' to ', targetOrder, `(${targetParent})`);

  const cp = currentParent.map((v) => [v, 'children']).flat();
  const tp = targetParent.map((v) => [v, 'children']).flat();

  const workingCopy = clone(data);
  const movingElement = path([...cp, currentOrder], workingCopy);
  const removedElement = assocPath([...cp, currentOrder], null, workingCopy);

  const targetParentList = pathOr([], tp, removedElement);


  const inserted = targetParentList.length === 0 ? [movingElement] : targetParentList.reduce((acc, curr, idx) => {
    if(idx === targetOrder) {
      return [...acc, movingElement, curr];
    }
    return [...acc, curr];
  }, [])

  const stitchedIn = assocPath(tp, inserted, removedElement);

  const cleanUp = (data) => {
    if(is(Array, data)) {
      return data.filter((el) => el !== null).map(el => cleanUp(el))
    }
    if(is(Object, data)) {
      if(!has('children', data)) {
        return data;
      }
      return {...data, children: cleanUp(data.children)}
    }
  }

  const cleansed = cleanUp(stitchedIn);
  return cleansed;
}


export const dataTableSort = (data, ordering) => {
  const {currentOrder, currentParent, currentDragId, targetOrder, targetParent, targetDragId} = ordering;

  // Drag & Drop inside the same table, we are good.
  if(currentDragId === targetDragId) {
    return {
      ...data,
      [targetDragId]: simpleDataTableSort(data[targetDragId], ordering)
    }
  }

  // Drag & Drop between diffrent dragId's

  const currentData = clone(data[currentDragId]);
  const targetData = clone(data[targetDragId]);

  const cp = currentParent.map((v) => [v, 'children']).flat();
  const tp = targetParent.map((v) => [v, 'children']).flat();

  const movingElement = path([...cp, currentOrder], currentData);
  const removedElement = assocPath([...cp, currentOrder], null, currentData);

  const targetParentList = pathOr([], tp, targetData);


  const inserted = targetParentList.length === 0 ? [movingElement] : targetParentList.reduce((acc, curr, idx) => {
    if(idx === targetOrder) {
      return [...acc, movingElement, curr];
    }
    return [...acc, curr];
  }, [])

  const stitchedIn = assocPath(tp, inserted, targetData);

  const cleanUp = (data) => {
    if(is(Array, data)) {
      return data.filter((el) => el !== null).map(el => cleanUp(el))
    }
    if(is(Object, data)) {
      if(!has('children', data)) {
        return data;
      }
      return {...data, children: cleanUp(data.children)}
    }
  }

  const cleansed = cleanUp(removedElement);
  return {
    [targetDragId]: stitchedIn,
    [currentDragId]: cleansed
  };
}

export const flattenDataTableToLevel = (data, maxLevel) => {
  const flatten = (data, currentLevel = 0) => {
    return data.reduce((acc, item) => {
      if(has('children', item) && !isEmpty(item.children)) {

        if(currentLevel + 1 > maxLevel) {
          return [...acc, dissoc('children', item), ...flatten(item.children, currentLevel + 1)]
        }
        else {
          return [...acc, {...item, children: flatten(item.children, currentLevel + 1)}]
        }


      } else {
        return [...acc, item];
      }
    }, [])
  };

  const f = flatten(data);
  return f;
}


