import React, { useEffect } from "react";
import { useT } from "../../../hooks/useT";
import { useFlag } from "../../../hooks/useFlag";
import { home } from "../../../queries";
import { Section } from "../../../layout/Section";
import { SummaryCard } from "../../../base/project/SummaryCard";
import { propOr, take, isNil, isEmpty } from "ramda";
import { EntityLink } from "../../../base/EntityLink";
import { DatePretty } from "../../../base/DatePretty";
import { Badge } from "../../../base/Badge";
import { Stat } from "../../../base/Stat";
import { useQ } from "../../../hooks/useQ";
import { useShowSomeMore } from "../../../hooks/useShowSomeMore";
import {
  Typography,
  Stack,
  Grid,
  Button,
  Link as LinkMui,
} from "@mui/material";
import { Link } from "react-router-dom";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { createReportLink } from "../../../util/reportLinks";

const Roles = ({ roles }) => {
  const { t } = useT();
  return (
    <span>
      {!isNil(roles) && !isEmpty(roles) ? (
        <>
          <Typography variant="h2">
            {t("page.home.favorites.yourRole")}:
          </Typography>
          <Typography
            sx={{
              color: "#6B7280",
              fontSize: "20px",
            }}
          >
            {roles.join(", ")}
          </Typography>
        </>
      ) : null}
    </span>
  );
};

const LatestHighlightReport = ({ report, onReportClick }) => {
  const { t } = useT();

  return (
    <>
      {!isNil(report) ? (
        <LinkMui href={createReportLink(report)} /*onClick={() => onReportClick(report)}*/ mt={1}>
          {t("page.home.favorites.latestReportLink")} (
          <DatePretty
            format={"YYYY-MM-DD"}
            date={propOr(null, "date", report)}
          />
          )
        </LinkMui>
      ) : null}
    </>
  );
};

const StatusBadge = ({ status, className = "" }) => {
  const { t } = useT();
  return (
    <Badge variant={status} sx={{ mr: 1 }}>
      <Typography>{t("page.home.favorites.overallStatus")}</Typography>
      <Stat
        status={status}
        className={"pd-l-nm"}
        style={{ marginRight: "-10px" }}
      />
    </Badge>
  );
};

const LinkToInitiativeHomePage = ({ id, type }) => {
  const { t } = useT();
  return (
    <EntityLink icon id={id} entity={type}>
      {t("page.home.favorites.goto." + type)}
    </EntityLink>
  );
};

const InitiativeDetails = ({
  id,
  type,
  roles,
  latestReport,
  onReportClick,
}) => {
  return (
    <div className={"d-f f-d-c f-1 f-jc-fe"}>
      <Roles roles={roles} />
      <LatestHighlightReport
        report={
          latestReport !== undefined
            ? { ...latestReport, initiative: { id: id, type: type } }
            : undefined
        }
        onReportClick={onReportClick}
      />

      <Stack
        direction="row"
        mt={2}
        alignItems="center"
        justifyContent="space-betwen"
      >
        <StatusBadge
          className={"mg-r-nm"}
          status={propOr(null, "status", latestReport)}
        />
        <LinkToInitiativeHomePage id={id} type={type} />
      </Stack>
    </div>
  );
};

export const FavSummaryCard = ({ name, onReportClick, ...initiative }) => {
  return (
    <SummaryCard>
      <Stack
        sx={{
          minHeight: {
            xs: "auto",
            md: "400px",
          },
        }}
        justifyContent="space-between"
      >
        <Typography variant="h1">{name}</Typography>
        <InitiativeDetails {...initiative} onReportClick={onReportClick} />
      </Stack>
    </SummaryCard>
  );
};

const FavoriteCards = ({ show, data, onReportClick }) => {
  return (
    <Grid container spacing={3}>
      {take(show, data).map((item, idx) => {
        return (
          <Grid item sm={12} md={6} lg={4} key={propOr(idx, "id", item)}>
            <FavSummaryCard {...item} onReportClick={onReportClick} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export const Favorites = ({ isLoading, data, onReportClick }) => {
  const { t } = useT();

  const stepFn = ({ show, min, direction }) => {
    return direction === -1 ? min : show + show * 2;
  };
  const { show, showMore, showLess, min, max } = useShowSomeMore({
    max: data.length,
    min: 3,
    step: stepFn,
    initial: 3,
  });

  useEffect(() => {
    showLess();
  }, [data]);

  return (
    <Section isLoading={isLoading} title={t("page.home.favorites.title")}>
      <FavoriteCards data={data} show={show} onReportClick={onReportClick} />

      {max > 3 ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={3}
        >
          <Button
            disabled={show <= min}
            onClick={() => showLess()}
            endIcon={<RemoveCircleOutlineIcon />}
          >
            {t("generic.showLess")}
          </Button>
          <Button
            disabled={show >= max}
            onClick={() => showMore()}
            endIcon={<AddCircleOutlineIcon />}
          >
            {t("generic.showMore")}
          </Button>
        </Stack>
      ) : null}
    </Section>
  );
};

export const ProjectCards = ({ onReportClick }) => {
  const { t } = useT();
  const { isLoading, data = [] } = useQ(`favorite-projects`, () =>
    home.favorites(),
  );

  return (
    <Favorites
      isLoading={isLoading}
      data={data}
      onReportClick={onReportClick}
    />
  );
};
