import React, { useEffect, useState } from "react";
import profile from "../assets/profile.png";
import { Icon } from "../base/Icon";
import { Link } from "react-router-dom";
import { LogoutForm } from "../pages/login/LogoutForm";
import { useT } from "../hooks/useT";
import { useQuery } from "react-query";
import { users } from "../queries";
import { isNil, pathOr, prop, propOr } from "ramda";
import { SwtichTenant } from "../pages/login/SwitchTenant";
import { useQ } from "../hooks/useQ";
import { ProfileIcon } from "../base/ProfileIcon";

const transformTenants = (data) => {
  const currentTenantId = prop("currentTenant", data);
  const t = propOr([], "tenants", data).filter((v) => v.id !== currentTenantId);

  return t.map((item) => {
    return { label: prop("name", item), icon: null, id: item.id };
  });
};

const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const ProfileMenu = () => {
  const [open, setOpen] = useState(false);
  const { isLoading, data } = useQ(`users-me`, users.me);

  const [tenants, setTenants] = useState([]);
  useEffect(() => {
    setTenants(transformTenants(data));
  }, [data]);

  const hasSwitch = pathOr(0, ["tenants", "length"], data) > 1;
  const { t } = useT();
  function hasAdminRoleOnCurrentTenant(data) {
    const currentTenant = !isNil(data)
      ? data.tenants.find(
          (item) => item.id === data.id || item.id === data.currentTenant,
        )
      : undefined;
    return currentTenant !== undefined && currentTenant.roles !== undefined
      ? currentTenant.roles.includes("admin")
      : false;
  }

  const profile_items = [
    [
      {
        label: t("profile_menu.profile"),
        icon: "my-profile",
        to: "/my-profile",
      },
      false
        ? {
            label: t("profile_menu.preferences"),
            icon: "profile",
            to: "/my-profile",
          }
        : null,
      hasAdminRoleOnCurrentTenant(data)
        ? {
            label: "Account Settings",
            icon: "profile",
            to: "/accountsettings/users",
          }
        : null,
    ].filter((item) => item !== null),
  ];
  return (
    <div className={"profile-menu"}>
      <ProfileIcon
        onClick={() => setOpen((v) => !v)}
        name={data != undefined ? data.name : ""}
        sx={{ height: "50px", width: "50px", mt: 1 }}
      />

      {open && (
        <div className={"pm-menu"}>
          {profile_items.map((sec, secidx) => {
            return (
              <div className={"pm-section"} key={secidx}>
                {sec.map((item) => {
                  const hasIcon = !isNil(item.icon);
                  if (isNil(item.to)) {
                    return (
                      <span
                        className={`label ${hasIcon ? "" : "no-icon"}`}
                        key={item.label}
                      >
                        {hasIcon && <Icon i={item.icon} />}
                        {item.label}
                      </span>
                    );
                  }
                  return (
                    <Link
                      to={item.to}
                      key={item.label}
                      className={`${hasIcon ? "" : "no-icon"}`}
                    >
                      {hasIcon && <Icon i={item.icon} />}
                      {item.label}
                    </Link>
                  );
                })}
              </div>
            );
          })}

          {hasSwitch && (
            <div className={"pm-section"}>
              <span className={`label`}>
                <Icon i={"profile"} />
                Switch Tenant
              </span>
              {tenants.map((item) => {
                return (
                  <SwtichTenant
                    key={item.id}
                    label={item.label}
                    tenantId={item.id}
                    next={() => setOpen(false)}
                    className={"cursor-link no-icon"}
                  >
                    {item.label}
                  </SwtichTenant>
                );
              })}
            </div>
          )}

          <div className={"pm-section"}>
            <LogoutForm className={"cursor-link"}>
              <Icon i={"sign-out"} />
              {t("profile_menu.logout")}
            </LogoutForm>
          </div>
        </div>
      )}
    </div>
  );
};
