import React from 'react';
import { GenericErrorHandler } from './GenericErrorHandler';
import { SimpleLayout } from './SimpleLayout';

export const SimplePage = ({ children }) => {
  return (
    <SimpleLayout>
      <div className={'page'}>
        <GenericErrorHandler>
          {children}
        </GenericErrorHandler>
      </div>
    </SimpleLayout>
  );
};
