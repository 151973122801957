import React from 'react';

export const GreenUp = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7188 23C10.5432 23 8.41642 22.3549 6.60748 21.1462C4.79854 19.9375 3.38864 18.2195 2.55608 16.2095C1.72352 14.1995 1.50568 11.9878 1.93012 9.85401C2.35455 7.72022 3.4022 5.76021 4.94058 4.22183C6.47896 2.68345 8.43897 1.6358 10.5728 1.21137C12.7066 0.786929 14.9183 1.00477 16.9283 1.83733C18.9383 2.66989 20.6562 4.07979 21.8649 5.88873C23.0736 7.69767 23.7188 9.82441 23.7188 12C23.7154 14.9164 22.5555 17.7123 20.4933 19.7745C18.4311 21.8367 15.6351 22.9967 12.7188 23Z" fill="#47B857"/>
      <g clipPath="url(#clip0)">
        <path d="M12.0117 6.29279L7.30469 10.9998L8.71869 12.4138L11.7187 9.41379V17.9998H13.7187V9.41379L16.7187 12.4138L18.1327 10.9998L13.4257 6.29279C13.2382 6.10532 12.9839 6 12.7187 6C12.4535 6 12.1992 6.10532 12.0117 6.29279Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" transform="translate(6.71875 6)"/>
        </clipPath>
      </defs>
    </svg>
  );
};
