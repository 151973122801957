import React, { memo, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import DateRangePicker from "rsuite/DateRangePicker";
import moment from "moment";

export const FormDateRangePicker = memo(
  ({ minName, maxName, label, ...props }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();

    return (
      <Controller
        control={control}
        name={minName}
        render={({ field: { onChange, value } }) => (
          <Controller
            control={control}
            name={maxName}
            render={({ field: { onChange: onChangeMax, value: maxValue } }) => {
              return (
                <DateRangePicker
                  value={[
                    moment(value).isValid()
                      ? moment(value).toDate()
                      : moment().toDate(),
                    moment(maxValue).isValid()
                      ? moment(maxValue).toDate()
                      : moment().toDate(),
                  ]}
                  showOneCalendar
                  character=" - "
                  renderValue={(value) => label(value[0], value[1])}
                  onChange={(value) => {
                    onChange(value[0]);
                    onChangeMax(value[1]);
                  }}
                />
              );
            }}
          />
        )}
      />
    );
    /*
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Button
          ref={buttonRef}
          onClick={() => setIsPickerOpen((o) => !o)}
          {...props}
        >
          {label}
        </Button>
        <Menu
          open={isPickerOpen}
          anchorEl={buttonRef.current}
          onClose={() => setIsPickerOpen(false)}
        >
          <Stack direction="row">
            <Controller
              control={control}
              name={minName}
              render={({ field: { onChange, value } }) => (
                <CalendarPicker date={value} onChange={onChange} />
              )}
            />
            <Controller
              control={control}
              name={maxName}
              render={({ field: { onChange, value } }) => (
                <CalendarPicker date={value} onChange={onChange} />
              )}
            />
          </Stack>
        </Menu>
      </LocalizationProvider>
    );
 */
  },
);
FormDateRangePicker.displayName = "FormDateRangePicker";
