import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { isDev } from './util/isDev';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: !isDev(),
    }
  }
});

export const UseQueryProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};
