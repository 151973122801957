import React, { memo, useState } from "react";
import { Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useFormStrings } from "../../../hooks/useFormStrings";
import { TitleWithHelper } from "../../TitleWithHelper";

export const FormCheckboxGroup = memo(
  ({
    name,
    label,
    placeholder,
    help,
    fieldKey,
    textFieldRef,
    inputRef,
    items,
    ...props
  }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const inputStrings = useFormStrings({
      name,
      label,
      placeholder,
      help,
      fieldKey,
    });

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          const [valueList, setValueList] = useState(value || []);
          return (
            <Stack sx={{ width: "100%" }}>
              {inputStrings.help ? (
                <TitleWithHelper
                  title={inputStrings.label}
                  help={inputStrings.help}
                />
              ) : (
                <Typography
                  sx={{
                    fontSize: "20px !important",
                    color: "#111827 !important",
                    mb: "12px !important",
                  }}
                >
                  {inputStrings.label}
                </Typography>
              )}
              <RadioGroup
                aria-labelledby="demo-radio-checkbox-group-label"
                name={name}
                value={value}
                {...props}
                inputRef={(e) => {
                  ref(e);
                  if (textFieldRef) {
                    textFieldRef.current = e;
                  }
                }}
                onBlur={onBlur}
              >
                {items?.map((element, index) => (
                  <Stack direction="row" spacing={0} sx={{ width: "100%" }}>
                    <FormControlLabel
                      name={element.name}
                      checked={valueList.includes(element.name)}
                      type="checkbox"
                      value={element.name}
                      control={<Checkbox />}
                      onChange={(e) => {
                        const valueCopy = [...valueList];

                        // update checkbox value
                        valueCopy[index] = e.target.checked
                          ? e.target.value
                          : null;

                        // send data to react hook form
                        onChange(valueCopy);

                        // update local state
                        setValueList(valueCopy);
                      }}
                      label={element.label}
                    />
                    {element.tooltip && (
                      <Tooltip title={element.tooltip}>
                        <InfoOutlinedIcon />
                      </Tooltip>
                    )}
                  </Stack>
                ))}
              </RadioGroup>
            </Stack>
          );
        }}
      />
    );
  },
);
FormCheckboxGroup.displayName = "FormCheckboxGroup";
