import React, { useState, useCallback } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const HeaderTypography = ({ sx, ...props }) => (
  <Typography
    variant="h5"
    sx={{
      wordBreak: "break-word",
      ...sx,
    }}
    {...props}
  />
);

/**

 CustomAccordion is a reusable component for rendering an accordion with a header and multiple rows.
 @param tableId
 @param draggable
 @param droppable
 @param onDrop
 @param rowSx
 @param {Object} props - The props for the component.
 @param {Object} props.header - An object representing the header of the accordion.
 @param {Array} props.header.columns - An array of objects representing the columns in the header. Each object should have a title property for the column title and can optionally have a flex or width property for styling.
 @param {ReactNode} props.header.details - The content to be displayed in the accordion details for the header.
 @param {boolean} props.header.expanded - A boolean indicating whether the header accordion should be expanded or not.
 @param {Array} props.rows - An array of objects representing the rows in the accordion. Each object should have an id property, an expanded boolean property, a columns array with the same structure as the header.columns array, and a details property for the content to be displayed in the accordion details.
 @param {boolean} props.tableView - A boolean indicating whether the accordion should be displayed in a table view or not.
 @param {any} props.otherProps - Any other props passed to the component will be spread onto the outer Box element.
 */
export const CustomAccordion = ({
  tableId = null,
  header,
  rows = [],
  tableView = true,
  draggable = false,
  droppable = false,
  onDrop = () => null,
  rowSx = {},
  ...props
}) => {
  const [dragOver, setDragOver] = useState(null);

  const handleDrag = useCallback((e, index, row) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ tableId, index, id: row.id }),
    );
  }, []);

  const handleDragOver = useCallback((e, index) => {
    e.preventDefault();
    setDragOver(index);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setDragOver(null);
  }, []);

  const handleDrop = useCallback(
    (e, index, row) => {
      e.preventDefault();
      setDragOver(null);
      onDrop({
        source: JSON.parse(e.dataTransfer.getData("text")),
        target: { tableId, index, id: row.id },
      });
    },
    [onDrop],
  );

  const expandDisabledForAllRows = rows
    .filter((row) => !!row)
    .every((row) => row?.expandDisabled);

  const renderColumns = (columns, isHeader) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        maxWidth="100%"
        spacing={2}
        sx={rowSx}
      >
        {columns.map((column, index) =>
          typeof column === "string" ? (
            <Typography key={column} variant="h5">
              {column}
            </Typography>
          ) : column.content ? (
            <Box
              key={index}
              sx={{
                flex: column.flex ? column.flex : undefined,
                width: column.width ? column.width : undefined,
                wordBreak: "break-all",
                ...(column.sx ?? {}),
              }}
            >
              {column.content}
            </Box>
          ) : (
            <Typography
              key={column.id ?? column.title}
              variant={isHeader ? "h5" : "h4"}
              sx={{
                flex: column.flex ? column.flex : undefined,
                width: column.width ? column.width : undefined,
                wordBreak: "break-word",
                ...(column.sx ?? {}),
              }}
            >
              {column.title}
            </Typography>
          ),
        )}
      </Stack>
    );
  };

  return (
    <Box {...props} sx={{ width: "100%" }}>
      {!!header && (
        <Accordion
          sx={{
            backgroundColor: tableView ? "#F9FAFB" : undefined,
            borderBottomColor: !header.columns ? "transparent" : undefined,
            ...(header.sx ?? {}),
          }}
          key="header"
          disableGutters
          expanded={header.expanded}
        >
          <AccordionSummary
            sx={{
              height: !header.columns ? "6px" : undefined,
              minHeight: !header.columns ? "0" : undefined,
              cursor: "default !important",
            }}
          >
            {header.columns
              ? renderColumns(
                  [
                    ...header.columns,
                    ...(!expandDisabledForAllRows
                      ? [{ title: "", width: 40 }]
                      : []),
                  ],
                  true,
                )
              : null}
          </AccordionSummary>
          {!!header.details && (
            <AccordionDetails>{header.details}</AccordionDetails>
          )}
        </Accordion>
      )}
      {rows
        .filter((row) => !!row)
        .map((row, index) => (
          <Accordion
            sx={[
              {
                backgroundColor: tableView && index % 2 ? "#F9FAFB" : undefined,
                borderTopColor:
                  index === 0 && !!header && !header?.columns
                    ? "transparent"
                    : undefined,
                ...(row.sx ?? {}),
              },
              droppable && dragOver === index
                ? { backgroundColor: "#c1d3f9" }
                : null,
            ]}
            key={row.id}
            disableGutters
            expanded={row.expanded}
          >
            <AccordionSummary
              onClick={row.onClick ? row.onClick : null}
              draggable={draggable}
              onDragStart={draggable ? (e) => handleDrag(e, index, row) : null}
              onDragOver={
                droppable ? (e) => handleDragOver(e, index) : undefined
              }
              onDrop={droppable ? (e) => handleDrop(e, index, row) : undefined}
              onDragExit={droppable ? handleDragLeave : undefined}
              onDragLeave={droppable ? handleDragLeave : undefined}
              sx={{
                ...(row.expandDisabled
                  ? {
                      cursor: "default !important",
                    }
                  : {}),
              }}
            >
              {renderColumns(
                [
                  ...row.columns,
                  ...(!expandDisabledForAllRows
                    ? row.expandDisabled
                      ? [{ title: "", width: 40 }]
                      : [
                          {
                            content: !row.expanded ? (
                              <ChevronRightIcon />
                            ) : (
                              <ExpandMoreIcon />
                            ),
                            width: 40,
                            sx: {
                              alignSelf: "flex-start",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                            },
                          },
                        ]
                    : []),
                ],
                false,
              )}
            </AccordionSummary>
            <AccordionDetails>{row.details}</AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};
