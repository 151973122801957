import React from 'react';

export const ReadMore = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.7477 12.3018H9.28906" stroke="#9A9EB1" strokeWidth="1.24311" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.6406 9.19385L16.7484 12.3016L13.6406 15.4094" stroke="#9A9EB1" strokeWidth="1.24311" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.31641 12.3019C4.31641 14.6097 5.2332 16.823 6.86509 18.4549C8.49699 20.0868 10.7103 21.0036 13.0182 21.0036C15.326 21.0036 17.5393 20.0868 19.1712 18.4549C20.8031 16.823 21.7199 14.6097 21.7199 12.3019C21.7199 9.99401 20.8031 7.78068 19.1712 6.14878C17.5393 4.51689 15.326 3.6001 13.0182 3.6001C10.7103 3.6001 8.49699 4.51689 6.86509 6.14878C5.2332 7.78068 4.31641 9.99401 4.31641 12.3019V12.3019Z" stroke="#9A9EB1" strokeWidth="1.24311" strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  );
};
