import React from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../layout/Page";
import { product } from "../../queries";
import { HighlightProductProgressReportForm } from "../projects/forms/HighlightReportForm";
import { useQ } from "../../hooks/useQ";

export const ProductProgressCreateForm = () => {
  const { id = "", type = "" } = useParams();
  const { isLoading, data = {} } = useQ(`product-${id}`, () =>
    product.single({ id }),
  );

  const { title, level } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <HighlightProductProgressReportForm
        productId={id}
        level={level}
        type={type}
      />
    </Page>
  );
};
