import React from "react";
import { useT } from "@/hooks/useT";
import { useParams } from "react-router-dom";
import { LoginTemplate } from "@/pages/login/Login";
import { Button, TextField, Typography } from "@mui/material";

const ConfirmCodeForm = () => {
  const { code } = useParams();
  const { t } = useT();

  return (
    <form action="/api/login/" method="POST">
      <input name="token" value={code} type="hidden" />
      <input name="approach" value="magicLink" type="hidden" />
      <Typography>{t("loginForm.fields.code.title")}</Typography>
      <TextField value={code} fullWidth disabled />
      <Button variant="contained" sx={{ mt: 2 }} type={"submit"}>
        {t("loginForm.submit")}
      </Button>
    </form>
  );
};

export const LinkLoginLander = () => {
  const { t } = useT();
  return (
    <LoginTemplate>
      <ConfirmCodeForm />
    </LoginTemplate>
  );
};
