import React from 'react';

export const Product = ({className, style, onClick}) => (
  <svg className={className} style={style} onClick={onClick}
    width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.854 4.0194L9.134 1.165C9.68955 0.945 10.3081 0.945 10.8636 1.165L18.1436 4.0194C18.403 4.13 18.6232 4.31578 18.7759 4.55279C18.9287 4.78979 19.0068 5.06712 19.0004 5.349V14.6514C19.0071 14.9329 18.9294 15.21 18.7773 15.4469C18.6251 15.6839 18.4056 15.8699 18.1468 15.981L10.8668 18.8362C10.311 19.0546 9.69305 19.0546 9.1372 18.8362L1.8572 15.981C1.59785 15.8704 1.37759 15.6846 1.22487 15.4476C1.07214 15.2106 0.993957 14.9333 1.0004 14.6514V5.349C0.993694 5.06749 1.07141 4.79041 1.22354 4.55345C1.37567 4.31649 1.59525 4.13049 1.854 4.0194V4.0194Z" stroke="#D1D5DB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 7.88244L1.2832 4.46484" stroke="#D1D5DB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 7.88244L18.7168 4.46484" stroke="#D1D5DB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 19.0003V7.88269" stroke="#D1D5DB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
