import React, { useCallback, useMemo, useState, useEffect } from "react";
import { DatePretty } from "@/base/DatePretty";
import { useT } from "@/hooks/useT";
import {
  DataTable,
  DataTableAutoRows,
  DataTableHeading,
  DataTableHierarchy,
} from "@/base/table/DataTable";
import { Stat } from "@/base/Stat";
import { isNil, keys, set } from "ramda";
import { stripHtml } from "@/util/stripHtml";
import { Icon } from "@/base/Icon";
import { Stack, Typography, Button, Box, Collapse } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { clientUrl } from "@/util/routing";
import { uris } from "@/config/nav";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { FormRichTextField } from "@/base/form/mui/FormRichTextField";
import { useFlag } from "@/hooks/useFlag";
import { project } from "@/queries";
import { useToast } from "@/hooks/useToast";
import { v4 as uuidV4 } from "uuid";
import { useMutation } from "react-query";
import { FormSelect } from "@/base/form/mui/FormSelect";
import {
  EditOutlined,
  AddCircleOutlineOutlined,
  ArrowDropUp,
  ArrowDropDown,
} from "@mui/icons-material";
import { OutputForm, PPDGuide } from "../ProjectProductDescription";
import { KeyValueDisplayView } from "@/base/KeyValueDisplayView";
import { ProductsTable } from "@/pages/projects/components/ProductsTable";
import { CustomDialog } from "@/layout/CustomDialog";
import { StepperSections } from "@/base/form/mui/StepperSections";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";

class InlineForm extends React.Component {
  render() {
    return null;
  }
}

InlineForm.propTypes = {};

export const ProductFormDialog = ({
  output,
  parent,
  grandParent,
  showNewProductForm,
  onClose,
  onUpdate,
  scopeId,
  state = "add",
  scopes,
  setEditId,
  editId,
}) => {
  const { t } = useT();
  const { id } = useParams();
  const toast = useToast();
  const [activeStep, setActiveStep] = useState(0);
  const useAddProduct = useFlag("quality.addProduct", id);

  const schema = yup.object().shape({});

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isDirty },
  } = formData;

  const [title] = useWatch({
    control,
    name: ["title"],
  });

  useEffect(() => {
    if (state === "edit") {
      reset(output);
    } else {
      reset({});
    }
    if (!output?.title?.length) {
      setActiveStep(0);
    }
  }, [state, output, parent]);
  const navigate = useNavigate();

  const createProduct = useMutation(project.addProduct, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Outcome added successfully");
      onUpdate?.();
      reset();
    },
    onError: ({ error }) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(`Error adding Outcome. ${error?.response?.data?.message}`);
    },
  });

  const updateProduct = useMutation(project.updateProduct, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Outcome added successfully");
      onUpdate?.();
      reset(formData.getValues());
    },
    onError: ({ error }) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(`Error adding Outcome. ${error?.response?.data?.message}`);
    },
  });

  const productStatus = {
    done: "green",
    inProgress: "yellow",
    overdue: "red",
    notStarted: "",
  };

  const statusOptions = [
    {
      value: "-",
      label: t("project.progress.highlightReport.fields.status.placeholder"),
    },
    ...keys(productStatus).map((status) => ({
      value: status,
      label: (
        <Stack direction="row" spacing={1} alignItems="center">
          <Stat status={productStatus[status]} />
          <span>{t(`project.quality.outputForm.fields.status.${status}`)}</span>
        </Stack>
      ),
    })),
  ];

  const [more, setMore] = useState(false);

  const handleAddOrEditSubmit = (data) => {
    const { id: _id, position, children, products, ...rest } = data;
    const outputId = uuidV4();
    if (isDirty) {
      useAddProduct
        ? state === "add"
          ? createProduct.mutate({
              id: id,
              data: {
                product: {
                  id: outputId,
                  parentId: parent.id,
                  ...data,
                },
                scope: { id: scopeId },
              },
            })
          : updateProduct.mutate({
              id: id,
              data: {
                product: {
                  id: output.id,
                  ...rest,
                },
                scope: { id: scopeId },
              },
            })
        : null;
    }
    if (state === "edit") {
      setActiveStep((a) => Math.min(4, activeStep + 1));
    }
    if (state === "add") {
      navigate(
        clientUrl(uris.project.qualityOutputDetails, {
          id,
          outputId: outputId,
        }),
      );
      setEditId(outputId);
    }
  };
  const isCreating = state === "add";
  const submitButtonLabel = useMemo(() => {
    if (isCreating) {
      return t("generic.button.save");
    } else if (!isDirty) {
      if (activeStep === 0) {
        return !title?.length ? false : t("generic.stepper.next");
      } else if (activeStep === 4) {
        return false;
      }
      return t("generic.stepper.next");
    } else {
      if (activeStep === 0) {
        return !title?.length
          ? t("generic.button.update")
          : t("generic.button.updateAndNext");
      } else if (activeStep === 1) {
        return t("generic.button.updateAndNext");
      } else if (activeStep === 2) {
        return t("generic.button.updateAndNext");
      } else {
        return activeStep < 4
          ? t("generic.button.updateAndNext")
          : t("generic.button.update");
      }
    }
  }, [isCreating, activeStep, title, isDirty]);

  const showPreviousButton = useMemo(() => {
    return activeStep > 0;
  }, [activeStep]);

  return (
    <CustomDialog
      isOpen={showNewProductForm}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "1100px",
        },
      }}
      isContentDirty={isDirty}
      title={
        <Typography variant="h2">
          {state === "add"
            ? "Create new product"
            : `Edit ${stripHtml(output?.title ?? "")}`}
        </Typography>
      }
      scrollDependencyArray={[activeStep]}
    >
      <StepperSections
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={["description", "content", "dependencies", "quality", "status"]}
        formatLabel={(step) => t(`project.quality.outputForm.steps.${step}`)}
      />
      <Stack sx={{ mt: 4 }} spacing={1}>
        <Typography sx={{ fontStyle: "italic" }}>
          {t(`project.quality.outputForm.deliver`)}
        </Typography>
        <Stack spacing={1} direction="row">
          <Typography
            fontWeight="bold"
            dangerouslySetInnerHTML={{ __html: parent?.title }}
          />
          <Stack salignItems="flex-start" spacing={2}>
            <Collapse sx={{ mt: "-22px", width: "fit-content" }} in={more}>
              {scopeId === parent?.parentId ? (
                <PPDGuide
                  scopeName={scopes.find((s) => s.id === scopeId)?.text}
                  qualityExpectation={parent?.qualityExpectation}
                  acceptanceResponsible={parent?.acceptanceResponsible}
                  acceptanceCriteria={parent?.acceptanceCriterias}
                  acceptanceMethod={parent?.acceptanceMethod}
                  output={parent?.title}
                  {...{
                    t,
                  }}
                />
              ) : (
                <Stack sx={{ mt: 4 }} spacing={1}>
                  <Typography sx={{ fontStyle: "italic" }}>
                    {t(`project.quality.outputForm.deliver`)}
                  </Typography>

                  <Typography
                    fontWeight="bold"
                    dangerouslySetInnerHTML={{
                      __html: grandParent?.title,
                    }}
                  />
                  <Typography sx={{ fontStyle: "italic" }}>
                    {t(`project.quality.outputForm.willProduce`)}
                  </Typography>
                  <Typography
                    fontWeight="bold"
                    dangerouslySetInnerHTML={{ __html: parent?.title }}
                  />
                </Stack>
              )}
            </Collapse>
            <Button
              variant="text"
              endIcon={!more ? <ArrowDropDown /> : <ArrowDropUp />}
              onClick={() => setMore((v) => !v)}
              sx={{ width: "fit-content" }}
            >
              {more
                ? t("generic.button.read_less")
                : t("generic.button.read_more")}{" "}
            </Button>
          </Stack>
        </Stack>
        <Typography sx={{ fontStyle: "italic" }}>
          {t(`project.quality.outputForm.willProduce`)}
        </Typography>
        <Typography
          fontWeight="bold"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Stack>
      <FormDataProvider
        formKey="project.quality.outputForm.fields"
        {...formData}
      >
        <form>
          <Box sx={{ mt: 4 }}>
            {activeStep === 0 && (
              <Stack spacing={2}>
                <FormTextField name="identifier" />
                <FormRichTextField name="title" required />
                <FormRichTextField name="purpose" />
              </Stack>
            )}
            {activeStep === 1 && (
              <Stack spacing={2}>
                <FormRichTextField name="composition" />
                <FormRichTextField name="format" />
              </Stack>
            )}
            {activeStep === 2 && (
              <Stack spacing={2}>
                <FormRichTextField name="derivation" />
                <FormRichTextField name="skills" />
              </Stack>
            )}
            {activeStep === 3 && (
              <Stack spacing={2}>
                <FormRichTextField name="qualityCriterias" />
                <FormRichTextField name="qualityMethod" />
              </Stack>
            )}
            {activeStep === 4 && (
              <Stack spacing={2}>
                <FormSelect
                  placeholder={t(
                    "project.quality.outputForm.fields.status.description",
                  )}
                  name="status"
                  options={statusOptions}
                  nullValue="-"
                />
                <FormRichTextField name="producer" />
                <FormRichTextField name="approver" />
                <FormRichTextField name="result" />
              </Stack>
            )}
            <StepperNavigator
              showPreviousButton={showPreviousButton}
              setActiveStep={setActiveStep}
              onCancel={onClose}
              isDirty={isDirty}
              submitButtonLabel={submitButtonLabel}
            />
          </Box>
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

export const OutputDetails = ({
  id,
  output,
  parent,
  grandParent,
  expandable,
  onUpdate,
  scopeId,
  showContent = "some",
  scopes,
  viewOnly,
  setEditId,
  editId,
}) => {
  const { t } = useT();
  const toast = useToast();
  const [showNewProductForm, setShowNewProductForm] = useState(false);
  const [showEditOutputForm, setShowEditOutputForm] = useState(false);

  const editItem = useMutation(project.editScopeItem, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Output updated successfully");
      onUpdate(data);
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating output");
    },
  });

  return (
    <>
      {showContent !== "none" && (
        <Stack sx={{ backgroundColor: "#FFF", p: 2, borderRadius: 2 }}>
          {!viewOnly && (
            <Stack spacing={1} direction="row" justifyContent="flex-end">
              <Button
                variant="text"
                color="secondary"
                startIcon={<AddCircleOutlineOutlined />}
                onClick={() => {
                  setShowNewProductForm(true);
                  setEditId(false);
                }}
              >
                {t("generic.button.add")}
              </Button>
              <Button
                variant="text"
                color="secondary"
                startIcon={<EditOutlined />}
                onClick={() => {
                  if (output.parentId !== output.scopeId) {
                    setEditId(output.id);
                  } else {
                    setEditId(output.id);
                    setShowNewProductForm(false);
                    setShowEditOutputForm(true);
                  }
                }}
              >
                {t("generic.button.edit")}
              </Button>
            </Stack>
          )}
          {showContent !== "none" && (
            <>
              <KeyValueDisplayView
                title={stripHtml(output.title)}
                properties={[
                  "purpose",
                  "composition",
                  "derivation",
                  "format",
                  "qualityExpectations",
                  "acceptanceCriterias",
                  "acceptanceMethod",
                  "acceptanceResponsible",
                  "qualityCriterias",
                  "qualityMethod",
                  "skills",
                  "producer",
                  "approver",
                ]
                  .filter((field) => output[field])
                  .filter((_f, index) =>
                    expandable && showContent === "some" ? index < 2 : true,
                  )
                  .filter(
                    (_f, index) => !(expandable && showContent === "none"),
                  )
                  .map((field) => ({
                    name: t("project.quality." + field),
                    html: output[field],
                  }))}
              />
            </>
          )}
        </Stack>
      )}
      <ProductFormDialog
        output={showNewProductForm ? null : output}
        parent={showNewProductForm ? output : parent}
        grandParent={showNewProductForm ? parent : grandParent}
        showNewProductForm={showNewProductForm || editId === output.id}
        onClose={() => {
          setEditId(false);
          setShowNewProductForm(false);
          setShowEditOutputForm(false);
        }}
        onUpdate={(data) => {
          onUpdate(data);
          setShowNewProductForm(false);
        }}
        scopeId={scopeId}
        state={editId === output.id ? "edit" : "add"}
        scopes={scopes}
        setEditId={setEditId}
      />
      <OutputForm
        open={editId === output.id && showEditOutputForm}
        item={{
          id: output?.id,
          acceptanceCriteria: output?.acceptanceCriterias || "",
          acceptanceMethod: output?.acceptanceMethod || "",
          acceptanceResponsible: output?.acceptanceResponsible || "",
          derivation: output?.derivation || "",
          output: output?.title || "",
          qualityExpectation: output?.qualityExpectation || "",
          skills: output?.skills || "",
        }}
        scopeName={scopes.find((s) => s.id === scopeId)?.text}
        onClose={() => {
          setEditId(false);
          setShowEditOutputForm(false);
          setShowNewProductForm(false);
        }}
        onSave={(data) => {
          editItem.mutate({
            id,
            scopeId,
            itemId: output.id,
            data,
          });
        }}
      />
    </>
  );
};

export const QualityTableForm = ({
  id,
  products,
  onSort,
  onUpdate,
  scopes,
}) => {
  const { outputId } = useParams();
  const { t } = useT();
  const toast = useToast();
  const navigate = useNavigate();
  const useOldTable = useFlag("quality.oldRegisterTable", id);

  const cols = "120px 1fr 1fr 1fr 1fr 1fr 50px";

  const handleOpenOutput = useCallback(
    (newOutputId, parentId) => {
      if (newOutputId === outputId) {
        if (parentId) {
          navigate(
            clientUrl(uris.project.qualityOutputDetails, {
              id,
              outputId: parentId,
            }),
          );
        } else {
          navigate(clientUrl(uris.project.quality, { id }));
        }
      } else {
        navigate(
          clientUrl(uris.project.qualityOutputDetails, {
            id,
            outputId: newOutputId,
          }),
        );
      }
    },
    [id, outputId, navigate],
  );

  const productContainsSubProduct = useCallback((product, subProductId) => {
    return (
      product.children &&
      product.children.some(
        (item) =>
          item.id === subProductId ||
          productContainsSubProduct(item, subProductId),
      )
    );
  }, []);

  const addProductsLines = useCallback(
    (multiLevelProducts, product) => {
      if (
        product.id === outputId ||
        productContainsSubProduct(product, outputId)
      ) {
        for (let i = 0; i < (product.children ?? []).length; i++) {
          const newSubProduct = {
            ...product.children[i],
            position: [...product.position, i + 1],
            parentId: product.id,
          };
          multiLevelProducts.push(newSubProduct);
          addProductsLines(multiLevelProducts, newSubProduct);
        }
      }
    },
    [outputId],
  );

  const multiLevelProducts = useMemo(() => {
    let counter = 0;
    return products.reduce((multiLevelProducts, item, index) => {
      const newProduct = {
        ...item,
        position: [index + 1],
        parentId: item.scopeId,
      };
      multiLevelProducts.push(newProduct);
      addProductsLines(multiLevelProducts, newProduct);
      counter++;
      return multiLevelProducts;
    }, []);
  }, [products, outputId]);

  const updateProduct = useMutation(project.updateProduct, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Outcome added successfully");
      onUpdate?.();
    },
    onError: ({ error }) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(`Error adding Outcome. ${error?.response?.data?.message}`);
    },
  });

  const handleDrop = useCallback(
    ({ source, target }) => {
      const sourceProduct = getOutputById(source.id);
      const targetProduct = getOutputById(target.id);
      // console.log({ source, target, sourceProduct, targetProduct });
      if (
        sourceProduct?.parentId &&
        sourceProduct?.parentId === targetProduct?.parentId
      ) {
        const {
          id: _id,
          position,
          children,
          products,
          ...rest
        } = sourceProduct;
        updateProduct.mutate({
          id: id,
          data: {
            product: {
              id: sourceProduct.id,
              ...rest,
            },
            scope: { id: sourceProduct.scopeId },
            position: [...targetProduct.position].pop() - 1,
          },
        });
      } else {
        toast.error(`Invalid product priority change`);
      }
    },
    [multiLevelProducts],
  );

  return (
    <Box>
      <ProductsTable
        products={multiLevelProducts}
        onDrop={handleDrop}
        isRowExpanded={(item) => item.id === outputId}
        handleRowClick={(item) => handleOpenOutput(item.id, item.parentId)}
        onUpdate={(item) => {
          onUpdate?.();
          if (item?.id) {
            handleOpenOutput(item.id, item.parentId);
          }
        }}
        id={id}
        scopes={scopes}
        openProductId={outputId}
      />
      {useOldTable && (
        <>
          <br />
          <br />
          <DataTable>
            <DataTableHeading
              cols={cols}
              headings={[
                t("generic.titles.id"),
                t("generic.titles.name"),
                t("generic.titles.status"),
                t("project.quality.table.signOffDate"),
                t("project.quality.table.producer"),
                t("project.quality.table.approver"),
              ]}
            />

            <DataTableAutoRows
              data={products}
              onSort={onSort}
              config={{
                draggable: true,
                canDropLevels: true,
                canDropBetween: true,
              }}
              cols={cols}
              cells={[
                (data, config) => (
                  <>
                    <Icon className={"cursor-dnd"} i={"three-dots-v"} />
                    <DataTableHierarchy data={data} config={config} />
                  </>
                ),
                ({ title, ident, child }, _, { setShowChild }) => (
                  <span
                    className={"cursor-link"}
                    onClick={() => setShowChild((v) => !v)}
                  >
                    {!isNil(ident) && ident.trim().length > 0 ? (
                      <>[{ident}] </>
                    ) : null}
                    {stripHtml(title)}
                  </span>
                ),
                ({ status }) =>
                  typeof status === "number" ? (
                    status + "%"
                  ) : (
                    <Stat status={status} />
                  ),
                ({ signOffDate }) => <DatePretty date={signOffDate} />,
                "producer",
                "approver",
              ]}
            />
          </DataTable>
        </>
      )}
    </Box>
  );
};
