import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Page } from "../../layout/Page";
import { useNavigate, useParams } from "react-router-dom";
import { useT } from "../../hooks/useT";
import { useQ } from "../../hooks/useQ";
import { programme } from "../../queries";
import { Section } from "../../layout/Section";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { HtmlReadMoreTypography } from "../../base/typography/HtmlReadMoreTypography";
import { clientUrl } from "../../util/routing";
import { uris } from "../../config/nav";
import { SummaryCard } from "../../base/project/SummaryCard";
import { isNil } from "ramda";
import { DataList } from "../../base/DataList";
import { StatusSummaryCard } from "../../base/StatusSummaryCard";
import { useFlag } from "@/hooks/useFlag";
import { useToast } from "@/hooks/useToast";
import { useMutation } from "react-query";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { PortfolioTable } from "../portfolio/Details";
import { portfolio } from "../../queries";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const InfoSummaryCard = ({ data }) => {
  const { location, department } = data?.attributes ?? {};
  return (
    <SummaryCard>
      <Typography variant="h2" textAlign="center" mb={1}>
        Key Information
      </Typography>
      <DataList
        items={[
          {
            label: "Location",
            value: location,
          },
          {
            label: "Department",
            value: department,
          },
        ].filter((item) => !isNil(item.value))}
      />
    </SummaryCard>
  );
};

const mapTableChildren = (data) => {
  return data.map(({ items, ...rest }) => {
    return { ...rest, items, children: items };
  });
};

export const Programme = () => {
  const { id = "" } = useParams();
  const { t } = useT();
  const useFavorite = useFlag("favorites.add");
  const toast = useToast();
  const navigate = useNavigate();

  const [showInactive, setShowInactive] = useState(false);

  function onlyActiveChildren(item) {
    return {
      ...item,
      items: isNil(item.items)
        ? item.items
        : item.items.filter((child) => child.isStarted),
    };
  }

  const {
    isLoading,
    data = {},
    refetch,
  } = useQ(`programme-${id}`, () => programme.single({ id }));
  const { projectsAndReleases: items = [] } = data;

  const { name, background } = data;

  const releases = useMemo(
    () =>
      mapTableChildren(
        items
          .filter((item) => item.type !== "programme")
          .filter((item) => item.programme === id),
      ),
    [items],
  );

  const [isFavorite, setFavorite] = useState(false);

  const addFavorite = useMutation(programme.addFavorite, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Programme added to favorites");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error adding programme to favorites");
    },
  });

  const removeFavorite = useMutation(programme.removeFavorite, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Programme removed from favorites");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error removing programme from favorites");
    },
  });

  const handleFavoriteToggle = useCallback(() => {
    if (useFavorite) {
      if (isFavorite) {
        removeFavorite.mutate({ id });
      } else {
        addFavorite.mutate({ id });
      }
    } else {
      setFavorite(!isFavorite);
    }
  }, [isFavorite, useFavorite, addFavorite, removeFavorite, id]);

  useEffect(() => {
    if (!useFavorite) {
      return;
    }
    const isFavorite = data?.isFavorite;
    setFavorite(isFavorite);
  }, [data?.isFavorite, useFavorite]);

  const projectsAndReleases = useMemo(
    () => ({
      activeData: mapTableChildren(
        releases
          .filter((item) => item.isStarted === true)
          .filter((item) => item.type !== "product")
          .filter((item) => item.priority !== undefined)
          .map((item) => onlyActiveChildren(item)),
      ),
      withoutPriority: mapTableChildren(
        releases
          .filter((item) => item.isStarted === true)
          .filter((item) => item.type !== "product")
          .filter((item) => item.priority === undefined)
          .map((item) => onlyActiveChildren(item))
          .map((item) => ({ ...item, level: "product" })),
      ),
      inactiveData: mapTableChildren(
        releases
          .filter((item) => item.isStarted !== true)
          .filter((item) => item.type !== "product")
          .map((item) => onlyActiveChildren(item))
          .map((item) => ({ ...item, level: "product" })),
      ),
    }),
    [releases],
  );

  const prioritize = useMutation(portfolio.prioritize, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      refetch();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating portfolios");
    },
  });

  const handleDrop = useCallback(
    ({ source, target }) => {
      prioritize.mutate({
        portfolioId: data.portfolio.id,
        portfolioName: data.portfolio.name,
        itemId: source.id,
        position: target.index,
      });
    },
    [prioritize],
  );

  return (
    <Page title={name} isLoading={isLoading}>
      <Section
        title={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h1">{name}</Typography>
            <IconButton onClick={handleFavoriteToggle}>
              {isFavorite ? <StarOutlinedIcon /> : <StarBorderOutlinedIcon />}
            </IconButton>
          </Stack>
        }
      >
        <Stack spacing={5}>
          <HtmlReadMoreTypography>{background}</HtmlReadMoreTypography>
          <Stack alignItems="center">
            <Button
              variant="contained"
              href={clientUrl(uris.programme.editDefinition, { id })}
            >
              {t("project.overview.goto_edit_definition")}
            </Button>
          </Stack>
          <Stack direction="row" spacing={6} px={6}>
            <StatusSummaryCard data={data} type={"programmes"} />
            <InfoSummaryCard data={data} />
          </Stack>
          <Stack>
            <Stack alignItems="flex-end" mb={1}>
              <Button
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => {
                  navigate(
                    clientUrl(uris.project.newWithPortfolioIdAndProgrammeId, {
                      portfolioId: data.portfolio.id,
                      programmeId: id,
                    }),
                  );
                }}
              >
                {t("project.addProject")}
              </Button>
            </Stack>
            <PortfolioTable data={projectsAndReleases.activeData} />
          </Stack>
          <PortfolioTable
            data={projectsAndReleases.activeData}
            draggable={false}
            droppable={false}
            onDrop={handleDrop}
          />
          <PortfolioTable
            data={projectsAndReleases.withoutPriority}
            draggable={false}
            hideHeader={true}
          />
          {projectsAndReleases.inactiveData.length ? (
            showInactive ? (
              <Stack spacing={2}>
                <Button onClick={() => setShowInactive(false)}>
                  {t("project.overview.initiativeList.hide")}
                </Button>
                <PortfolioTable
                  data={projectsAndReleases.inactiveData}
                  hideHeader={true}
                />
              </Stack>
            ) : (
              <Button onClick={() => setShowInactive(true)}>
                {`${t("project.overview.initiativeList.show1")} ${
                  projectsAndReleases.inactiveData.length
                } ${t("project.overview.initiativeList.show2")}`}
              </Button>
            )
          ) : null}
        </Stack>
      </Section>
    </Page>
  );
};
