import React from 'react';

export const MyProfile = ({ className, style, onClick }) => {
  return (
    <svg className={className} style={style} onClick={onClick} width="20" height="20"
         viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 13.7351C2.00038 12.8647 2.23736 12.0108 2.6856 11.2647C3.13383 10.5186 3.77645 9.90835 4.54473 9.49926C5.313 9.09018 6.17803 8.89763 7.04729 8.94222C7.91655 8.98681 8.75734 9.26685 9.47972 9.7524"
        stroke="#111827" strokeWidth="1.0668" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M3.86719 4.93371C3.86719 5.71178 4.17627 6.45798 4.72645 7.00816C5.27663 7.55834 6.02283 7.86742 6.8009 7.86742C7.57897 7.86742 8.32517 7.55834 8.87535 7.00816C9.42553 6.45798 9.73461 5.71178 9.73461 4.93371C9.73461 4.15564 9.42553 3.40944 8.87535 2.85926C8.32517 2.30909 7.57897 2 6.8009 2C6.02283 2 5.27663 2.30909 4.72645 2.85926C4.17627 3.40944 3.86719 4.15564 3.86719 4.93371V4.93371Z"
        stroke="#111827" strokeWidth="1.0668" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M14.2314 9.77363L15.3281 12.1348H17.4617C17.5693 12.1306 17.6756 12.1597 17.766 12.2182C17.8565 12.2766 17.9266 12.3616 17.9669 12.4615C18.0073 12.5613 18.0157 12.6712 17.9913 12.776C17.9668 12.8809 17.9105 12.9756 17.8301 13.0473L15.981 14.8694L17.0073 17.2263C17.0521 17.3342 17.0619 17.4535 17.0354 17.5673C17.009 17.6811 16.9475 17.7837 16.8597 17.8608C16.7719 17.9379 16.6621 17.9855 16.5458 17.9969C16.4296 18.0084 16.3126 17.9832 16.2114 17.9247L13.7315 16.5301L11.2522 17.9247C11.151 17.9834 11.034 18.0089 10.9175 17.9976C10.8011 17.9862 10.6912 17.9386 10.6032 17.8615C10.5152 17.7844 10.4537 17.6816 10.4272 17.5677C10.4008 17.4537 10.4107 17.3343 10.4557 17.2263L11.4819 14.8694L9.63281 13.0473C9.55258 12.9762 9.49618 12.8821 9.47129 12.7778C9.44641 12.6735 9.45423 12.5641 9.49369 12.4645C9.53316 12.3648 9.60236 12.2797 9.69189 12.2207C9.78142 12.1617 9.88693 12.1317 9.9941 12.1348H12.1277L13.2258 9.7715C13.2744 9.68021 13.3469 9.60389 13.4356 9.55074C13.5243 9.4976 13.6259 9.46963 13.7293 9.46985C13.8327 9.47007 13.9341 9.49847 14.0225 9.55199C14.111 9.60551 14.1832 9.68214 14.2314 9.77363Z"
        stroke="#111827" strokeWidth="1.0668" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

