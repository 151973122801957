import React from 'react';

export const ChevronUp = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.199 6C10.0023 5.99976 9.80778 6.04147 9.62848 6.12236C9.44919 6.20324 9.28921 6.32144 9.15922 6.46906L3.25934 13.1754C3.07286 13.3976 2.98074 13.6839 3.00266 13.9732C3.02457 14.2625 3.1588 14.5317 3.37663 14.7233C3.59446 14.9149 3.87861 15.0137 4.16831 14.9985C4.45802 14.9833 4.73028 14.8554 4.92689 14.6421L10.0859 8.77765C10.1 8.76156 10.1174 8.74866 10.1369 8.73982C10.1564 8.73098 10.1776 8.72641 10.199 8.72641C10.2204 8.72641 10.2415 8.73098 10.261 8.73982C10.2805 8.74866 10.2979 8.76156 10.312 8.77765L15.471 14.6421C15.6676 14.8554 15.9399 14.9833 16.2296 14.9985C16.5193 15.0137 16.8035 14.9149 17.0213 14.7233C17.2391 14.5317 17.3733 14.2625 17.3953 13.9732C17.4172 13.6839 17.3251 13.3976 17.1386 13.1754L11.2405 6.47146C11.1103 6.32348 10.9501 6.2049 10.7705 6.12361C10.5909 6.04233 10.3961 6.00019 10.199 6Z" fill="#111827"/>
    </svg>
  );
};
