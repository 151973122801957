import { uris } from "../config/nav";
import { clientUrl } from "./routing";

function initiativePath(report) {
  return report.initiative !== undefined
    ? report.initiative.type + "s/" + report.initiative.id
    : undefined;
}

function reportPart(report) {
  if (report.id) {
    if (
      report.initiative?.type === "project" ||
      report.initiative?.type === "product" ||
      report.initiative?.type === "release"
    ) {
      return report.type === "highlight" || report.type === "projectEnd"
        ? "progress/highlight/" + report.id
        : report.type === "stagePlan"
        ? "plan/stages/" + report.id
        : report.type === "pid"
        ? "pid?version=" + report.id
        : report.type === "issue" && report.issue !== undefined
        ? "change/issues/" + report.issue.id + "/reports/" + report.id
        : report.type === "releasePlan"
        ? "plan/" + report.id
        : report.type === "endStage"
        ? "reports/?id=" + report.id + "&showEndStage=true"
        : "";
    } else if (report.initiative?.type === "programme") {
      return report.type === "highlight"
        ? "knowledge/highlight/" + report.id
        : "";
    }
  }
  return "";
}

export function createReportLink(report, initiativeOnly = false) {
  var uri;
  switch (report?.initiative?.type) {
    case "project":
      report.type === "issue" || report.type === "exception"
        ? (uri = uris.project.change)
        : (uri = uris.project.progressFull);
      break;
    case "programme":
      report.type === "issue" || report.type === "exception"
        ? (uri = uris.programme.change)
        : (uri = uris.programme.knowledgeFull);
      break;
    case "release":
      report.type === "issue" || report.type === "exception"
        ? (uri = uris.release.change)
        : (uri = uris.release.progressFull);
      break;
    case "product":
      report.type === "issue" || report.type === "exception"
        ? (uri = uris.product.change)
        : (uri = uris.product.progressFull);
      break;
    default:
      "";
  }
  const path = initiativePath(report);
  return uri !== undefined
    ? !initiativeOnly
      ? clientUrl(uri, {
          id: report.initiative.id,
          reportId: report.id,
          type: report.type,
        })
      : path
    : undefined;
}
