import React from "react";
import { Table, TableCell, TableRow } from "@mui/material";

export const DataList = ({ items = [], ...props }) => {
  const renderCell = (item) => {
    return (
      <TableCell
        sx={{
          borderBottom: "none",
          px: 0,
          py: "2px",
        }}
      >
        {item}
      </TableCell>
    );
  };

  return (
    <Table {...props}>
      {items.map((item, index) => (
        <TableRow key={`${item.label}-${item.value}`}>
          {renderCell(item.label)}
          {renderCell(item.value)}
        </TableRow>
      ))}
    </Table>
  );
};
