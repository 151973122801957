import React from 'react';
import { useStyleParams } from '../../hooks/useStyleParams';

export const Steps = ({ children, ...rest }) => {
  const {className, style} = useStyleParams(rest);

  return (
    <div className={`steps ${className}`} style={style}>
      {children}
    </div>
  );
};
