import React, { useMemo, useState, memo } from "react";
import { useT } from "../../../hooks/useT";
import { pathOr } from "ramda";
import { Section } from "../../../layout/Section";
import { BlockChart } from "../../../base/charts/BlockChart";
import { E, Grid } from "../../../layout/Grid";

import { priorityColor } from "../../../util/priority";
import {
  threatMatrix,
  opportunityMatrix,
  issueMatrix,
  top5OpenRisks,
  top5OpenIssues,
  riskMatrix,
} from "../../../base/risk/Risk";
import { CustomAccordion } from "../../../layout/CustomAccordion";
import {
  Box,
  Stack,
  Typography,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

import { RiskThreatsBlockChart } from "./RiskThreatsBlockChart";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { project, programme, product, release } from "../../../queries";
import { useToast } from "../../../hooks/useToast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { v4 as uuidv4 } from "uuid";
import { RiskForm } from "./RiskRegister";
import { IssueForm, IssueTable } from "./IssueRegister";
import { IssuesBlockChart } from "@/pages/projects/components/IssuesBlockChart";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IssueStatusIcon } from "./IssueStatusIcon";

const PriorityIcon = ({ item }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill={priorityColor(item)} />
  </svg>
);

export const RiskSection = memo(
  ({ report, type, refetchRisks, viewOnly = true }) => {
    const { t } = useT();
    const { id } = useParams();
    const toast = useToast();
    //
    const [risksWhitelist, setRisksWhitelist] = useState([]);
    const [view, setView] = useState("identify");

    const risks = useMemo(() => {
      const allRisks = report?.risk?.items ?? [];
      const top5 = top5OpenRisks(allRisks);
      const whitelist = allRisks.filter(
        (item) =>
          risksWhitelist.includes(item.id) &&
          !top5.find((r) => r.id === item.id),
      );
      return [...top5, ...whitelist];
    }, [report]);
    const threats = useMemo(() => riskMatrix(risks), [risks]);
    const hasOpportunities =
      risks.filter((item) => item.type === "opportunity").length > 0;

    const [showNewItemForm, setShowNewItemForm] = useState(false);
    const [isEditingRisk, setIsEditingRisk] = useState("");

    const createRisk = useMutation(
      type === "project"
        ? project.createRisk
        : type === "programme"
          ? programme.createRisk
          : type === "release"
            ? release.createRisk
            : product.createRisk,
      {
        onSuccess: (data) => {
          console.log("Received data: " + JSON.stringify(data, null, 2));
          toast.success("Risk created successfully");
          refetchRisks();
          setShowNewItemForm(false);
        },
        onError: (error) => {
          console.log("Received error: " + JSON.stringify(error));
          toast.error("Error creating risk");
        },
      },
    );

    const editRisk = useMutation(
      type === "project"
        ? project.editRisk
        : type === "programme"
          ? programme.editRisk
          : type === "release"
            ? release.editRisk
            : product.editRisk,
      {
        onSuccess: (data) => {
          console.log("Received data: " + JSON.stringify(data));
          toast.success("Risk updated successfully");
          refetchRisks();
        },
        onError: (error) => {
          console.log("Received error: " + JSON.stringify(error));
          toast.error("Error updating risk");
        },
      },
    );

    return (
      <Section title={t("project.progress.highlightReport.fields.risk.title")}>
        <Grid cols={"5fr 1fr 5fr 6fr"}>
          <E>
            <h2 className={"txt-a-c"}>{t("project.risksAndIssues.threats")}</h2>

            <RiskThreatsBlockChart threats={threats} type={"project"} id={id} />
          </E>
          <E></E>
          {hasOpportunities ? (
            <E>
              <h2 className={"txt-a-c"}>
                {t("project.risksAndIssues.opportunities")}
              </h2>
              <BlockChart
                data={opportunityMatrix(risks)}
                yLabel={t("project.risksAndIssues.probability")}
                xLabel={t("project.risksAndIssues.impact")}
              />
            </E>
          ) : null}
        </Grid>
        <Box sx={{ mt: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
          >
            <RadioGroup
              defaultValue="identify"
              name="radio-buttons-group"
              row
              value={view}
              onChange={(event) => setView(event.target.value)}
            >
              <Stack direction={"row"} sx={{ width: "100%" }}>
                <Stack
                  direction="row"
                  sx={{ width: "75%", whiteSpace: "nowrap" }}
                  spacing={1}
                >
                  <FormControlLabel
                    value="identify"
                    control={<Radio />}
                    label={t("risk.view.identify")}
                  />

                  <FormControlLabel
                    value="followUp"
                    control={<Radio />}
                    label={t("risk.view.followUp")}
                  />
                </Stack>
              </Stack>
            </RadioGroup>
            {!viewOnly && (
              <Button
                variant="text"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => setShowNewItemForm((s) => !s)}
              >
                {showNewItemForm
                  ? t("risk.registerForm.buttons.close")
                  : t("risk.registerForm.buttons.open")}
              </Button>
            )}
          </Stack>

          <CustomAccordion
            tableView
            header={{
              expanded: !viewOnly && showNewItemForm,
              columns:
                view === "identify"
                  ? [
                      {
                        title: "",
                        width: 20,
                      },
                      {
                        title: "ID",
                        flex: 1,
                      },
                      {
                        title: t("risk.registerForm.fields.title.title"),
                        flex: 2,
                      },
                      {
                        title: t("risk.registerForm.fields.category.title"),
                        flex: 2,
                      },
                      {
                        title: t("risk.registerForm.fields.cause.title"),
                        flex: 2,
                      },
                    ]
                  : [
                      {
                        title: "",
                        width: 20,
                      },
                      {
                        title: "ID",
                        flex: 1,
                      },
                      {
                        title: t("risk.registerForm.fields.title.title"),
                        flex: 2,
                      },
                      {
                        title: t("risk.registerForm.fields.action.title"),
                        flex: 5,
                      },
                      {
                        title: t("risk.registerForm.fields.owner.title"),
                        flex: 2,
                      },
                    ],
              details: (
                <RiskForm
                  isOpen={showNewItemForm}
                  onClose={() => setShowNewItemForm(false)}
                  t={t}
                  onSave={async (data) => {
                    const riskId = uuidv4();
                    setRisksWhitelist([...risksWhitelist, riskId]);
                    setIsEditingRisk(riskId);
                    await createRisk.mutate({
                      id: id,
                      data: {
                        ...data,
                        id: riskId,
                      },
                    });
                  }}
                  useScroll={false}
                />
              ),
            }}
            rows={risks.map((item, i) => ({
              id: item.id,
              onClick: () =>
                setIsEditingRisk(item.id !== isEditingRisk ? item.id : ""),
              expanded: !viewOnly && item.id === isEditingRisk,
              expandDisabled: !!viewOnly,
              columns:
                view === "identify"
                  ? [
                      {
                        content: (
                          <Stack alignItems="center" justifyContent="center">
                            <IssueStatusIcon
                              status={
                                threats
                                  .flat()
                                  .find((risk) =>
                                    risk.cellItems.find(
                                      (i) => i.id === item?.id,
                                    ),
                                  )?.status
                              }
                            />
                          </Stack>
                        ),
                        width: 20,
                      },
                      {
                        title: item.no,
                        flex: 1,
                      },
                      {
                        title: item.title,
                        flex: 2,
                      },
                      {
                        title:
                          item.category &&
                          t(
                            "risk.registerForm.fields.category.options." +
                              item.category?.toLowerCase(),
                          ),
                        flex: 2,
                      },
                      {
                        title: item.cause,
                        flex: 2,
                      },
                    ]
                  : [
                      {
                        content: (
                          <Stack alignItems="center" justifyContent="center">
                            <PriorityIcon item={item} />
                          </Stack>
                        ),
                        width: 20,
                      },
                      {
                        title: item.no,
                        flex: 1,
                      },
                      {
                        title: item.title,
                        flex: 2,
                      },
                      {
                        content: (
                          <Stack spacing={1}>
                            <Typography
                              dangerouslySetInnerHTML={{ __html: item.action }}
                            />
                            <Typography>
                              {item.response} {item.response && "Status:"}
                            </Typography>
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: item.comments,
                              }}
                            />
                          </Stack>
                        ),
                        flex: 5,
                      },
                      {
                        title: item.owner,
                        flex: 2,
                      },
                    ],
              details: (
                <RiskForm
                  isOpen={!viewOnly && item.id === isEditingRisk}
                  t={t}
                  risk={item}
                  onClose={() => setIsEditingRisk("")}
                  closeForm={() => setIsEditingRisk("")}
                  onSave={async (data) => {
                    await editRisk.mutate({
                      id: id,
                      riskId: item?.id,
                      data: {
                        ...item,
                        ...data,
                      },
                    });
                  }}
                  isHighlighted={false}
                  setIsHighlighted={() => null /*highlightRisk("")*/}
                  index={i}
                  useScroll={false}
                />
              ),
            }))}
          />
        </Box>
      </Section>
    );
  },
);

export const IssueSection = ({
  report,
  type = "project",
  refetchIssues = () => null,
  isLoading,
  initiativeData,
  viewOnly = true,
}) => {
  const { t } = useT();
  const issues = top5OpenIssues(pathOr([], ["change", "items"], report));
  const { id, issueId } = useParams();

  return (
    <Section title={t("project.risksAndIssues.issues")}>
      <Grid cols={"5fr 1fr 5fr 6fr"}>
        <E>
          <IssuesBlockChart id={id} issues={issueMatrix(issues)} type={type} />
        </E>
      </Grid>
      <IssueTable
        initiativeData={initiativeData}
        isLoading={isLoading}
        id={id}
        issueId={issueId}
        issues={issues}
        refetchIssues={refetchIssues}
        type={type}
        navigation={false}
        viewOnly={viewOnly}
      />
    </Section>
  );
};
