import React from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../layout/Page";
import { useT } from "../../hooks/useT";
import { project } from "../../queries";
import { HighlightProgressReportForm } from "./forms/HighlightReportForm";
import { useQ } from "../../hooks/useQ";

export const ProgressCreateForm = () => {
  const { id = "", type = "" } = useParams();
  const { t } = useT();
  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );

  const { title, level } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <HighlightProgressReportForm projectId={id} level={level} type={type} />
    </Page>
  );
};
