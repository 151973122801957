import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";

export const Processing = () => {
  const purpose =
    "Give access to service and capture roles and responsibilities in activities related to projects, programs and products"; //[DESCRIBE THE PURPOSE OF THE PROCESSING].
  const nature = "Single sign on using SAML2"; //[DESCRIBE THE NATURE OF THE PROCESSING].
  const typeOfData =
    "The name and email of users. Mention of persons in content provided by users of the system"; //[DESCRIBE THE TYPE OF PERSONAL DATA BEING PROCESSED]
  /*
    [FOR EXAMPLE]

“Name, e-mail address, telephone number, address, national identification number, payment details, membership number, type of membership, attendance at fitness centre and registration for specific fitness classes.”

[NOTE: DESCRIPTION SHOULD BE MADE IN THE MOST DETAILED POSSIBLE MANNER AND, IN ANY CIRCUMSTANCE, THE TYPES OF PERSONAL DATA MUST BE SPECIFIED FURTHER THAN MERELY “PERSONAL DATA AS DEFINED IN AR-TICLE 4(1) GDPR” OR STATING WHICH CATEGORY (“ARTICLE 6, 9 OG 10 GDPR”) OF PERSONAL DATA IS SUBJECT TO PROCESSING.]

    */

  const category = "Employees and contacts of the data controller"; //[DESCRIBE CATEGORY OF DATA SUBJECT]
  const duration = "The duration of the agreement"; //[DESCRIBE THE DURATION OF THE PROCESSING]
  return (
    <Section id="appendixA">
      <Typography variant="h2">
        Appendix A. Information about the processing
      </Typography>
      <ol>
        <li>
          The purpose of the data processor's processing of personal data on
          behalf of the data controller is:
          <div>{purpose}</div>
        </li>

        <li>
          The data processor's processing of personal data on behalf of the data
          controller shall mainly pertain to (the nature of the processing):
          <div>{nature}</div>
        </li>
        <li>
          The processing includes the following types of personal data about
          data subjects:
          <div>{typeOfData}</div>
        </li>

        <li>
          Processing includes the following categories of data subject:
          <div>{category}</div>
        </li>
        <li>
          The data processor's processing of personal data on behalf of the data
          controller may be performed when the Clauses commence. Processing has
          the following duration:
          <div>{duration}</div>
        </li>
      </ol>
    </Section>
  );
};
