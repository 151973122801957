import React, { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useQ } from "../hooks/useQ";
import { EntityLink } from "../base/EntityLink";
import { project, portfolio, product, programme, release } from "../queries";
import { Breadcrumbs } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const AppBreadcrumbs = () => {
  const loc = useLocation();
  const match = matchPath({ path: "/:type/:id", end: false }, loc.pathname);
  const id = match?.params?.id;
  const type = match?.params?.type;
  const { data: projectDetails = {} } = useQ(
    `project-${id}`,
    () => project.single({ id }),
    { enabled: type === "projects" && id !== "new" },
  );
  const { data: productDetails = {} } = useQ(
    `product-${id}`,
    () => product.single({ id }),
    { enabled: type === "products" && id !== "new" },
  );
  const { data: programmeDetails = {} } = useQ(
    `programme-${id}`,
    () => programme.single({ id }),
    { enabled: type === "programmes" && id !== "new" },
  );
  const { data: releaseDetails = {} } = useQ(
    `release-${id}`,
    () => release.single({ id }),
    { enabled: type === "releases" && id !== "new" },
  );
  const { data: productReleaseDetails = {} } = useQ(
    `product-${id}`,
    () => product.single({ id: releaseDetails?.product?.id }),
    {
      enabled:
        type === "releases" &&
        id !== "new" &&
        releaseDetails?.product?.id !== undefined,
    },
  );
  const { data: portfolios = [] } = useQ(`portfolios`, () => portfolio.list());

  const breadcrumbLinks = useMemo(() => {
    const links = [];
    if (type === "projects" && id !== "new") {
      const portfolio = (portfolios ?? []).find(
        (item) => item.id === projectDetails.portfolio,
      );
      if (portfolio) {
        links.push({
          entity: "portfolio",
          id: portfolio.id,
          name: portfolio.name,
        });
      }
      if (type === "projects" && projectDetails?.programme) {
        links.push({
          entity: "programme",
          id: projectDetails.programme,
          name: projectDetails.programmeName,
        });
      }
      links.push({
        entity: "project",
        id: id,
        name: projectDetails.name,
      });
    }
    if (type === "products" && id !== "new") {
      const portfolio = (portfolios ?? []).find(
        (item) => item.id === productDetails?.portfolio?.id,
      );
      if (portfolio) {
        links.push({
          entity: "portfolio",
          id: portfolio.id,
          name: portfolio.name,
        });
      }
      links.push({
        entity: "product",
        id: id,
        name: productDetails.name,
      });
    }
    if (type === "programmes" && id !== "new") {
      const portfolio = (portfolios ?? []).find(
        (item) => item.id === programmeDetails?.portfolio?.id,
      );
      if (portfolio) {
        links.push({
          entity: "portfolio",
          id: portfolio.id,
          name: portfolio.name,
        });
      }
      links.push({
        entity: "programme",
        id: id,
        name: programmeDetails.name,
      });
    }
    if (type === "releases" && id !== "new") {
      const portfolio = (portfolios ?? []).find(
        (item) => item.id === releaseDetails?.portfolio?.id,
      );

      if (portfolio) {
        links.push({
          entity: "portfolio",
          id: portfolio.id,
          name: portfolio.name,
        });
      }

      if (productReleaseDetails?.id !== undefined) {
        links.push(
          productReleaseDetails?.id !== ""
            ? {
                entity: "product",
                id: productReleaseDetails?.id,
                name: productReleaseDetails?.name,
              }
            : {
                entity: "product",
                id: productReleaseDetails?.uri?.replace("/products/", ""),
                name: productReleaseDetails?.name,
              },
        );
      }

      links.push({
        entity: "release",
        id: id,
        name: releaseDetails.name,
      });
    }
    return links;
  }, [productDetails, projectDetails, releaseDetails, portfolios, id, type]);

  return (
    <Breadcrumbs separator={<ChevronRightIcon />}>
      <Link to={"/"} style={{ textDecoration: "none", display: "flex" }}>
        <HomeIcon
          sx={{
            color: "#9A9EB1 !important",
          }}
        />
      </Link>
      {breadcrumbLinks.map((breadcrumb) => (
        <EntityLink
          entity={breadcrumb.entity}
          id={breadcrumb.id}
          style={{ color: "#6B7280" }}
        >
          {breadcrumb.name}
        </EntityLink>
      ))}
    </Breadcrumbs>
  );
};
