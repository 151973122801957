export function translateStatusCode(statusCode){
    switch (statusCode) {
      case 401:
        return 'api.401'
      case 403:
        return 'api.403'
      case 404:
        return 'api.404'
      case 408:
        return 'api.408'
      case 500:
        return 'api.500'
      default:
        return 'api.generic'
    }
  }
