import React from 'react';

export const NavtoggleClosed = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.21094 24.2739H29.3012" stroke="white" strokeWidth="2.00694" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.21094 16.2461H29.3012" stroke="white" strokeWidth="2.00694" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.21094 8.21826H29.3012" stroke="white" strokeWidth="2.00694" strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  );
};
