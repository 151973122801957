import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App.jsx";
import "./styles/theme.scss";

import FlagProvider from "@unleash/proxy-client-react";

const container = document.getElementById("root");
const root = createRoot(container);

fetch("/unleash")
  .then((response) => response.text())
  .then((clientKey) => {
    const config = {
      url: window.location.origin + "/unleash/proxy",
      clientKey: clientKey, //NDwhHNfMXiTSPsgjWkjgunG',
      refreshInterval: 15,
      appName: "design3",
    };

    root.render(
      <React.StrictMode>
        <FlagProvider config={config}>
          <App />
        </FlagProvider>
      </React.StrictMode>,
    );
  });

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/concepts/hot-module-replacement
if (import.meta.hot) {
  import.meta.hot.accept();
}
