import React from 'react';

export const ThreeDotsV = ({ className, style, onClick }) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5957 1.625C4.5957 2.05598 4.76691 2.4693 5.07165 2.77405C5.3764 3.0788 5.78973 3.25 6.2207 3.25C6.65168 3.25 7.065 3.0788 7.36975 2.77405C7.6745 2.4693 7.8457 2.05598 7.8457 1.625C7.8457 1.19402 7.6745 0.780698 7.36975 0.475951C7.065 0.171205 6.65168 0 6.2207 0C5.78973 0 5.3764 0.171205 5.07165 0.475951C4.76691 0.780698 4.5957 1.19402 4.5957 1.625Z"
        fill="#6B7280"/>
      <path
        d="M4.5957 6.00049C4.5957 6.43147 4.76691 6.84479 5.07165 7.14954C5.3764 7.45428 5.78973 7.62549 6.2207 7.62549C6.65168 7.62549 7.065 7.45428 7.36975 7.14954C7.6745 6.84479 7.8457 6.43147 7.8457 6.00049C7.8457 5.56951 7.6745 5.15619 7.36975 4.85144C7.065 4.54669 6.65168 4.37549 6.2207 4.37549C5.78973 4.37549 5.3764 4.54669 5.07165 4.85144C4.76691 5.15619 4.5957 5.56951 4.5957 6.00049Z"
        fill="#6B7280"/>
      <path
        d="M4.5957 10.3745C4.5957 10.8055 4.76691 11.2188 5.07165 11.5236C5.3764 11.8283 5.78973 11.9995 6.2207 11.9995C6.65168 11.9995 7.065 11.8283 7.36975 11.5236C7.6745 11.2188 7.8457 10.8055 7.8457 10.3745C7.8457 9.94353 7.6745 9.53021 7.36975 9.22546C7.065 8.92072 6.65168 8.74951 6.2207 8.74951C5.78973 8.74951 5.3764 8.92072 5.07165 9.22546C4.76691 9.53021 4.5957 9.94353 4.5957 10.3745Z"
        fill="#6B7280"/>
    </svg>
  );
};
