import React from 'react';

export const Folder = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4375 6.9375V4.5625C16.4375 4.24756 16.3124 3.94551 16.0897 3.72281C15.867 3.50011 15.5649 3.375 15.25 3.375H6.9375V2.1875C6.9375 1.87256 6.81239 1.57051 6.58969 1.34781C6.36699 1.12511 6.06494 1 5.75 1H2.1875C1.87256 1 1.57051 1.12511 1.34781 1.34781C1.12511 1.57051 1 1.87256 1 2.1875V15.0917C1.00447 15.415 1.12522 15.726 1.34017 15.9676C1.55511 16.2092 1.84987 16.3654 2.17051 16.4075C2.49115 16.4495 2.81622 16.3748 3.08625 16.1968C3.35628 16.0189 3.55321 15.7497 3.641 15.4384L5.50221 7.79329C5.57408 7.54622 5.72427 7.32918 5.93015 7.17484C6.13603 7.0205 6.38648 6.93721 6.64379 6.9375H17.625C17.807 6.93744 17.9866 6.97922 18.1499 7.05963C18.3132 7.14003 18.4559 7.2569 18.5668 7.40121C18.6778 7.54552 18.7541 7.7134 18.7898 7.89189C18.8255 8.07037 18.8198 8.25469 18.7729 8.43058L17.0313 15.5556C16.9639 15.8086 16.8148 16.0322 16.6072 16.1917C16.3996 16.3512 16.1451 16.4376 15.8833 16.4375H2.34346" stroke="#111827" strokeWidth="1.1875" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
