import { Font, StyleSheet, Text, View } from "@react-pdf/renderer";
import LatoRegular from "../../assets/fonts/Lato-Regular.ttf";
import LatoItalic from "../../assets/fonts/Lato-Italic.ttf";
import LatoThin from "../../assets/fonts/Lato-Thin.ttf";
import LatoThinItalic from "../../assets/fonts/Lato-ThinItalic.ttf";
import LatoLight from "../../assets/fonts/Lato-Light.ttf";
import LatoLightItalic from "../../assets/fonts/Lato-LightItalic.ttf";
import LatoBold from "../../assets/fonts/Lato-Bold.ttf";
import LatoBoldItalic from "../../assets/fonts/Lato-BoldItalic.ttf";
import LatoBlack from "../../assets/fonts/Lato-Black.ttf";
import LatoBlackItalic from "../../assets/fonts/Lato-BlackItalic.ttf";
import Html from "react-pdf-html";
import { flatten, is, repeat } from "ramda";

Font.register({
  family: "'Lato'",
  fonts: [
    {
      src: LatoRegular,
    },
    {
      src: LatoItalic,
      fontStyle: "italic",
    },
    {
      src: LatoThin,
      fontWeight: "extralight",
    },
    {
      src: LatoThinItalic,
      fontWeight: "extralight",
      fontStyle: "italic",
    },
    {
      src: LatoLight,
      fontWeight: "light",
    },
    {
      src: LatoLightItalic,
      fontWeight: "light",
      fontStyle: "italic",
    },
    {
      src: LatoBold,
      fontWeight: "bold",
    },
    {
      src: LatoBoldItalic,
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: LatoBlack,
      fontWeight: "extrabold",
    },
    {
      src: LatoBlackItalic,
      fontWeight: "extrabold",
      fontStyle: "italic",
    },
  ],
});

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "'Lato'",
    padding: 20,
  },
  h1: {
    fontSize: 24,
    fontWeight: "black",
    textAlign: "center",
  },
  h2: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  h3: {
    fontSize: 16,
    fontWeight: "bold",
  },
  h4: {
    fontSize: 14,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,
  },
  caption: {
    fontSize: 10,
    color: "#9CA3AF",
  },
  section: {
    marginTop: 10,
  },
  previewBox: {
    width: "100%",
    backgroundColor: "#FFEADBFF",
    textAlign: "center",
    padding: 10,
    marginTop: 10,
  },
  hr: {
    borderBottom: "1px solid #E5E7EB",
    marginBottom: 10,
  },
});

export const BlockChartPdf = ({ title, data, xLabel, yLabel }) => {
  const isListItems = data.reduce((acc, curr) => {
    return acc && is(Array, curr);
  }, true);

  if (!is(Array, data) || !isListItems) {
    return null;
  }

  const cols = Math.max(...data.map((e) => e.length));
  const rows = data.length;

  const padded = flatten(
    data.map((row) => {
      if (row.length < cols) {
        return row.concat(repeat({ status: "filler" }, cols - row.length));
      }
      return row;
    }),
  );

  return (
    <View wrap={false} style={{ height: "310px" }}>
      <Html style={{ fontSize: 12 }}>
        {`
        <div style="font-size: 14px; font-weight: bold; text-align: center; width: 300px; margin-bottom: 5px;">
        ${title}
</div>
        <div style="position: relative">
          <div style="writing-mode: tb-rl; transform: rotate(270deg); text-align: center; position: absolute; top: 125px; left: -25px;">
            ${yLabel}
          </div>
            ${padded
              .map((block, i) => {
                const left = i % 5;
                const top = Math.floor(i / 5);
                return `<div style="flex: 1; border-radius: 2px; align-items: center; justify-content: center; background-color: ${
                  {
                    filler: "rgba(0,0,0,0)",
                    success: "#47B857",
                    warn: "#F4C403",
                    danger: "#F85252",
                    avoid: "#F85252",
                  }[block.status]
                }; width: 48px; height: 48px; position: absolute; top: ${
                  top * 54
                }px; left: ${20 + left * 54}px">
          ${
            !!block.label
              ? `<div style="width: 40px; height: 40px; background-color: white; border-radius: 50%; border: 1px solid #9a9eb1; display: flex; align-items: center; justify-content: center;">
              ${block.label}
            </div>`
              : ""
          }
        </div>`;
              })
              .join("")}
            <div style="text-align: center; position: absolute; top: 270px; left: 130px;">
              ${xLabel}
            </div>
          </div>
                  `}
      </Html>
    </View>
  );
};

const stylesheet = {
  p: {
    margin: 0,
  },
};

export const PDFKit = {
  H1: ({ children }) => <Text style={styles.h1}>{children}</Text>,
  H2: ({ children }) => <Text style={styles.h2}>{children}</Text>,
  H3: ({ children }) => <Text style={styles.h3}>{children}</Text>,
  H4: ({ children }) => <Text style={styles.h4}>{children}</Text>,
  BODY: ({ children }) => <Text style={styles.body}>{children}</Text>,
  CAPTION: ({ children, style = {} }) => (
    <Text style={{ ...styles.caption, ...style }}>{children}</Text>
  ),
  HR: () => <View style={styles.hr} />,
  HTML: ({ children }) => (
    <Html stylesheet={stylesheet} style={{ fontSize: 12, lineHeight: 14 }}>
      {children}
    </Html>
  ),
  BlockChartPdf,
};
