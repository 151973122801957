import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en_uk from "./lang/en_uk.json";
import en_us from "./lang/en_us.json";
import no from "./lang/no.json";
import de from "./lang/de.json";
import { isDev } from "./util/isDev";
import { is, keys } from "ramda";

const cleanTranslations = (translation) => {
  if (isDev()) {
    return translation; // No cleansing
  }

  const clean = (o) => {
    return keys(o).reduce((key, acc) => {
      if (is(String, o[key])) {
        if (o[key] === "__STRING_NOT_TRANSLATED__") {
          return acc;
        } else {
          return { ...acc, [key]: o[key] };
        }
      } else {
        return { ...acc, [key]: clean(o[key]) };
      }
    }, {});
  };

  return clean(translation);
};

let i18nReturn;
if (process.env.NODE_ENV === "test") {
  i18nReturn = { language: "en_uk" };
} else {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: "en_uk",
      defaultNS: "translation",
      fallbackNS: "translation",
      debug: false,
      nsSeparator: ":", // namespace separator
      keySeparator: ".", // key separator

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
        en_uk: { translation: cleanTranslations(en_uk) },
        en_us: { translation: cleanTranslations(en_us) },
        no: { translation: cleanTranslations(no) },
        de: { translation: cleanTranslations(de) },
      },
    });
  i18nReturn = i18n;
}

export default i18nReturn;
