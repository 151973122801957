import React from "react";
import { useT } from "../../../hooks/useT";
import { programme } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import { isEmpty, isNil, path, pathOr } from "ramda";
import { SummaryCard } from "../../../base/project/SummaryCard";
import { Stat } from "../../../base/Stat";
import { DatePretty } from "../../../base/DatePretty";
import {
  RiskSection,
  IssueSection,
} from "../../projects/components/RiskAndIssueSections";

import { Section } from "../../../layout/Section";
import { Typography, Stack } from "@mui/material";
import { StatusTable } from "../../projects/components/HighlightReport";

export const HighlightReport = ({ programmeId, reportId, type }) => {
  const { t } = useT();
  const { data: programmeData = {} } = useQ(`programme-${programmeId}`, () =>
    programme.single({ id: programmeId }),
  );
  const { data: reportData = [] } = useQ(
    `programme-${programmeId}-report-highlights`,
    () => programme.report.highlights({ id: programmeId }),
  );
  const report = reportData
    .filter(
      (item, index) =>
        item.id === reportId ||
        (type === "highlight" && isNil(reportId)) ||
        (isEmpty(reportId) && index === 0 && type === "highlight"),
    )
    .map((item) => ({
      ...item,
      summary: path(["summary", "content"], item),
      value: path(["summary", "status"], item),
    }))[0];

  if (report === undefined) {
    return (
      <Section>
        <SummaryCard className={"pd-lg"}>
          No report exists. Reports are written on...
        </SummaryCard>
      </Section>
    );
  }

  return (
    <>
      <Typography variant={"h1"} sx={{ textAlign: "center", mb: 1 }}>
        {t(
          "project.progress." +
            (type !== undefined ? type : "highlightReport") +
            ".title",
        )}
      </Typography>
      <Typography variant={"h2"} sx={{ textAlign: "center", mb: 1 }}>
        {programmeData.name} -{" "}
        <DatePretty format={"YYYY-MM-DD"} date={report.to || report.date} />
      </Typography>
      {!!pathOr(programmeData.projectManager, ["manager", "name"], report) && (
        <Typography variant={"h3"} sx={{ textAlign: "center" }}>
          Managed by:{" "}
          {pathOr(programmeData.projectManager, ["manager", "name"], report)}
        </Typography>
      )}
      <Section
        title={
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant={"h1"}>
              {t("project.progress.highlightReport.fields.summary.title")}
            </Typography>
            <Stat status={path(["value"], report)} size={"lg"} />
          </Stack>
        }
      ></Section>
      <Typography dangerouslySetInnerHTML={{ __html: report?.summary }} />
      <Section
        title={t(
          "project.progress.highlightReport.fields.performedAchievements.title",
        )}
      >
        <Typography>
          ({t("project.progress.report.from")}{" "}
          <DatePretty format={"YYYY-MM-DD"} date={report.from} />)
        </Typography>
        <Typography
          dangerouslySetInnerHTML={{ __html: report?.performedAchivements }}
        />
      </Section>
      <Section
        title={t(
          "project.progress.highlightReport.fields.plannedAchievements.title",
        )}
      >
        <Typography
          dangerouslySetInnerHTML={{ __html: report?.plannedAchivements }}
        />
      </Section>

      <StatusTable report={report} />
      {type === "project" ? (
        <>
          <RiskSection report={report} />
          <IssueSection report={report} />
        </>
      ) : null}
    </>
  );
};
