import React, { useMemo } from "react";
import { useT } from "../../../hooks/useT";
import { Section } from "../../../layout/Section";
import { useMutation } from "react-query";
import { project } from "../../../queries";
import { Label } from "../../../base/form/Label";
import { Textarea } from "../../../base/form/Textarea";
import { isEmpty } from "ramda";
import { useToast } from "../../../hooks/useToast";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Stack, Button } from "@mui/material";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";

export const QualityApproachForm = ({ data, projectId, onClose, onUpdate }) => {
  const { t } = useT();
  const toast = useToast();

  const schema = yup.object().shape({
    process: yup.string().trim(),
    tools: yup.string().trim(),
    reporting: yup.string().trim(),
    timing: yup.string().trim(),
    roles: yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      process: data.process ?? "",
      tools: data.tools ?? "",
      reporting: data.reporting ?? "",
      timing: data.timing ?? "",
      roles: data.roles ?? "",
    },
  });

  const { handleSubmit, reset } = formData;

  const updateQualityApproach = useMutation(project.quality.updateApproach, {
    onSuccess: (data) => {
      toast.success(t("project.quality.approach.successfulUpdate"));
    },
    onError: () => {
      toast.error(t("project.quality.approach.updateFailed"));
    },
  });

  const handleUpdateApproach = useMemo(
    () =>
      handleSubmit((data) => {
        updateQualityApproach.mutate(
          {
            projectId,
            approach: data,
          },
          {
            onSuccess: () => {
              onUpdate();
              onClose();
            },
          },
        );
      }),
    [handleSubmit, projectId, updateQualityApproach, onUpdate, onClose],
  );

  // const persist = usePersist(qualityApproachForm, "quality-approach-form s");

  return (
    <Section title={t("project.quality.approach.title")}>
      <FormDataProvider formKey="project.quality.approach.fields" {...formData}>
        <form onSubmit={handleUpdateApproach}>
          <Stack spacing={2}>
            <FormRichTextField name={"process"} />
            <FormRichTextField name={"tools"} />
            <FormRichTextField name={"reporting"} />
            <FormRichTextField name={"timing"} />
            <FormRichTextField name={"roles"} />
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ width: "100%", mt: 2 }}
            >
              <Button variant="contained" color="secondary" onClick={onClose}>
                {t("generic.button.close")}
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {t(
                  isEmpty(data)
                    ? "project.quality.approach.actions.create"
                    : "project.quality.approach.actions.update",
                )}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormDataProvider>
    </Section>
  );
};
