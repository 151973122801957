
import React from 'react';

export const UserOff = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1.75C6.20507 1.75 4.75 3.20507 4.75 5C4.75 6.79493 6.20507 8.25 8 8.25C9.79493 8.25 11.25 6.79493 11.25 5C11.25 3.20507 9.79493 1.75 8 1.75ZM3.25 5C3.25 2.37665 5.37665 0.25 8 0.25C10.6234 0.25 12.75 2.37665 12.75 5C12.75 7.62335 10.6234 9.75 8 9.75C5.37665 9.75 3.25 7.62335 3.25 5ZM5 13.75C3.20507 13.75 1.75 15.2051 1.75 17C1.75 17.6904 2.30964 18.25 3 18.25H13C13.6904 18.25 14.25 17.6904 14.25 17C14.25 15.2051 12.7949 13.75 11 13.75H5ZM0.25 17C0.25 14.3766 2.37665 12.25 5 12.25H11C13.6234 12.25 15.75 14.3766 15.75 17C15.75 18.5188 14.5188 19.75 13 19.75H3C1.48122 19.75 0.25 18.5188 0.25 17ZM17.5303 4.46967C17.2374 4.17678 16.7626 4.17678 16.4697 4.46967C16.1768 4.76256 16.1768 5.23744 16.4697 5.53033L18.4393 7.5L16.4697 9.46967C16.1768 9.76256 16.1768 10.2374 16.4697 10.5303C16.7626 10.8232 17.2374 10.8232 17.5303 10.5303L19.5 8.56066L21.4697 10.5303C21.7626 10.8232 22.2374 10.8232 22.5303 10.5303C22.8232 10.2374 22.8232 9.76256 22.5303 9.46967L20.5607 7.5L22.5303 5.53033C22.8232 5.23744 22.8232 4.76256 22.5303 4.46967C22.2374 4.17678 21.7626 4.17678 21.4697 4.46967L19.5 6.43934L17.5303 4.46967Z" fill="#9A9EB1"/>
    </svg>

  );
};