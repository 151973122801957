import React from 'react';
import { useStyleParams } from '../hooks/useStyleParams';

export const Grid = ({ children,  ...rest }) => {
  const {cols = "1fr", rows = '', cg = "none", rg = "none",  className, style, hide} = useStyleParams(rest);
  if(hide) {
    return null;
  }

  return (
    <div className={`grid cg-${cg} rg-${rg} ${className}`} style={{
      gridTemplateColumns: cols,
      gridTemplateRows: rows,
      ...style }}>
      {children}
    </div>
  )
}

export const E = ({children, onClick, ...rest}) => {
  const {hide, className, style} = useStyleParams(rest);
  if(hide) {
    return null;
  }
  return (
    <div onClick={onClick} className={`grid-element ${className}`} style={style}>
      {children}
    </div>
  )
}
