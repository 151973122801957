import React, { memo, useEffect, useState } from "react";
import { CustomAccordion } from "@/layout/CustomAccordion";
import { IconButton } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Link, useNavigate } from "react-router-dom";
import { stripHtml } from "@/util/stripHtml";
import moment, { Moment } from "moment";

type DraftReportRow = {
  action: string;
  initiativeType: string;
  initiativeId: string;
  initiativeName: string;
  lastUpdate: Moment;
  reportType: string;
  reportId: string;
  summary: string;
};

export const DraftReportsTable = memo(() => {
  const navigate = useNavigate();
  const [reports, setReports] = useState<DraftReportRow[]>([]);

  useEffect(() => {
    setReports(
      Object.keys(window.localStorage)
        .filter((key) => {
          const regex =
            /^(create|edit)-(project|product|programme|release)-([0-9a-fA-F-]+)-(highlight|checkpoint|endStage|endProject)-report-form-?([0-9a-fA-F-]+|draft)? s/g;

          const matches = key.match(regex);
          return matches && matches.length > 0;
        })
        .map((key) => {
          const regex =
            /^(create|edit)-(project|product|programme|release)-([0-9a-fA-F-]+)-(highlight|checkpoint|endStage|endProject)-report-form-?([0-9a-fA-F-]+|draft)? s/g;

          const match = regex.exec(key);
          const action = match[1];
          const initiativeType = match[2];
          const initiativeId = match[3];
          const reportType = match[4];
          const reportId = match[5];
          const { summary } = JSON.parse(window.localStorage.getItem(key));
          const metadata = JSON.parse(
            window.localStorage.getItem(`meta-${key}`) ?? "{}",
          );
          return {
            action,
            initiativeType,
            initiativeId,
            initiativeName: metadata?.initiativeName ?? "Not available",
            lastUpdate: metadata?.lastUpdate
              ? moment(metadata.lastUpdate)
              : null,
            reportType,
            reportId,
            summary,
          };
        }),
    );
  }, []);

  const renderReportType = (type: string) => {
    switch (type) {
      case "highlight":
        return "Highlight Report";
      case "checkpoint":
        return "Checkpoint Report";
      case "endStage":
        return "End Stage Report";
      case "endProject":
        return "End Project Report";
      default:
        return "Unknown Report";
    }
  };

  const renderReportAction = (report: DraftReportRow) => {
    switch (report.action) {
      case "create":
        return `New Report`;
      case "edit":
        return stripHtml(report.summary);
      default:
        return "Unknown Action";
    }
  };

  const goToReportForm = (report: DraftReportRow) => {
    let url = "";
    switch (report.action) {
      case "create":
        url = `/projects/${report.initiativeId}/progress/${report.reportType}/new`;
        break;
      case "edit":
        url = `/projects/${report.initiativeId}/progress/${report.reportType}/${report.reportId}/edit`;
        break;
      default:
        break;
    }
    if (url !== "") {
      navigate(url);
    }
  };

  return (
    <CustomAccordion
      header={{
        columns: [
          { title: `Initiative Name`, width: 150 },
          { title: "Last Update", width: 150 },
          { title: "Report Type", width: 150 },
          { title: "Title", flex: 1 },
          { title: "", width: 50 },
        ],
        expanded: false,
      }}
      rows={
        reports.length > 0
          ? reports.map((report) => ({
              columns: [
                {
                  content: report.initiativeName ? (
                    <Link
                      to={`/${report.initiativeType}s/${report.initiativeId}`}
                    >
                      {report.initiativeName}
                    </Link>
                  ) : (
                    "Not available"
                  ),
                  width: 150,
                },
                {
                  title: report.lastUpdate
                    ? report.lastUpdate?.format("YYYY-MM-DD")
                    : "Not available",
                  width: 150,
                },
                { title: renderReportType(report.reportType), width: 150 },
                { title: renderReportAction(report), flex: 1 },
                {
                  content: (
                    <IconButton onClick={() => goToReportForm(report)}>
                      <OpenInFullIcon />
                    </IconButton>
                  ),
                  width: 50,
                },
              ],
              expanded: false,
            }))
          : [
              {
                columns: [{ title: "No draft reports", flex: 1 }],
                expanded: false,
              },
            ]
      }
    />
  );
});
