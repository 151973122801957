import React from "react";
import { uris } from "../../config/nav";
import { useMutation, useQueryClient } from "react-query";
import { users } from "../../queries";
import { toast } from "react-toastify";
import { useT } from "../../hooks/useT";
import { useNavigate } from "react-router-dom";

export const SwtichTenant = ({
  tenantId,
  label,
  children,
  next,
  className,
  style,
}) => {
  const navigate = useNavigate();
  const { t } = useT();
  const queryClient = useQueryClient();

  const switchTenantRequest = useMutation(users.switchTenant, {
    onSuccess: ({ id }) => {
      queryClient
        .invalidateQueries()
        .then(() => {
          toast.success(
            `${t("users.messages.switchTenant.success")} "${label}".`,
          );
          navigate(uris.home);
        })
        .finally(() => {
          next();
        });
    },
    onError: () => {
      toast.error(`${t("users.messages.error.generic")} "${label}".`);
    },
  });

  const onSwitchTenant = () => {
    switchTenantRequest.mutate({ tenantId });
  };

  return (
    <div
      className={`switch-tenant-form ${className}`}
      style={style}
      onClick={onSwitchTenant}
    >
      {children}
    </div>
  );
};
