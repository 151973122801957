import React from 'react';

export const Danger = ({className, style, onClick, color}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8594 23C10.6838 23 8.55705 22.3549 6.74811 21.1462C4.93917 19.9375 3.52927 18.2195 2.69671 16.2095C1.86414 14.1995 1.6463 11.9878 2.07074 9.85401C2.49518 7.72022 3.54283 5.76021 5.08121 4.22183C6.61958 2.68345 8.5796 1.6358 10.7134 1.21137C12.8472 0.786929 15.0589 1.00477 17.0689 1.83733C19.0789 2.66989 20.7969 4.07979 22.0055 5.88873C23.2142 7.69767 23.8594 9.82441 23.8594 12C23.8561 14.9164 22.6961 17.7123 20.6339 19.7745C18.5717 21.8367 15.7757 22.9967 12.8594 23Z" fill={color || "#F85252"}/>
      <path d="M11.8594 6H13.9714V9.888L13.7154 13.872H12.1154L11.8594 9.888V6ZM11.8594 14.992H13.9714V17.2H11.8594V14.992Z" fill="white"/>
    </svg>
  );
};
