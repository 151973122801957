import React, { useMemo } from "react";
import { Icon } from "./Icon";
import { isEmpty } from "ramda";
import { statusDef } from "../util/constants";
import { useStyleParams } from "../hooks/useStyleParams";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Stack, Typography } from "@mui/material";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

type StatProps = {
  size?: "sm" | "md" | "lg";
  subtitle?: string;
  status?: string;
  onClick?: () => void;
  color?: string;
};

export const Stat = ({
  size = "md",
  subtitle = "",
  status,
  onClick,
  color,
  ...rest
}: StatProps) => {
  const iconProps = useMemo<{
    onClick?: () => void;
    fontSize: "small" | "medium" | "large";
  }>(
    () => ({
      onClick,
      fontSize: { sm: "small", md: "medium", lg: "large" }[size] as
        | "small"
        | "medium"
        | "large",
    }),
    [onClick],
  );

  const renderIcon = () => {
    switch (status) {
      case "green":
      case "done":
        return (
          <ArrowCircleUpIcon {...iconProps} sx={{ color: "success.main" }} />
        );
      case "yellow":
      case "inProgress":
        return (
          <RemoveCircleOutlineIcon
            {...iconProps}
            sx={{ color: "warning.main" }}
          />
        );
      case "red":
      case statusDef.overdue:
        return <ErrorOutlineIcon {...iconProps} sx={{ color: "error.main" }} />;
      default:
        return <PanoramaFishEyeIcon {...iconProps} />;
    }
  };

  if (isEmpty(subtitle)) {
    return renderIcon();
  }

  return (
    <Stack alignItems="center" spacing={1}>
      {renderIcon()}
      <Typography>{subtitle}</Typography>
    </Stack>
  );
};
