import React from 'react';

export const ChevronDown = ({className, style, onClick, color}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.199 15C10.0023 15.0002 9.80778 14.9585 9.62848 14.8776C9.44919 14.7968 9.28921
        14.6786 9.15922 14.5309L3.25934 7.82465C3.07286 7.60242 2.98074 7.31605 3.00266 7.02678C3.02457
        6.73751 3.1588 6.46829 3.37663 6.2767C3.59446 6.08511 3.87861 5.98634 4.16831 6.00152C4.45802
        6.01671 4.73028 6.14463 4.92689 6.35795L10.0859 12.2223C10.1 12.2384 10.1174 12.2513 10.1369
        12.2602C10.1564 12.269 10.1776 12.2736 10.199 12.2736C10.2204 12.2736 10.2415 12.269 10.261
        12.2602C10.2805 12.2513 10.2979 12.2384 10.312 12.2223L15.471 6.35795C15.6676 6.14463 15.9399
        6.01671 16.2296 6.00152C16.5193 5.98634 16.8035 6.08511 17.0213 6.2767C17.2391 6.46829 17.3733
        6.73751 17.3953 7.02678C17.4172 7.31605 17.3251 7.60242 17.1386 7.82465L11.2405 14.5285C11.1103
        14.6765 10.9501 14.7951 10.7705 14.8764C10.5909 14.9577 10.3961 14.9998 10.199 15Z"
        fill={color || "#111827"}/>
    </svg>
  );
};
