import React from "react";
import { Section } from "@/layout/Section";
import { useT } from "@/hooks/useT";
import { SimplePage } from "@/layout/SimplePage";
import loginClip from "../../assets/cliparts/login.svg";
import { LinkLoginForm } from "./LinkLoginForm";
import { Grid } from "@mui/material";

interface LoginTemplateProps {
  children: React.ReactNode;
}

export const LoginTemplate: React.FC<LoginTemplateProps> = ({ children }) => {
  const { t } = useT();

  return (
    <SimplePage>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Section title={t("page.login.title")}>
            <p>{t("page.login.description")}</p>
          </Section>
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid item xs={12} sx={{ mt: 6 }} />
        <Grid item xs={12} md={6}>
          {children}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          <img
            src={loginClip}
            alt={"Login Clipart"}
            style={{ maxWidth: "100%" }}
          />
        </Grid>
      </Grid>
    </SimplePage>
  );
};

export const Login: React.FC = () => {
  const { t } = useT();
  return (
    <LoginTemplate>
      <LinkLoginForm />
    </LoginTemplate>
  );
};
