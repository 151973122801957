import { useQ } from "@/hooks/useQ";
import { users } from "@/queries";
import { Stack, Typography } from "@mui/material";
import React from "react";

export const StandardContractualClauses = () => {
  const { isLoading, data } = useQ(`users-me`, users.me);

  return (
    <Stack spacing={1}>
      <Typography variant="h2">Standard Contractual Clauses</Typography>
      <Typography>
        For the purposes of Article 28(3) of Regulation 2016/679 (the GDPR)
      </Typography>
      <Typography>between</Typography>
      <Typography fontWeight="bold">
        {data !== undefined
          ? data.tenants.find((tenant) => tenant.id === data.currentTenant).name
          : null}
      </Typography>
      <Typography>(the data controller)</Typography>
      <Typography>and</Typography>
      <Typography fontWeight="bold">
        Progreto AS
        <br />
        Gimleveien 28
        <br />
        5052 BERGEN
        <br />
        NORWAY
      </Typography>
      <Typography>(the data processor)</Typography>
      <Typography>each a ‘party'; together ‘the parties'</Typography>
      <Typography>
        HAVE AGREED on the following Contractual Clauses (the Clauses) in order
        to meet the requirements of the GDPR and to ensure the protection of the
        rights of the data subject.
      </Typography>
    </Stack>
  );
};
