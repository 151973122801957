import React, { useMemo, useState } from "react";
import { isNil } from "ramda";
import { IconButton, Popover, Stack, Typography } from "@mui/material";
import { PopoutCard } from "./tooltip/Popout";
import { DatePretty } from "./DatePretty";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const StageFlag = ({ data, projectTypes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const texts = useMemo(() => {
    if (data?.requestedStageTransition?.stage) {
      return {
        title: `Requested ${data.requestedStageTransition.stage.name}`,
        date: data.requestedStageTransition.date,
        content:
          data.requestedStageTransition.stage.comments ??
          data.requestedStageTransition.comments ??
          "",
      };
    } else if (data?.requestedPhase) {
      const projectType = (projectTypes ?? []).find(
        (type) => type.id === data.projectType,
      );
      const phase = (projectType?.phases ?? []).find(
        (phase) => phase.id === data.requestedPhase.id,
      );
      return {
        title: `Requested phase ${phase?.text}`,
        date: data.requestedPhase.date,
        content: data.requestedPhase.comments ?? "",
      };
    } else if (data?.recommendedProjectClosure) {
      return {
        title: data?.recommendedProjectClosure?.description,
        date: data.recommendedProjectClosure.date,
        content:
          data.recommendedProjectClosure.comments ?? "No description available",
      };
    } else if (data?.requestedStageException) {
      return {
        date: data.requestedStageException.date,
        content:
          data.requestedStageException.summary ?? "No description available",
        icon: <WarningAmberIcon />,
      };
    }
    return null;
  }, [data, projectTypes]);

  if (!texts) {
    return null;
  }

  /*
  var title="";
  if (this.props.requestedPhase!==undefined) {
    let projectType=this.props.tenant!==undefined && this.props.tenant.projectTypes!==undefined
            ? this.props.tenant.projectTypes.find(type=>type.id===this.props.requestedPhase.projectType.id)
            : this.props.projectTypes!==undefined
                    ? this.props.projectTypes.find(type=>type.id===this.props.requestedPhase.projectType.id)
                    : undefined;
    title=labels.requestedPhase+(projectType!==undefined ? " "+projectType.phases.find(phase=>phase.id===this.props.requestedPhase.id).text : "");
  } else if (this.props.recommendedProjectClosure!==undefined) {
    title=this.props.recommendedProjectClosure.description;
  } else if (this.props.requestedStageTransition!==undefined) {
    if (this.props.requestedStageTransition.stage!==undefined) {
      title=labels.requestedStageTransition+" "+this.props.requestedStageTransition.stage.name;
    } else if (this.props.requestedStageTransition.description!==undefined) {
      title=this.props.requestedStageTransition.description;
    }
  }
  var message=this.props.recommendedProjectClosure!==undefined ? {
    date:this.props.recommendedProjectClosure.date,
    content: this.props.recommendedProjectClosure.comments!==undefined
            ? this.props.recommendedProjectClosure.comments
            : <i>No description available</i>,
    title: title
  }  : this.props.requestedPhase!==undefined ? {
    date: this.props.requestedPhase.date,
    content: this.props.requestedPhase.comments!==undefined ? this.props.requestedPhase.comments : <i>No description available</i>,
    title: title
  } : this.props.requestedStageTransition!==undefined ? {
    date:this.props.requestedStageTransition.date,
    content: this.props.requestedStageTransition.stage!==undefined
            ? this.props.requestedStageTransition.stage.comments
            : this.props.requestedStageTransition.comments!==undefined
                    ? this.props.requestedStageTransition.comments
                    : <i>No description available</i>,
    title: title
  } : this.props.requestedStageException!==undefined ? {
    date:this.props.requestedStageException.date,
    content: this.props.requestedStageException.summary!==undefined ? this.props.requestedStageException.summary : <i>No description available</i>
  } : undefined;
   */

  return (
    <>
      <Popover
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <PopoutCard
          title={
            <Typography sx={{ color: "#fff !important" }}>
              {data.name}
            </Typography>
          }
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography fontWeight="bold">{texts.title}</Typography>
          </Stack>
          <Typography>
            <DatePretty format={"YYYY-MM-DD"} date={texts.date} />
            {texts.content ? ": " : ""}
          </Typography>
          {texts.content && (
            <Typography
              mt={1}
              dangerouslySetInnerHTML={{
                __html: texts.content,
              }}
            />
          )}
        </PopoutCard>
      </Popover>

      <IconButton onClick={!isNil(status) ? handleClick : null}>
        {texts.icon ?? <SportsScoreIcon />}
      </IconButton>
    </>
  );
};
