import React from "react";
import {
  DisplayContent,
  DisplayField,
} from "../../../base/content/DisplayContent";
import { List, ListItem, Typography } from "@mui/material";
import { isEmpty } from "ramda";
import { useT } from "../../../hooks/useT";
import {
  DataTable,
  DataTableAutoRows,
  DataTableHeading,
} from "../../../base/table/DataTable";

const ObjectivesTable = ({ data = [] }) => {
  const cols = "200px 700px 100px ";
  const { t } = useT();
  return (
    <DataTable>
      <DataTableHeading
        cols={cols}
        headings={[
          t("project.projectBrief.definition.objectivesForm.performanceAspect"),
          t(
            "project.projectBrief.definition.objectivesForm.objectiveDescription",
          ),
          t("project.projectBrief.definition.objectivesForm.tolerance"),
        ]}
      />

      <DataTableAutoRows
        cols={cols}
        data={data}
        cells={[
          ({ name, id }) =>
            !t(`generic.status.${id}`).includes("generic.status.")
              ? t(`generic.status.${id}`)
              : name,
          ({ description }) => (
            <Typography dangerouslySetInnerHTML={{ __html: description }} />
          ),
          ({ tolerance }) => (
            <Typography dangerouslySetInnerHTML={{ __html: tolerance }} />
          ),
        ]}
      />
    </DataTable>
  );
};

export const ProjectDefinition = ({ isCurrent, projectData }) => {
  const { t } = useT();

  const notEmptyObjectives = projectData?.objectivesSummary?.find(
    (item) => item.description || item.tolerance,
  );
  return (
    <>
      <DisplayContent
        disableTitle={true}
        data={
          isCurrent ? projectData : projectData?.definition?.background ?? {}
        }
        fields={[
          isCurrent ? "background" : "initiated",
          "timing",
          "bigPicture",
        ]}
      />
      {projectData.benefitsDescription ||
      notEmptyObjectives ||
      projectData?.definition?.objective?.benefits ||
      projectData?.definition?.objective?.summary ? (
        <>
          <Typography variant="h2">
            {t("projectForm.steps.objectives.title")}
          </Typography>
          <DisplayField
            data={isCurrent ? projectData : projectData?.definition?.objective}
            name={isCurrent ? "benefitsDescription" : "benefits"}
            disableTitle={true}
          />
          {notEmptyObjectives || projectData?.definition?.objective?.summary ? (
            <ObjectivesTable
              data={
                isCurrent
                  ? projectData?.objectivesSummary
                  : projectData?.definition?.objective?.summary
              }
            />
          ) : null}
        </>
      ) : null}
      {(
        isCurrent
          ? projectData?.projectScope && !isEmpty(projectData?.projectScope)
          : projectData?.definition?.scope?.items &&
            !isEmpty(projectData?.definition?.scope?.items)
      ) ? (
        <>
          <Typography variant="h2">
            {t("projectForm.steps.scope.title")}
          </Typography>
          <List
            sx={{
              listStyleType: "disc",
              pl: 2,
              "& .MuiListItem-root": {
                display: "list-item",
              },
            }}
          >
            {(isCurrent
              ? projectData?.projectScope
              : projectData?.definition?.scope?.items
            )?.map((scope) => {
              return <ListItem>{scope.text}</ListItem>;
            })}
          </List>
        </>
      ) : null}
      <DisplayContent
        area={"projectBrief"}
        data={isCurrent ? projectData : projectData?.definition ?? {}}
        fields={[
          "constraints",
          "assumptions",
          isCurrent ? "stakeholderDescription" : "stakeholders",
          "interfaces",
        ]}
      />
    </>
  );
};
