import React from "react";
import { GenericErrorHandler } from "./GenericErrorHandler";
import { AuthenticatedLayout } from "./AuthenticatedLayout";
import { Box, CircularProgress, Stack } from "@mui/material";

export const Page = ({ children, title, isLoading = false }) => {
  return (
    <AuthenticatedLayout title={title}>
      <Box className={"page"} sx={{ height: "100%" }}>
        <GenericErrorHandler>
          {isLoading ? (
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Stack>
          ) : (
            children || null
          )}
        </GenericErrorHandler>
      </Box>
    </AuthenticatedLayout>
  );
};
