import React, { memo, useRef, useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { Controller, useFormContext } from "react-hook-form";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { v4 as uuidV4 } from "uuid";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { TitleWithHelper } from "../../TitleWithHelper";
import { useFormStrings } from "../../../hooks/useFormStrings";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Stat } from "../../Stat";
import { useT } from "@/hooks/useT";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { HtmlTypography } from "@/base/typography/HtmlTypography";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

export const FormRichTextField = memo(
  ({
    name,
    label,
    placeholder,
    help,
    fieldKey,
    select,
    previous,
    readOnly,
    editable,
    onEditClick,
    labelStyle,
    required,
    ...props
  }) => {
    const { control } = useFormContext();
    const inputStrings = useFormStrings({
      name,
      label,
      placeholder,
      help,
      fieldKey,
    });
    const { t } = useT();
    const editorId = useRef(uuidV4().replaceAll("-", ""));

    const currentValue = useRef(null);
    const currentPlaceholder = useRef(null);
    const currentKey = useRef(null);

    const [editMode, setEditMode] = useState(false);
    const [isPreviousOpen, setIsPreviousOpen] = useState(false);
    const [isFontSizeMenuOpen, setIsFontSizeMenuOpen] = useState(false);

    // We created this useEffect because the react-quill library doesn't provide a direct handler callback
    // to detect when the font size dropdown menu is opened or closed. As a workaround, we monitor changes
    // in the "header.picker" element within the react-quill component. This approach enables us to determine whether
    // the font size dropdown is currently open or closed, and we can then use this information to determine whether
    // to apply the disabled style to the toolbar buttons.
    useEffect(() => {
      const parentDiv = document.getElementById(editorId.current);

      const handleDropdownOpen = () => {
        setTimeout(() => {
          setIsFontSizeMenuOpen(
            !!parentDiv.querySelector(".ql-header.ql-picker.ql-expanded"),
          );
        }, 0);
      };

      const fontSizeDropdown = parentDiv.querySelector(".ql-header.ql-picker");

      if (fontSizeDropdown) {
        fontSizeDropdown.addEventListener("click", handleDropdownOpen);
        document.addEventListener("click", handleDropdownOpen);
      }

      return () => {
        if (fontSizeDropdown) {
          fontSizeDropdown.removeEventListener("click", handleDropdownOpen);
          document.removeEventListener("click", handleDropdownOpen);
        }
      };
    }, []);

    return (
      <Box
        id={editorId.current}
        data-text-editor={name}
        sx={{
          mt: "-2px",
          "& .ql-toolbar *":
            editMode || isFontSizeMenuOpen
              ? undefined
              : {
                  color: "#ddd !important",
                  stroke: "#ddd !important",
                },
          "& .ql-editor p, & .ql-editor ol, & .ql-editor ul": {
            color: "#111827 !important",
            fontSize: "16px !important",
          },
        }}
      >
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => {
            if (currentValue.current !== value) {
              currentValue.current = value;
              currentKey.current = Math.random();
            }
            if (currentPlaceholder.current !== inputStrings.placeholder) {
              currentPlaceholder.current = inputStrings.placeholder;
              currentKey.current = Math.random();
            }
            return (
              <Stack data-cy={`rte-${name}`}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    mb: "12px !important",
                  }}
                >
                  {!readOnly && inputStrings.help ? (
                    <TitleWithHelper
                      title={inputStrings.label}
                      help={inputStrings.help}
                      placeholder={inputStrings.placeholder}
                      select={select}
                      labelStyle={labelStyle}
                      required={required}
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "20px !important",
                        color: "#111827 !important",
                        ...labelStyle,
                      }}
                    >
                      {inputStrings.label}
                      {required && (
                        <Typography
                          component="span"
                          color="error"
                          fontSize="1.3em"
                          lineHeight="1em"
                        >
                          {" "}
                          *
                        </Typography>
                      )}
                    </Typography>
                  )}
                  {readOnly && editable && (
                    <Button
                      variant="text"
                      color="secondary"
                      startIcon={<EditOutlinedIcon />}
                      onClick={onEditClick}
                    >
                      {t("generic.button.edit")}
                    </Button>
                  )}
                </Stack>
                {readOnly ? (
                  <Typography
                    sx={{
                      "& p": {
                        fontSize: "16px !important",
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                ) : (
                  <ReactQuill
                    key={currentKey.current}
                    theme="snow"
                    defaultValue={value}
                    onChange={(value) => {
                      currentValue.current = value;
                      onChange(value);
                    }}
                    formats={formats}
                    modules={modules}
                    onFocus={() => setEditMode(true)}
                    onBlur={() => setEditMode(false)}
                    placeholder={inputStrings.placeholder}
                    preserveWhitespace
                    bounds={
                      name?.includes("[") || name?.includes("]")
                        ? undefined
                        : `[data-text-editor=${name}]`
                    }
                    {...props}
                  />
                )}
                {previous && (
                  <>
                    <Stack alignItems="flex-end">
                      <Button
                        endIcon={
                          isPreviousOpen ? (
                            <ExpandLessOutlinedIcon />
                          ) : (
                            <ExpandMoreOutlinedIcon />
                          )
                        }
                        onClick={() => setIsPreviousOpen((o) => !o)}
                      >
                        {isPreviousOpen
                          ? t("generic.button.hidePrevious")
                          : t("generic.button.showPrevious")}
                      </Button>
                    </Stack>
                    {isPreviousOpen && (
                      <Paper sx={{ px: 1, py: 2, backgroundColor: "#F9FAFB" }}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {typeof previous.status !== "undefined" && (
                            <Stat status={previous.status} />
                          )}
                          <HtmlTypography>
                            {typeof previous?.text !== "undefined" &&
                            previous?.text !== ""
                              ? previous?.text
                              : t("generic.missingPrevious")}
                          </HtmlTypography>
                        </Stack>
                      </Paper>
                    )}
                  </>
                )}
              </Stack>
            );
          }}
        />
      </Box>
    );
  },
);
FormRichTextField.displayName = "FormRichTextField";
