import React, { useState } from "react";
import RDraft, {Editor} from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { d2h, h2d, valueToEvent } from "./util";
import { useDebouncedCallback } from "use-debounce";

// Toolbar Options via: https://jpuri.github.io/react-draft-wysiwyg/#/docs
const toolbar = {
  options: ["blockType", "inline", "list", "link"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline", "strikethrough"],
    bold: { icon: RDraft?.bold, className: undefined },
    italic: { icon: RDraft?.italic, className: undefined },
    underline: { icon: RDraft?.underline, className: undefined },
    strikethrough: { icon: RDraft?.strikethrough, className: undefined },
  },
  blockType: {
    inDropdown: false,
    options: [], //'Normal', 'H1', 'H2', 'H3', 'H4', 'H5'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },

  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered"],
    unordered: { icon: RDraft?.unordered, className: undefined },
    ordered: { icon: RDraft?.ordered, className: undefined },
  },

  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link", "unlink"],
    link: { icon: RDraft?.link, className: undefined },
    unlink: { icon: RDraft?.unlink, className: undefined },
    linkCallback: undefined,
  },
};

const ToggleAA = ({ onClick }) => {
  return (
    <span className={`rdw-option-wrapper toggle-aa`} onClick={onClick}>
      Aa
    </span>
  );
};

export const Textarea = ({
  plain = false,
  value,
  onChange,
  name,
  placeholder,
}) => {
  const [localValue, setLocalValue] = useState(h2d(value));
  const debounceFormUpdate = useDebouncedCallback(onChange, 400);

  const [showFullToolbar, setShowFullToolbar] = useState(true);

  const oc = (v) => {
    setLocalValue(v);
    debounceFormUpdate(valueToEvent(d2h(v), name));
  };

  return (
    <Editor
      toolbarOnFocus
      placeholder={placeholder}
      wrapperClassName={"wysiwyg-textarea"}
      editorClassName={`wysiwyg-textarea__editor ${
        showFullToolbar
          ? "wysiwyg-textarea__editor--full"
          : "wysiwyg-textarea__editor--simple"
      }`}
      toolbarClassName={`wysiwyg-textarea__toolbar ${
        showFullToolbar
          ? "wysiwyg-textarea__toolbar--full"
          : "wysiwyg-textarea__toolbar--simple"
      }`}
      toolbar={toolbar}
      toolbarHidden={plain}
      defaultEditorState={EditorState.createEmpty()}
      editorState={localValue}
      toolbarCustomButtons={[
        <ToggleAA onClick={() => setShowFullToolbar((v) => !v)} />,
      ]}
      onEditorStateChange={oc}
    />
  );
};
