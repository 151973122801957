import React, { useEffect, useState } from "react";
import { useMatch, useParams } from "react-router-dom";
import { useQ } from "@/hooks/useQ";
import { product, programme, project, release } from "@/queries";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { useT } from "@/hooks/useT";
import { CustomAccordion } from "@/layout/CustomAccordion";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { clientUrl } from "@/util/routing";
import { uris } from "@/config/nav";
import { DiffMethod, StringDiff } from "react-string-diff";
import {
  DiffTable,
  generateHTMLDiffString,
  KeyValueTable,
} from "@/pages/projects/EventLog.utils";
import { KeyValueDisplayView } from "@/base/KeyValueDisplayView";
import { stripHtml } from "@/util/stripHtml";
import capitalize from "@mui/utils/capitalize";

const EventDetail = ({
  isProject,
  isProgramme,
  isProduct,
  isRelease,
  event = {} as { type: string; timestamp: string; id: string },
  id = "",
  open = false,
  language = "",
}) => {
  const {
    data: logProjectDetail = { html: "" },
    isLoading: isLoadingProjectLogDetails,
  } = useQ(
    `project-${id}-log-${event.type}-${event.timestamp}`,
    () =>
      project.logDetails({
        id,
        logId: event.id,
        language,
      }),
    {
      enabled: open && !!isProject,
    },
  );
  const {
    data: logProgrammeDetail = { html: "" },
    isLoading: isLoadingProgrammeLogDetails,
  } = useQ(
    `programme-${id}-log-${event.type}-${event.timestamp}`,
    () =>
      programme.logDetails({
        id,
        logId: event.id,
        language,
      }),
    {
      enabled: open && !!isProgramme,
    },
  );
  const {
    data: logProductDetail = { html: "" },
    isLoading: isLoadingProductLogDetails,
  } = useQ(
    `product-${id}-log-${event.type}-${event.timestamp}`,
    () =>
      product.logDetails({
        id,
        logId: event.id,
        language,
      }),
    {
      enabled: open && !!isProduct,
    },
  );
  const {
    data: logReleaseDetail = { html: "" },
    isLoading: isLoadingReleaseLogDetails,
  } = useQ(
    `release-${id}-log-${event.type}-${event.timestamp}`,
    () =>
      release.logDetails({
        id,
        logId: event.id,
        language,
      }),
    {
      enabled: open && !!isRelease,
    },
  );
  const logDetail = !open
    ? null
    : isProject
      ? logProjectDetail
      : isProgramme
        ? logProgrammeDetail
        : isProduct
          ? logProductDetail
          : logReleaseDetail;
  const isLoading = isProject
    ? isLoadingProjectLogDetails
    : isProgramme
      ? isLoadingProgrammeLogDetails
      : isProduct
        ? isLoadingProductLogDetails
        : isLoadingReleaseLogDetails;

  if (!logDetail) {
    return null;
  }

  const renderAction = () => {
    const {
      programmeBefore,
      programmeAfter,
      projectBefore,
      projectAfter,
      productBefore,
      productAfter,
      releaseBefore,
      releaseAfter,
      log,
    } = logDetail ?? {};
    switch (log?.type) {
      case "addedOutcome": {
        return (
          <Typography>
            Added new outcome with name: <strong>{log?.outcome?.name}</strong>
          </Typography>
        );
      }
      case "addedProgramme": {
        return (
          <KeyValueDisplayView
            title={programmeAfter.name}
            properties={Object.keys(programmeAfter).map((key) => ({
              name: key,
              html:
                typeof programmeAfter[key] === "object"
                  ? JSON.stringify(programmeAfter[key], null, 2)
                  : programmeAfter[key],
            }))}
          />
        );
      }
      case "updatedOutcome": {
        const outcomeBefore = programmeBefore?.outcomes?.find(
          (o) => o.id === log?.outcome?.id,
        );
        const outcomeAfter = programmeAfter?.outcomes?.find(
          (o) => o.id === log?.outcome?.id,
        );
        return (
          <Typography>
            Modified Outcome:
            <br />
            <br />
            <StringDiff
              method={DiffMethod.Chars}
              oldValue={outcomeBefore?.name ?? ""}
              newValue={outcomeAfter?.name ?? ""}
            />
          </Typography>
        );
      }
      case "deletedOutcome": {
        const outcome = programmeBefore?.outcomes?.find(
          (o) => o.id === log?.outcome?.id,
        );
        return (
          <Typography>
            Deleted outcome with name: <strong>{outcome?.name}</strong>
          </Typography>
        );
      }
      case "addedTargetOperationModel": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: `${programmeAfter?.target ?? ""}`,
            }}
          />
        );
      }
      case "modifiedTargetOperationModel": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: generateHTMLDiffString(
                programmeBefore?.target ?? "",
                programmeAfter?.target ?? "",
              ),
            }}
          />
        );
      }
      case "addedVisionStatement": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: `${programmeAfter?.vision ?? ""}`,
            }}
          />
        );
      }
      case "modifiedVisionStatement": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: generateHTMLDiffString(
                programmeBefore?.vision ?? "",
                programmeAfter?.vision ?? "",
              ),
            }}
          />
        );
      }
      case "addedMeasurableBenefit": {
        return (
          <KeyValueDisplayView
            title={log?.measurableBenefit?.title}
            properties={Object.keys(log?.measurableBenefit).map((key) => ({
              name: key,
              html:
                typeof log?.measurableBenefit[key] === "object"
                  ? JSON.stringify(log?.measurableBenefit[key], null, 2)
                  : log?.measurableBenefit[key],
            }))}
          />
        );
      }
      case "updatedMeasurableBenefit": {
        const measurableBenefitBefore =
          programmeBefore?.measurableBenefits?.find(
            (b) => b.id === log?.measurableBenefit?.id,
          );
        const measurableBenefitAfter = programmeAfter?.measurableBenefits?.find(
          (b) => b.id === log?.measurableBenefit?.id,
        );
        return (
          <DiffTable
            properties={[
              {
                name: "Title",
                valueBefore: measurableBenefitBefore?.title ?? "",
                valueAfter: measurableBenefitAfter?.title ?? "",
                type: "string",
              },
              {
                name: "Accountable",
                valueBefore: measurableBenefitBefore?.accountable ?? "",
                valueAfter: measurableBenefitAfter?.accountable ?? "",
                type: "string",
              },
              {
                name: "Baseline",
                valueBefore: measurableBenefitBefore?.baseline ?? "",
                valueAfter: measurableBenefitAfter?.baseline ?? "",
                type: "string",
              },
              {
                name: "Goal",
                valueBefore: measurableBenefitBefore?.goal ?? "",
                valueAfter: measurableBenefitAfter?.goal ?? "",
                type: "string",
              },
              {
                name: "Measurement",
                valueBefore: measurableBenefitBefore?.measurement ?? "",
                valueAfter: measurableBenefitAfter?.measurement ?? "",
                type: "html",
              },
              {
                name: "Actions",
                valueBefore: measurableBenefitBefore?.actions ?? "",
                valueAfter: measurableBenefitAfter?.actions ?? "",
                type: "html",
              },
            ]}
          />
        );
      }
      case "deletedMeasurableBenefit": {
        const measurableBenefitBefore =
          programmeBefore?.measurableBenefits?.find(
            (b) => b.id === log?.measurableBenefit?.id,
          );
        return (
          <Typography>
            Deleted Measurable Benefit with name:{" "}
            <strong>{measurableBenefitBefore?.title}</strong>
          </Typography>
        );
      }
      case "addedProject": {
        return (
          <KeyValueDisplayView
            title={projectAfter.name}
            properties={Object.keys(projectAfter).map((key) => ({
              name: key,
              html:
                typeof projectAfter[key] === "object"
                  ? JSON.stringify(projectAfter[key], null, 2)
                  : projectAfter[key],
            }))}
          />
        );
      }
      case "definedOutput": {
        return (
          <KeyValueDisplayView
            title={stripHtml(log?.output?.output)}
            properties={Object.keys(log?.output).map((key) => ({
              name: key,
              html:
                typeof log?.output[key] === "object"
                  ? JSON.stringify(log?.output[key], null, 2)
                  : log?.output[key],
            }))}
          />
        );
      }
      case "updatedProjectDefinition": {
        return (
          <KeyValueDisplayView
            title={stripHtml(log?.definition?.background?.initiated)}
            properties={Object.keys(log?.definition ?? {}).map((key) => ({
              name: key,
              html:
                typeof log?.definition?.[key] === "object"
                  ? JSON.stringify(log?.definition?.[key], null, 2)
                  : log?.definition?.[key],
            }))}
          />
        );
      }
      case "capturedIssue": {
        return (
          <KeyValueDisplayView
            title={stripHtml(log?.item?.title)}
            properties={Object.keys(log?.item ?? {}).map((key) => ({
              name: key,
              html:
                typeof log?.item?.[key] === "object"
                  ? JSON.stringify(log?.item?.[key], null, 2)
                  : log?.item?.[key],
            }))}
          />
        );
      }
      case "addedProjectProduct": {
        return (
          <KeyValueDisplayView
            title={stripHtml(log?.product?.title)}
            properties={Object.keys(log?.product).map((key) => ({
              name: key,
              html:
                typeof log?.product[key] === "object"
                  ? JSON.stringify(log?.product[key], null, 2)
                  : log?.product[key],
            }))}
          />
        );
      }
      case "updatedProjectProduct": {
        const ppId = log?.product?.id;
        let ppBefore = null;
        let ppAfter = null;
        for (const scope of projectBefore?.projectScope ?? []) {
          for (const product of scope?.productItems ?? []) {
            if (product.id === ppId) {
              ppBefore = product;
            }
          }
        }
        for (const scope of projectAfter?.projectScope ?? []) {
          for (const product of scope?.productItems ?? []) {
            if (product.id === ppId) {
              ppAfter = product;
            }
          }
        }
        return (
          <DiffTable
            properties={[
              {
                name: "Title",
                valueBefore: ppBefore?.title ?? "",
                valueAfter: ppAfter?.title ?? "",
                type: "html",
              },
            ]}
          />
        );
      }
      case "addedProduct": {
        return (
          <KeyValueDisplayView
            title={productAfter.name}
            properties={Object.keys(productAfter).map((key) => ({
              name: key,
              html:
                typeof productAfter[key] === "object"
                  ? JSON.stringify(productAfter[key], null, 2)
                  : productAfter[key],
            }))}
          />
        );
      }
      case "updatedProductDefinitionDescription": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: generateHTMLDiffString(
                productBefore?.description ?? "",
                productAfter?.description ?? "",
              ),
            }}
          />
        );
      }
      case "updatedProductDefinitionVision": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: generateHTMLDiffString(
                productBefore?.vision ?? "",
                productAfter?.vision ?? "",
              ),
            }}
          />
        );
      }
      case "updatedProductDefinitionBackground": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: generateHTMLDiffString(
                productBefore?.background ?? "",
                productAfter?.background ?? "",
              ),
            }}
          />
        );
      }
      case "changedProductName": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: generateHTMLDiffString(
                productBefore?.name ?? "",
                productAfter?.name ?? "",
              ),
            }}
          />
        );
      }
      case "changedProductIdentifier": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: generateHTMLDiffString(
                productBefore?.identifier ?? "",
                productAfter?.identifier ?? "",
              ),
            }}
          />
        );
      }
      case "updatedProductCurrency": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: generateHTMLDiffString(
                productBefore?.currency ?? "",
                productAfter?.currency ?? "",
              ),
            }}
          />
        );
      }
      case "changedProductLocation": {
        return (
          <Typography
            dangerouslySetInnerHTML={{
              __html: generateHTMLDiffString(
                productBefore?.location ?? "",
                productAfter?.location ?? "",
              ),
            }}
          />
        );
      }
      case "addedRelease": {
        return (
          <KeyValueDisplayView
            title={releaseAfter.name}
            properties={Object.keys(releaseAfter).map((key) => ({
              name: key,
              html:
                typeof releaseAfter[key] === "object"
                  ? JSON.stringify(releaseAfter[key], null, 2)
                  : releaseAfter[key],
            }))}
          />
        );
      }
      case "setReleaseMethod": {
        const methodBefore =
          releaseBefore?.method ??
          (productBefore?.releases ?? []).find((r) => r.id === log?.release?.id)
            ?.method ??
          "nothing";
        const methodAfter =
          releaseAfter?.method ??
          (productAfter?.releases ?? []).find((r) => r.id === log?.release?.id)
            ?.method ??
          "nothing";
        return (
          <Typography>
            Release method changed from {methodBefore} to: {methodAfter}
          </Typography>
        );
      }
      case "activatedRelease": {
        return <Typography>Release activated</Typography>;
      }
      case "updatedRelease": {
        console.log({ releaseBefore, releaseAfter });
        return (
          <DiffTable
            properties={[
              "name",
              "active",
              "assumptions",
              "isRelease",
              "isStarted",
              "method",
              "phase",
              "scheduleComments",
              "timeTolerance",
              "timeToleranceUnit",
            ]
              .map((field) => ({
                name: capitalize(field),
                valueBefore: releaseBefore?.[field] ?? "",
                valueAfter: releaseAfter?.[field] ?? "",
                type: "string" as "string" | "html",
              }))
              .concat(
                ["description", "timeComments", "prerequisite", "comments"].map(
                  (field) => ({
                    name: capitalize(field),
                    valueBefore: releaseBefore?.[field] ?? "",
                    valueAfter: releaseAfter?.[field] ?? "",
                    type: "html" as "string" | "html",
                  }),
                ),
              )}
          />
        );
      }
      default:
        if (logDetail?.html) {
          return (
            <Typography
              mt={1}
              sx={{
                display: "block",
                padding: "10px 30px",
                margin: "0",
                overflow: "scroll",
              }}
              dangerouslySetInnerHTML={{
                __html: logDetail?.html,
              }}
            />
          );
        } else {
          return (
            <Typography
              mt={1}
              sx={{
                display: "block",
                padding: "10px 30px",
                margin: "0",
                overflow: "scroll",
              }}
            >
              {JSON.stringify(logDetail?.log ?? {}, null, 2)}
            </Typography>
          );
        }
    }
  };

  return <Box>{renderAction()}</Box>;
};

export const EventLog = () => {
  const { t } = useT();
  const { id = "" } = useParams<{ id?: string }>();
  const isProgramme = useMatch(
    clientUrl(uris.programme.log, {
      id,
    }),
  );
  const isProject = useMatch(
    clientUrl(uris.project.log, {
      id,
    }),
  );
  const isProduct = useMatch(
    clientUrl(uris.product.log, {
      id,
    }),
  );
  const isRelease = useMatch(
    clientUrl(uris.release.log, {
      id,
    }),
  );
  const { data: projectData = {} } = useQ(
    `project-${id}`,
    () => project.single({ id }),
    {
      enabled: !!isProject,
    },
  );
  const { data: programmeData = {} } = useQ(
    `programme-${id}`,
    () => programme.single({ id }),
    {
      enabled: !!isProgramme,
    },
  );
  const { data: productData = {} } = useQ(
    `product-${id}`,
    () => product.single({ id }),
    {
      enabled: !!isProduct,
    },
  );
  const { data: releaseData = {} } = useQ(
    `release-${id}`,
    () => release.single({ id }),
    {
      enabled: !!isRelease,
    },
  );
  const { data: logProjectData = [], isLoading: isLoadingProjectLogs } = useQ(
    `project-${id}-log`,
    () => project.log({ id }),
    {
      enabled: !!isProject,
    },
  );
  const { data: logProgrammeData = [], isLoading: isLoadingProgrammeLogs } =
    useQ(`programme-${id}-log`, () => programme.log({ id }), {
      enabled: !!isProgramme,
    });
  const { data: logProductData = [], isLoading: isLoadingProductLogs } = useQ(
    `product-${id}-log`,
    () => product.log({ id }),
    {
      enabled: !!isProduct,
    },
  );
  const { data: logReleaseData = [], isLoading: isLoadingReleaseLogs } = useQ(
    `release-${id}-log`,
    () => release.log({ id }),
    {
      release: !!isRelease,
    },
  );
  const log = (
    isProject
      ? logProjectData
      : isProgramme
        ? logProgrammeData
        : isProduct
          ? logProductData
          : logReleaseData
  ) as [
    {
      id: string;
      type: string;
      user: {
        name: string;
        tenants?: [string];
        isUser?: boolean;
        lastActive?: string;
        emailConfirmed?: boolean;
      };
      timestamp: string;
    },
  ];
  const isLoading = isProject
    ? isLoadingProjectLogs
    : isProgramme
      ? isLoadingProgrammeLogs
      : isProduct
        ? isLoadingProductLogs
        : isLoadingReleaseLogs;

  const [searchResults, updateSearchResults] = useState(log);
  const [showDetails, toggleShowDetails] = useState(0);
  const [searchWord, updateSearchWord] = useState("");
  const [searchText, updateSearchText] = useState("");

  const initiative: { name: string } = isProject
    ? projectData
    : isProgramme
      ? programmeData
      : isProduct
        ? productData
        : releaseData;

  useEffect(() => {
    updateSearchResults(log);
  }, [isLoading]);

  return (
    <Page isLoading={isLoading} title={t("project.log.title")}>
      <Section title={t("project.log.title")}>
        <Paper sx={{ padding: "30px 20px", mb: 4 }}>
          <Stack direction="row" spacing={5}>
            <Stack direction="column" width={"40%"}>
              <Typography variant="h2">{initiative.name}</Typography>
              <Typography>{t("project.log.description")}</Typography>
            </Stack>
            <Box width={"50%"}>
              <Stack direction="row" mt={4} sx={{ float: "right" }} spacing={2}>
                <Stack direction="column">
                  <TextField
                    value={searchWord}
                    onChange={(e) => updateSearchWord(`${e.target.value}`)}
                    placeholder={t("project.log.search.placeholder")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        updateSearchResults(
                          log.filter((item) =>
                            item.type
                              .toLowerCase()
                              .includes(searchWord.toLowerCase()),
                          ) as [
                            {
                              id: string;
                              type: string;
                              user: { name: string };
                              timestamp: string;
                            },
                          ],
                        );
                        updateSearchText(
                          `${
                            searchWord && searchWord !== ""
                              ? `${t(
                                  "project.log.search.filteredOn",
                                )} '${searchWord}'`
                              : ""
                          }`,
                        );
                      }
                    }}
                  ></TextField>
                  <Typography>{t("project.log.search.help")}</Typography>
                  <Typography fontStyle="bold">{searchText}</Typography>
                </Stack>
                <Button
                  onClick={() => {
                    updateSearchResults(
                      log.filter((item) =>
                        item.type
                          .toLowerCase()
                          .includes(searchWord.toLowerCase()),
                      ) as [
                        {
                          id: string;
                          type: string;
                          user: { name: string };
                          timestamp: string;
                        },
                      ],
                    );
                    updateSearchText(
                      `${
                        searchWord && searchWord !== ""
                          ? `${t(
                              "project.log.search.filteredOn",
                            )} '${searchWord}'`
                          : ""
                      }`,
                    );
                  }}
                  variant="outlined"
                >
                  {t("project.log.search.submit")}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Paper>
        <CustomAccordion
          otherProps={""}
          tableView
          header={{
            columns: [
              { title: t("project.log.fields.date"), flex: "1 20px" },
              { title: t("project.log.fields.event"), flex: 2 },
              {
                title: t("project.log.fields.performedBy"),
                alignSelf: "flex-start",
                flex: 3,
              },
            ],
            details: "",
            expanded: false,
          }}
          rows={(searchResults ?? log ?? []).map((event, i) => {
            delete event?.user?.tenants;
            delete event?.user?.isUser;
            delete event?.user?.lastActive;
            delete event?.user?.emailConfirmed;
            return {
              id: i + 1,
              columns: [
                {
                  content: (
                    <Typography sx={{ fontWeight: "bold" }}>
                      {`${moment(event.timestamp).format(
                        "YYYY-MM-DD HH:mm:ss",
                      )} GMT`}
                    </Typography>
                  ),
                  flex: "1 20px",
                  sx: { alignSelf: "flex-start" },
                },
                {
                  content: (
                    <Typography sx={{ fontWeight: "bold" }}>
                      {event.type}
                    </Typography>
                  ),
                  flex: 2,
                  sx: { alignSelf: "flex-start" },
                },
                {
                  content: (
                    <Typography sx={{ fontWeight: "bold" }}>
                      {event?.user?.name ?? "<anonymous>"}
                    </Typography>
                  ),
                  sx: { alignSelf: "flex-start" },
                  flex: 3,
                },
              ],
              expanded: showDetails === i + 1,
              onClick: () =>
                toggleShowDetails(showDetails === i + 1 ? 0 : i + 1),
              details: (
                <EventDetail
                  isProject={isProject}
                  isProgramme={isProgramme}
                  isProduct={isProduct}
                  isRelease={isRelease}
                  event={event}
                  id={id}
                  open={showDetails === i + 1}
                  language={t("language")}
                />
              ),
            };
          })}
        />
      </Section>
    </Page>
  );
};
