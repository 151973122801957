import React from 'react';

export const Success = ({className, style, onClick, color}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8594 23C10.6838 23 8.55705 22.3549 6.74811 21.1462C4.93917 19.9375 3.52927 18.2195 2.69671 16.2095C1.86414 14.1995 1.6463 11.9878 2.07074 9.85401C2.49518 7.72022 3.54283 5.76021 5.08121 4.22183C6.61958 2.68345 8.5796 1.6358 10.7134 1.21137C12.8472 0.786929 15.0589 1.00477 17.0689 1.83733C19.0789 2.66989 20.7969 4.07979 22.0055 5.88873C23.2142 7.69767 23.8594 9.82441 23.8594 12C23.8561 14.9164 22.6961 17.7123 20.6339 19.7745C18.5717 21.8367 15.7757 22.9967 12.8594 23Z" fill={color || "#47B857"}/>
      <g clipPath="url(#clip0)">
        <path d="M12.1523 6.29279L7.44531 10.9998L8.85931 12.4138L11.8593 9.41379V17.9998H13.8593V9.41379L16.8593 12.4138L18.2733 10.9998L13.5663 6.29279C13.3788 6.10532 13.1245 6 12.8593 6C12.5941 6 12.3398 6.10532 12.1523 6.29279Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" transform="translate(6.85938 6)"/>
        </clipPath>
      </defs>
    </svg>
  );
};
