import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useT } from "../../hooks/useT";
import { Page } from "../../layout/Page";
import { NoMobileView } from "../../base/responsive/NoMobileView";
import { Hide } from "../../base/responsive/Hide";
import { useParams } from "react-router-dom";
import { product, programme, project } from "../../queries";
import { useQ } from "../../hooks/useQ";
import { Section } from "../../layout/Section";
import {
  StepButton,
  Stepper,
  Step,
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRichTextField } from "../../base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { useToast } from "../../hooks/useToast";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";

export const ProgrammeDefinitionForm = () => {
  const { t } = useT();
  const { id = "" } = useParams();
  const {
    isLoading,
    data: programmeData,
    refetch: refetchProgramme,
  } = useQ(`programme-${id}`, () => programme.single({ id }));

  console.log("programmeData", programmeData);

  const schema = yup.object().shape({
    background: yup.string().trim().optional(),
    timing: yup.string().trim().optional(),
    successFactors: yup.string().trim().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      background: programmeData?.background ?? "",
      timing: programmeData?.timing ?? "",
      successFactors: programmeData?.successFactors ?? "",
    },
  });

  const { handleSubmit, reset } = formData;

  useEffect(() => {
    reset({
      background: programmeData?.background ?? "",
      timing: programmeData?.timing ?? "",
      successFactors: programmeData?.successFactors ?? "",
    });
  }, [reset, programmeData]);

  const toast = useToast();

  const updateProgrammeDefinition = useMutation(programme.updateDefinition, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Programme definiton updated successfully");
      refetchProgramme();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating programme definition");
    },
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        updateProgrammeDefinition.mutate({
          id,
          data,
        });
      }),
    [handleSubmit, id],
  );

  return (
    <Page isLoading={isLoading} title={t("programme.editDefinition.title")}>
      <Section
        title={t("programme.editDefinition.title") + ": " + programmeData?.name}
      >
        <FormDataProvider formKey="programme.addEditForm.fields" {...formData}>
          <form onSubmit={onSubmit}>
            <Stack spacing={3}>
              <FormRichTextField name="background" />
              <FormRichTextField name="timing" />
              <FormRichTextField name="successFactors" />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ width: "100%", mt: 2 }}
            >
              <Button type="submit" variant="contained">
                Update definition
              </Button>
            </Stack>
          </form>
        </FormDataProvider>
      </Section>
    </Page>
  );
};
