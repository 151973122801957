import React from 'react';

export const StageFlag = ({className, style, onClick}) => (
  <svg className={className} style={style} onClick={onClick}
    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2799 18.7953V19.6794C12.1172 19.8657 13.234 20.47 13.234 20.9125C13.234 21.4241 11.5593 22.2156 9.04676 22.2156C6.48792 22.2156 4.85956 21.4477 4.85956 20.9125C4.85956 20.4709 5.97633 19.8657 7.81363 19.6794L7.81454 18.7953C5.76728 19.0043 4 19.7258 4 20.9125C4 22.3319 6.53512 23.0988 9.04767 23.0988C11.5602 23.0988 14.0953 22.3546 14.0953 20.9125C14.1181 19.7258 12.3507 19.0043 10.2799 18.7953H10.2799Z" fill="#ABABAB"/>
    <path d="M19.4003 1.79056C15.911 0.837346 13.3984 2.60474 9.49016 1.95412V1.41891C9.49016 1.18628 9.30388 1 9.07125 1C8.83862 1 8.65234 1.18628 8.65234 1.41891V20.5639C8.65234 20.7966 8.83862 20.9828 9.07125 20.9828C9.30388 20.9828 9.49016 20.7966 9.49016 20.5639V9.53718C12.7006 10.1887 15.8172 8.56036 19.2366 9.30456C19.4228 9.35091 19.6091 9.21188 19.6091 9.00197V2.09332C19.6328 1.9543 19.5164 1.83797 19.4001 1.79073L19.4003 1.79056Z" fill="#ABABAB"/>
    <path d="M12.6992 4.25665C12.0713 4.32662 11.7451 4.34934 11.1172 4.34934V5.93136C11.7451 5.93136 12.0713 5.90773 12.6992 5.83867V4.25665Z" fill="#ABABAB"/>
    <path d="M15.8398 5.42003V3.83801C15.2119 3.90798 14.8857 3.95432 14.2578 4.04701V5.62903C14.9093 5.53634 15.2119 5.49 15.8398 5.42003Z" fill="#ABABAB"/>
    <path d="M12.6992 5.83888V7.4209C13.3271 7.35093 13.6533 7.30459 14.2812 7.2119V5.62988C13.6297 5.72257 13.3271 5.76891 12.6992 5.83888Z" fill="#ABABAB"/>
    <path d="M15.8398 7.00191C16.4677 6.93194 16.794 6.90923 17.4219 6.90923V5.32721C16.794 5.32721 16.4677 5.35083 15.8398 5.4199V7.00191Z" fill="#ABABAB"/>
  </svg>
)
