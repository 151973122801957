import { Step, StepButton, Stepper } from "@mui/material";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import React from "react";
import { useT } from "@/hooks/useT";

type StepperFlowProps = {
  activeStep: number;
  setActiveStep: (step: number) => void;
  isStepDisabled: boolean[];
  steps: string[];
  formatLabel: (step: any) => string;
};

export const StepperFlow = ({
  activeStep,
  setActiveStep,
  isStepDisabled,
  steps,
  formatLabel,
}: StepperFlowProps) => {
  const { t } = useT();

  return (
    <Stepper nonLinear activeStep={activeStep}>
      {steps.map((step, index) => {
        return (
          <Step key={step}>
            <StepButton
              onClick={() => setActiveStep(index)}
              disabled={isStepDisabled[index]}
              icon={
                isStepDisabled[index] ? (
                  <DoDisturbOnIcon />
                ) : activeStep !== index && !isStepDisabled[index + 1] ? (
                  <CheckCircleOutlineIcon />
                ) : null
              }
              sx={{
                "& .MuiSvgIcon-root": {
                  color:
                    activeStep !== index && !isStepDisabled[index + 1]
                      ? "success.main"
                      : undefined,
                },
              }}
            >
              {formatLabel(step)}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};
