import React from 'react';

export const ChevronRight = ({className, style, onClick, color}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 10.1995C15.0002 10.3962 14.9585 10.5907 14.8776 10.77C14.7968 10.9492 14.6786 11.1092 14.5309 11.2392L7.82465 17.1391C7.60242 17.3256 7.31605 17.4177 7.02678 17.3958C6.73751 17.3739 6.46829 17.2396 6.2767 17.0218C6.08511 16.804 5.98634 16.5198 6.00152 16.2301C6.01671 15.9404 6.14463 15.6682 6.35795 15.4715L12.2223 10.3125C12.2384 10.2984 12.2513 10.281 12.2602 10.2615C12.269 10.242 12.2736 10.2209 12.2736 10.1995C12.2736 10.1781 12.269 10.1569 12.2602 10.1374C12.2513 10.1179 12.2384 10.1005 12.2223 10.0864L6.35795 4.92741C6.14463 4.7308 6.01671 4.45854 6.00152 4.16883C5.98634 3.87913 6.08511 3.59498 6.2767 3.37715C6.46829 3.15932 6.73751 3.0251 7.02678 3.00318C7.31605 2.98126 7.60242 3.07339 7.82465 3.25986L14.5285 9.15794C14.6765 9.28815 14.7951 9.44838 14.8764 9.62796C14.9577 9.80754 14.9998 10.0024 15 10.1995Z" fill={color || "#111827"}/>

    </svg>
  );
};
