import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const DataControllerRights = () => (
  <Section id="3">
    <Typography variant="h2">
      3. The rights and obligations of the data controller
    </Typography>
    <ol>
      <li>
        The data controller is responsible for ensuring that the processing of
        per-sonal data takes place in compliance with the GDPR (see Article 24
        GDPR), the applicable EU or Member State data protection provisions and
        the Clauses.
      </li>

      <li>
        The data controller has the right and obligation to make decisions about
        the purposes and means of the processing of personal data.
      </li>

      <li>
        The data controller shall be responsible, among other, for ensuring that
        the processing of personal data, which the data processor is instructed
        to per-form, has a legal basis.
      </li>
    </ol>
  </Section>
);
