import React, { memo } from "react";
import { Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useFormStrings } from "../../../hooks/useFormStrings";
import { TitleWithHelper } from "../../TitleWithHelper";

export const FormRadioSelector = memo(
  ({
    name,
    label,
    placeholder,
    help,
    fieldKey,
    textFieldRef,
    inputRef,
    items,
    required,
    ...props
  }) => {
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const inputStrings = useFormStrings({
      name,
      label,
      placeholder,
      help,
      fieldKey,
    });

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <Stack sx={{ width: "100%" }}>
              {inputStrings.help ? (
                <TitleWithHelper
                  title={inputStrings.label}
                  help={inputStrings.help}
                />
              ) : (
                <Typography
                  sx={{
                    fontSize: "20px !important",
                    color: "#111827 !important",
                    mb: "12px !important",
                  }}
                >
                  {inputStrings.label}
                  {required && (
                    <Typography
                      component="span"
                      color="error"
                      fontSize="1.3em"
                      lineHeight="1em"
                    >
                      {" "}
                      *
                    </Typography>
                  )}
                </Typography>
              )}
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name={name}
                value={value}
                {...props}
                inputRef={(e) => {
                  ref(e);
                  if (textFieldRef) {
                    textFieldRef.current = e;
                  }
                }}
                onBlur={onBlur}
              >
                {items?.map((element) => (
                  <Stack direction="row" spacing={0} sx={{ width: "100%" }}>
                    <FormControlLabel
                      value={element.value}
                      control={<Radio />}
                      label={element.label}
                      onChange={() => onChange(element.value)}
                    />
                    {element.tooltip && (
                      <Tooltip title={element.tooltip}>
                        <InfoOutlinedIcon />
                      </Tooltip>
                    )}
                  </Stack>
                ))}
              </RadioGroup>
            </Stack>
          );
        }}
      />
    );
  },
);
FormRadioSelector.displayName = "FormRadioSelector";
