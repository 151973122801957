import React from "react";
import { Section } from "../../layout/Section";
import { DynamicPage } from "../../layout/DynamicPage";
import { useScreen } from "../../hooks/useScreen";

export const Responsive = () => {
  const screen = useScreen();
  return (
    <DynamicPage>
      <Section title={"Responsive Tester"}>
        <div className={"d-f f-ai-c f-jc-c"} style={{ height: "100%" }}>
          <span style={{ fontSize: "50px" }}>{screen}</span>
        </div>
      </Section>
    </DynamicPage>
  );
};
