import React from "react";
import {
  Box,
  BoxProps,
  CircularProgress,
  Divider,
  SxProps,
  Typography,
} from "@mui/material";

export const Section = ({
  children,
  title,
  isLoading = false,
  sx,
  ...props
}: {
  children: React.ReactNode;
  title?: React.ReactNode;
  isLoading?: boolean;
  sx?: SxProps;
  props?: BoxProps;
}) => {
  return (
    <Box
      sx={{
        mt: 4,
        mb: 2,
        ...sx,
      }}
      {...props}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {!!title ? (
            typeof title === "string" ? (
              <Typography variant="h1">{title}</Typography>
            ) : (
              title
            )
          ) : null}
          {!!title && <Divider sx={{ my: 2, mb: 4 }} />}
          {children}
        </>
      )}
    </Box>
  );
};
