import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const SubProcessors = () => {
  const timePeriod = "one week";
  return (
    <Section id="7">
      <Typography variant="h2"> 7. Use of sub-processors</Typography>
      <ol>
        <li>
          The data processor shall meet the requirements specified in Article
          28(2) and (4) GDPR in order to engage another processor (a
          sub-processor).
        </li>

        <li>
          The data processor shall therefore not engage another processor
          (sub-processor) for the fulfilment of the Clauses without the prior
          general written authorisation of the data controller.
        </li>

        <li>
          The data processor has the data controller's general authorisation for
          the engagement of sub-processors. The data processor shall inform in
          writing the data controller of any intended changes concerning the
          addition or replacement of sub-processors at least {timePeriod}
          in advance, thereby giving the data controller the opportunity to
          object to such changes prior to the en-gagement of the concerned
          sub-processor(s). Longer time periods of prior notice for specific
          sub-processing services can be provided in Appendix B. The list of
          sub-processors already authorised by the data controller can be found
          in Appendix B.
        </li>

        <li>
          Where the data processor engages a sub-processor for carrying out
          specific processing activities on behalf of the data controller, the
          same data protec-tion obligations as set out in the Clauses shall be
          imposed on that sub-processor by way of a contract or other legal act
          under EU or Member State law, in particular providing sufficient
          guarantees to implement appropriate technical and organisational
          measures in such a manner that the processing will meet the
          requirements of the Clauses and the GDPR.
          <br />
          The data processor shall therefore be responsible for requiring that
          the sub-processor at least complies with the obligations to which the
          data processor is subject pursuant to the Clauses and the GDPR.
        </li>

        <li>
          A copy of such a sub-processor agreement and subsequent amendments
          shall - at the data controller's request - be submitted to the data
          controller, thereby giving the data controller the opportunity to
          ensure that the same data protection obligations as set out in the
          Clauses are imposed on the sub-processor. Clauses on business related
          issues that do not affect the le-gal data protection content of the
          sub-processor agreement, shall not re-quire submission to the data
          controller.
        </li>

        <li>
          The data processor shall agree a third-party beneficiary clause with
          the sub-processor where - in the event of bankruptcy of the data
          processor - the data controller shall be a third-party beneficiary to
          the sub-processor agree-ment and shall have the right to enforce the
          agreement against the sub-processor engaged by the data processor,
          e.g. enabling the data controller to instruct the sub-processor to
          delete or return the personal data.
        </li>

        <li>
          If the sub-processor does not fulfil his data protection obligations,
          the data processor shall remain fully liable to the data controller as
          regards the fulfil-ment of the obligations of the sub-processor. This
          does not affect the rights of the data subjects under the GDPR - in
          particular those foreseen in Arti-cles 79 and 82 GDPR - against the
          data controller and the data processor, including the sub-processor.
        </li>
      </ol>
    </Section>
  );
};
