import React from 'react';

export const Home = ({className, style, onClick, color}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.8045 9.52852L10.4712 2.19519C10.3462 2.07021 10.1766 2 9.99986 2C9.82308 2 9.65354 2.07021 9.52852 2.19519L2.19519 9.52852C2.10198 9.62176 2.03851 9.74054 2.0128 9.86984C1.98709 9.99914 2.00029 10.1332 2.05074 10.255C2.10119 10.3768 2.18661 10.4809 2.29622 10.5541C2.40583 10.6274 2.53469 10.6665 2.66652 10.6665H3.49986C3.54406 10.6665 3.58645 10.6841 3.61771 10.7153C3.64897 10.7466 3.66652 10.789 3.66652 10.8332V17.3332C3.66652 17.51 3.73676 17.6796 3.86179 17.8046C3.98681 17.9296 4.15638 17.9999 4.33319 17.9999H8.16652C8.21073 17.9999 8.25312 17.9823 8.28438 17.951C8.31563 17.9198 8.33319 17.8774 8.33319 17.8332V14.6665C8.33319 14.2245 8.50879 13.8006 8.82135 13.488C9.13391 13.1755 9.55783 12.9999 9.99986 12.9999C10.4419 12.9999 10.8658 13.1755 11.1784 13.488C11.4909 13.8006 11.6665 14.2245 11.6665 14.6665V17.8332C11.6665 17.8774 11.6841 17.9198 11.7153 17.951C11.7466 17.9823 11.789 17.9999 11.8332 17.9999H15.6665C15.8433 17.9999 16.0129 17.9296 16.1379 17.8046C16.263 17.6796 16.3332 17.51 16.3332 17.3332V10.8332C16.3332 10.789 16.3507 10.7466 16.382 10.7153C16.4133 10.6841 16.4557 10.6665 16.4999 10.6665H17.3332C17.465 10.6665 17.5939 10.6274 17.7035 10.5541C17.8131 10.4809 17.8985 10.3768 17.949 10.255C17.9994 10.1332 18.0126 9.99914 17.9869 9.86984C17.9612 9.74054 17.8977 9.62176 17.8045 9.52852Z" fill={color || "#9A9EB1"}/>

    </svg>
  );
};
