import { Section } from "@/layout/Section";
import {
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export const ApprovedSubProcessors = () => {
  const subProcessors = [
    {
      name: "MongoDB",
      description: "Storage of data",
      region: "AWS Ireland",
    },
    {
      name: "Heroku",
      description: "Operating platform",
      region: "Europe",
    },
    {
      name: "Papertrail",
      description: "Logging",
      region: "Europe, (same as Heroku)",
    },
    {
      name: "Slack",
      description: "Internal communcation",
      type: "internal",
    },
    {
      name: "Trello",
      description: "Customer contact for feature requests",
      type: "internal",
    },
  ];
  return (
    <Section id="appendixB">
      <Stack spacing={2}>
        <Typography variant="h2">
          Appendix B. Authorised sub-processors
        </Typography>
        <Typography variant="h3">B.1 Approved sub-processors</Typography>
        <Typography>
          On commencement of the Clauses, the data controller authorises the
          engagement of the following sub-processors:
        </Typography>
        <Table>
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell>Description of Processing</TableCell>
            <TableCell>Region</TableCell>
          </TableHead>
          <tbody>
            {subProcessors
              .filter((item) => item.type !== "internal")
              .map((item) => (
                <TableRow key={item.name}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.region}</TableCell>
                </TableRow>
              ))}
          </tbody>
        </Table>
        <Typography>
          The data controller shall on the commencement of the Clauses authorise
          the use of the abovementioned sub-processors for the processing
          described for that party. The data processor shall not be entitled -
          without the data controller’s explicit written authorisation - to
          engage a sub-processor for a ‘different’ processing than the one which
          has been agreed upon or have another sub-processor perform the
          described processing.
        </Typography>
        <Typography variant="h3">
          B.2 Prior notice for the authorisation of sub-processors
        </Typography>
      </Stack>
    </Section>
  );
};
