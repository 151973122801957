/**
 * Given an object obj {} and a string of separated for keys by dots
 * like "key1.key2.key3", it will return the object property under obj.key1.key2.key3
 * @param obj
 * @param str
 */
export const accessObjectByDotSeparatedKeys = (obj, str) => {
  return str
    .split(".")
    .reduce((o, i) => (o ?? {})?.[i] ?? (o ?? {})?.[parseInt(i, 10)], obj);
};
