import React from "react";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { DynamicPage } from "../../layout/DynamicPage";

export const E500 = () => {
  const { t } = useT();
  return (
    <DynamicPage>
      <Section title={t("page.500.title")}>
        <p>{t("page.500.body")}</p>
      </Section>
    </DynamicPage>
  );
};
