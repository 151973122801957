import React, {useState} from 'react';
import { isNil } from 'ramda';
import { useStyleParams } from '../../hooks/useStyleParams';
import { Icon } from '../Icon';

export const Label = ({ children, title, htmlFor, touched = false, error,
  required = false, position = 'top', subtitle = null, previous, help, ...rest }) => {
  const {className, style} = useStyleParams(rest)

  const [helpText, setHelpText] = useState("hidden");
  const [lastText, setLastText] = useState("hidden");

  const e = touched ? error : null;
   
  return (
    <label htmlFor={htmlFor} className={`form-label position-${position} ${className}`} style={style}>
      <span className={'label-title'}>
        {title}
        {required && <span className={'pd-l-xs c-danger'}>*</span>}
        {subtitle}
      </span>
      {!isNil(help) ? (
        <div>
          <style>{
                `.hidden {display: none}
                .visible {display: block}`}</style>
<Icon
          style={{float: 'right', position: 'absolute', right: '8px', marginTop: '-30px'}}
          onClick={() => setHelpText((helpText) => (helpText === "hidden" ? "visible" : "hidden"))}
          i={helpText === "hidden" ? "tooltip" : "tooltip-active"}
          size="md"
        />
        <div className={helpText} style={{fontStyle: 'italic', color: 'grey', padding: 10, marginBottom: 5, border: 'solid 1px darkgrey', borderRadius:5}}>
          {help}
        </div>
        </div>
      ) : null}
      <div className={'f-d-c d-f'}>
        {children}
        <span className={'form-label-error'}>{e || <>&nbsp;</>}</span>
      </div>
      {!isNil(previous) ? (
        <div>
        <div style={{float: 'right', position: 'absolute', right: '8px',marginTop: "-23px"}}>
        <p style={{color: "black"}} onClick={() => setLastText((lastText) => (lastText === "hidden" ? "visible" : "hidden"))}>{lastText === "hidden" ? "Show previous text" : "Hide previous text"}
          <Icon
            color="black"
            onClick={() => setLastText((lastText) => (lastText === "hidden" ? "visible" : "hidden"))}
            i={lastText === "hidden" ? "chevron-right" : "chevron-down"}
            size="nm"
          />   
        </p>
      </div>
        <div className={lastText} style={{fontSize: '80%', color: 'grey', backgroundColor: 'lightgrey', border: 'solid 1px darkgrey', borderRadius:5, padding:10, marginTop: '-15px'}}>
          {previous}
        </div>
        </div>
      ) : null}
      <br/>
    </label>
  )
}
