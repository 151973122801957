import React, { useMemo, useRef } from "react";
import { flatten, is, isNil, repeat } from "ramda";
import { E, Grid } from "../../layout/Grid";
import { Link } from "react-router-dom";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

const Block = ({
  status,
  label,
  to,
  inBackground,
  index,
  onClick,
  cellItems,
  hideTooltips,
}) => {
  const tooltipItems = useMemo(
    () =>
      (cellItems ?? []).filter((item) => item.title).map((item) => item.title),
    [cellItems],
  );
  const ref = useRef();

  return (
    <Tooltip
      title={
        !hideTooltips && !!tooltipItems.length ? (
          <Stack>
            {tooltipItems.map((item) => (
              <Typography key={item}>{item}</Typography>
            ))}
          </Stack>
        ) : null
      }
    >
      <Stack
        ref={ref}
        sx={{
          width: "48px",
          height: "48px",
          borderRadius: "2px",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: {
            filler: "rgba(0,0,0,0)",
            success: "success.main",
            warn: "warning.main",
            danger: "error.main",
            avoid: "#F0F0F0",
          }[status],
          cursor: onClick ? "pointer" : "default",
        }}
        onClick={onClick ? () => onClick(index, ref.current, cellItems) : null}
      >
        {!isNil(label) && (
          <Stack
            sx={{
              width: "40px",
              height: "40px",
              bgcolor: "white",
              borderRadius: "50%",
              border: inBackground
                ? "2px dashed var(--shade-04)"
                : "2px solid var(--shade-04)",
              opacity: inBackground ? 0.7 : 1,
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {!isNil(to) ? <Link to={to}>{label}</Link> : label}
          </Stack>
        )}
      </Stack>
    </Tooltip>
  );
};

export const BlockChart = ({
  data,
  xLabel,
  yLabel,
  onBlockSelected,
  hideTooltips,
}) => {
  const isListItems = data.reduce((acc, curr) => {
    return acc && is(Array, curr);
  }, true);

  if (!is(Array, data) || !isListItems) {
    return null;
  }

  const cols = Math.max(...data.map((e) => e.length));
  const rows = data.length;

  const padded = data.map((row) => {
    if (row.length < cols) {
      return row.concat(repeat({ status: "filler" }, cols - row.length));
    }
    return row;
  });

  return (
    <Stack direction="row" spacing={1}>
      <Typography
        sx={{
          writingMode: "tb-rl",
          transform: "rotate(180deg)",
          textAlign: "center",
          pt: "26px",
        }}
      >
        {yLabel}
      </Typography>
      <Stack spacing={1}>
        <Grid
          cols={{
            default: `repeat(${cols}, 48px)`,
            sm: `repeat(${cols}, 44px)`,
          }}
          rows={{
            default: `repeat(${rows}, 48px)`,
            sm: `repeat(${rows}, 44px)`,
          }}
          cg={"xs"}
          rg={"xs"}
        >
          {flatten(padded).map((block, i) => {
            return (
              <Block
                key={i}
                index={i}
                {...block}
                onClick={onBlockSelected}
                hideTooltips={hideTooltips}
              />
            );
          })}
        </Grid>
        <Typography
          sx={{
            textAlign: "center",
          }}
        >
          {xLabel}
        </Typography>
      </Stack>
    </Stack>
  );
};
