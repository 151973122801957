import React from "react";
import { Stack, Box } from "@mui/material";

export const PopoutCard = ({ title, children }) => {
  return (
    <Stack
      sx={{
        maxWidth: {
          xs: "100%",
          md: "600px",
        },
        minWidth: {
          xs: 0,
          md: "400px",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "#111827",
          color: "#fff",
          p: 2,
        }}
      >
        {title}
      </Box>
      <Box p={2}>{children}</Box>
    </Stack>
  );
};
