import React, { useState } from "react";
import { useT } from "../../hooks/useT";
import { Icon } from "../Icon";

const NormalP = ({ children, title, className, style }) => {
  return (
    <p
      className={`paragraph paragraph--normal ${className}`}
      style={style}
      title={title}
    >
      {children}
    </p>
  );
};

const HtmlP = ({ children, title, className, style }) => {
  return (
    <p
      className={`paragraph paragraph--normal ${className}`}
      style={style}
      title={title}
    >
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </p>
  );
};

export const P = ({ variant = "normal", ...args }) => {
  switch (variant) {
    case "normal":
      return <NormalP {...args} />;
    case "read-more":
      return <ReadMoreP {...args} />;
    case "html":
      return <HtmlP {...args} />;
    case "html-read-more":
      return <HtmlReadMoreP {...args} />;

    default:
      return <NormalP {...args} />;
  }
};
