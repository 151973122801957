import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import { release, product } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import {
  Grid,
  Stack,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { StatusSummaryCard } from "../../../base/StatusSummaryCard";
import { SummaryCard } from "../../../base/project/SummaryCard";
import { DataList } from "../../../base/DataList";
import { isNil } from "ramda";
import { CustomAccordion } from "../../../layout/CustomAccordion";
import { DisplayContent } from "../../../base/content/DisplayContent";
import { useFlag } from "@/hooks/useFlag";
import { useToast } from "@/hooks/useToast";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { useMutation } from "react-query";

const InfoSummaryCard = ({ data }) => {
  const { identifier, executive, pm } = data?.attributes ?? {};
  return (
    <SummaryCard>
      <Typography variant="h2" textAlign="center" mb={1}>
        Key Information
      </Typography>
      <DataList
        items={[
          {
            label: "Identifier",
            value: identifier,
          },
          {
            label: "Executive",
            value: executive,
          },
          {
            label: "PM",
            value: pm,
          },
        ].filter((item) => !isNil(item.value))}
      />
    </SummaryCard>
  );
};

export const Release = () => {
  const { id = "" } = useParams();
  const { t } = useT();
  const useFavorite = useFlag("favorites.add");
  const toast = useToast();

  const {
    isLoading,
    data: releaseData = {},
    refetch,
  } = useQ(`release-${id}`, () => release.single({ id }));

  const { isLoading: isLoadingProduct, data: productData = {} } = useQ(
    `product-${id}`,
    () => product.single({ id: releaseData?.product?.id }),
    {
      enabled: !!releaseData?.product?.id,
    },
  );

  const { name = "", description = "", status = "" } = releaseData;

  const [isFavorite, setFavorite] = useState(false);

  const addFavorite = useMutation(release.addFavorite, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Release added to favorites");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error adding release to favorites");
    },
  });

  const removeFavorite = useMutation(release.removeFavorite, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Release removed from favorites");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error removing release from favorites");
    },
  });

  const handleFavoriteToggle = useCallback(() => {
    if (useFavorite) {
      if (isFavorite) {
        removeFavorite.mutate({ id });
      } else {
        addFavorite.mutate({ id });
      }
    } else {
      setFavorite(!isFavorite);
    }
  }, [isFavorite, useFavorite, addFavorite, removeFavorite, id]);

  useEffect(() => {
    if (!useFavorite) {
      return;
    }
    const isFavorite = releaseData?.isFavorite;
    setFavorite(isFavorite);
  }, [releaseData?.isFavorite, useFavorite]);

  return (
    <Page isLoading={isLoading || isLoadingProduct} title={name}>
      <Section
        title={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {`${productData.name} / ${name}`}
            </Typography>
            <IconButton onClick={handleFavoriteToggle}>
              {isFavorite ? <StarOutlinedIcon /> : <StarBorderOutlinedIcon />}
            </IconButton>
          </Stack>
        }
      >
        <Stack direction="row" spacing={6} px={6}>
          <StatusSummaryCard data={releaseData} type={"releases"} />
          <InfoSummaryCard data={releaseData} />
        </Stack>
        <Stack mt={3} spacing={2}>
          <Typography variant="h2">
            Release plan {`${productData.name} / ${name}`}
          </Typography>
          <CustomAccordion
            rows={[
              ...[
                "description",
                "prerequisite",
                "externalDependencies",
                "assumptions",
                "stageApproach",
                "lessonsIncorporated",
                "monitorAndControl",
              ].map((item) =>
                releaseData?.[item]
                  ? {
                      expanded: false,
                      expandDisabled: true,
                      columns: [
                        {
                          title: {
                            description: t(
                              "project.plans.stage.description.title",
                            ),
                            prerequisite: t(
                              "project.plans.stage.prerequisite.title",
                            ),
                            externalDependencies: t(
                              "project.plans.stage.externalDependencies.title",
                            ),
                            assumptions: t(
                              "project.plans.stage.assumptions.title",
                            ),
                            stageApproach: t(
                              "project.plans.stage.stageApproach.title",
                            ),
                            lessonsIncorporated: t(
                              "project.plans.stage.lessonsIncorporated.title",
                            ),
                            monitorAndControl: t(
                              "project.plans.stage.monitorAndControl.title",
                            ),
                          }[item],
                          flex: 1,
                        },
                        {
                          content: (
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: releaseData?.[item],
                              }}
                            />
                          ),
                          flex: 5,
                        },
                      ],
                    }
                  : null,
              ),
              (releaseData?.budget ?? []).some(
                (budget) =>
                  budget?.cost || budget?.tolerance || budget?.comment,
              )
                ? {
                    expanded: false,
                    expandDisabled: true,
                    columns: [
                      {
                        title: t("project.plans.schedule.details.budget"),
                        flex: 1,
                      },
                      {
                        content: (
                          <Box>
                            <CustomAccordion
                              header={{
                                sx: {
                                  backgroundColor: "#F9FAFB",
                                },
                                columns: [
                                  {
                                    title: t(
                                      "project.plans.stage.costElement.title",
                                    ),
                                    flex: 1,
                                  },
                                  {
                                    title: t("project.plans.stage.cost.title"),
                                    flex: 1,
                                  },
                                  {
                                    title: t(
                                      "project.plans.schedule.headings.tolerance",
                                    ),
                                    flex: 1,
                                  },
                                  {
                                    title: t(
                                      "project.plans.schedule.headings.comments",
                                    ),
                                    flex: 1,
                                  },
                                ],
                              }}
                              rows={releaseData?.budget?.map((item) => ({
                                columns: [
                                  {
                                    title: {
                                      hours: t(
                                        "project.plans.schedule.headings.hours",
                                      ),
                                      expences: t(
                                        "project.plans.schedule.headings.expenses",
                                      ),
                                      investment: t(
                                        "project.plans.schedule.headings.investments",
                                      ),
                                      total: t(
                                        "project.plans.schedule.headings.total",
                                      ),
                                    }[item?.id],
                                    flex: 1,
                                  },
                                  {
                                    title: item?.cost,
                                    flex: 1,
                                  },
                                  {
                                    title: item?.tolerance,
                                    flex: 1,
                                  },
                                  {
                                    title: item?.comment,
                                    flex: 1,
                                  },
                                ],
                              }))}
                            />
                            <Typography mt={2}>
                              {t(
                                "project.plans.schedule.details.costIsShownIn",
                              )}
                              : {productData.currency}
                            </Typography>
                          </Box>
                        ),
                        flex: 5,
                      },
                    ],
                  }
                : null,
              {
                expanded: false,
                expandDisabled: true,
                columns: [
                  {
                    title: t("project.plans.schedule.details.schedule"),
                    flex: 1,
                  },
                  {
                    content: (
                      <Stack
                        direction={"row"}
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Typography>
                          {t("project.plans.schedule.details.startDate")}:{" "}
                          {releaseData?.time?.start}
                        </Typography>
                        <Typography>
                          {t("project.plans.schedule.details.endDate")}:{" "}
                          {releaseData?.time?.end}
                        </Typography>
                        <Typography>
                          {t("project.plans.schedule.headings.tolerance")}:{" "}
                          {releaseData?.timeTolerance}{" "}
                          {releaseData?.timeToleranceUnit
                            ? t(
                                "project.plans.stage.timeTolerance.units." +
                                  releaseData?.timeToleranceUnit,
                              ).toLowerCase()
                            : null}
                        </Typography>
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: releaseData?.timeComments
                              ? `${t(
                                  "project.plans.schedule.details.comments",
                                )}: ${releaseData?.timeComments}`
                              : "",
                          }}
                        />
                      </Stack>
                    ),
                    flex: 5,
                  },
                ],
              },
            ]}
          />
        </Stack>
      </Section>
    </Page>
  );
};
