import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const Transfer = () => (
  <Section id="8">
    <Typography variant="h2">
      8. Transfer of data to third countries or international organisations
    </Typography>
    <ol>
      <li>
        Any transfer of personal data to third countries or international
        organisations by the data processor shall only occur on the basis of
        documented instruc-tions from the data controller and shall always take
        place in compliance with Chapter V GDPR.{" "}
      </li>

      <li>
        In case transfers to third countries or international organisations,
        which the data processor has not been instructed to perform by the data
        controller, is required under EU or Member State law to which the data
        processor is sub-ject, the data processor shall inform the data
        controller of that legal require-ment prior to processing unless that
        law prohibits such information on im-portant grounds of public interest.
      </li>

      <li>
        Without documented instructions from the data controller, the data
        processor therefore cannot within the framework of the Clauses:
        <ol type="a">
          <li>
            transfer personal data to a data controller or a data processor in a
            third country or in an international organization
          </li>

          <li>
            transfer the processing of personal data to a sub-processor in a
            third country{" "}
          </li>

          <li>
            have the personal data processed in by the data processor in a third
            country
          </li>
        </ol>
      </li>

      <li>
        The data controller's instructions regarding the transfer of personal
        data to a third country including, if applicable, the transfer tool
        under Chapter V GDPR on which they are based, shall be set out in
        Appendix C.6.
      </li>

      <li>
        The Clauses shall not be confused with standard data protection clauses
        within the meaning of Article 46(2)(c) and (d) GDPR, and the Clauses
        cannot be relied upon by the parties as a transfer tool under Chapter V
        GDPR.
      </li>
    </ol>
  </Section>
);
