import { Page } from "@/layout/Page";
import {
  PDFViewer,
  Page as PDFPage,
  Text,
  View,
  Document,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Button, Stack } from "@mui/material";
import { useQ } from "@/hooks/useQ";
import { project } from "@/queries";
import { useT } from "@/hooks/useT";
import { useParams } from "react-router-dom";
import { DatePretty } from "@/base/DatePretty";
import Html from "react-pdf-html";
import { styles, PDFKit } from "@/base/pdf";
import moment from "moment/moment";
import DownloadIcon from "@mui/icons-material/Download";
import React from "react";

export const BriefPdfDocument = ({
  t,
  projectData,
  roleData = [],
  title = true,
  businessCase,
}) => {
  const { H1, H2, H3, H4, BODY, HR, HTML } = PDFKit;

  const categories = [
    "projectBoard",
    "projectAssurance",
    "projectManagement",
    "deliveryTeam",
  ];

  const roleOrder = [
    "executive",
    "seniorUser",
    "seniorSupplier",
    "businessAssurance",
    "userAssurance",
    "supplierAssurance",
    "projectManager",
    "projectSupport",
    "changeAuthority",
    "stakeholder",
    "teamManager",
    "teamMember",
  ];

  const additionalRoles = [];

  roleData?.forEach((person) => {
    if (person.additionalRoles?.length > 0) {
      person.additionalRoles.forEach((e) => {
        let additionalPerson = {};
        additionalPerson.name = person.name;
        additionalPerson.email = person.email;
        additionalPerson.id = person.id;
        additionalPerson.role = e.role;
        additionalPerson.details = e.details;
        additionalRoles.push(additionalPerson);
      });
    }
  });

  function lowecaseFirstLetter(string) {
    return (string ?? "").charAt(0).toLowerCase() + (string ?? "").slice(1);
  }

  const getCategory = (role) =>
    categories.filter(
      (category) =>
        !t(
          "project.organization." +
            category +
            ".roles." +
            (role !== "Excecutive"
              ? lowecaseFirstLetter(role).replace(" ", "")
              : "executive") +
            ".title",
        ).includes("project.organization."),
    )[0];
  const roles = [...roleData, ...additionalRoles];

  const sortedRoles = !roles
    ? []
    : roles.filter((item) =>
        roleOrder.includes(
          item.role !== "Excecutive"
            ? lowecaseFirstLetter(item.role).replace(" ", "")
            : "executive",
        ),
      );

  if (sortedRoles !== undefined) {
    sortedRoles.sort((a, b) => a.name.localeCompare(b.name));
    sortedRoles.sort(function (a, b) {
      return (
        roleOrder.indexOf(
          a.role !== "Excecutive"
            ? lowecaseFirstLetter(a.role).replace(" ", "")
            : "executive",
        ) -
        roleOrder.indexOf(
          b.role !== "Excecutive"
            ? lowecaseFirstLetter(b.role).replace(" ", "")
            : "executive",
        )
      );
    });
  }

  return (
    <>
      {title ? (
        <>
          <H1>Project Brief</H1>
          <H2>
            {projectData?.name} -&nbsp;
            <DatePretty
              format={"ll"}
              date={projectData?.date ? projectData.date : new Date()}
            />
          </H2>
        </>
      ) : null}
      {["background", "timing", "bigPicture"].some(
        (key) => !!projectData?.[key],
      ) && (
        <View style={styles.section}>
          <H3>{t("project.projectBrief.definition.title")}</H3>
          <HR />
          {["background", "timing", "bigPicture"]
            .filter((key) => !!projectData?.[key])
            .map((key) => (
              <Html
                style={{
                  fontSize: 12,
                }}
                key={key}
              >
                {projectData?.[key]}
              </Html>
            ))}
        </View>
      )}
      {(!!projectData?.objectivesSummary && !!projectData?.objectivesSummary) ||
      projectData?.benefitsDescription ? (
        <View style={styles.section}>
          <H3>{t("projectForm.steps.objectives.title")}</H3>
          <HR />
          {!!projectData?.benefitsDescription && (
            <HTML>{projectData?.benefitsDescription}</HTML>
          )}
          {!!projectData?.objectivesSummary &&
          !!projectData?.objectivesSummary ? (
            <HTML>
              {`<table>
              <thead>
                <tr style="background-color: #f3f4f6; border: 1px solid #e5e7db;">
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.projectBrief.definition.objectivesForm.performanceAspect",
                  )}</th>
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.projectBrief.definition.objectivesForm.objectiveDescription",
                  )}</th>
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.projectBrief.definition.objectivesForm.tolerance",
                  )}</th>
                </tr>
              </thead>
              <tbody>
                ${(projectData?.objectivesSummary ?? [])
                  .map(
                    (objective) =>
                      `<tr>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      !t(`generic.status.${objective.id}`).includes(
                        "generic.status.",
                      )
                        ? t(`generic.status.${objective.id}`)
                        : objective.name ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      objective.description ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      objective.tolerance ?? ""
                    }</td>
                  </tr>`,
                  )
                  .join("")}
              </tbody>
            </table>`}
            </HTML>
          ) : null}
        </View>
      ) : null}

      {(!!projectData?.projectScope && !!projectData?.projectScope) ||
      [
        "constraints",
        "assumptions",
        "stakeholderDescription",
        "interfaces",
      ].some((key) => !!projectData?.[key]) ? (
        <View style={styles.section}>
          <H3>{t("projectForm.steps.scope.title")}</H3>
          <HR />
          <HTML>
            {`
            <ul>
              ${(projectData?.projectScope ?? [])
                .map((scope) => `<li>${scope.text}</li>`)
                .join("")}
            </ul>
            `}
          </HTML>
          {[
            "constraints",
            "assumptions",
            "stakeholderDescription",
            "interfaces",
          ].some((key) => !!projectData?.[key]) ? (
            <>
              <H3>{t("projectForm.editProject.fields.constraints.title")}</H3>
              <HTML>{projectData?.constraints}</HTML>
              <H3>{t("projectForm.editProject.fields.assumptions.title")}</H3>
              <HTML>{projectData?.assumptions}</HTML>
              <H3>
                {t(
                  "projectForm.editProject.fields.stakeholderDescription.title",
                )}
              </H3>
              <HTML>{projectData?.stakeholderDescription}</HTML>
              <H3>{t("projectForm.editProject.fields.interfaces.title")}</H3>
              <HTML>{projectData?.interfaces}</HTML>
            </>
          ) : null}
        </View>
      ) : null}
      <View style={styles.section}>
        {[
          "reasons",
          "doNothing",
          "doMinimal",
          "doSomething",
          "executiveSummary",
          "investmentAppraisal",
          "risks",
        ].some((key) => !!businessCase?.[key]) && (
          <>
            <H3>{t("project.businessCase.title")}</H3>
            <HR />
          </>
        )}

        {["reasons", "doNothing", "doMinimal", "doSomething"].map((key) =>
          !!businessCase?.[key] && businessCase?.[key] !== "" ? (
            <>
              <H3>
                {t(`project.businessCase.addEditForm.fields.${key}.title`)}
              </H3>
              <Html
                style={{
                  fontSize: 12,
                }}
                key={key}
              >
                {businessCase?.[key]}
              </Html>
            </>
          ) : null,
        )}
        {!!businessCase?.benefits && (
          <>
            <H3>{t("project.projectBrief.businessCase.title")}</H3>
            <HTML>
              {`<table>
              <thead>
                <tr style="background-color: #f3f4f6; border: 1px solid #e5e7db;">
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.businessCase.addEditForm.fields.benefits.title.title",
                  )}</th>
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.businessCase.addEditForm.fields.benefits.baseline.title",
                  )}</th>
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.businessCase.addEditForm.fields.benefits.goal.title",
                  )}</th>
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.businessCase.addEditForm.fields.benefits.accountable.title",
                  )}
                </tr>
              </thead>
              <tbody>
                ${(businessCase.benefits ?? [])
                  .map(
                    (benefit) =>
                      `<tr>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      benefit.title ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      benefit.baseline ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      benefit.goal ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      benefit.accountable ?? ""
                    }</td>
                  </tr>`,
                  )
                  .join("")}
              </tbody>
            </table>`}
            </HTML>
          </>
        )}
        {["executiveSummary", "investmentAppraisal", "risks"].map((key) =>
          !!businessCase?.[key] && businessCase?.[key] !== "" ? (
            <>
              <H3>
                {t(`project.businessCase.addEditForm.fields.${key}.title`)}
              </H3>
              <Html
                style={{
                  fontSize: 12,
                }}
                key={key}
              >
                {businessCase?.[key]}
              </Html>
            </>
          ) : null,
        )}
      </View>
      {!!projectData?.projectScope && !!projectData?.projectScope ? (
        <View style={styles.section}>
          <H3>{t("project.quality.ppd.title")}</H3>
          <HR />
          <HTML>
            {`
            
              ${(projectData?.projectScope ?? [])
                .map(
                  (scope) => `<h3 style="margin:0px;padding:0px">${
                    scope.text
                  }</h3>
                ${
                  scope?.items
                    ? scope?.items
                        .map(
                          (
                            item,
                          ) => `<div style="margin-left:10px;padding:0px">${
                            item?.output
                          }</div>
                ${[
                  "qualityExpectation",
                  "acceptanceCriteria",
                  "acceptanceMethod",
                ]
                  .map(
                    (key) =>
                      `<div style="margin-top:-15px;margin-left:20px">
                         <h4>${t(
                           `project.quality.ppd.fields.${key}.title`,
                         )}</h4>
                       <div style="font-size: 12px;margin-top:-20px" key=${key}>
                ${item?.[key]}
              </div>
              </div>
                  `,
                  )
                  .join("")}
                `,
                        )
                        .join("")
                    : "No outputs defined."
                }
                `,
                )
                .join("")}
            
            `}
          </HTML>
        </View>
      ) : null}

      {!!projectData?.approach &&
        ["methods", "technology", "rollOut"].some(
          (key) =>
            projectData?.approach?.[key] !== "" &&
            !!projectData?.approach?.[key],
        ) && (
          <View style={styles.section}>
            <H3>{t("project.projectBrief.projectApproach.title")}</H3>
            <HR />
            {["methods", "technology", "rollOut"]
              .filter((key) => !!projectData?.approach?.[key])
              .map((key) => (
                <>
                  <H3>{t(`project.plans.approach.fields.${key}.title`)}</H3>
                  <Html
                    style={{
                      fontSize: 12,
                    }}
                    key={key}
                  >
                    {projectData?.approach?.[key]}
                  </Html>
                </>
              ))}
          </View>
        )}
      {!!sortedRoles?.length && (
        <View style={styles.section}>
          <H3>{t("project.projectBrief.organization.title")}</H3>
          <HR />
          <HTML>
            {`<table>
              <thead>
                <tr style="background-color: #f3f4f6; border: 1px solid #e5e7db;">
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.organization.headers.name",
                  )}</th>
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.organization.headers.role",
                  )}</th>
                  <th style="padding: 16px; text-transform: uppercase; font-size: 10px; color: #6b7280">${t(
                    "project.projectBrief.organization.form.description",
                  )}</th>
                </tr>
              </thead>
              <tbody>
                ${(sortedRoles ?? [])
                  .map(
                    (role) =>
                      `<tr>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      role.name ?? ""
                    }</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${t(
                      "project.organization." +
                        getCategory(role.role) +
                        ".roles." +
                        (role.role !== "Excecutive"
                          ? lowecaseFirstLetter(role.role).replace(" ", "")
                          : "executive") +
                        ".title",
                    )}</td>
                    <td style="padding: 16px; border: 1px solid #e5e7db;">${
                      role.details ?? ""
                    }</td>
                  </tr>`,
                  )
                  .join("")}
              </tbody>
            </table>`}
          </HTML>
        </View>
      )}
    </>
  );
};

function getVersion(data, version) {
  return !!data?.briefHistory
    ? data.briefHistory.find((x) => `1.${x.version}` === version)
    : null;
}

export const BriefPdf = () => {
  const { t } = useT();
  const { id = "", version = null } = useParams();

  const {
    isLoading,
    data: projectData = [],
    refetch: refetchProject,
  } = useQ(`project-${id}`, () => project.single({ id }));

  const { data: roles = [], refetch } = useQ(`project-${id}-roles`, () =>
    project.roles({ id }),
  );

  const { data: businessCaseData = {} } = useQ(
    `project-${projectData?.id}-businessCase`,
    () => project.businessCase({ id: projectData?.id }),
  );

  const roleOrder = [
    "executive",
    "seniorUser",
    "seniorSupplier",
    "businessAssurance",
    "userAssurance",
    "supplierAssurance",
    "projectManager",
    "projectSupport",
    "changeAuthority",
    "stakeholder",
    "teamManager",
    "teamMember",
  ];
  const sortedRoles = !roles
    ? []
    : roles.filter((item) => roleOrder.includes(item.role));
  if (sortedRoles !== undefined) {
    sortedRoles.sort((a, b) => a.name.localeCompare(b.name));
    sortedRoles.sort(function (a, b) {
      return roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role);
    });
  }

  const historicReport = getVersion(projectData, version);
  const historicProjectData = {
    name: projectData?.name,
    date: historicReport?.date,
    background: historicReport?.definition?.background?.initiated,
    timing: historicReport?.definition?.background?.timing,
    bigPicture: historicReport?.definition?.background?.bigPicture,
    benefitsDescription: historicReport?.definition?.objective?.benefits,
    objectivesSummary: historicReport?.definition?.objective?.summary,
    projectScope:
      historicReport?.projectProductDescription !== undefined
        ? historicReport?.projectProductDescription
        : historicReport?.definition?.scope?.items,
    constraints: historicReport?.definition?.constraints,
    assumptions: historicReport?.definition?.assumptions,
    stakeholderDescription: historicReport?.definition?.stakeholders,
    approach: historicReport?.approach,
    communicationApproach: historicReport?.communicationManagementApproach,
  };

  const data = version ? historicProjectData : projectData;
  const businessCase = version ? historicReport.businessCase : businessCaseData;
  return (
    <Page title={"PID PDF"} isLoading={!data}>
      <Stack py={2} height="100%" spacing={2}>
        {!!data && (
          <PDFDownloadLink
            document={
              <Document>
                <PDFPage size="A4" style={styles.page}>
                  <BriefPdfDocument
                    t={t}
                    projectData={data}
                    roleData={version ? historicReport?.organization : roles}
                    businessCase={businessCase}
                  />
                </PDFPage>
              </Document>
            }
            fileName={`brief_${data?.name?.replace?.(/ /g, "")}_${moment(
              data?.date ?? new Date(),
            ).format("YYYY-MM-DD")}`}
          >
            <Button variant="contained" endIcon={<DownloadIcon />}>
              Download PDF
            </Button>
          </PDFDownloadLink>
        )}
        <PDFViewer
          style={{
            width: "100%",
            height: "100%",
          }}
          showToolbar={false}
        >
          <Document>
            <PDFPage size="A4" style={styles.page}>
              <BriefPdfDocument
                t={t}
                projectData={data}
                roleData={version ? historicReport?.organization : roles}
                businessCase={businessCase}
              />
            </PDFPage>
          </Document>
        </PDFViewer>
      </Stack>
    </Page>
  );
};
