import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import { project } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { HtmlReadMoreTypography } from "../../../base/typography/HtmlReadMoreTypography";
import qualityApproachClip from "../../../assets/cliparts/qualityApproach.svg";
import { useToast } from "../../../hooks/useToast";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { DisplayContent } from "../../../base/content/DisplayContent";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";

const ProjectApproachForm = ({ data, projectId, onClose, onSuccess }) => {
  const { t } = useT();
  const toast = useToast();

  const schema = yup.object().shape({
    methods: yup.string().trim(),
    resources: yup.string().trim(),
    technology: yup.string().trim(),
    rollOut: yup.string().trim(),
    other: yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      methods: data?.methods ?? "",
      resources: data?.resources ?? "",
      technology: data?.technology ?? "",
      rollOut: data?.rollOut ?? "",
      other: data?.other ?? "",
    },
  });

  const { handleSubmit, reset } = formData;

  useEffect(() => {
    reset({
      methods: data?.methods ?? "",
      resources: data?.resources ?? "",
      technology: data?.technology ?? "",
      rollOut: data?.rollOut ?? "",
      other: data?.other ?? "",
    });
  }, [data, reset]);

  const addApproach = useMutation(project.addApproach, {
    onSuccess: (data) => {
      toast.success(t("project.plans.approach.successfulCreate"));
    },
    onError: () => {
      toast.error(t("project.plans.approach.createFailed"));
    },
  });

  const updateApproach = useMutation(project.updateApproach, {
    onSuccess: (data) => {
      toast.success(t("project.plans.approach.successfulUpdate"));
    },
    onError: () => {
      toast.error(t("project.plans.approach.updateFailed"));
    },
  });

  const handleCreateOrUpdateApproach = useMemo(
    () =>
      handleSubmit((formData) => {
        (data ? updateApproach.mutate : addApproach.mutate)(
          {
            projectId,
            approach: formData,
          },
          {
            onSuccess: () => {
              onClose();
              onSuccess();
            },
          },
        );
      }),
    [handleSubmit, projectId, updateApproach, addApproach, data],
  );

  return (
    <FormDataProvider formKey="project.plans.approach.fields" {...formData}>
      <form onSubmit={handleCreateOrUpdateApproach}>
        <Stack spacing={2}>
          {["methods", "resources", "technology", "rollOut", "other"].map(
            (fieldName) => (
              <FormRichTextField name={fieldName} />
            ),
          )}
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="contained" color="secondary" onClick={onClose}>
              {t("generic.button.cancel")}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {t(
                !data
                  ? "project.plans.approach.actions.createNew"
                  : "project.plans.approach.actions.update",
              )}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormDataProvider>
  );
};

export const PlansApproach = () => {
  const { t } = useT();
  const { id = "" } = useParams();

  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );
  const {
    isLoading: isLoadingApproach,
    data: approachData = {},
    refetch: refetchApproach,
  } = useQ(`project-${id}-approach`, () => project.approach({ id }));

  const { title } = data;

  const [displayForm, showForm] = useState(false);

  const approach = approachData?.approach;

  return (
    <Page isLoading={isLoading || isLoadingApproach} title={title}>
      <Section title={t("project.plans.title")}>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Box flex={3}>
            <Typography variant="h2">
              {t("project.plans.approach.title")}
            </Typography>
            <Typography
              dangerouslySetInnerHTML={{
                __html: t("project.plans.approach.description"),
              }}
            />
          </Box>
          <Box flex={1} textAlign="right">
            <img
              src={qualityApproachClip}
              alt={"Project Approach Method Clipart"}
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Stack>
        {displayForm ? (
          <ProjectApproachForm
            data={approach}
            projectId={id}
            onClose={() => showForm(false)}
            onSuccess={() => refetchApproach()}
          />
        ) : !!approach ? (
          <Stack spacing={2}>
            <Button color="secondary" onClick={() => showForm(true)}>
              {t("generic.button.edit")}
            </Button>
            <DisplayContent
              area="plans.approach.fields"
              data={approach ?? {}}
              fields={[
                "methods",
                "resources",
                "technology",
                "rollOut",
                "other",
              ]}
            />
          </Stack>
        ) : (
          <Paper sx={{ p: 3 }}>
            <Stack spacing={2}>
              <Typography>{t("project.plans.approach.noContent")}</Typography>
              <Button
                onClick={() => showForm((v) => !v)}
                color="secondary"
                endIcon={<ArrowCircleRightOutlinedIcon />}
              >
                {t("project.plans.approach.actions.createNew")}
              </Button>
            </Stack>
          </Paper>
        )}
      </Section>
    </Page>
  );
};
