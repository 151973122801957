import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthenticationContext } from "@/auth/AuthenticationProvider";
import { uris } from "@/config/nav";
import { useNavigate } from "react-router-dom";
import { useUnleashContext } from "@unleash/proxy-client-react";
import { Box, Stack } from "@mui/material";
import { StepperFlow } from "@/base/form/mui/StepperFlow";
import { useForm } from "react-hook-form";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { FormSelect } from "@/base/form/mui/FormSelect";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";

export const LinkSignupForm = () => {
  const [auth, dispatch] = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const updateContext = useUnleashContext();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (auth.authenticated) {
      console.log(
        "[LoginForm] redirect to home if user information is available",
      );
      users
        .me()
        .then((result) => {
          console.log(
            "[INFO] Retrieved user information, login user: " +
              JSON.stringify(result),
          );
          dispatch({ type: "LOGIN", payload: result });
          navigate(uris.home);
          updateContext({
            userId: result.id,
            hostName: window.location.hostname,
            account: result.currentTenant,
          });
        })
        .catch((error) => {
          console.log(
            "[WARN] Not able to access user information, log out",
            error,
          );
          dispatch({ type: "LOGOUT" });
        });
    }
  }, [auth]);

  const formData = useForm({
    defaultValues: {
      name: "",
      email: "",
      role: "",
      phone: "",
    },
  });
  const {
    formState: { isDirty },
  } = formData;

  const steps = useMemo(() => ["Name", "Email", "Role", "Phone"], []);

  const triggerSubmit = () => null;

  return (
    <Box>
      <FormDataProvider formKey="signupForm.fields" {...formData}>
        <form onSubmit={triggerSubmit}>
          <Stack spacing={3}>
            <StepperFlow
              steps={steps}
              activeStep={activeStep}
              isStepDisabled={[]}
              setActiveStep={setActiveStep}
              formatLabel={(step) => step}
            />
            {activeStep === 0 && (
              <Stack spacing={2}>
                <FormTextField name="name" />
              </Stack>
            )}
            {activeStep === 1 && (
              <Stack spacing={2}>
                <FormTextField name="email" />
              </Stack>
            )}
            {activeStep === 2 && (
              <Stack spacing={2}>
                <FormTextField name="role" />
              </Stack>
            )}
            {activeStep === 3 && (
              <Stack spacing={2}>
                <FormTextField name="phone" />
              </Stack>
            )}
            <StepperNavigator
              isDirty={isDirty}
              hideCancelButton
              showPreviousButton={activeStep > 0}
              setActiveStep={setActiveStep}
              submitButtonLabel="Submit"
            />
          </Stack>
        </form>
      </FormDataProvider>
    </Box>
  );
};
