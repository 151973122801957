import React, { useState } from "react";
import { useT } from "../../hooks/useT";
import { Button, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const HtmlReadMoreTypography = ({ children, ...props }) => {
  const { t } = useT();
  const [more, setMore] = useState(false);

  const doc = new DOMParser().parseFromString(children, "text/html");
  const text = doc.body.textContent || "";
  const isLong = text.length > 150;

  const preview = (text) => {
    if (isLong) {
      const cut = text.indexOf(" ", 100);
      return `${text.substring(0, cut)} ...`;
    }
    return text;
  };

  if (!isLong) {
    return (
      <Typography
        className="ql-editor"
        sx={{ minHeight: "1em" }}
        dangerouslySetInnerHTML={{ __html: children }}
        {...props}
      />
    );
  }

  return (
    <Stack alignItems="flex-start" spacing={2}>
      <Typography
        className="ql-editor"
        sx={{
          fontStyle: "italic",
          minHeight: "1em",
          ...(props.sx ?? {}),
        }}
        dangerouslySetInnerHTML={{ __html: more ? children : preview(text) }}
        {...props}
      />
      <Button
        variant="text"
        endIcon={!more ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        onClick={() => setMore((v) => !v)}
      >
        {more ? t("generic.button.read_less") : t("generic.button.read_more")}{" "}
      </Button>
    </Stack>
  );
};
