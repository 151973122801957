import React from 'react';

export const Programme = ({className, style, onClick}) => (
    <svg className={className} style={style} onClick={onClick}
    width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.84375 0.621582H20.0937V16.8716H3.84375V0.621582Z" stroke="#64D3D0" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.5937 19.3716H2.59375C2.26223 19.3716 1.94429 19.2399 1.70987 19.0055C1.47545 18.771 1.34375 18.4531 1.34375 18.1216V3.12158" stroke="#64D3D0" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.59375 5.62158H16.3437" stroke="#64D3D0" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.59375 8.12158H16.3437" stroke="#64D3D0" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.59375 10.6216H13.8437" stroke="#64D3D0" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
