import React, { useEffect, useState } from "react";
import { Spinner } from "../../base/Spinner";
import { useQuery } from "react-query";
import { isEmpty, propOr } from "ramda";
import { client } from "../../axios";
import request from "superagent";

import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import {
  DataTable,
  DataTableAutoRows,
  DataTableHeading,
  DataTableHierarchy,
  DropZone,
} from "../../base/table/DataTable";
import { Stat } from "../../base/Stat";
import { Icon } from "../../base/Icon";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormTextField } from "../../base/form/mui/FormTextField";
import {
  Button,
  CircularProgress,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomAccordion } from "../../layout/CustomAccordion";
import { project, projectType } from "../../queries";
import { useQ } from "../../hooks/useQ";

const ConnectAction = ({ row, products, projects, onClick }) => {
  const productName = row.Product;
  const projectId = row.ID;
  const createRelease = row["Activity Type"] === "Release";
  const alreadyConnected =
    products !== undefined &&
    products.find(
      (item) =>
        item.name === productName &&
        item.roadmap !== undefined &&
        item.roadmap.find((release) => release.id === projectId) !== undefined,
    ) !== undefined;

  const productExists =
    products !== undefined &&
    products.find((item) => item.name === productName) !== undefined;
  const projectExists =
    projects !== undefined &&
    projects.find((item) => item.id === projectId) !== undefined;
  return (
    <div>
      {alreadyConnected ? (
        <span>
          <Stat status="success" /> Already linked to product
        </span>
      ) : productExists ? (
        createRelease ? (
          projectExists ? (
            <span>
              <Stat status="danger" /> Release not found in roadmap
            </span>
          ) : (
            <>
              <Button style={{ display: "none" }}>
                Create release and add to product
              </Button>
              <span>
                <Stat status="danger" /> Release not found
              </span>
            </>
          )
        ) : projectExists ? (
          <Button onClick={onClick}>Connect product</Button>
        ) : (
          <span>
            <Stat status="warn" /> Project does not exist
          </span>
        )
      ) : (
        <span>
          <Stat status="danger" /> Product not found
        </span>
      )}
    </div>
  );
};

const LoadingStatus = ({ items, type, isLoading }) => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={1}
  >
    <Typography sx={{ fontWeight: "bold" }}>Loaded {type}:</Typography>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
        <Stat status={isLoading || items === undefined ? "warn" : "success"} />
        <Typography>
          {items !== undefined
            ? items.length
            : "Could not load existing " + type}
        </Typography>
      </>
    )}
  </Stack>
);

const dataTypes = [
  { heading: "ID", dataType: "id" },
  { heading: "ID Name", dataType: "name" },
  { heading: "Portfolio", dataType: "portfolio.name" },
  { heading: "Product", dataType: "product.name" },
  { heading: "Activity Type", dataType: "type" },
  {
    heading: "Department",
    dataType: "configuration.department.name",
  },
  {
    heading: "Location",
    dataType: "configuration.location.name",
  },
  {
    heading: "Project Type",
    dataType: "configuration.projectType.name",
  },
  {
    heading: "Project Category",
    dataType: "configuration.category.name",
  },
  { heading: "Currency", dataType: "configuration.currency.id" },
];

const ImportSettings = ({ headers }) => {
  const [displaySettings, showSettings] = useState(false);
  return (
    <Stack spacing={2} sx={{ mt: 3 }}>
      <FormControlLabel
        control={<Switch sx={{ mx: 1 }} checked={displaySettings} />}
        label="Show Import settings"
        onChange={() => {
          showSettings((s) => !s);
        }}
      />
      {displaySettings && (
        <CustomAccordion
          tableView
          header={{
            columns: [
              {
                title: "Column title",
                flex: 1,
              },
              {
                title: "Data type",
                flex: 2,
              },
            ],
          }}
          rows={headers.map((header) => ({
            id: header,
            expanded: false,
            columns: [
              {
                title: header,
                flex: 1,
              },
              {
                title: dataTypes.find((item) => item.heading === header)
                  ?.dataType,
                flex: 2,
              },
            ],
          }))}
        />
      )}
    </Stack>
  );
};

const CreateProjectOrRelease = ({ row, projects, products, onClick }) => {
  const itemExists =
    projects != undefined &&
    projects.find((item) => item.id === row.ID) !== undefined;
  const foundProduct =
    products !== undefined &&
    products.find((item) => item.name === row.Product);
  const productExists = foundProduct !== undefined;
  const isRelease = row["Activity Type"] === "Release";
  const productHasAlereadyRelease =
    foundProduct !== undefined &&
    foundProduct.roadmap !== undefined &&
    foundProduct.roadmap.find((item) => item.id === row.ID) !== undefined;
  return !itemExists &&
    ((isRelease && productExists && !productHasAlereadyRelease) ||
      !isRelease) ? (
    <Button onClick={onClick} variant="contained" color="secondary">
      {row["Activity Type"] !== undefined &&
      row["Activity Type"].trim().length > 0 ? (
        <span>
          <Icon i={row["Activity Type"].toLowerCase()} />
          <span> </span>
        </span>
      ) : null}
      <span>Add "</span>
      {row["ID Name"]}
      <span>"</span>
    </Button>
  ) : (
    <span>
      {productHasAlereadyRelease ? (
        <span>
          <Stat status="success" />
          <span> </span>
        </span>
      ) : null}
      <Icon i={row["Activity Type"].toLowerCase()} /> {row["ID Name"]}
    </span>
  );
};

export const BulkImport = () => {
  const [headers, setHeaders] = useState([]);
  const [connections, setConnections] = useState([]);
  const {
    isLoading: isLoadingProjects,
    data: projects,
    refetch: reloadProjects,
  } = useQ(`projects`, () => project.list());
  const {
    isLoading: isLoadingProducts,
    data: products,
    refetch: reloadProducts,
  } = useQuery(`products`, () =>
    client.get("/products").then((res) => propOr([], "data", res)),
  );
  const {
    isLoading: isLoadingPortfolios,
    data: portfolios,
    refetch: reloadPortfolios,
  } = useQuery(`portfolios`, () =>
    client.get("/portfolios").then((res) => propOr([], "data", res)),
  );
  const {
    isLoading: isLoadingConfiguration,
    data: configuration,
    refetch: reloadConfiguration,
  } = useQuery(`configuration`, () =>
    client.get("/configuration").then((res) => propOr([], "data", res)),
  );

  function reloadData() {
    return Promise.all([
      reloadProjects(),
      reloadProducts(),
      reloadPortfolios(),
      reloadConfiguration(),
    ]);
  }

  /*
  if(isLoadingProducts || isLoadingProducts) {
    return <Spinner />
  }
*/

  function pasteContent(content) {
    try {
      const pastedContent = content.trim();
      console.log("[INFO] Pasted content: " + JSON.stringify(pastedContent));
      if (
        pastedContent.indexOf("\t") <= 0 ||
        pastedContent.indexOf("\n") <= 0
      ) {
        throw new Error("Needs minimun headers and one column");
      }
      console.log("[DEBUG] Has minimum headers and one column");
      const headerLine = pastedContent.match(
        /^((([^\n\t]+)*|("[^"]*"))\t)*(([^\n\t]+)*|("[^"]*"))\n/g,
      );
      console.log("[DEBUG] Found headers: " + JSON.stringify(headerLine));
      const rows = pastedContent.slice(headerLine[0].length).split("\n");
      const headers = headerLine[0]
        .trim()
        .split("\t")
        .map((item) => item.trim());
      setHeaders(headers);
      const connections = rows.map((row) =>
        row
          .split("\t")
          .reduce(
            (row, value, index) => ({ ...row, [headers[index]]: value }),
            {},
          ),
      );
      setConnections(connections);
      console.log("[DEBUG] Found connections: " + JSON.stringify(connections));
    } catch (error) {
      console.log("[ERROR] Unexpected formatting, got", error);
      alert(
        "Not able to parse content, please check the requirements for content formatting.",
      );
    }
  }
  const [addingItem, addItem] = useState(undefined);
  const [addingProduct, addProduct] = useState(undefined);
  const [addingConnection, addConnection] = useState(undefined);

  const cols = "130px 300px 1fr 280px 1fr";

  function lookupConfiguration(configuration, name, lookup) {
    const values =
      configuration !== undefined && configuration.values !== undefined
        ? configuration.values.find((item) => item.name === name)
        : undefined;
    const item =
      values !== undefined
        ? values.items.find((item) => item.name === lookup)
        : undefined;
    return item !== undefined ? item.id : undefined;
  }

  function lookupPortfolio(portfolios, lookup) {
    const item =
      portfolios !== undefined
        ? portfolios.find((item) => item.name === lookup)
        : undefined;
    return item !== undefined ? item.id : undefined;
  }

  function createProject(row, portfolios, configuration) {
    addItem(row.ID);
    request
      .post("/api/projects")
      .send({
        identifier: row["ID"],
        name: row["ID Name"],
        portfolio: lookupPortfolio(portfolios, row["Portfolio"]),
        location: lookupConfiguration(
          configuration,
          "location",
          row["Location"],
        ),
        department: lookupConfiguration(
          configuration,
          "department",
          row["Department"],
        ),
        category: lookupConfiguration(
          configuration,
          "category",
          row["Project Category"],
        ),
        projectType: lookupConfiguration(
          configuration,
          "projectType",
          row["Project Type"],
        ),
        currency: row["Currency"],
      })
      .then((response) => {
        reloadProjects()
          .then(() => addItem(undefined))
          .catch((error) => {
            console.log("[ERROR] Not able to reload");
            addItem(undefined);
          });
      })
      .catch((error) => {
        console.log("[ERROR] Not able to add project, got", error);
        addItem(undefined);
        alert("Not able to add project");
      });
  }

  function createRelease(row, products) {
    addItem(row.ID);
    const product = products.find((item) => item.name === row["Product"]);
    console.log("[INFO] Product: " + JSON.stringify(product));
    if (product !== undefined) {
      request
        .post("/api" + product.uri + "/releases")
        .send({
          name: row["ID Name"],
          identifier: row["ID"],
        })
        .then((response) => {
          reloadProducts()
            .then(() => addItem(undefined))
            .catch((error) => {
              console.log("[ERROR] Not able to reload");
              addItem(undefined);
            });
        })
        .catch((error) => {
          console.log("[ERROR] Not able to add release, got", error);
          addItem(undefined);
          alert("Not able to add release");
        });
    } else {
      alert("Product is required to add release");
    }
  }

  function createItem(row, loadedData) {
    if (row["Activity Type"] === "Project") {
      createProject(row, loadedData.portfolios, loadedData.configuration);
    } else {
      createRelease(row, loadedData.products);
    }
  }

  function createProduct(row, loadedData) {
    addProduct(row.Product);
    request
      .post("/api/products")
      .send({
        name: row["Product"],
        portfolio: lookupPortfolio(loadedData.portfolios, row["Portfolio"]),
        location: lookupConfiguration(
          loadedData.configuration,
          "location",
          row["Location"],
        ),
        department: lookupConfiguration(
          loadedData.configuration,
          "department",
          row["Department"],
        ),
        currency: row["Currency"],
      })
      .then((response) => {
        reloadProducts()
          .then(() => addProduct(undefined))
          .catch((error) => {
            console.log("[ERROR] Not able to reload");
            addProduct(undefined);
          });
      })
      .catch((error) => {
        console.log("[ERROR] Not able to add product, got", error);
        addProduct(undefined);
        alert("Not able to add product");
      });
  }

  function connectProjectAndProduct(row, products, projects) {
    addConnection(row.ID);
    const product = products.find((item) => item.name === row["Product"]);
    console.log("[INFO] Connect to product: " + JSON.stringify(product));
    const project = projects.find((item) => item.id === row["ID"]);
    console.log("[INFO] Connect project: " + JSON.stringify(project));
    if (product !== undefined && project !== undefined) {
      request
        .post("/api" + product.uri + "/releases")
        .send({
          name: row["ID Name"],
          //          identifier: row['ID'],
          project: project.uri,
        })
        .then((response) => {
          reloadProducts()
            .then(() => addConnection(undefined))
            .catch((error) => {
              console.log("[ERROR] Not able to reload");
              addConnection(undefined);
            });
        })
        .catch((error) => {
          console.log(
            "[ERROR] Not able to connect project and product, got",
            error,
          );
          addConnection(undefined);
          alert("Not able to add release");
        });
    } else {
      alert("Existing product and project is required to establish connection");
    }
  }

  const schema = yup.object().shape({
    excelContent: yup.string().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      excelContent: "",
    },
  });

  const { handleSubmit, control } = formData;
  const excelContent = useWatch({
    control,
    name: "excelContent",
  });

  useEffect(() => {
    if (excelContent) {
      pasteContent(excelContent);
    }
  }, [excelContent]);

  return (
    <Page title={"Bulk import"}>
      <Section title="Bulk import">
        <FormDataProvider formKey="bulkImport.fields" {...formData}>
          <form onSubmit={handleSubmit(console.log)}>
            <Stack spacing={2}>
              <FormTextField name="excelContent" multiline />
              <LoadingStatus
                type="projects"
                isLoading={isLoadingProjects}
                items={projects}
              />
              <LoadingStatus
                type="products"
                isLoading={isLoadingProducts}
                items={products}
              />
              <LoadingStatus
                type="portfolios"
                isLoading={isLoadingPortfolios}
                items={portfolios}
              />
              <LoadingStatus
                type="configuration"
                isLoading={isLoadingConfiguration}
                items={
                  configuration !== undefined ? configuration.values : undefined
                }
              />
              <Stack alignItems="flex-start">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={reloadData}
                >
                  Reload
                </Button>
              </Stack>
            </Stack>
            <ImportSettings headers={headers} />
            {connections.length > 0 ? (
              <Section title="Pasted content">
                <CustomAccordion
                  header={{
                    columns: [
                      {
                        title: "ID",
                        flex: 1,
                      },
                      {
                        title: "ID Name",

                        flex: 2,
                      },
                      {
                        title: "Portfolio",
                        flex: 1,
                      },
                      {
                        title: "Product",
                        flex: 2,
                      },
                      {
                        title: "Status",
                        flex: 1,
                      },
                    ],
                  }}
                  rows={connections.map((row) => ({
                    id: row.ID,
                    expanded: false,
                    columns: [
                      {
                        content: (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            {isLoadingProjects ? (
                              <CircularProgress />
                            ) : (
                              <Stat
                                status={
                                  projects !== undefined &&
                                  projects.find(
                                    (item) => item.id === row.ID,
                                  ) !== undefined
                                    ? "success"
                                    : "danger"
                                }
                              />
                            )}
                            <Typography>{row.ID}</Typography>
                          </Stack>
                        ),
                        flex: 1,
                      },
                      {
                        content:
                          addingItem === row.ID ? (
                            <CircularProgress />
                          ) : (
                            <CreateProjectOrRelease
                              row={row}
                              projects={projects}
                              products={products}
                              onClick={() =>
                                createItem(row, {
                                  products,
                                  portfolios,
                                  configuration,
                                })
                              }
                            />
                          ),
                        flex: 2,
                      },
                      {
                        content: (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Stat
                              status={
                                portfolios !== undefined &&
                                portfolios.find(
                                  (item) => item.name === row.Portfolio,
                                ) !== undefined
                                  ? "success"
                                  : "danger"
                              }
                            />{" "}
                            <Typography>{row.Portfolio}</Typography>
                          </Stack>
                        ),
                        flex: 1,
                      },
                      {
                        title: row["Product"],
                        flex: 2,
                      },
                      {
                        content:
                          addingConnection === row.ID ? (
                            <CircularProgress />
                          ) : (
                            <ConnectAction
                              row={row}
                              projects={projects}
                              products={products}
                              onClick={() =>
                                connectProjectAndProduct(
                                  row,
                                  products,
                                  projects,
                                )
                              }
                            />
                          ),
                        flex: 1,
                      },
                    ],
                  }))}
                />
                <DataTable>
                  <DataTableHeading
                    cols={cols}
                    headings={[
                      "ID",
                      "ID Name",
                      "Portfolio",
                      "Product",
                      "Status",
                    ]}
                  />

                  <DataTableAutoRows
                    data={connections}
                    cols={cols}
                    cells={[
                      (row, config) => (
                        <Stack direction="row" spacing={1} alignItems="center">
                          {isLoadingProjects ? (
                            <Spinner />
                          ) : (
                            <Stat
                              status={
                                projects !== undefined &&
                                projects.find((item) => item.id === row.ID) !==
                                  undefined
                                  ? "success"
                                  : "danger"
                              }
                            />
                          )}
                          <span> </span>
                          {row.ID}
                        </Stack>
                      ),
                      (row) =>
                        addingItem === row.ID ? (
                          <Spinner />
                        ) : (
                          <CreateProjectOrRelease
                            row={row}
                            projects={projects}
                            products={products}
                            onClick={() =>
                              createItem(row, {
                                products,
                                portfolios,
                                configuration,
                              })
                            }
                          />
                        ),
                      (row) => (
                        <span>
                          <Stat
                            status={
                              portfolios !== undefined &&
                              portfolios.find(
                                (item) => item.name === row.Portfolio,
                              ) !== undefined
                                ? "success"
                                : "danger"
                            }
                          />{" "}
                          {row.Portfolio}
                        </span>
                      ),
                      (row) => (
                        <span>
                          {isLoadingProducts ||
                          addingProduct === row.Product ? (
                            <Spinner />
                          ) : products !== undefined &&
                            products.find(
                              (item) => item.name === row.Product,
                            ) !== undefined ? (
                            <span>
                              <Stat
                                status={
                                  products !== undefined &&
                                  products.find(
                                    (item) => item.name === row.Product,
                                  ) !== undefined
                                    ? "success"
                                    : "danger"
                                }
                              />
                              <span> </span>
                              {row.Product}
                            </span>
                          ) : (
                            <span>
                              <Stat status="danger" />
                              <span> </span>
                              <button
                                onClick={() =>
                                  createProduct(row, {
                                    products,
                                    portfolios,
                                    configuration,
                                  })
                                }
                                style={{
                                  padding: 3,
                                  paddingRight: 10,
                                  backgroundColor: "#111827",
                                  color: "white",
                                  borderRadius: 6,
                                  border: "none",
                                }}
                              >
                                <Icon i="product" />
                                <span> Add "</span>
                                {row.Product}
                                <span>"</span>
                              </button>
                            </span>
                          )}
                        </span>
                      ),
                      (row, config) =>
                        addingConnection === row.ID ? (
                          <Spinner />
                        ) : (
                          <ConnectAction
                            row={row}
                            projects={projects}
                            products={products}
                            onClick={() =>
                              connectProjectAndProduct(row, products, projects)
                            }
                          />
                        ),
                    ]}
                  />
                </DataTable>
              </Section>
            ) : null}
          </form>
        </FormDataProvider>
      </Section>
    </Page>
  );
};
