import React, { useEffect, useMemo, useState } from "react";
import useMeasure from "react-use-measure";
import { animated, useSpring } from "react-spring";
import { isNil } from "ramda";
import { Icon } from "../base/Icon";
import { Link, useLocation, matchPath } from "react-router-dom";
import { useStyleParams } from "../hooks/useStyleParams";
import {
  Box,
  Collapse,
  IconButton,
  List,
  Paper,
  Typography,
  Link as MuiLink,
  Stack,
  ListItem,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const NavDrawerToggle = ({ open, onClick }) => {
  return (
    <Box onClick={onClick} className={"fl-r cursor-link"}>
      {open ? (
        <ExpandMoreIcon fontSize="large" />
      ) : (
        <ChevronRightIcon fontSize="large" />
      )}
    </Box>
  );
};

const MenuLink = ({
  href,
  to,
  label,
  children,
  end = false,
  exact = true,
  matchUrl,
  onClick,
}) => {
  const loc = useLocation();
  const matchUrls = matchUrl
    ? Array.isArray(matchUrl)
      ? matchUrl
      : [matchUrl]
    : null;
  const isUrlMatched = !!matchUrls
    ? matchUrls.some((matchUrl) =>
        matchPath(
          {
            path: matchUrl,
            end,
          },
          loc.pathname,
        ),
      )
    : false;
  const isSubMenuMatched = useMemo(() => {
    if (!children) {
      return [];
    }
    return children.map(({ childMatchUrl, end }) => {
      const matchUrls = childMatchUrl
        ? Array.isArray(childMatchUrl)
          ? childMatchUrl
          : [childMatchUrl]
        : null;
      return !!matchUrls
        ? matchUrls.some((matchUrl) =>
            matchPath(
              {
                path: matchUrl,
                end,
              },
              loc.pathname,
            ),
          )
        : false;
    });
  }, [children, loc.pathname]);

  return !isNil(href) ? (
    <MuiLink
      className="link"
      style={{
        textDecoration: "none",
        display: "block",
        fontSize: 16,
        padding: 8,
        borderLeft: "2px solid rgb(230, 230, 230)",
        width: "100%",
      }}
      href={href}
      target="_blank"
    >
      {label}
    </MuiLink>
  ) : !isNil(to) && to.indexOf("/external") === 0 ? (
    <MuiLink
      className="link"
      style={{
        textDecoration: "none",
        display: "block",
        fontSize: 16,
        padding: 8,
        borderLeft: "2px solid rgb(230, 230, 230)",
      }}
      href={to}
      sx={{ width: "100%" }}
    >
      {label}
    </MuiLink>
  ) : (
    <Stack>
      <ListItem sx={{ padding: 0 }}>
        <Link
          to={to}
          {...(exact ? exact : null)}
          end={end}
          className={isNil(matchUrl) ? "" : isUrlMatched && "active"}
          onClick={onClick}
          style={{ width: "100%" }}
        >
          {label}
        </Link>
      </ListItem>
      {!!children && isUrlMatched && (
        <li>
          {children.map(
            (
              { label, to, linkTo, end = true, exact = true, childMatchUrl },
              idx,
            ) =>
              !!to && to.includes("/external") ? (
                <MuiLink
                  key={idx}
                  href={to}
                  sx={{
                    paddingLeft: "20px",
                    borderLeft: "2px solid rgb(230, 230, 230)",
                    fontSize: "15px",
                    width: "100%",
                  }}
                >
                  {label}
                </MuiLink>
              ) : (
                <Link
                  key={idx}
                  to={to ?? linkTo}
                  {...(exact ? exact : null)}
                  end={end}
                  className={isSubMenuMatched[idx] && "active"}
                  style={{
                    paddingLeft: "20px",
                    borderLeft: "2px solid rgb(230, 230, 230)",
                    fontSize: "15px",
                    width: "100%",
                  }}
                >
                  {label}
                </Link>
              ),
          )}
        </li>
      )}
    </Stack>
  );
};

export const NavSection = ({
  label,
  icon,
  className,
  style,
  items,
  startsOpen = true,
  title = ["sm", "nm", "md", "lg"],
}) => {
  const { isTitle } = useStyleParams({ title });

  const defaultHeight = "16px";
  const [open, setOpen] = useState(startsOpen);
  const [contentHeight, setContentHeight] = useState(defaultHeight);
  const [ref, { height }] = useMeasure();

  const expand = useSpring({
    height: open ? `${contentHeight}px` : defaultHeight,
  });

  useEffect(() => {
    setContentHeight(height + 15);
  }, [height]);

  const hasToggle = !isNil(items) && items.length > 0;
  return (
    <section
      className={`nav-section ${className} ${!isNil(icon) ? "icon" : ""}`}
      style={style}
    >
      {isTitle && (
        <Typography
          variant="h2"
          onClick={() => hasToggle && setOpen((v) => !v)}
          className={`${hasToggle ? "cursor-link" : ""}`}
        >
          <Icon i={icon} size={"md"} />
          {label}
          {hasToggle && <NavDrawerToggle open={open} />}
        </Typography>
      )}
      <animated.div className={"nav-accordion"} style={expand}>
        {isNil(items) ? null : (
          <List dense={true} className={"nav"} ref={ref}>
            {items.map(
              (
                { label, to, href, children, end, exact, matchUrl, onClick },
                idx,
              ) => (
                <MenuLink
                  key={label + to + idx}
                  to={to}
                  label={label}
                  href={href}
                  children={children}
                  end={end}
                  {...(exact ? exact : null)}
                  matchUrl={matchUrl}
                  onClick={onClick}
                />
              ),
            )}
          </List>
        )}
      </animated.div>
    </section>
  );
};
