import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { useQ } from "@/hooks/useQ";
import { programme } from "@/queries";
import { useT } from "@/hooks/useT";
import { Button, Stack, Typography } from "@mui/material";
import { useFlag } from "@/hooks/useFlag";
import { HtmlReadMoreTypography } from "@/base/typography/HtmlReadMoreTypography";
import { VisibilityOutlined } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { VisionForm } from "@/pages/programmes/Design/VisionForm";
import {
  BenefitForm,
  BenefitsForm,
} from "@/pages/programmes/Design/BenefitsForm";
import { TargetForm } from "@/pages/programmes/Design/TargetForm";
import { OutcomesForm } from "@/pages/programmes/Design/OutcomesForm";
import { BenefitMap } from "@/pages/programmes/Design/BenefitMap";
import { v4 as uuidV4 } from "uuid";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import { CustomDialog } from "@/layout/CustomDialog";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import { NewItemForm } from "@/pages/home/AccountSettings/TextsArraySection";

export const Design = () => {
  const { t } = useT();
  const { id = "" } = useParams();
  const useDesign = useFlag("programme.design");
  const toast = useToast();

  const {
    isLoading,
    data = {},
    refetch: refetchProgramme,
  } = useQ(`programme-${id}`, () => programme.single({ id }));

  const { title } = data;

  const [showVisualBenefits, setShowVisualBenefits] = useState(true);
  const [showNewBenefitForm, setShowNewBenefitForm] = useState(false);
  const [showNewOutcomeForm, setShowNewOutcomeForm] = useState(false);

  const addMeasurableBenefit = useMutation(programme.addMeasurableBenefit, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Measurable benefit added successfully");
      refetchProgramme?.();
    },
    onError: (error) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(
        `Error adding measurable benefit. ${error?.response?.data?.message}`,
      );
    },
  });

  const schema = yup.object().shape({
    benefits: yup.array().optional(),
    title: yup.string().optional(),
    accountable: yup.string().optional(),
    measurement: yup.string().optional(),
    actions: yup.string().optional(),
    baseline: yup.string().optional(),
    goal: yup.string().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      benefits: data?.measurableBenefits || [],
      title: "",
      accountable: "",
      measurement: "",
      actions: "",
      baseline: "",
      goal: "",
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = formData;

  const createOutcome = useMutation(programme.addOutcome, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success(data?.message ?? "Outcome added successfully");
      refetchProgramme?.();
    },
    onError: ({ error }) => {
      console.error(
        `Received error: ${error?.message ?? "-"} ${
          error?.response?.data?.message ?? "-"
        }`,
      );
      toast.error(`Error adding Outcome. ${error?.response?.data?.message}`);
    },
  });

  if (!useDesign) {
    return (
      <Page
        isLoading={isLoading}
        title={t("programme.navigation.design.title")}
      >
        <Section title={t("programme.navigation.design.title")}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="h3">Work in progress...</Typography>
        </Section>
      </Page>
    );
  }

  const isEmpty =
    (data.measurableBenefits ?? []).length === 0 &&
    (data.outcomes ?? []).length === 0;

  return (
    <Page isLoading={isLoading} title={t("programme.navigation.design.title")}>
      <Section title={t("programme.navigation.design.title")}>
        <Stack spacing={5}>
          <VisionForm
            programmeData={data}
            onUpdate={() => {
              refetchProgramme();
            }}
          />
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h2">
                {t("project.businessCase.addEditForm.steps.benefits")}
              </Typography>
              <Stack alignItems="flex-end">
                {showVisualBenefits ? (
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<EditOutlinedIcon />}
                    onClick={() => setShowVisualBenefits((s) => !s)}
                  >
                    {t("generic.button.edit")}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<VisibilityOutlined />}
                    onClick={() => setShowVisualBenefits((s) => !s)}
                  >
                    {t("programme.design.showMap")}
                  </Button>
                )}
              </Stack>
            </Stack>
            {(isEmpty || !showVisualBenefits) && (
              <HtmlReadMoreTypography>
                {t("programme.editDesignForm.benefits.description")}
              </HtmlReadMoreTypography>
            )}
          </Stack>
          {showVisualBenefits && (
            <BenefitMap
              outcomes={data.outcomes}
              measurableBenefits={data.measurableBenefits}
              addBenefit={() => setShowNewBenefitForm(true)}
              addOutcome={() => setShowNewOutcomeForm(true)}
            />
          )}
          <CustomDialog
            isOpen={showNewBenefitForm}
            onClose={() => setShowNewBenefitForm(!showNewBenefitForm)}
            isContentDirty={isDirty}
          >
            <FormDataProvider
              formKey={"project.businessCase.addEditForm.fields"}
              {...formData}
            >
              <form
                onSubmit={handleSubmit((data) => {
                  addMeasurableBenefit.mutate({
                    id: id,
                    data: {
                      measurableBenefit: { id: uuidV4(), ...data },
                    },
                  });
                  reset({});
                  setShowNewBenefitForm(!showNewBenefitForm);
                })}
              >
                <Stack>
                  <BenefitForm />
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    sx={{ width: "100%", mt: 3 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setShowNewBenefitForm(!showNewBenefitForm)}
                    >
                      {t("generic.button.cancel")}
                    </Button>
                    <Button type="submit" variant="contained">
                      {t("generic.button.save")}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </FormDataProvider>
          </CustomDialog>
          <NewItemForm
            open={showNewOutcomeForm}
            onClose={() => setShowNewOutcomeForm(false)}
            onSave={(data) => {
              createOutcome.mutate({
                id: id,
                data: {
                  outcome: {
                    id: uuidV4(),
                    name: data.name,
                  },
                },
              });
              setShowNewOutcomeForm(false);
            }}
          />
          {!showVisualBenefits && (
            <>
              <Stack spacing={5} sx={{ paddingLeft: 3 }}>
                <BenefitsForm
                  programmeData={data}
                  onUpdate={refetchProgramme}
                />
                <OutcomesForm
                  programmeData={data}
                  onUpdate={refetchProgramme}
                />
              </Stack>
            </>
          )}
          <TargetForm programmeData={data} onUpdate={refetchProgramme} t={t} />
        </Stack>
      </Section>
    </Page>
  );
};
