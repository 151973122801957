import React from 'react';

export const Circle = ({ className, style, onClick }) => {
  return (
    <svg className={className} style={style} onClick={onClick} width="25" height="24"
         viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4989 22C10.5215 21.9998 8.58849 21.4133 6.9443 20.3147C5.29981 19.2159 4.01809 17.6541 3.26121 15.8268C2.50433 13.9996 2.3063 11.9889 2.69215 10.0491C3.078 8.10928 4.03041 6.32745 5.42894 4.92893C6.82746 3.5304 8.60929 2.57799 10.5491 2.19214C12.4889 1.80629 14.4996 2.00432 16.3268 2.7612C18.1541 3.51808 19.7159 4.7998 20.8147 6.44429C21.9133 8.08847 22.4998 10.0214 22.5 11.9989C22.497 14.6504 21.4423 17.1925 19.5674 19.0674C17.6925 20.9423 15.1505 21.997 12.4989 22Z"
        stroke="#D1D5DB" strokeWidth="2"/>
    </svg>

  );
};
