import { useEffect, useRef, useState } from 'react';
import { propOr, isEmpty } from 'ramda';
import { useNavigate, useParams } from "react-router-dom";

export const useSteps = ({ steps = [] }) => {
  const { type }  = useParams();
  const navigate = useNavigate();
  // calculate index of path params or first {active: true} step and default to 0;
  const activeFromLocation = steps.findIndex(item=>item.id===type);
  const firstHasActive =  activeFromLocation>=0 ? activeFromLocation : [...steps].reverse().reduce((acc, curr, idx) => propOr(false, 'active', curr) ? idx : acc, 0);
  const [active, setActive] = useState(firstHasActive);
  const history = useRef([]);
  const amount = steps.length + 1;
  const activatedSteps = steps.map((s, i ) => i === firstHasActive ? {...s, active: true}: s);
  const renderSteps = useRef(activatedSteps);


  const next = () => {
    if (active >= amount) {
      return Promise.resolve();
    } else {
      const next = active + 1;
      history.current = history.current.concat(active);

      renderSteps.current[active].active = false;
      renderSteps.current[active].complete = true;

      renderSteps.current[next].active = true;
      renderSteps.current[next].touched = true;
      setActive(next);

      return Promise.resolve(renderSteps.current[next]);
    }
  };


  const last = () => {
    if (history.current.length === 0) {
      return Promise.resolve();
    } else {
      const prev = history.current[history.current.length - 1];
      history.current = history.current.slice(0, history.current.length - 1);

      renderSteps.current[prev].active = true;
      renderSteps.current[active].active = false;
      renderSteps.current[active].complete = false;

      setActive(prev);
      return Promise.resolve(renderSteps.current[prev]);
    }
  };

  const goto = (step) => {
    if (steps.find(item=>item.id===step)) {
      const index=steps.findIndex(item=>item.id===step)
      if (!isEmpty(steps[index].uri)) {
        navigate(steps[index].uri);
      }
      return goto(index)
    }
    if (step >= amount || step < 0) {
      return Promise.resolve();
    } else {
      const next = step;
      history.current = history.current.concat(active);

      renderSteps.current[active].active = false;

      renderSteps.current[next].active = true;
      renderSteps.current[next].touched = true;
      setActive(next);

      return Promise.resolve(renderSteps.current[next]);
    }
  }

  return { active, next, last, goto, amount, current: renderSteps.current[active], steps: renderSteps.current };

};
