
import React from 'react';

export const Arrows = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
        width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.26517 6.23484C1.11872 6.08839 0.881282 6.08839 0.734835 6.23484C0.588389 6.38128 0.588389 6.61872 0.734835 6.76517L3.73484 9.76517C3.88128 9.91161 4.11872 9.91161 4.26517 9.76517L7.26516 6.76517C7.41161 6.61872 7.41161 6.38128 7.26516 6.23484C7.11872 6.08839 6.88128 6.08839 6.73484 6.23484L4 8.96967L1.26517 6.23484ZM0.734835 3.23484C0.588389 3.38128 0.588389 3.61872 0.734835 3.76517C0.881282 3.91161 1.11872 3.91161 1.26517 3.76517L4 1.03033L6.73484 3.76517C6.88128 3.91161 7.11872 3.91161 7.26517 3.76517C7.41161 3.61872 7.41161 3.38128 7.26517 3.23484L4.26517 0.234835C4.11872 0.088389 3.88128 0.088389 3.73484 0.234835L0.734835 3.23484Z" fill="#6B7280"/>
</svg>

  );
};
