import React from "react";
import MartinGaugeChart from "./vendor/MartinGaugeChart";

export const GaugeChart = ({ data }) => {
  const { percent, blocks = 26 } = data;

  return (
    <MartinGaugeChart
      className={"gauge-chart"}
      percent={percent}
      nrOfLevels={blocks}
      cornerRadius={2}
      colors={["#f85252", "#47b857"]}
      arcPadding={0.02}
      needleColor={"#fff"}
      textColor={"#111"}
      fontSize={"40px"}
      formatTextValue={(v) => `${v}%`}
    />
  );
};
