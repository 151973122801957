import React from 'react';

export const Dropzone = ({ className, style, onClick }) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2598 15.9006H3.15945C2.68204 15.9006 2.22418 15.7109 1.8866 15.3734C1.54903 15.0358 1.35938 14.5779 1.35938 14.1005V3.30007C1.35938 2.82267 1.54903 2.36481 1.8866 2.02723C2.22418 1.68965 2.68204 1.5 3.15945 1.5H24.7603C25.2378 1.5 25.6956 1.68965 26.0332 2.02723C26.3708 2.36481 26.5604 2.82267 26.5604 3.30007V14.1005C26.5604 14.5779 26.3708 15.0358 26.0332 15.3734C25.6956 15.7109 25.2378 15.9006 24.7603 15.9006"
        stroke="#D1D5DB" strokeWidth="1.80007" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M16.1769 16.7518L19.657 27.8666C19.7022 28.0105 19.7828 28.1408 19.8914 28.2455C19.9999 28.3501 20.1331 28.4259 20.2785 28.4658C20.424 28.5057 20.5771 28.5084 20.7239 28.4738C20.8707 28.4391 21.0064 28.3682 21.1187 28.2675L22.9571 23.0988L28.0598 22.0188C28.1702 21.9193 28.2543 21.794 28.3045 21.6541C28.3547 21.5141 28.3695 21.364 28.3475 21.2169C28.3255 21.0699 28.2675 20.9306 28.1785 20.8115C28.0896 20.6924 27.9725 20.5972 27.8377 20.5343L17.4177 15.6705C17.2598 15.5968 17.0839 15.5704 16.9113 15.5947C16.7387 15.619 16.5769 15.6929 16.4455 15.8074C16.3141 15.9219 16.2187 16.0721 16.1711 16.2397C16.1234 16.4074 16.1254 16.5853 16.1769 16.7518V16.7518Z"
        stroke="#D1D5DB" strokeWidth="1.80007" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M6.76252 8.25C6.85153 8.25 6.93853 8.27639 7.01254 8.32584C7.08654 8.37529 7.14422 8.44558 7.17828 8.52781C7.21234 8.61004 7.22126 8.70052 7.20389 8.78781C7.18653 8.87511 7.14367 8.9553 7.08073 9.01823C7.0178 9.08117 6.93761 9.12403 6.85031 9.14139C6.76302 9.15876 6.67254 9.14984 6.59031 9.11578C6.50808 9.08172 6.43779 9.02404 6.38834 8.95004C6.33889 8.87603 6.3125 8.78903 6.3125 8.70002C6.31234 8.64088 6.32388 8.58229 6.34643 8.52762C6.36899 8.47295 6.40214 8.42328 6.44396 8.38146C6.48578 8.33964 6.53545 8.30649 6.59012 8.28393C6.64479 8.26138 6.70338 8.24984 6.76252 8.25"
        stroke="#D1D5DB" strokeWidth="1.80007" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M13.9617 8.25C14.0507 8.25 14.1378 8.27639 14.2118 8.32584C14.2858 8.37529 14.3434 8.44558 14.3775 8.52781C14.4116 8.61004 14.4205 8.70052 14.4031 8.78781C14.3857 8.87511 14.3429 8.9553 14.28 9.01823C14.217 9.08117 14.1368 9.12403 14.0495 9.14139C13.9622 9.15876 13.8718 9.14984 13.7895 9.11578C13.7073 9.08172 13.637 9.02404 13.5876 8.95004C13.5381 8.87603 13.5117 8.78903 13.5117 8.70002C13.5116 8.64088 13.5231 8.58229 13.5457 8.52762C13.5682 8.47295 13.6014 8.42328 13.6432 8.38146C13.685 8.33964 13.7347 8.30649 13.7893 8.28393C13.844 8.26138 13.9026 8.24984 13.9617 8.25"
        stroke="#D1D5DB" strokeWidth="1.80007" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M21.161 8.25C21.25 8.25 21.337 8.27639 21.411 8.32584C21.485 8.37529 21.5427 8.44558 21.5767 8.52781C21.6108 8.61004 21.6197 8.70052 21.6023 8.78781C21.585 8.87511 21.5421 8.9553 21.4792 9.01823C21.4162 9.08117 21.336 9.12403 21.2488 9.14139C21.1615 9.15876 21.071 9.14984 20.9887 9.11578C20.9065 9.08172 20.8362 9.02404 20.7868 8.95004C20.7373 8.87603 20.7109 8.78903 20.7109 8.70002C20.7108 8.64088 20.7223 8.58229 20.7449 8.52762C20.7674 8.47295 20.8006 8.42328 20.8424 8.38146C20.8842 8.33964 20.9339 8.30649 20.9886 8.28393C21.0432 8.26138 21.1018 8.24984 21.161 8.25"
        stroke="#D1D5DB" strokeWidth="1.80007" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};
