import React, { useState } from 'react';
import { is } from 'ramda';

export const useShowSomeMore = ({ min, max, initial, step }) => {

  const [show, setShow] = useState(initial);

  const showMore = () => {
    if(is(Number, step)) {
      setShow(v => Math.min(v + step, max));
    }

    if(is(Function, step)) {
      setShow(v => Math.min(step({show, min, max, initial, direction: 1}), max));
    }

  }

  const showLess = () => {
    if(is(Number, step)) {
      setShow((v => Math.max(v - ((v % step === 0) ? step : (v % step)), min)));
    }
    if(is(Function, step)) {
      setShow(v => Math.max(step({min, max, initial, direction: -1}), min));
    }
  }

  return {show, showMore, showLess, min, max};

}
