import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { project } from "../../queries";
import { useQ } from "../../hooks/useQ";
import {
  Divider,
  Box,
  Typography,
  Stack,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import { CustomAccordion } from "../../layout/CustomAccordion";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormRichTextField } from "../../base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { useToast } from "../../hooks/useToast";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";
import { CustomDialog } from "@/layout/CustomDialog";
import { StepperSections } from "@/base/form/mui/StepperSections";
import { DeleteButton } from "@/base/DeleteButton";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";

export const PPDGuide = ({
  scopeName,
  output,
  qualityExpectation,
  acceptanceResponsible,
  acceptanceCriteria,
  acceptanceMethod,
  t,
}) => {
  return (
    <Stack spacing={2} sx={{ mt: 3 }}>
      <Stack>
        <Typography fontStyle="italic">
          {t("project.quality.ppd.outputSummary.cover")}
        </Typography>
        <Stack direction="row" spacing={1} ml={2}>
          <Typography fontWeight="bold">{scopeName}</Typography>
          <Typography sx={{ fontSize: ".8em !important" }}>(scope)</Typography>
        </Stack>
      </Stack>

      <Stack>
        <Typography fontStyle="italic">
          {t("project.quality.ppd.outputSummary.projectWillDeliver")}
        </Typography>
        <Stack direction="row" spacing={1} ml={2}>
          <Typography
            fontWeight="bold"
            dangerouslySetInnerHTML={{ __html: output }}
          />
          <Typography sx={{ fontSize: ".8em !important" }}>
            ({t("project.quality.ppd.fields.output.title")})
          </Typography>
        </Stack>
      </Stack>

      <Stack>
        <Typography fontStyle="italic">
          {t("project.quality.ppd.outputSummary.theReceiverWants")}
        </Typography>
        <Stack direction="row" spacing={1} ml={2}>
          <Typography
            fontWeight="bold"
            dangerouslySetInnerHTML={{ __html: qualityExpectation }}
          />
          <Typography sx={{ fontSize: ".8em !important" }}>
            ({t("projectForm.output.fields.qualityExpectation.title")})
          </Typography>
        </Stack>
      </Stack>

      <Stack>
        <Stack direction="row" spacing={1}>
          <Typography
            fontWeight="bold"
            dangerouslySetInnerHTML={{ __html: acceptanceResponsible }}
          />
          <Typography sx={{ fontSize: ".8em !important" }}>
            ({t("projectForm.output.fields.acceptanceResponsible.title")})
          </Typography>
        </Stack>

        <Typography fontStyle="italic">
          {t("project.quality.ppd.outputSummary.willConfirm")}
        </Typography>
        <Stack direction="row" spacing={1} ml={2}>
          <Typography
            fontWeight="bold"
            dangerouslySetInnerHTML={{ __html: acceptanceCriteria }}
          />
          <Typography sx={{ fontSize: ".8em !important" }}>
            ({t("projectForm.output.fields.acceptanceCriteria.title")})
          </Typography>
        </Stack>

        <Typography fontStyle="italic">
          {t("project.quality.ppd.outputSummary.by")}
        </Typography>
        <Stack direction="row" spacing={1} ml={2}>
          <Typography
            fontWeight="bold"
            dangerouslySetInnerHTML={{ __html: acceptanceMethod }}
          />
          <Typography sx={{ fontSize: ".8em !important" }}>
            ({t("projectForm.output.fields.acceptanceMethod.title")})
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const OutputForm = ({
  scopeName,
  item,
  onSave,
  open,
  onClose,
  setEditId,
  ...props
}) => {
  const { t } = useT();
  const schema = yup.object().shape({
    id: yup.string().optional(),
    acceptanceCriteria: yup.string().optional(),
    acceptanceMethod: yup.string().optional(),
    acceptanceResponsible: yup.string().optional(),
    derivation: yup.string().optional(),
    output: yup.string().required(),
    qualityExpectation: yup.string().optional(),
    skills: yup.string().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      acceptanceCriteria: item?.acceptanceCriteria || "",
      acceptanceMethod: item?.acceptanceMethod || "",
      acceptanceResponsible: item?.acceptanceResponsible || "",
      derivation: item?.derivation || "",
      output: item?.output || "",
      qualityExpectation: item?.qualityExpectation || "",
      skills: item?.skills || "",
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = formData;

  const [
    output,
    qualityExpectation,
    acceptanceResponsible,
    acceptanceCriteria,
    acceptanceMethod,
    derivation,
    skills,
  ] = useWatch({
    control,
    name: [
      "output",
      "qualityExpectation",
      "acceptanceResponsible",
      "acceptanceCriteria",
      "acceptanceMethod",
      "derivation",
      "skills",
    ],
  });

  useEffect(() => {
    reset({
      acceptanceCriteria: item?.acceptanceCriteria || "",
      acceptanceMethod: item?.acceptanceMethod || "",
      acceptanceResponsible: item?.acceptanceResponsible || "",
      derivation: item?.derivation || "",
      output: item?.output || "",
      qualityExpectation: item?.qualityExpectation || "",
      skills: item?.skills || "",
    });
  }, [item, reset]);

  const [activeStep, setActiveStep] = useState(0);

  const isCreating = !item;
  const submitButtonLabel = useMemo(() => {
    if (isCreating) {
      return t("generic.button.save");
    } else if (!isDirty) {
      if (activeStep === 0) {
        return !output?.length ? false : t("generic.stepper.next");
      } else if (activeStep === 1) {
        return t("generic.stepper.next");
      } else if (activeStep === 2) {
        return false;
      }
      return false;
    } else {
      if (activeStep === 0) {
        return !output?.length
          ? t("generic.button.update")
          : t("generic.button.updateAndNext");
      } else if (activeStep === 1) {
        return t("generic.button.updateAndNext");
      } else {
        return t("generic.button.update");
      }
    }
  }, [
    isCreating,
    activeStep,
    output,
    isDirty,
    qualityExpectation,
    acceptanceCriteria,
    acceptanceMethod,
    acceptanceResponsible,
    derivation,
    skills,
  ]);

  const showPreviousButton = useMemo(() => {
    return activeStep > 0;
  }, [activeStep]);

  const triggerSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        if (isDirty) {
          var id = item?.id ?? uuidv4();
          await onSave({
            id: id,
            ...data,
          });
          if (!item) {
            reset();
            setEditId(id);
          }
          setActiveStep((a) => Math.min(2, activeStep + 1));
        } else {
          if (activeStep < 2) {
            setActiveStep((a) => a + 1);
          }
        }
      }),
    [handleSubmit, onSave, item, reset, isDirty, activeStep],
  );

  return (
    <CustomDialog
      isOpen={open}
      onClose={onClose}
      scrollDependencyArray={[activeStep]}
    >
      <FormDataProvider formKey="projectForm.output.fields" {...formData}>
        <form onSubmit={triggerSubmit}>
          <StepperSections
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={["output", "acceptance", "dependencies"]}
            formatLabel={(step) => t(`project.quality.ppd.steps.${step}`)}
          />
          <Stack spacing={2} sx={{ mt: 3 }}>
            <PPDGuide
              {...{
                scopeName,
                output,
                qualityExpectation,
                acceptanceResponsible,
                acceptanceCriteria,
                acceptanceMethod,
                t,
              }}
            />
            {activeStep === 0 && (
              <>
                <FormRichTextField name="output" required />
                <FormRichTextField name="qualityExpectation" />
              </>
            )}
            {activeStep === 1 && (
              <>
                <FormRichTextField name="acceptanceCriteria" />
                <FormRichTextField name="acceptanceMethod" />
                <FormRichTextField name="acceptanceResponsible" />
              </>
            )}
            {activeStep === 2 && (
              <>
                <FormRichTextField name="derivation" />
                <FormRichTextField name="skills" />
              </>
            )}
            <StepperNavigator
              showPreviousButton={showPreviousButton}
              setActiveStep={setActiveStep}
              onCancel={onClose}
              isDirty={isDirty}
              submitButtonLabel={submitButtonLabel}
            />
          </Stack>
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

const DeletePanel = ({ onDelete, onClose, open, ...props }) => {
  return (
    <Paper
      sx={{
        padding: "30px 20px",
        display: open ? "block" : "none",
      }}
      {...props}
    >
      <Typography variant="body1">
        Are you sure to delete this output?
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ width: "100%", mt: 2 }}
      >
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDelete}>
          Delete
        </Button>
      </Stack>
    </Paper>
  );
};

export const ProjectProductDescription = () => {
  const { id = "" } = useParams();
  const { t } = useT();
  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );
  const {
    isLoading: isLoadingScope,
    data: scopeData = {},
    refetch: refetchProjectScopes,
  } = useQ(`project-${id}-scopes`, () => project.scopes({ id }));

  const scopes = useMemo(() => scopeData?.projectScope || [], [scopeData]);

  const { title } = data;

  const [showNewItemForm, setShowNewItemForm] = useState(null);
  const [editItemId, setEditItemId] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const handleShowEditItemForm = useCallback((itemId, index) => {
    setShowNewItemForm(null);
    setEditItemId(itemId);
    setDeleteItemId(null);
  }, []);

  const toast = useToast();

  const createItem = useMutation(project.addScopeItem, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Output created successfully");
      refetchProjectScopes();
      setShowNewItemForm(null);
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating output");
    },
  });

  const editItem = useMutation(project.editScopeItem, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Output updated successfully");
      refetchProjectScopes();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating output");
    },
  });

  const deleteItem = useMutation(project.deleteScopeItem, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Output deleted successfully");
      refetchProjectScopes();
      setDeleteItemId(null);
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error deleting output");
    },
  });

  const handleShowDeleteItemForm = useCallback((itemId) => {
    setShowNewItemForm(null);
    setEditItemId(null);
    setDeleteItemId(itemId);
  }, []);

  return (
    <Page isLoading={isLoading || isLoadingScope} title={title}>
      <Section title={t("project.quality.title")}>
        <Typography variant="h1">{t("project.quality.ppd.title")}</Typography>
        <Typography mt={2}>{t("project.quality.ppd.description")}</Typography>
        <Divider sx={{ my: 3 }} />
        {scopes.length > 0 ? (
          <Stack spacing={3}>
            {scopes.map((scope, index) => (
              <Stack key={scope.id} spacing={1}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h2">{scope.text}</Typography>
                  <Button
                    variant="text"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() =>
                      setShowNewItemForm(
                        showNewItemForm === index ? null : index,
                      )
                    }
                  >
                    {showNewItemForm === index
                      ? t("generic.button.cancel")
                      : t("project.quality.ppd.add")}
                  </Button>
                </Stack>
                <CustomAccordion
                  tableView
                  header={{
                    columns: [
                      {
                        title: t(
                          "project.quality.ppd.fields.compositionOutput.title",
                        ),
                        flex: 1,
                      },
                      {
                        title: t(
                          "project.quality.ppd.fields.qualityExpectation.title",
                        ),
                        flex: 1,
                      },
                      {
                        title: t(
                          "project.quality.ppd.fields.acceptanceCriteria.title",
                        ),
                        flex: 1,
                      },
                      {
                        title: t(
                          "project.quality.ppd.fields.acceptanceMethod.title",
                        ),
                        flex: 1,
                      },
                      {
                        title: "",
                        width: 40,
                      },
                      {
                        title: "",
                        width: 40,
                      },
                    ],
                    expanded: showNewItemForm === index,
                    details: (
                      <OutputForm
                        open={showNewItemForm === index}
                        scopeName={scope.text}
                        setEditId={setEditItemId}
                        onSave={(data) => {
                          createItem.mutate({
                            id,
                            scopeId: scope.id,
                            data,
                          });
                        }}
                        onClose={() => setShowNewItemForm(null)}
                      />
                    ),
                  }}
                  rows={scope?.items?.map((item) => ({
                    expanded: false,
                    expandDisabled: true,
                    columns: [
                      {
                        content: (
                          <Typography
                            dangerouslySetInnerHTML={{ __html: item?.output }}
                          />
                        ),
                        flex: 1,
                      },
                      {
                        content: (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: item?.qualityExpectation,
                            }}
                          />
                        ),
                        flex: 1,
                      },
                      {
                        content: (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: item?.acceptanceCriteria,
                            }}
                          />
                        ),
                        flex: 1,
                      },
                      {
                        content: (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: item?.acceptanceMethod,
                            }}
                          />
                        ),
                        flex: 1,
                      },
                      {
                        content: (
                          <DeleteButton
                            onConfirm={() =>
                              deleteItem.mutate({
                                id,
                                scopeId: scope.id,
                                itemId: item.id,
                              })
                            }
                            content="Are you sure to delete this item?"
                            iconMode
                          />
                        ),
                        width: 40,
                      },
                      {
                        content: (
                          <IconButton
                            onClick={() => {
                              handleShowEditItemForm(item.id, index);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        ),
                        width: 40,
                      },
                    ],
                    details: (
                      <>
                        <OutputForm
                          scopeName={scope.text}
                          item={item}
                          open={editItemId === item.id}
                          setEditId={setEditItemId}
                          onClose={() => handleShowEditItemForm(null, index)}
                          onSave={(data) => {
                            editItem.mutate({
                              id,
                              scopeId: scope.id,
                              itemId: item.id,
                              data,
                            });
                          }}
                        />
                        <DeletePanel
                          open={deleteItemId === item.id}
                          onClose={() => handleShowDeleteItemForm(null)}
                          item={item}
                          onDelete={() => {
                            deleteItem.mutate({
                              id,
                              scopeId: scope.id,
                              itemId: item.id,
                            });
                          }}
                        />
                      </>
                    ),
                  }))}
                  rowSx={{
                    alignItems: "flex-start",
                  }}
                />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Box
            sx={{
              p: 4,
              mt: 2,
              backgroundColor: "#f5f5f5",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {t("project.quality.ppd.noScope")}
            </Typography>
          </Box>
        )}
      </Section>
    </Page>
  );
};
