import React from "react";
import { useParams, useMatch } from "react-router-dom";
import { Page } from "../../../layout/Page";
import { Section } from "../../../layout/Section";
import { useT } from "../../../hooks/useT";
import { programme } from "../../../queries";
import { useQ } from "../../../hooks/useQ";
import { HighlightReport } from "./HighlightReport";
import { clientUrl } from "../../../util/routing";
import { uris } from "../../../config/nav";
import { HighlightReportPdf } from "../../projects/HighlightReportPdf";

export const Knowledge = () => {
  const { id = "", reportId = "" } = useParams();
  const { t } = useT();
  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    programme.single({ id }),
  );

  const {
    activities,
    background,
    bigPicture,
    executive,
    objectivesSummary,
    projectManager,
    projectScope,
    roles,
    stage,
    status,
    title,
    updated,
  } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("programme.knowledge.title")}>
        {/*useFlag("project.progress.checkpoint", id)
        ? useMatch(
            clientUrl(uris.project.progressFull, {
              id,
              type: "checkpoint",
              reportId,
            }),
          ) && <CheckpointReport projectId={id} reportId={reportId} />
        : useMatch(
            clientUrl(uris.project.progressFull, {
              id,
              type: "checkpoint",
              reportId,
            }),
          ) && (
            <Section>
              <SummaryCard className={"pd-lg"}>
                <i>Checkpoint report is not available for this project</i>
              </SummaryCard>
            </Section>
          )*/}

        {useMatch(
          clientUrl(uris.programme.knowledgeFull, {
            id,
            type: "highlight",
            reportId,
          }),
        ) && <HighlightReport programmeId={id} reportId={reportId} />}
        {useMatch(
          clientUrl(uris.programme.knowledgeFull, {
            id,
            type: "endStage",
            reportId,
          }),
        ) && (
          <HighlightReport
            programmeId={id}
            reportId={reportId}
            type="endStage"
          />
        )}
        {useMatch(
          clientUrl(uris.programme.knowledgeFull, {
            id,
            type: "endProject",
            reportId,
          }),
        ) && (
          <HighlightReport
            programmeId={id}
            reportId={reportId}
            type="endProject"
          />
        )}
        {useMatch(
          clientUrl(uris.programme.knowledgeReportIdPdf, {
            id,
            reportId,
          }),
        ) && (
          <HighlightReportPdf
            programmeId={id}
            reportId={reportId}
            reportType="programme"
          />
        )}
      </Section>
    </Page>
  );
};
