import React from 'react';
import { useStyleParams } from '../hooks/useStyleParams';

export const Div = (props) => {
  const {hide, ...stilyProps } = useStyleParams(props);
  if(hide) {
    return null;
  }

  return (
    <div {...stilyProps} />
  )
}
