import { BlockChart } from "@/base/charts/BlockChart";
import { Link, Popover, Stack, Typography } from "@mui/material";
import React, { Ref, useCallback, useRef, useState } from "react";
import { useT } from "@/hooks/useT";
import { useNavigate } from "react-router-dom";
import { clientUrl } from "@/util/routing";
import { uris } from "@/config/nav";

type IssuesBlockChartProps = {
  id: string;
  type: string;
  issues: any[];
};

export const IssuesBlockChart = ({
  id,
  type,
  issues,
}: IssuesBlockChartProps) => {
  const { t } = useT();
  const navigate = useNavigate();

  const [blockChartPopoverOpen, setBlockChartPopoverOpen] = useState(false);
  const [blockChartItems, setBlockChartItems] = useState<any[]>([]);
  const anchorEl = useRef<HTMLElement | null>(null);

  const handleBlockSelected = useCallback(
    (index: number, ref: HTMLElement, cellItems: any[]) => {
      anchorEl.current = ref;
      setBlockChartItems(cellItems);
      setBlockChartPopoverOpen(true);
    },
    [],
  );

  const handleOpenIssue = useCallback(
    (issueId: string) => {
      navigate(
        // @ts-ignore
        clientUrl(uris[type].changeDetails, {
          id,
          issueId,
        }),
      );
    },
    [navigate, id],
  );

  return (
    <>
      <BlockChart
        data={issues}
        xLabel={t("project.risksAndIssues.severity")}
        yLabel={t("project.risksAndIssues.priority")}
        onBlockSelected={handleBlockSelected}
        hideTooltips
      />
      <Popover
        onClose={() => {
          setBlockChartPopoverOpen(false);
          anchorEl.current = null;
        }}
        open={blockChartPopoverOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack
          sx={{
            p: 1,
          }}
        >
          {blockChartItems.map((issue) => (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              key={issue.no}
            >
              <Link
                onClick={() => {
                  setBlockChartPopoverOpen(false);
                  anchorEl.current = null;
                  handleOpenIssue(issue.id);
                }}
                variant="body2"
              >
                {issue.no}
              </Link>
              <Typography variant="body2">{issue.title}</Typography>
            </Stack>
          ))}
        </Stack>
      </Popover>
    </>
  );
};
