
import React from 'react';

export const UserOn = ({className, style, onClick}) => {
  return (
    <svg className={className} style={style} onClick={onClick}
         width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1.75C6.20507 1.75 4.75 3.20507 4.75 5C4.75 6.79493 6.20507 8.25 8 8.25C9.79493 8.25 11.25 6.79493 11.25 5C11.25 3.20507 9.79493 1.75 8 1.75ZM3.25 5C3.25 2.37665 5.37665 0.25 8 0.25C10.6234 0.25 12.75 2.37665 12.75 5C12.75 7.62335 10.6234 9.75 8 9.75C5.37665 9.75 3.25 7.62335 3.25 5ZM5 13.75C3.20507 13.75 1.75 15.2051 1.75 17C1.75 17.6904 2.30964 18.25 3 18.25H13C13.6904 18.25 14.25 17.6904 14.25 17C14.25 15.2051 12.7949 13.75 11 13.75H5ZM0.25 17C0.25 14.3766 2.37665 12.25 5 12.25H11C13.6234 12.25 15.75 14.3766 15.75 17C15.75 18.5188 14.5188 19.75 13 19.75H3C1.48122 19.75 0.25 18.5188 0.25 17ZM22.5303 6.53033C22.8232 6.23744 22.8232 5.76256 22.5303 5.46967C22.2374 5.17678 21.7626 5.17678 21.4697 5.46967L18 8.93934L16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967C15.1768 7.76256 15.1768 8.23744 15.4697 8.53033L17.4697 10.5303C17.7626 10.8232 18.2374 10.8232 18.5303 10.5303L22.5303 6.53033Z" fill="#22282F"/>
    </svg>

  );
};
