import React, { useState } from "react";
import { Icon } from "../base/Icon";
import { useNavigate } from "react-router-dom";
import { uris } from "../config/nav";
import { clientUrl } from "../util/routing";

function performSearch(query) {
  window.location = "/external/search?criteria=" + query;
}

export const PageSearch = () => {
  const [query, updateQuery] = useState("");
  const navigate = useNavigate();

  return (
    <span className={"page-search"}>
      <Icon i={"search"} />
      <input
        type={"text"}
        onChange={(event) => updateQuery(event.target.value)}
        value={query}
        placeholder={"Search"}
        className={"page-search-input"}
        onKeyDown={(event) =>
          event.key === "Enter"
            ? navigate(clientUrl(uris.searchResults, { query: query.trim() }))
            : null
        }
      />
    </span>
  );
};
