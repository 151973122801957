import React, { useCallback, useMemo, useState } from "react";
import { Page } from "../../layout/Page";
import { useT } from "../../hooks/useT";
import { portfolio, projectType } from "../../queries";
import { Section } from "../../layout/Section";
import { useParams } from "react-router-dom";
import { path, isNil } from "ramda";
import { useQ } from "../../hooks/useQ";
import { InitiativeIcon } from "../../base/InitiativeIcon";
import { CustomAccordion } from "../../layout/CustomAccordion";
import {
  Stack,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Popover,
} from "@mui/material";
import { ReadMoreTypography } from "../../base/typography/ReadMoreTypography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useMutation } from "react-query";
import { useToast } from "../../hooks/useToast";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { clientUrl } from "../../util/routing";
import { uris } from "../../config/nav";
import { TableStat } from "../../base/TableStat";
import { InitiativeLink } from "../../base/InitiativeLink";
import { StageFlag } from "../../base/StageFlag";

const mapTableChildren = (data) => {
  return data.map(({ items, ...rest }) => {
    return { ...rest, items, children: items };
  });
};

export const PortfolioTable = ({
  data,
  draggable,
  droppable,
  hideHeader = false,
  ...props
}) => {
  const { t } = useT();
  const { data: projectTypes = [], refetch: refetchprojectTypes } = useQ(
    `projectTypes`,
    () => projectType.list(),
  );

  return (
    <CustomAccordion
      tableView
      draggable={draggable}
      droppable={droppable}
      {...props}
      header={
        !hideHeader
          ? {
              columns: [
                {
                  title: t("generic.titles.priority"),
                  flex: 2,
                },
                {
                  title: t("generic.titles.name"),
                  flex: 4,
                },
                {
                  title: t("generic.titles.status"),
                  width: 70,
                },
                {
                  title: t("portfolio.priority.table.part"),
                  flex: 2,
                },
                {
                  title: "",
                  width: 70,
                },
                ...["benefits", "time", "cost", "quality", "scope", "risk"].map(
                  (name) => ({
                    title: t("generic.status." + name),
                    width: 70,
                    sx: { textAlign: "center" },
                  }),
                ),
              ],
            }
          : null
      }
      rows={
        !data?.length
          ? [
              {
                id: "empty",
                expanded: false,
                expandDisabled: true,
                columns: [
                  {
                    title: "Drop here your items",
                    flex: 1,
                  },
                ],
                sx: {
                  backgroundColor: "#eee",
                  border: "3px dashed #ccc",
                },
              },
            ]
          : data.map((data, index) => {
              const { type, id, name, level, part } = data;
              return {
                id,
                expandDisabled: true,
                expanded: false,
                columns: [
                  {
                    content: (
                      <Stack direction="row" alignItems="center">
                        {draggable && <MoreVertIcon />}
                        {droppable && (
                          <Typography sx={{ mr: 1 }}>{index + 1}</Typography>
                        )}
                        <InitiativeIcon initiativeType={type} />
                      </Stack>
                    ),
                    flex: 2,
                  },
                  {
                    content: (
                      <InitiativeLink
                        type={type}
                        id={id}
                        title={name}
                        level={level}
                      />
                    ),
                    flex: 4,
                    sx: { overflow: "hidden" },
                  },
                  {
                    content: <TableStat data={data} name={null} hide={null} />,
                    width: 70,
                  },
                  {
                    title: part,
                    flex: 2,
                  },
                  {
                    content: (
                      <StageFlag data={data} projectTypes={projectTypes} />
                    ),
                    width: 70,
                  },
                  ...[
                    "benefits",
                    "time",
                    "cost",
                    "quality",
                    "scope",
                    "risk",
                  ].map((name) => ({
                    content: <TableStat data={data} name={name} />,
                    width: 70,
                    sx: { textAlign: "center" },
                  })),
                ],
              };
            })
      }
    />
  );
};

function onlyActiveChildren(item) {
  return {
    ...item,
    items: isNil(item.items)
      ? item.items
      : item.items.filter((child) => child.isStarted),
  };
}

export const Details = () => {
  const { t } = useT();
  const toast = useToast();
  const { id } = useParams();

  const [showInactive, setShowInactive] = useState(false);

  const {
    isLoading,
    data: portfolioData = {},
    refetch: refetchPortfolios,
  } = useQ(`portfolio-${id}`, () => portfolio.single({ id: id }));
  const { name, description, items = [] } = portfolioData;

  const projectsAndReleases = useMemo(
    () => ({
      activeData: mapTableChildren(
        items
          .filter((item) => item.isStarted === true)
          .filter((item) => item.type !== "product")
          .filter((item) => item.priority !== undefined)
          .map((item) => onlyActiveChildren(item)),
      ),
      withoutPriority: mapTableChildren(
        items
          .filter((item) => item.isStarted === true)
          .filter((item) => item.type !== "product")
          .filter((item) => item.priority === undefined)
          .map((item) => onlyActiveChildren(item))
          .map((item) => ({ ...item, level: "product" })),
      ),
      inactiveData: mapTableChildren(
        items
          .filter((item) => item.isStarted !== true)
          .filter((item) => item.type !== "product")
          .map((item) => onlyActiveChildren(item))
          .map((item) => ({ ...item, level: "product" })),
      ),
    }),
    [items],
  );

  const productsAndPlatforms = useMemo(
    () => ({
      activeData: mapTableChildren(
        items
          .filter((item) => item.type === "product")
          .filter((item) => item.priority !== undefined)
          .map((item) => onlyActiveChildren(item))
          .map((item) => ({
            ...item,
            items: item.items
              ? item.items.map((item2) => ({
                  ...item2,
                  level: "product",
                }))
              : item.items,
          })),
      ),
      withoutPriority: mapTableChildren(
        items
          .filter((item) => item.type === "product")
          .filter((item) => item.priority === undefined)
          .map((item) => ({
            ...item,
            level: "product",
          })),
      ),
    }),
    [items],
  );

  const prioritize = useMutation(portfolio.prioritize, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      refetchPortfolios();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating portfolios");
    },
  });

  const handleDrop = useCallback(
    ({ source, target }) => {
      prioritize.mutate({
        portfolioId: id,
        portfolioName: portfolioData.name,
        itemId: source.id,
        position: target.index,
      });
    },
    [prioritize],
  );

  return (
    <Page isLoading={isLoading} title={name}>
      <Typography variant="h1" mt={3}>
        {name}
      </Typography>
      <Stack alignItems="flex-end" mt={3}>
        <Button
          variant="text"
          startIcon={<AddCircleOutlineIcon />}
          href={clientUrl(uris.new, { portfolioId: id })}
        >
          {t("portfolio.add")}
        </Button>
      </Stack>
      <Section title={t("portfolio.projectAndReleases")}>
        <Stack spacing={4}>
          {!!description && <ReadMoreTypography text={description} />}
          <PortfolioTable
            data={projectsAndReleases.activeData}
            draggable
            droppable
            onDrop={handleDrop}
          />
          <Stack spacing={2}>
            <Typography variant="h2">
              {t("portfolio.dropPrioritizedItems")}
            </Typography>
            <PortfolioTable
              data={projectsAndReleases.withoutPriority}
              draggable
            />
          </Stack>
          {projectsAndReleases.inactiveData.length ? (
            showInactive ? (
              <Stack spacing={2}>
                <Button onClick={() => setShowInactive(false)}>
                  Hide inactive projects and releases
                </Button>
                <PortfolioTable data={projectsAndReleases.inactiveData} />
              </Stack>
            ) : (
              <Button onClick={() => setShowInactive(true)}>
                Show {projectsAndReleases.inactiveData.length} inactive projects
                and releases
              </Button>
            )
          ) : null}
        </Stack>
      </Section>
      <Section title={t("portfolio.productsAndPlatforms")}>
        <PortfolioTable data={productsAndPlatforms.activeData} />
        <PortfolioTable data={productsAndPlatforms.withoutPriority} />
      </Section>
    </Page>
  );
};
