import { isEmpty, filter, has, keys, isNil } from 'ramda';

// Function to prepare form values before posting them
export const prepareFormValues = (v) => {
  // First step: Take out values from Select fields and just send the "value" attribute
  // {formField: {label: "Selected Input Value", value 42}}
  // =>
  // {formField: 42}

  const selectedValues = keys(v)
    .reduce((acc, curr) => {
      return { ...acc, [curr]: has('value', v[curr]) ? v[curr].value : v[curr] };
    }, {});


  return selectedValues
};



export const formErrorsToTooltip = (errors) => {

  return keys(errors).reduce((acc, curr) => {
    return `${acc} ${errors[curr]} \n`
  }, '');
}
