import React from "react";
import { useParams } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { useQ } from "../../hooks/useQ";
import { programme } from "../../queries";
import { RiskRegister } from "../projects/components/RiskRegister";

export const ProgrammeRisk = () => {
  const { id = "", riskId } = useParams();
  const { t } = useT();

  const { isLoading, data = {} } = useQ(`programme-${id}`, () =>
    programme.single({ id }),
  );

  const { title } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.risk.title")}>
        <RiskRegister programmeId={id} type="programme" riskId={riskId} />
      </Section>
    </Page>
  );
};
