import React, { useState } from "react";
import { useT } from "../../hooks/useT";
import { Button, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { is } from "ramda";
import { Icon } from "../Icon";

export const ReadMoreTypography = ({ text, title, ...props }) => {
  const { t } = useT();
  const [more, setMore] = useState(false);
  const isLong = text.length > 150;

  const preview = (text) => {
    if (isLong) {
      const cut = text.indexOf(" ", 100);
      return `${text.substring(0, cut)} ...`;
    }
    return text;
  };

  if (!isLong) {
    return <Typography>{text}</Typography>;
  }

  return (
    <Stack alignItems="flex-start" spacing={2}>
      <Typography title={title}>{more ? text : preview(text)}</Typography>
      <Button
        variant="text"
        endIcon={!more ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        onClick={() => setMore((v) => !v)}
      >
        {more ? t("generic.button.read_less") : t("generic.button.read_more")}{" "}
      </Button>
    </Stack>
  );
};
