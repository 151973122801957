import React, { useReducer } from 'react';
import { isNil, prop } from 'ramda';

const authProviderStorageKey = 'PROGRETO_AUTH';

const initialAuthState = {authenticated: false, user: null};



const authReducer = (state, action) => {
  switch(prop('type', action)) {
    case 'LOGIN':
      return {...state, authenticated: true, user: prop('payload', action)}
    case 'LOGOUT':
      return {...state, authenticated: false, user: null}
    default:
      return state;
  }
}

const wrapLocalStorage = (reducer, state, action) => {
  const newState = reducer(state, action);
  localStorage.setItem(authProviderStorageKey, JSON.stringify(newState));

  return newState;
}

const wrapInitialState = (state) => {
  let hydrate = null;
  try {
    hydrate = JSON.parse(localStorage.getItem(authProviderStorageKey));
  } catch (e) {
    hydrate = null;
  }
  if(isNil(hydrate)) {
    return state;
  }
  return hydrate;
}

export const AuthenticationContext = React.createContext();

export const AuthenticationProvider = ({children}) => {
  const [state, dispatch] = useReducer((state, action) => wrapLocalStorage(authReducer, state, action),
    wrapInitialState(initialAuthState));

  return (
    <AuthenticationContext.Provider value={[state, dispatch]}>
      {children}
    </AuthenticationContext.Provider>
  )
}
