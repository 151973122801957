import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import React from "react";

export const DataBreach = () => {
  const numberOfHours = 72;
  return (
    <Section id="10">
      <Typography variant="h2">
        10. Notification of personal data breach
      </Typography>
      <ol>
        <li>
          In case of any personal data breach, the data processor shall, without
          un-due delay after having become aware of it, notify the data
          controller of the personal data breach.
        </li>

        <li>
          The data processor's notification to the data controller shall, if
          possible, take place within {numberOfHours} after the data processor
          has become aware of the personal data breach to enable the data
          controller to comply with the data controller's obligation to notify
          the personal data breach to the competent supervisory authority, cf.
          Article 33 GDPR.
        </li>

        <li>
          In accordance with Clause 9(2)(a), the data processor shall assist the
          data controller in notifying the personal data breach to the competent
          supervisory authority, meaning that the data processor is required to
          assist in obtaining the information listed below which, pursuant to
          Article 33(3)GDPR, shall be stated in the data controller's
          notification to the competent supervisory au-thority:{" "}
          <ol type="a">
            <li>
              The nature of the personal data including where possible, the
              cate-gories and approximate number of data subjects concerned and
              the categories and approximate number of personal data records
              con-cerned;{" "}
            </li>

            <li>the likely consequences of the personal data breach;</li>

            <li>
              the measures taken or proposed to be taken by the controller to
              ad-dress the personal data breach, including, where appropriate,
              measures to mitigate its possible adverse effects.{" "}
            </li>
          </ol>
        </li>

        <li>
          The parties shall define in Appendix C all the elements to be provided
          by the data processor when assisting the data controller in the
          notification of a per-sonal data breach to the competent supervisory
          authority.
        </li>
      </ol>
    </Section>
  );
};
