import React from "react";
import noMobileClip from "../../assets/cliparts/no-mobile.svg";
import logo from "../../assets/logo-inv.png";
import { CenterH } from "../CenterH";
import { Backlink } from "../Backlink";
import { includes, is } from "ramda";
import { useScreen } from "../../hooks/useScreen";
import { useT } from "../../hooks/useT";
import { useOverrideScreen } from "../../hooks/useOverrideScreen";

export const NoMobileView = ({ show }) => {
  const screen = useScreen();
  const doShow = show === screen || (is(Array, show) && includes(screen, show));
  const { t } = useT();
  const overrideScreen = useOverrideScreen();

  if (!doShow) {
    return null;
  }

  return (
    <div className={"no-mobile-view"}>
      <CenterH className={"txt-a-c f-d-c f-ai-c "}>
        <img
          src={noMobileClip}
          alt={"clipart no mobile view"}
          width={"200px"}
        />
        <br />
        <p>{t("generic.error.no_mobile_view_available")}</p>

        <img src={logo} alt={"Project Link Logo"} width={"210px"} />
        <br />
        <Backlink />
        <br />
        <br />
        <span className={"link"} onClick={() => overrideScreen("nm")}>
          {t("generic.button.showDesktopVersion")}
        </span>
      </CenterH>
    </div>
  );
};
