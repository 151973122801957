import { Typography } from "@mui/material";
import { DiffMethod, StringDiff } from "react-string-diff";
import { CustomAccordion } from "@/layout/CustomAccordion";
import React from "react";
import * as Diff from "diff";

export const generateHTMLDiffString = (oldValue: string, newValue: string) => {
  const diff = Diff[DiffMethod.Chars](
    oldValue.replace(/(<br>|<li>)/g, "\n").replace(/<\/?[^>]+(>|$)/g, ""),
    newValue.replace(/(<br>|<li>)/g, "\n").replace(/<\/?[^>]+(>|$)/g, ""),
  );
  return diff
    .map((part, index) => {
      return part.added
        ? `<span style="background: lightgreen">${part.value}</span>`
        : part.removed
          ? `<span style="background: salmon">${part.value}</span>`
          : part.value;
    })
    .join("")
    .replace(/\n/g, "<br>");
};

type DiffTableProps = {
  properties: {
    name: string;
    valueBefore: string;
    valueAfter: string;
    type: "string" | "html";
  }[];
};

export const DiffTable = ({ properties }: DiffTableProps) => {
  return (
    <KeyValueTable
      properties={properties
        .filter(({ valueBefore, valueAfter }) => valueBefore !== valueAfter)
        .map(({ name, valueAfter, valueBefore, type }) => ({
          name,
          content:
            type === "string" ? (
              <StringDiff
                method={DiffMethod.Chars}
                oldValue={valueBefore}
                newValue={valueAfter}
              />
            ) : (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: generateHTMLDiffString(valueBefore, valueAfter),
                }}
              />
            ),
        }))}
    />
  );
};

type KeyValueTableProps = {
  properties: {
    name: string;
    content: any;
  }[];
};

export const KeyValueTable = ({ properties }: KeyValueTableProps) => {
  return (
    <CustomAccordion
      tableView
      header={{
        columns: [
          {
            content: (
              <Typography
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Field
              </Typography>
            ),
            flex: 1,
            sx: { alignSelf: "flex-start" },
          },
          {
            content: (
              <Typography
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Value
              </Typography>
            ),
            flex: 5,
          },
        ],
        details: "",
        expanded: false,
      }}
      rows={properties.map((property) => {
        return {
          columns: [
            {
              content: (
                <Typography sx={{ fontWeight: "bold" }}>
                  {property.name}
                </Typography>
              ),
              flex: 1,
              sx: { alignSelf: "flex-start" },
            },
            {
              content: property.content,
              flex: 5,
              sx: { alignSelf: "flex-start" },
            },
          ],
          details: "",
          expanded: false,
        };
      })}
    />
  );
};
