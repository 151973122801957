"use strict";

let mostlyGreenPriority = [
  { row: 5, column: 5, classification: "high" }, //1
  { row: 4, column: 5, classification: "high" }, //2
  { row: 5, column: 4, classification: "high" }, //3
  { row: 4, column: 4, classification: "medium" }, //4
  { row: 3, column: 5, classification: "medium" }, //5
  { row: 5, column: 3, classification: "medium" }, //6
  { row: 3, column: 4, classification: "medium" }, //7
  { row: 4, column: 3, classification: "medium" }, //8
  { row: 2, column: 5, classification: "medium" }, //9
  { row: 5, column: 2, classification: "medium" }, //10
  { row: 3, column: 3, classification: "low" }, //11
  { row: 2, column: 4, classification: "low" }, //12
  { row: 4, column: 2, classification: "low" }, //13
  { row: 2, column: 3, classification: "low" }, //14
  { row: 3, column: 2, classification: "low" }, //15
  { row: 1, column: 5, classification: "low" }, //16
  { row: 5, column: 1, classification: "low" }, //17
  { row: 1, column: 4, classification: "low" }, //18
  { row: 2, column: 2, classification: "low" }, //19
  { row: 4, column: 1, classification: "low" }, //20
  { row: 3, column: 1, classification: "low" }, //21
  { row: 1, column: 3, classification: "low" }, //22
  { row: 1, column: 2, classification: "low" }, //23
  { row: 2, column: 1, classification: "low" }, //24
  { row: 1, column: 1, classification: "low" }, //25
];

let defaultPriority = [
  { row: 5, column: 5, classification: "high" }, //1
  { row: 4, column: 5, classification: "high" }, //2
  { row: 5, column: 4, classification: "high" }, //3
  { row: 3, column: 5, classification: "high" }, //4
  { row: 4, column: 4, classification: "high" }, //5
  { row: 5, column: 3, classification: "high" }, //6
  { row: 2, column: 5, classification: "high" }, //7
  { row: 3, column: 4, classification: "high" }, //8
  { row: 4, column: 3, classification: "medium" }, //9
  { row: 2, column: 4, classification: "medium" }, //10
  { row: 3, column: 3, classification: "medium" }, //11
  { row: 1, column: 5, classification: "medium" }, //12
  { row: 5, column: 2, classification: "medium" }, //13
  { row: 2, column: 3, classification: "medium" }, //14
  { row: 4, column: 2, classification: "medium" }, //15
  { row: 1, column: 4, classification: "medium" }, //16
  { row: 3, column: 2, classification: "medium" }, //17
  { row: 1, column: 3, classification: "low" }, //18
  { row: 2, column: 2, classification: "low" }, //19
  { row: 5, column: 1, classification: "low" }, //20
  { row: 4, column: 1, classification: "low" }, //21
  { row: 1, column: 2, classification: "low" }, //22
  { row: 3, column: 1, classification: "low" }, //23
  { row: 2, column: 1, classification: "low" }, //24
  { row: 1, column: 1, classification: "low" }, //25
];

//let priority=mostlyGreenPriority; //defaultPriority;

function sortByColumn(cellA, cellB) {
  return cellA.column < cellB.column ? -1 : cellA.column > cellB.column ? 1 : 0;
}

function getPriorityProfile(profile) {
  return profile !== "mostlyGreen" ? defaultPriority : mostlyGreenPriority;
}

function cellRow(row, profile) {
  let priority = getPriorityProfile(profile);
  return {
    label: row.toString(),
    value: row,
    colors: priority
      .filter((cell) => cell.row === row)
      .sort(sortByColumn)
      .map((cell) => cell.classification),
  };
}

export function getTableColors(size, profile) {
  return [5, 4, 3, 2, 1].map((row) => cellRow(row, profile));
}

function exposure(item) {
  var row = parseInt(item.row);
  var column = parseInt(item.column);
  return item.status === "Closed" ||
    item.row === undefined ||
    item.column === undefined
    ? 0
    : row * column + column * column * row;
}

function exposurePriority(item, profile) {
  let priority = getPriorityProfile(profile);
  var foundIndex = priority.length;
  var index = 0;
  while (foundIndex === priority.length && index < priority.length) {
    foundIndex =
      priority[index].row === parseInt(item.row) &&
      priority[index].column === parseInt(item.column)
        ? index
        : priority.length;
    index++;
  }
  return foundIndex;
}

function exposureClassification(item, profile) {
  var index = exposurePriority(item, profile);

  var priority = getPriorityProfile(profile);
  return index !== undefined && index >= 0 && index < priority.length
    ? priority[index].classification
    : undefined;
}

function compareValue(a, b) {
  return a > b
    ? new Compare(a, b, 1)
    : a < b
    ? new Compare(a, b, -1)
    : new Compare(a, b, 0);
}

function compareStatus(a, b) {
  var getStatus = (value) =>
    value !== undefined && value === "Closed" ? 1 : 0;
  return compareValue(getStatus(a), getStatus(b), 0);
}

class Compare {
  constructor(a, b, outcome) {
    this.value = outcome;
    this.log = { evaluate: a + " vs " + b };
  }

  evaluate(compare, type) {
    this.value = compare.value;
    this.log = Object.assign({}, this.log, {
      calculations: (this.log.calculations !== undefined
        ? this.log.calculations
        : []
      ).concat({ type: type, compares: compare.log, outcome: compare.value }),
    });
    return this;
  }

  isDefined() {
    return this.value !== undefined && this.value !== 0;
  }

  compareValue(a, b) {
    return this.isDefined() ? this : this.evaluate(compareValue(a, b), "value");
  }

  compareStatus(a, b) {
    return this.isDefined()
      ? this
      : this.evaluate(compareStatus(a, b), "status");
  }
}

function comparePriority(a, b, profile) {
  let valueA = exposurePriority(a, profile);
  let valueB = exposurePriority(b, profile);

  var outcome = new Compare(a.no + ". " + a.title, b.no + ". " + b.title)
    .compareStatus(a.status, b.status)
    .compareValue(valueA, valueB)
    .compareValue(parseInt(a.no), parseInt(b.no));
  return outcome.value;
}

export function getProfileSort(profile) {
  return (a, b) => comparePriority(a, b, profile);
}

export function priorityColor(item, profile) {
  var color =
    (item !== item.row) !== undefined && item.column !== undefined
      ? exposureClassification(item, profile)
      : item;
  if (item.type.toLowerCase() === "opportunity") {
    switch (color) {
      case "low":
        return "#f0f0f0";
      case "medium":
        return "#f4c403";
      case "high":
        return "#47b857";
      default:
        return "#fff";
    }
  } else {
    switch (color) {
      case "low":
        return "#47b857";
      case "medium":
        return "#f4c403";
      case "high":
        return "#f85252";
      default:
        return "#fff";
    }
  }
}

function allItems(createItem, profile) {
  let priority = getPriorityProfile(profile);
  return priority.map((item, index) => createItem(item, index));
}
