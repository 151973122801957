import axios from "axios";
//import { path } from "ramda";

export const client = axios.create({
  baseURL: "/api",
  timeout: 15000,
});

export const restClient = axios.create({
  baseURL: "/api/v1",
  timeout: 15000,
});
/*
client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.config.method === "get") {
      if (path(["response", "status"], error) === 500) {
        window.location = "/500";
      }
      if (path(["response", "status"], error) === 404) {
        window.location = "/404";
      }
      if ([401, 403].includes(path(["response", "status"], error))) {
        if (location.pathname !== "/login" || location.pathname !== "/login/") {
          window.location = "/401";
        } else {
          console.log("[TRACE] Is already on loginpage, ignore rediret to 401");
        }
      }
    } else {
      if ([401, 403].includes(path(["response", "status"], error))) {
        window.open("/login", "_blank", "noopener,noreferrer");
        window.alert("Log in on the opened tab and try again.");
      }
    }
    return Promise.reject(error);
  },
);

restClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.config.method === "get") {
      if (path(["response", "status"], error) === 500) {
        window.location = "/500";
      }
      if (path(["response", "status"], error) === 404) {
        window.location = "/404";
      }
      if ([401, 403].includes(path(["response", "status"], error))) {
        if (location.pathname !== "/login" || location.pathname !== "/login/") {
          window.location = "/401";
        } else {
          console.log("[TRACE] Is already on loginpage, ignore rediret to 401");
        }
      }
    } else {
      if ([401, 403].includes(path(["response", "status"], error))) {
        window.open("/login", "_blank", "noopener,noreferrer");
        window.alert("Log in on the opened tab and try again.");
      }
    }
    return Promise.reject(error);
  },
);
*/
