import React, { useMemo } from "react";
import { useMatch, useParams } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { useQ } from "../../hooks/useQ";
import { programme } from "../../queries";
import { clientUrl } from "../../util/routing";
import { uris } from "../../config/nav";
import { IssueRegister } from "../projects/components/IssueRegister";
import { useT } from "../../hooks/useT";

export const Decisions = () => {
  const { t } = useT();
  const { id = "", issueId } = useParams();

  const { isLoading, data = {} } = useQ(`programme-${id}`, () =>
    programme.single({ id }),
  );

  const { title } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.navigation.change.title")}>
        <IssueRegister programmeId={id} type={"programme"} issueId={issueId} />
      </Section>
    </Page>
  );
};
