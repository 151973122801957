import { Step, StepButton, Stepper } from "@mui/material";
import React from "react";
import { useT } from "@/hooks/useT";
import AdjustIcon from "@mui/icons-material/Adjust";

type StepperSectionsProps = {
  activeStep: number;
  setActiveStep: (step: number) => void;
  steps: string[];
  formatLabel: (step: any) => string;
};

export const StepperSections = ({
  activeStep,
  setActiveStep,
  steps,
  formatLabel,
}: StepperSectionsProps) => {
  const { t } = useT();

  return (
    <Stepper nonLinear activeStep={activeStep}>
      {steps.map((step, index) => {
        return (
          <Step key={step}>
            <StepButton
              icon={<AdjustIcon />}
              onClick={() => setActiveStep(index)}
              data-cy={`stage-step-button-${step}`}
              sx={{
                "&.MuiStepButton-root ": {
                  color: "rgba(0, 0, 0, 0.26)",
                },
              }}
            >
              {formatLabel(step)}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};
