import React from "react";
import { useParams } from "react-router-dom";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { useT } from "@/hooks/useT";
import { RiskRegister } from "./components/RiskRegister";
import { useQ } from "@/hooks/useQ";
import { project } from "@/queries";

export const Risk = () => {
  const { id, riskId = "" } = useParams();
  const { t } = useT();

  const { isLoading, data = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );

  const { title } = data;

  return (
    <Page isLoading={isLoading} title={title}>
      <Section title={t("project.risk.title")}>
        <RiskRegister projectId={id} riskId={riskId} />
      </Section>
    </Page>
  );
};
