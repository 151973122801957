import React, { useContext, useEffect, useState } from 'react';

import { useStyleParams } from '../../hooks/useStyleParams';
import { E } from '../../layout/Grid';
import { has, is, isEmpty, isNil, keys, prop, propOr } from 'ramda';
import { Icon } from '../Icon';
import { useDrag, useDrop } from 'react-dnd';
import { useDebouncedCallback } from 'use-debounce';
import { SummaryCard } from '../project/SummaryCard';

const DROPTABLE_TYPE = 'DROPTABLE_TR';
const ShowChildrenContext = React.createContext({});
const ShowChildContext = React.createContext({});

const identifyComponentData = (data) => {
  if (has('id', data)) {
    return data.id;
  }
  return keys(data)
    .filter(k => k !== 'children')
    .map((k) => data[k])
    .join('');
};

export const DataTableHierarchy = ({ data, config }) => {
  const { parent = [], order } = config;
  return `${parent.map(v => v + 1)
    .join('.')}${parent.length > 0 ? '.' : ''}${order + 1}`;
};

export const DataTable = ({ children, ...rest }) => {
  const { className, style } = useStyleParams(rest);
  const [showChildrenState, setShowChildrenState] = useState({});
  const [showChildState, setShowChildState] = useState({});

  const componentState = (state, setState) => (id) => {
    return [
      propOr(false, id, state),
      (v) => {
        if (is(Function, v)) {
          setState((current) => {
            const vof = v(current[id]);
            return { ...current, [id]: vof };
          });
        } else {
          setState((current) => ({ ...current, [id]: v }));
        }
      }
    ];
  };
  return (
    <ShowChildrenContext.Provider value={componentState(showChildrenState, setShowChildrenState)}>
      <ShowChildContext.Provider value={componentState(showChildState, setShowChildState)}>
        <div className={`data-table ${className}`} style={style}>
          {children}
        </div>
      </ShowChildContext.Provider>
    </ShowChildrenContext.Provider>
  );
};

export const DataTableHeading = ({ headings, ...rest }) => {
  const { className, style, cols } = useStyleParams(rest);

  return (
    <div className={`data-table__headrow ${className}`}
         style={{ gridTemplateColumns: cols, ...style }}>
      {headings.map((heading, idx) => {
        return <E className={'cell'} key={idx}>{heading}</E>;
      })}
    </div>
  );
};

export const DropZone = ({ children, targetOrder, targetParent, title, dragId, ...rest }) => {
  const { className, style } = useStyleParams(rest);

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: DROPTABLE_TYPE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop: () => {
      return { targetOrder, targetParent, dragId };
    }
  }));

  return (
    <>
      {!isNil(title) && <span className={'txt-b'}>{title}</span>}
      <div ref={dropRef}
           className={`dropzone ${className} ${canDrop && isOver ? 'dropzone--droppable' : ''}`}>
        {children}
      </div>
    </>
  );

};

export const DropBetweenRow = ({ targetOrder, targetParent, isParentDragging, dragId }) => {

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: DROPTABLE_TYPE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop: () => {
      return { targetOrder, targetParent, dragId };
    }
  }));

  return (
    <div ref={dropRef}
         className={`data-table__droprow-between ${isParentDragging ? 'data-table__droprow-between--parent-dragging' : ''} ${canDrop && isOver ? 'data-table__droprow-between--droppable' : ''}`}>
    </div>
  );
};

export const TableChildWrapper = ({ children, ...rest }) => {
  const { className, style } = useStyleParams(rest);

  return (
    <div className={`data-table__child-wrapper ${className}`} style={style}>
      <SummaryCard className={'mg-nm pd-lg'}>
        {children}
      </SummaryCard>
    </div>
  );
};

export const DropBelowRow = ({
  onHover,
  hasTableChildren,
  targetOrder,
  targetParent,
  isParentDragging,
  dragId
}) => {

  const openChildren = useDebouncedCallback(() => {
    // is it still hovering after the 500ms?
    isOver && onHover();
  }, 500);

  const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
    accept: DROPTABLE_TYPE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: () => !hasTableChildren,
    drop: () => {
      // Create new level and drop this one as first item
      return { targetOrder: 0, targetParent: [...targetParent, targetOrder], dragId};
    }
  }));

  useEffect(() => {
    if (isOver) {
      openChildren();
    }
  }, [isOver, openChildren]);

  return (
    <div ref={dropRef}
         className={`data-table__droprow-below ${isParentDragging ? 'data-table__droprow-below--parent-dragging' : ''} ${isOver && canDrop ? 'data-table__droprow-below--droppable' : ''}`}>
      {isOver && canDrop && <Icon i={'chevron-right'} size={'lg'}/>}
    </div>
  );
};

export const DataTableRow = ({
  data,
  cells,
  config = {},
  onSort,
  isParentDragging = false,
  ...rest
}) => {
  const { className, style, cols } = useStyleParams(rest);

  const {
    level = 0,
    draggable = false,
    order = null,
    parent = [],
    canDropLevels = false,
    canDropBetween = false,
    maxDropLevelDepth = Infinity,
    dragId = 'DEFAULT'
  } = config;

  const componentId = identifyComponentData(data);
  const [showChildren, setShowChildren] = useContext(ShowChildrenContext)(componentId);
  const [showChild, setShowChild] = useContext(ShowChildContext)(componentId);

  const hasTableChildren = has('children', data) && is(Array, data.children) && !isEmpty(data.children);
  const hasRenderChild = has('child', data) && is(Function, data.child);

  const [{ isDragging, isOver }, dragRef, dragPreview] = useDrag(() => ({
    type: DROPTABLE_TYPE,
    item: { order: order, level: level, parent: parent, dragId },
    canDrag: (monitor)=>draggable,
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end: (item, monitor) => {
      const { order, level, parent, dragId } = item;
      const res = monitor.getDropResult();
      if (!isNil(res)) {
        onSort({
          currentOrder: order,
          currentParent: parent,
          currentDragId: dragId,
          targetOrder: res.targetOrder,
          targetParent: res.targetParent,
          targetDragId: res.dragId
        });
      }
    }
  }));

  const controls = {
    setShowChild,
    setShowChildren
  };

  return (
    <>
      {draggable && !isDragging && canDropBetween &&
      <DropBetweenRow targetOrder={order} targetParent={parent} isParentDragging={isParentDragging}
                      dragId={dragId}/>
      }
      <div ref={dragRef}
           className={`data-table__row ${isDragging ? 'data-table__row--is-dragging' : ''} ${className}`}
           style={{ gridTemplateColumns: cols, ...style }}>
        {cells.map((key, idx) => {
          if (is(Function, key)) {
            return <E className={`cell`} key={key}>
              {key(data, config, controls)}
            </E>;
          }
          return <E className={'cell'} key={key}>
            {idx === 0 && draggable && <Icon i={'three-dots-v'} className={'cursor-dnd pd-r-nm'}
                                             style={{ display: 'inline' }}/>}
            {prop(key, data)}
          </E>;
        })}
        {(hasTableChildren) &&
        <E className={'cell cursor-link'} onClick={() => setShowChildren(v => !v)}>
          <Icon i={showChildren ? 'chevron-down' : 'chevron-right'}/>
        </E>
        }
      </div>
      {draggable && !isDragging && canDropLevels &&
      <DropBelowRow
        dragId={dragId}
        isParentDragging={isParentDragging}
        targetOrder={order}
        targetParent={parent}
        onHover={() => setShowChildren(true)}
        hasTableChildren={hasTableChildren}/>}


      {hasRenderChild && showChild &&
      data.child(data, config, controls)
      }


      {hasTableChildren && showChildren &&
      <DataTableAutoRows
        data={data.children}
        cols={cols}
        isParentDragging={isDragging || isParentDragging}
        cells={cells}
        onSort={onSort}
        config={{
          ...config,
          level: level + 1,
          parent: [...parent, order],
          canDropLevels: maxDropLevelDepth > level + 1
        }}
        style={style}
        className={`data-table__row--child ${isDragging ? 'data-table__row--is-dragging' : ''} level-${level + 1} ${className}`}/>
      }

    </>
  );
};

/**
 *
 * onSort = ({currentOrder, targetOrder, currentParent, targetParent}) => {
    console.log('from ', currentOrder, `(${currentParent})` , ' to ', targetOrder, `(${targetParent})`);
  }
 *
 * @param data
 * @param cells
 * @param config
 * @param onSort
 * @param isParentDragging
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */

export const DataTableAutoRows = ({ data, cells, config, onSort, isParentDragging, ...rest }) => {
  const { className, style, cols } = useStyleParams(rest);

  return (
    <>
      {data.map((row, idx) => {
        return <DataTableRow
          onSort={onSort}
          cols={cols}
          isParentDragging={isParentDragging}
          config={{ ...config, order: idx }}
          key={idx}
          data={row}
          cells={cells}
          className={className}
          style={style}/>;
      })}
    </>
  );
};
