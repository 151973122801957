import React from "react";
import { useT } from "../../hooks/useT";
import { stripHtml } from "../../util/stripHtml.js";
import { isNil } from "ramda";
import { Stack, Typography } from "@mui/material";

function hasValue(content) {
  return (
    content !== undefined &&
    content !== null &&
    stripHtml(content).trim().length > 0
  );
}

export const DisplayField = ({
  realm = "project",
  area,
  name,
  data,
  additionalTitle,
  disableTitle = false,
}) => {
  const { t } = useT();
  return hasValue(data) && hasValue(data[name]) ? (
    <Stack spacing={0.5}>
      <Typography
        variant="h2"
        sx={{
          fontSize: "18px !important",
        }}
      >
        {!disableTitle
          ? t(realm + "." + (isNil(area) ? "" : area + ".") + name + ".title")
          : null}
        {additionalTitle}
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{
          __html: data[name],
        }}
      />
    </Stack>
  ) : null;
};

export const DisplayContent = ({
  realm = "project",
  area,
  data,
  fields,
  titleAdditions,
  disableTitle = false,
}) => (
  <Stack spacing={3}>
    {fields.map((name, index) => (
      <DisplayField
        key={index}
        name={name}
        data={data}
        area={area}
        realm={realm}
        additionalTitle={
          !isNil(titleAdditions) ? titleAdditions[name] : undefined
        }
        disableTitle={disableTitle}
      />
    ))}
  </Stack>
);
