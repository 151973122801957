import { Button, Divider, Paper, Stack } from "@mui/material";
import React from "react";
import {
  ColoredCard,
  ColoredCardSize,
} from "@/pages/programmes/Design/ColoredCard";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { useT } from "@/hooks/useT";

export const BenefitMap = ({
  measurableBenefits,
  outcomes,
  addBenefit,
  addOutcome,
}) => {
  const { t } = useT();
  const placeholder = <div style={{ width: 250 }} />;
  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={8}
        alignItems="center"
      >
        <ColoredCard color="#13353C" text={t("programme.design.objectives")} />
        <ColoredCard color="#007081" text={t("programme.design.benefit")} />
        <ColoredCard color="#5D7D70" text={t("programme.design.outcome")} />
      </Stack>
      <Divider
        sx={{
          width: "100%",
          height: 4,
          backgroundColor: "#F27B1C",
        }}
      />
      <Stack
        direction="row"
        justifyContent="center"
        spacing={8}
        alignItems="flex-start"
      >
        <Stack spacing={1}>{placeholder}</Stack>
        <Stack spacing={1}>
          {(measurableBenefits ?? []).length === 0 ? placeholder : null}
          {(measurableBenefits ?? []).map((benefit) => (
            <ColoredCard
              color="#007081"
              text={benefit.title}
              size={ColoredCardSize.SMALL}
            />
          ))}
          <Paper
            onClick={addBenefit}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid",
              borderRadius: 4,
              fontSize: ColoredCardSize.SMALL,
              px: 3,
              py: 1,
              fontWeight: 400,
              minWidth: 250,
              maxWidth: 250,
              cursor: "pointer",
            }}
          >
            {" "}
            {t("project.businessCase.addEditForm.addEntry")}
            <AddCircleOutlineOutlined />
          </Paper>
        </Stack>
        <Stack spacing={1}>
          {(outcomes ?? []).length === 0 ? placeholder : null}
          {(outcomes ?? []).map((benefit) => (
            <ColoredCard
              color="#5D7D70"
              text={benefit.name}
              size={ColoredCardSize.SMALL}
            />
          ))}
          <Paper
            onClick={addOutcome}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid",
              borderRadius: 4,
              fontSize: ColoredCardSize.SMALL,
              px: 3,
              py: 1,
              fontWeight: 400,
              minWidth: 250,
              maxWidth: 250,
              cursor: "pointer",
            }}
          >
            {" "}
            {t("generic.button.add")}
            <AddCircleOutlineOutlined />
          </Paper>
        </Stack>
      </Stack>
    </Stack>
  );
};
