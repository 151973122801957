import { isNil } from "ramda";

import { getProfileSort, priorityColor } from "../../util/priority";

const blockChartData = [
  [
    { status: "success" },
    { status: "warn" },
    { status: "danger" },
    { status: "danger" },
    { status: "danger" },
  ],
  [
    { status: "success" },
    { status: "warn" },
    { status: "warn" },
    { status: "danger" },
    { status: "danger" },
  ],
  [
    { status: "success" },
    { status: "warn" },
    { status: "warn" },
    { status: "danger" },
    { status: "danger" },
  ],
  [
    { status: "success" },
    { status: "success" },
    { status: "warn" },
    { status: "warn" },
    { status: "danger" },
  ],
  [
    { status: "success" },
    { status: "success" },
    { status: "success" },
    { status: "warn" },
    { status: "warn" },
  ],
];

const blockChartDataOpportunities = [
  [
    { status: "avoid" },
    { status: "warn" },
    { status: "success" },
    { status: "success" },
    { status: "success" },
  ],
  [
    { status: "avoid" },
    { status: "warn" },
    { status: "warn" },
    { status: "success" },
    { status: "success" },
  ],
  [
    { status: "avoid" },
    { status: "warn" },
    { status: "warn" },
    { status: "success" },
    { status: "success" },
  ],
  [
    { status: "avoid" },
    { status: "avoid" },
    { status: "warn" },
    { status: "warn" },
    { status: "success" },
  ],
  [
    { status: "avoid" },
    { status: "avoid" },
    { status: "avoid" },
    { status: "warn" },
    { status: "warn" },
  ],
];

function assignRiskPriority(item) {
  return item.status !== "Closed"
    ? Object.assign({}, item, {
        row: parseInt(item.probability),
        column: parseInt(item.impact),
      })
    : Object.assign({}, item, { row: undefined, column: undefined });
}

export const top5OpenRisks = (risks) =>
  risks
    .filter((item) => item.status !== "Closed")
    .map(assignRiskPriority)
    .sort(getProfileSort(undefined))
    .slice(0, 5);

function assignIssuePriority(item) {
  return item.status !== "Closed"
    ? Object.assign({}, item, {
        row: parseInt(item.priority),
        column: parseInt(item.severity),
      })
    : Object.assign({}, item, { row: undefined, column: undefined });
}

export const top5OpenIssues = (issues) =>
  issues
    .filter((item) => item.status !== "Closed")
    .map(assignIssuePriority)
    .sort(getProfileSort(undefined))
    .slice(0, 5);

export function threatMatrix(risks) {
  const filteredRisks = (risks ?? [])
    .map((item) =>
      !isNil(item.probability) && !isNil(item.impact)
        ? assignRiskPriority(item)
        : item,
    )
    .filter((item) => item.type.toLowerCase() === "threat");
  return blockChartData.map((row, probability) =>
    row.map((column, impact) => {
      const cellItems = filteredRisks.filter(
        (item) => item.row === 5 - probability && item.column === impact + 1,
      );
      return {
        status: column.status,
        label: cellItems
          .map((item) => item.no)
          .reduce((text, no) => (text !== undefined ? "..." : no), undefined),
        to: undefined,
        inBackground:
          cellItems?.length > 1 ? false : cellItems?.[0]?.inBackground ?? false,
        cellItems,
      };
    }),
  );
}

export function riskMatrix(risks) {
  const filteredRisks = (risks ?? []).map((item) =>
    !isNil(item.probability) && !isNil(item.impact)
      ? assignRiskPriority(item)
      : item,
  );
  return blockChartData.map((row, probability) =>
    row.map((column, impact) => {
      const cellItems = filteredRisks.filter(
        (item) => item.row === 5 - probability && item.column === impact + 1,
      );
      return {
        status: column.status,
        label: cellItems
          .map((item) => item.no)
          .reduce((text, no) => (text !== undefined ? "..." : no), undefined),
        to: undefined,
        inBackground:
          cellItems?.length > 1 ? false : cellItems?.[0]?.inBackground ?? false,
        cellItems,
      };
    }),
  );
}

export function issueMatrix(issues) {
  const filteredIssues = (issues ?? []).map((item) =>
    !isNil(item.priority) && !isNil(item.severity)
      ? assignIssuePriority(item)
      : item,
  );
  return blockChartData.map((row, probability) =>
    row.map((column, impact) => {
      const cellItems = filteredIssues.filter(
        (item) => item.row === 5 - probability && item.column === impact + 1,
      );
      return {
        status: column.status,
        label: cellItems
          .map((item) => item.no)
          .reduce((text, no) => (text !== undefined ? "..." : no), undefined),
        to: undefined,
        inBackground:
          cellItems?.length > 1 ? false : cellItems?.[0]?.inBackground ?? false,
        cellItems,
      };
    }),
  );
}

export function opportunityMatrix(risks) {
  const filteredOpportunities = (risks ?? [])
    .map((item) =>
      !isNil(item.probability) && !isNil(item.impact)
        ? assignRiskPriority(item)
        : item,
    )
    .filter((item) => item.type.toLowerCase() === "opportunity");
  return blockChartDataOpportunities.map((row, probability) =>
    row.map((column, impact) => {
      const cellItems = filteredOpportunities.filter(
        (item) => item.row === 5 - probability && item.column === impact + 1,
      );
      return {
        status: column.status,
        label: cellItems
          .map((item) => item.no)
          .reduce((text, no) => (text !== undefined ? "..." : no), undefined),
        to: undefined,
        inBackground:
          cellItems?.length > 1 ? false : cellItems?.[0]?.inBackground ?? false,
        cellItems,
      };
    }),
  );
}
