import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Trans } from "react-i18next";
import {
  Typography,
  Stack,
  Box,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { BlockChart } from "../../../base/charts/BlockChart";
import { riskMatrix, threatMatrix } from "../../../base/risk/Risk";
import { useQ } from "../../../hooks/useQ";
import { project, programme, release, product } from "../../../queries";
import { useT } from "../../../hooks/useT";
import { CustomAccordion } from "../../../layout/CustomAccordion";
import { RiskStatusIcon } from "./RiskStatusIcon";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormTextField } from "../../../base/form/mui/FormTextField";
import { FormSelect } from "../../../base/form/mui/FormSelect";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useMutation } from "react-query";
import { useToast } from "../../../hooks/useToast";
import { v4 as uuidv4 } from "uuid";
import { FormRadioSelector } from "../../../base/form/mui/FormRadioSelector";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";
import { useNavigate } from "react-router-dom";
import { uris } from "@/config/nav";
import { clientUrl } from "@/util/routing";
import { RiskThreatsBlockChart } from "./RiskThreatsBlockChart";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { StepperFlow } from "@/base/form/mui/StepperFlow";
import { CustomDialog } from "@/layout/CustomDialog";
import { DeleteButton } from "@/base/DeleteButton";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";

const riskSteps = ["Identify", "Assess", "Plan", "Implement"];

const riskTypeOptions = (t) => {
  return [
    { value: "threat", label: t("risk.type.threat") },
    { value: "opportunity", label: t("risk.type.opportunity") },
  ];
};

const riskCategoryOptions = (t) => [
  { value: "Time", label: t("risk.registerForm.fields.category.options.time") },
  { value: "Cost", label: t("risk.registerForm.fields.category.options.cost") },
  {
    value: "Quality",
    label: t("risk.registerForm.fields.category.options.quality"),
  },
  {
    value: "Benefit",
    label: t("risk.registerForm.fields.category.options.benefit"),
  },
  { value: "Risk", label: t("risk.registerForm.fields.category.options.risk") },
];

const riskImpactOptions = (t) => [
  { value: 5, label: t("risk.registerForm.fields.impact.options.5") },
  { value: 4, label: t("risk.registerForm.fields.impact.options.4") },
  { value: 3, label: t("risk.registerForm.fields.impact.options.3") },
  { value: 2, label: t("risk.registerForm.fields.impact.options.2") },
  { value: 1, label: t("risk.registerForm.fields.impact.options.1") },
];

const riskProbabilityOptions = (t) => [
  { value: 5, label: t("risk.registerForm.fields.probability.options.5") },
  { value: 4, label: t("risk.registerForm.fields.probability.options.4") },
  { value: 3, label: t("risk.registerForm.fields.probability.options.3") },
  { value: 2, label: t("risk.registerForm.fields.probability.options.2") },
  { value: 1, label: t("risk.registerForm.fields.probability.options.1") },
];

const riskResponseOptions = (t) => [
  {
    value: "Avoid",
    label: t("risk.registerForm.fields.response.options.avoid"),
  },
  {
    value: "Reduce",
    label: t("risk.registerForm.fields.response.options.reduce"),
  },
  {
    value: "Transfer",
    label: t("risk.registerForm.fields.response.options.transfer"),
  },
  {
    value: "Accept",
    label: t("risk.registerForm.fields.response.options.accept"),
  },
  {
    value: "Share",
    label: t("risk.registerForm.fields.response.options.share"),
  },
  {
    value: "Enhance",
    label: t("risk.registerForm.fields.response.options.exploit"),
  },
  {
    value: "Exploit",
    label: t("risk.registerForm.fields.response.options.enhance"),
  },
  {
    value: "ContingencyPlan",
    label: t("risk.registerForm.fields.response.options.contingencyPlan"),
  },
];

const riskStatusOptions = (t) => [
  {
    value: "Active",
    label: t("risk.registerForm.fields.status.options.open"),
  },
  {
    value: "Closed",
    label: t("risk.registerForm.fields.status.options.closed"),
  },
];

export const RiskForm = ({
  risk,
  onSave,
  onClose,
  t,
  isOpen,
  index,
  useScroll = true,
}) => {
  const formData = useForm({
    defaultValues: {
      id: risk?.id ?? "",
      title: risk?.title ?? "",
      owner: risk?.owner ?? "",
      type: risk?.type ?? "",
      category: risk?.category ?? "",
      cause: risk?.cause ?? "",
      event: risk?.event ?? "",
      effect: risk?.effect ?? "",
      proximity: risk?.proximity ?? "",
      impact: risk?.impact,
      probability: risk?.probability,
      response: risk?.response ?? "",
      actionee: risk?.actionee ?? "",
      action: risk?.action ?? "",
      status: risk?.status ?? riskStatusOptions(t)[0].value,
      comments: risk?.comments ?? "",
      inherentImpact: risk?.inherentRisk?.impact ?? "",
      inherentProbability: risk?.inherentRisk?.probability ?? "",
      inherentOrResidualRisk:
        !risk?.inherentRisk?.impact || !risk?.inherentRisk?.probability
          ? "inherent"
          : "residual",
    },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = formData;
  const [
    title,
    inherentOrResidualRisk,
    inherentImpact,
    inherentProbability,
    impact,
    probability,
    cause,
    event,
    effect,
    type,
    category,
    response,
    actionee,
    action,
  ] = useWatch({
    control: formData.control,
    name: [
      "title",
      "inherentOrResidualRisk",
      "inherentImpact",
      "inherentProbability",
      "impact",
      "probability",
      "cause",
      "event",
      "effect",
      "type",
      "category",
      "response",
      "actionee",
      "action",
    ],
  });

  const isCreating = !risk;

  const [activeStep, setActiveStep] = useState(0);
  const [isStepDisabled, setIsStepDisabled] = useState([]);
  const nextStep = useRef(null);

  const initialDisabledSteps = useMemo(() => {
    if (isCreating) {
      return riskSteps.map((step, index) => index > 0);
    } else {
      const disabledSteps = riskSteps.map(() => true);
      const inherentImpact = risk?.inherentRisk?.impact
        ? Number(risk?.inherentRisk?.impact)
        : undefined;
      const inherentProbability = risk?.inherentRisk?.probability
        ? Number(risk?.inherentRisk?.probability)
        : undefined;
      disabledSteps[0] = false;
      if (risk?.title?.length && risk?.type && risk?.category) {
        disabledSteps[1] = false;
        if (inherentImpact && inherentProbability) {
          disabledSteps[2] = false;
          if (risk?.response && risk?.action) {
            disabledSteps[3] = false;
          }
        }
      }
      return disabledSteps;
    }
  }, [isCreating, risk]);

  useEffect(() => {
    reset({
      id: risk?.id ?? "",
      title: risk?.title ?? "",
      owner: risk?.owner ?? "",
      type: risk?.type ?? "",
      category: risk?.category ?? "",
      cause: risk?.cause ?? "",
      event: risk?.event ?? "",
      effect: risk?.effect ?? "",
      proximity: risk?.proximity ?? "",
      impact: risk?.impact ?? undefined,
      probability: risk?.probability ?? undefined,
      response: risk?.response ?? "",
      actionee: risk?.actionee ?? "",
      action: risk?.action ?? "",
      status: risk?.status ?? riskStatusOptions(t)[0].value,
      comments: risk?.comments ?? "",
      inherentImpact: risk?.inherentRisk?.impact
        ? Number(risk?.inherentRisk?.impact)
        : "",
      inherentProbability: risk?.inherentRisk?.probability
        ? Number(risk?.inherentRisk?.probability)
        : "",
      inherentOrResidualRisk:
        !risk?.inherentRisk?.impact || !risk?.inherentRisk?.probability
          ? "inherent"
          : "residual",
    });
    const title = risk?.title;
    const type = risk?.type;
    const category = risk?.category;
    const inherentImpact = risk?.inherentRisk?.impact
      ? Number(risk?.inherentRisk?.impact)
      : undefined;
    const inherentProbability = risk?.inherentRisk?.probability
      ? Number(risk?.inherentRisk?.probability)
      : undefined;
    const response = risk?.response;
    const actionee = risk?.actionee;
    const action = risk?.action;
    if (!title?.length || !type || !category) {
      setActiveStep(0);
    } else if (!inherentImpact || !inherentProbability) {
      setActiveStep(1);
    } else if (!response || !action) {
      if (nextStep.current === 1) {
        setActiveStep(1);
      } else {
        setActiveStep(2);
      }
    } else if (nextStep.current !== null) {
      setActiveStep(nextStep.current);
      nextStep.current = null;
    } else {
      setActiveStep(3);
    }
  }, [risk, reset]);

  useEffect(() => {
    if (isCreating) {
      setIsStepDisabled(riskSteps.map((step, index) => index > 0));
    } else {
      const disabledSteps = riskSteps.map(() => true);
      const inherentImpact = risk?.inherentRisk?.impact
        ? Number(risk?.inherentRisk?.impact)
        : undefined;
      const inherentProbability = risk?.inherentRisk?.probability
        ? Number(risk?.inherentRisk?.probability)
        : undefined;
      disabledSteps[0] = false;
      if (risk?.title?.length && risk?.type && risk?.category) {
        disabledSteps[1] = false;
        if (inherentImpact && inherentProbability) {
          disabledSteps[2] = false;
          if (risk?.response && risk?.action) {
            disabledSteps[3] = false;
          }
        }
      }
      setIsStepDisabled(disabledSteps);
    }
  }, [isCreating, risk]);

  useEffect(() => {
    if (!isCreating && isDirty) {
      const disabledSteps = [...isStepDisabled];
      disabledSteps[0] = false;
      disabledSteps[1] =
        initialDisabledSteps[1] || !title?.length || !type || !category;
      disabledSteps[2] =
        initialDisabledSteps[2] ||
        !title?.length ||
        !type ||
        !category ||
        !inherentImpact ||
        !inherentProbability;
      disabledSteps[3] =
        initialDisabledSteps[3] ||
        !title?.length ||
        !type ||
        !category ||
        !inherentImpact ||
        !inherentProbability ||
        !response ||
        !action;
      setIsStepDisabled(disabledSteps);
    } else {
      setIsStepDisabled(initialDisabledSteps);
    }
  }, [
    isCreating,
    isDirty,
    initialDisabledSteps,
    title,
    type,
    category,
    inherentImpact,
    inherentProbability,
    response,
    action,
  ]);

  useEffect(() => {
    window.onbeforeunload = function (event) {
      if (isDirty) {
        const message = "Sure you want to leave?";
        if (typeof event == "undefined") {
          event = window.event;
        }
        if (event) {
          event.returnValue = message;
        }
        return message;
      }
      return undefined;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  const blockChartThreats = useMemo(() => {
    if (risk) {
      if (impact !== inherentImpact || probability !== inherentProbability) {
        return threatMatrix([
          {
            impact,
            probability,
            type: "threat",
            no: risk.no,
            inBackground: inherentOrResidualRisk === "inherent",
          },
          {
            impact: inherentImpact,
            probability: inherentProbability,
            type: "threat",
            no: risk.no,
            inBackground: inherentOrResidualRisk === "residual",
          },
        ]);
      } else {
        return threatMatrix([
          {
            impact,
            probability,
            type: "threat",
            no: risk.no,
          },
        ]);
      }
    } else {
      return threatMatrix([]);
    }
  }, [
    impact,
    inherentImpact,
    probability,
    inherentProbability,
    risk?.no,
    inherentOrResidualRisk,
  ]);

  const handleChangeProbabilityAndImpact = useCallback(
    (index) => {
      const impact = 1 + (index % 5);
      const probability = 5 - Math.floor(index / 5);
      if (inherentOrResidualRisk === "residual") {
        setValue("impact", impact, { shouldDirty: true });
        setValue("probability", probability, { shouldDirty: true });
      } else {
        setValue("inherentImpact", impact, { shouldDirty: true });
        setValue("inherentProbability", probability, { shouldDirty: true });
      }
    },
    [blockChartThreats, inherentOrResidualRisk],
  );

  useEffect(() => {
    if (isOpen && useScroll) {
      setTimeout(function () {
        window.scrollTo({ top: 690 + 72 * index, behavior: "smooth" });
      }, 150);
    }
  }, [isOpen, risk]);

  const triggerSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        if (isDirty) {
          const {
            inherentImpact,
            inherentProbability,
            inherentOrResidualRisk,
            ...rest
          } = data;
          await onSave({
            inherentRisk: {
              probability: inherentProbability,
              impact: inherentImpact,
            },
            assessment: {
              no: data?.no,
              category: inherentOrResidualRisk,
              inherent: {
                probability: inherentProbability,
                impact: inherentImpact,
              },
              residual: {
                impact: data?.impact,
                probability: data?.probability,
              },
            },
            matrixCategory: inherentOrResidualRisk,
            ...rest,
          });
          if (!risk) {
            reset();
          }
          nextStep.current = Math.min(3, activeStep + 1);
        } else {
          if (!isStepDisabled[activeStep + 1]) {
            setActiveStep((a) => a + 1);
          }
        }
      }),
    [handleSubmit, onSave, risk, reset, isDirty, isStepDisabled, activeStep],
  );

  const submitButtonLabel = useMemo(() => {
    if (isCreating) {
      return t("risk.registerForm.buttons.create");
    } else if (!isDirty) {
      if (activeStep === 0) {
        return !title?.length || !type || !category
          ? false
          : t("generic.stepper.next");
      } else if (activeStep === 1) {
        return !inherentImpact || !inherentProbability
          ? false
          : t("generic.stepper.next");
      } else if (activeStep === 2) {
        return !response || !action ? false : t("generic.stepper.next");
      }
      return false;
    } else {
      if (activeStep === 0) {
        return !title?.length || !type || !category
          ? t("risk.registerForm.buttons.update")
          : t("risk.registerForm.buttons.updateAndNext");
      } else if (activeStep === 1) {
        return !inherentImpact || !inherentProbability
          ? t("risk.registerForm.buttons.update")
          : t("risk.registerForm.buttons.updateAndNext");
      } else if (activeStep === 2) {
        return !response || !action
          ? t("risk.registerForm.buttons.update")
          : t("risk.registerForm.buttons.updateAndNext");
      } else {
        return t("risk.registerForm.buttons.update");
      }
    }
  }, [
    isCreating,
    activeStep,
    isStepDisabled,
    title,
    inherentImpact,
    inherentProbability,
    response,
    action,
    isDirty,
    type,
    category,
  ]);

  const showPreviousButton = useMemo(() => {
    return !isCreating && activeStep > 0;
  }, [isCreating, activeStep]);

  const renderSentence = (header) => (
    <Typography
      variant={header ? "h3" : undefined}
      fontWeight={header ? "bold" : undefined}
      sx={{
        pt: 3,
        pb: 2,
      }}
    >
      {`${t("risk.registerForm.formHelpText.1")}${cause}${t(
        "risk.registerForm.formHelpText.2",
      )}${(type
        ? t("risk.type." + type?.toLowerCase())
        : "(" + t("risk.registerForm.fields.type.title") + ")"
      ).toLowerCase()}${t("risk.registerForm.formHelpText.3")}${event}${t(
        "risk.registerForm.formHelpText.4",
      )}${effect}${t("risk.registerForm.formHelpText.5")}`}
    </Typography>
  );

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      scrollDependencyArray={[activeStep]}
    >
      <FormDataProvider formKey="risk.registerForm.fields" {...formData}>
        <form onSubmit={triggerSubmit}>
          <Stack spacing={3}>
            <StepperFlow
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isStepDisabled={isStepDisabled}
              steps={riskSteps}
              formatLabel={(step) =>
                t("risk.steps." + step?.toLowerCase() + ".title")
              }
            />
            {activeStep === 0 && (
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <FormTextField name="title" required />
                  <FormTextField name="owner" />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormSelect
                    name="type"
                    options={riskTypeOptions(t)}
                    required
                  />
                  <FormSelect
                    name="category"
                    options={riskCategoryOptions(t)}
                    required
                  />
                </Stack>
                {renderSentence(true)}
                <FormTextField name="cause" multiline />
                <FormTextField name="event" multiline />
                <FormTextField name="effect" multiline />
              </Stack>
            )}
            {activeStep === 1 && (
              <Stack>
                {renderSentence()}
                <Stack spacing={4} direction="row">
                  <Stack spcing={2} flex={1}>
                    <BlockChart
                      data={blockChartThreats}
                      yLabel={t("project.risksAndIssues.probability")}
                      xLabel={t("project.risksAndIssues.impact")}
                      onBlockSelected={handleChangeProbabilityAndImpact}
                    />
                    <FormTextField name="proximity" />
                  </Stack>
                  <Stack spacing={2} flex={1}>
                    {!!risk?.inherentRisk?.impact && (
                      <FormRadioSelector
                        name="inherentOrResidualRisk"
                        items={[
                          {
                            label: t(
                              "risk.registerForm.fields.inherentOrResidualRisk.options.inherent",
                            ),
                            value: "inherent",
                          },
                          {
                            label: t(
                              "risk.registerForm.fields.inherentOrResidualRisk.options.residual",
                            ),
                            value: "residual",
                          },
                        ]}
                      />
                    )}
                    {inherentOrResidualRisk === "inherent" ? (
                      <>
                        {inherentImpact ? (
                          <FormSelect
                            key="inherentImpact"
                            name="inherentImpact"
                            options={riskImpactOptions(t)}
                            required
                          />
                        ) : (
                          <FormSelect
                            name="inherentImpact"
                            options={riskImpactOptions(t)}
                            required
                          />
                        )}
                        {inherentProbability ? (
                          <FormSelect
                            key="inherentProbability"
                            name="inherentProbability"
                            options={riskProbabilityOptions(t)}
                            required
                          />
                        ) : (
                          <FormSelect
                            name="inherentProbability"
                            options={riskProbabilityOptions(t)}
                            required
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {impact ? (
                          <FormSelect
                            key="impact"
                            name="impact"
                            options={riskImpactOptions(t)}
                            required
                          />
                        ) : (
                          <FormSelect
                            name="impact"
                            options={riskImpactOptions(t)}
                            required
                          />
                        )}
                        {probability ? (
                          <FormSelect
                            key="probability"
                            name="probability"
                            options={riskProbabilityOptions(t)}
                            required
                          />
                        ) : (
                          <FormSelect
                            name="probability"
                            options={riskProbabilityOptions(t)}
                            required
                          />
                        )}
                      </>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}
            {activeStep === 2 && (
              <Stack spacing={2}>
                {renderSentence()}
                <Stack direction="row" spacing={2}>
                  <FormSelect
                    name="response"
                    options={riskResponseOptions(t)}
                    required
                  />
                  <FormTextField name="actionee" />
                </Stack>
                <FormRichTextField name="action" required />
              </Stack>
            )}
            {activeStep === 3 && (
              <Stack spacing={2}>
                {renderSentence()}
                <FormSelect name="status" options={riskStatusOptions(t)} />
                <FormRichTextField name="comments" />
              </Stack>
            )}
            <StepperNavigator
              showPreviousButton={showPreviousButton}
              setActiveStep={setActiveStep}
              onCancel={onClose}
              isDirty={isDirty}
              submitButtonLabel={submitButtonLabel}
            />
          </Stack>
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

const RiskDetailsRow = ({ title, value }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} flex={1}>
      <Typography variant="h3" sx={{ fontWeight: "bold" }} flex={1}>
        {title}
      </Typography>
      <Typography
        flex={1}
        sx={{
          "& p": {
            fontSize: "16px !important",
          },
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

const RiskDetails = ({ risk, onEdit, isOpen, index }) => {
  const { t } = useT();
  const threats = useMemo(() => riskMatrix([risk]), [risk]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(function () {
        window.scrollTo({ top: 690 + 72 * index, behavior: "smooth" });
      }, 150);
    }
  }, [isOpen, risk]);

  return (
    <Stack spacing={3}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        flex={1}
      >
        <Typography variant="h2">{risk.title}</Typography>
        {!risk?.readOnly && (
          <Stack direction="row" alignItems="flex-end" spacing={2}>
            <Button
              variant="text"
              color="secondary"
              startIcon={<EditOutlined />}
              onClick={onEdit}
            >
              {t("risk.registerForm.buttons.edit")}
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack direction="row" flex={1} alignItems="center">
        <Stack flex={1} spacing={2}>
          <RiskDetailsRow
            title={t("risk.registerForm.fields.owner.title")}
            value={risk.owner}
          />
          <RiskDetailsRow
            title={t("risk.registerForm.fields.type.title")}
            value={riskTypeOptions(t).find((o) => o.value === risk.type)?.label}
          />
          <RiskDetailsRow
            title={t("risk.registerForm.fields.category.title")}
            value={
              riskCategoryOptions(t).find((o) => o.value === risk.category)
                ?.label
            }
          />
          <RiskDetailsRow
            title={t("risk.registerForm.fields.proximity.title")}
            value={risk.proximity}
          />
          <RiskDetailsRow
            title={t("risk.registerForm.fields.response.title")}
            value={
              riskResponseOptions(t).find((o) => o.value === risk.response)
                ?.label
            }
          />
          <RiskDetailsRow
            title={t("risk.registerForm.fields.actionee.title")}
            value={risk.actionee}
          />
          <RiskDetailsRow
            title={t("risk.registerForm.fields.status.title")}
            value={
              riskStatusOptions(t).find((o) => o.value === risk.status)?.label
            }
          />
        </Stack>
        <Stack flex={1}>
          <BlockChart
            data={threats}
            yLabel={t("project.risksAndIssues.probability")}
            xLabel={t("project.risksAndIssues.impact")}
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={4} className="ql-editor">
        <Stack spacing={4}>
          <Box>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {t("risk.registerForm.fields.riskStatement")}
            </Typography>
            <Typography>
              {`${t("risk.registerForm.formHelpText.1")}${
                risk.cause ? risk.cause : ""
              }${t("risk.registerForm.formHelpText.2")}${
                risk.type
                  ? t("risk.type." + risk.type?.toLowerCase())
                  : "threat"
              }${t("risk.registerForm.formHelpText.3")}${
                risk.event ? risk.event : ""
              }${t("risk.registerForm.formHelpText.4")}${
                risk.effect ? risk.effect : ""
              }${t("risk.registerForm.formHelpText.5")}`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {t("risk.registerForm.fields.action.title")}
            </Typography>
            <Typography dangerouslySetInnerHTML={{ __html: risk.action }} />
          </Box>
        </Stack>
        <Box>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            {t("risk.registerForm.fields.comments.title")}
          </Typography>
          <Typography dangerouslySetInnerHTML={{ __html: risk.comments }} />
        </Box>
      </Stack>
    </Stack>
  );
};

export const RiskRegister = ({
  projectId = "",
  riskId = "",
  programmeId = "",
  releaseId = "",
  productId = "",
  type = "project",
}) => {
  const { t } = useT();
  const navigate = useNavigate();

  const initiativeId =
    type === "project"
      ? projectId
      : type === "product"
        ? productId
        : type === "release"
          ? releaseId
          : programmeId;

  const { data: projectRisks = [], refetch: refetchProjectRisks } = useQ(
    `project-${projectId}-risks`,
    () => project.risks({ id: projectId }),
    {
      enabled: type === "project",
    },
  );
  const { data: programmeRisks = [], refetch: refetchProgrammeRisks } = useQ(
    `programme-${programmeId}-risks`,
    () => programme.risks({ id: programmeId }),
    {
      enabled: type === "programme",
    },
  );
  const { data: releaseRisks = [], refetch: refetchReleaseRisks } = useQ(
    `release-${releaseId}-risks`,
    () => release.risks({ id: releaseId }),
    {
      enabled: type === "release",
    },
  );
  const { data: productRisks = [], refetch: refetchProductRisks } = useQ(
    `product-${productId}-risks`,
    () => product.risks({ id: productId }),
    {
      enabled: type === "product",
    },
  );

  const risks = useMemo(
    () =>
      type === "project"
        ? projectRisks
        : type === "programme"
          ? programmeRisks
          : type === "release"
            ? releaseRisks
            : productRisks,
    [type, projectRisks, programmeRisks, releaseRisks, productRisks],
  );
  const threats = useMemo(() => riskMatrix(risks), [risks]);

  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [isEditingRisk, setIsEditingRisk] = useState({});
  const [highlightedRisk, highlightRisk] = useState(false);
  const [sortByRecent, setSortByRecent] = useState(false);
  const [view, setView] = useState("identify");

  useEffect(() => {
    setIsEditingRisk((prev) =>
      Object.fromEntries(risks.map((i) => [i.id, prev[i.id] ?? false])),
    );
  }, [risks]);

  const toast = useToast();

  const handleOpenRisk = useCallback(
    (newRiskId) => {
      navigate(
        clientUrl(uris[type][newRiskId === riskId ? "risk" : "riskDetails"], {
          id: initiativeId,
          riskId: newRiskId === riskId ? "" : newRiskId,
        }),
      );
    },
    [navigate, projectId, productId, riskId, type],
  );

  const createRisk = useMutation(
    type === "project"
      ? project.createRisk
      : type === "programme"
        ? programme.createRisk
        : type === "release"
          ? release.createRisk
          : product.createRisk,
    {
      onSuccess: (result, { data }) => {
        console.log("Received data: " + JSON.stringify(result));
        toast.success("Risk created successfully");
        type === "project"
          ? refetchProjectRisks()
          : type === "programme"
            ? refetchProgrammeRisks()
            : type === "release"
              ? refetchReleaseRisks()
              : refetchProductRisks();
        setShowNewItemForm(false);
        if (data?.id) {
          handleOpenRisk(data?.id);
          highlightRisk(data?.id);
          setIsEditingRisk((prev) => ({
            ...prev,
            [data?.id]: true,
          }));
        }
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error creating risk");
      },
    },
  );

  const editRisk = useMutation(
    type === "project"
      ? project.editRisk
      : type === "programme"
        ? programme.editRisk
        : type === "release"
          ? release.editRisk
          : product.editRisk,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Risk updated successfully");
        type === "project"
          ? refetchProjectRisks()
          : type === "programme"
            ? refetchProgrammeRisks()
            : type === "release"
              ? refetchReleaseRisks()
              : refetchProductRisks();
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error updating risk");
      },
    },
  );

  const statusValues = {
    undefined: 0,
    success: 1,
    warn: 2,
    danger: 3,
  };

  const tableRisks = useMemo(
    () =>
      risks
        .map((risk) => ({
          ...risk,
          riskColor: threats
            .flat()
            .find((threat) =>
              threat.cellItems.find((cell) => cell.no === risk.no),
            )?.status,
        }))
        .sort((r1, r2) =>
          !sortByRecent
            ? r1.closed !== r2.closed
              ? !!r1.closed
                ? 1
                : -1
              : r1.riskColor === r2.riskColor
                ? r2.impact === r1.impact
                  ? r2.probability - r1.probability
                  : r2.impact - r1.impact
                : statusValues[r2.riskColor] - statusValues[r1.riskColor]
            : r1.no < r2.no,
        ),
    [risks, threats, sortByRecent],
  );

  return (
    <Stack spacing={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={8}
      >
        <Stack spacing={2}>
          <Typography variant="h1">{t("risk.title")}</Typography>
          <Typography variant="h3">
            <Trans i18nKey="risk.description" components={{ br: <br /> }} />
          </Typography>
        </Stack>

        <RiskThreatsBlockChart
          threats={threats}
          type={type}
          id={
            type === "project"
              ? projectId
              : type === "product"
                ? productId
                : type === "release"
                  ? releaseId
                  : programmeId
          }
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
      >
        <RadioGroup
          defaultValue="identify"
          name="radio-buttons-group"
          row
          value={view}
          onChange={(event) => setView(event.target.value)}
        >
          <Stack direction={"row"} sx={{ width: "100%" }}>
            <Stack
              direction="row"
              sx={{ width: "75%", whiteSpace: "nowrap" }}
              spacing={1}
            >
              <FormControlLabel
                value="identify"
                control={<Radio />}
                label={t("risk.view.identify")}
              />

              <FormControlLabel
                value="followUp"
                control={<Radio />}
                label={t("risk.view.followUp")}
              />
            </Stack>
          </Stack>
        </RadioGroup>
        <Button
          variant="text"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setShowNewItemForm((s) => !s)}
        >
          {showNewItemForm
            ? t("generic.button.close")
            : t("risk.registerForm.buttons.open")}
        </Button>
      </Stack>
      <CustomAccordion
        tableView
        header={{
          expanded: false,
          columns:
            view === "identify"
              ? [
                  {
                    title: "",
                    width: 20,
                  },
                  {
                    title: "ID",
                    flex: 1,
                  },
                  {
                    title: t("risk.registerForm.fields.title.title"),
                    flex: 3,
                  },
                  {
                    title: t("risk.registerForm.fields.category.title"),
                    flex: 2,
                  },
                  {
                    title: t("risk.registerForm.fields.cause.title"),
                    flex: 2,
                  },
                ]
              : [
                  {
                    title: "",
                    width: 20,
                  },
                  {
                    title: "ID",
                    flex: 0.5,
                  },
                  {
                    title: t("risk.registerForm.fields.title.title"),
                    flex: 2,
                  },
                  {
                    title: t("risk.registerForm.fields.action.title"),
                    flex: 5,
                  },
                  {
                    title: t("risk.registerForm.fields.owner.title"),
                    flex: 2,
                  },
                ],
        }}
        rows={tableRisks.map((risk, i) => ({
          id: risk.id,
          onClick: () => handleOpenRisk(risk.id),
          expanded: risk.id === riskId,
          sx: {
            "& .MuiAccordionSummary-root": {
              opacity: risk.closed ? 0.5 : 1,
              textDecoration: risk.closed ? "line-through" : "initial",
            },
          },
          columns:
            view === "identify"
              ? [
                  {
                    content: (
                      <Stack alignItems="center" justifyContent="center">
                        <RiskStatusIcon
                          type={risk.type}
                          status={risk.riskColor}
                        />
                      </Stack>
                    ),
                    width: 20,
                  },
                  {
                    title: risk.no,
                    flex: 1,
                  },
                  {
                    title: risk.title,
                    flex: 3,
                  },
                  {
                    title:
                      risk.category &&
                      t(
                        "risk.registerForm.fields.category.options." +
                          risk.category?.toLowerCase(),
                      ),
                    flex: 2,
                  },
                  {
                    title: risk.cause,
                    flex: 2,
                  },
                ]
              : [
                  {
                    content: (
                      <Stack alignItems="center" justifyContent="center">
                        <RiskStatusIcon
                          type={risk.type}
                          status={risk.riskColor}
                        />
                      </Stack>
                    ),
                    width: 20,
                  },
                  {
                    title: risk.no,
                    flex: 0.5,
                  },
                  {
                    title: risk.title,
                    flex: 2,
                  },
                  {
                    content: (
                      <Stack spacing={1}>
                        <Typography
                          dangerouslySetInnerHTML={{ __html: risk.action }}
                        />
                        <Typography>
                          {risk.response} {risk.response && "Status:"}
                        </Typography>
                        <Typography
                          dangerouslySetInnerHTML={{ __html: risk.comments }}
                        />
                      </Stack>
                    ),
                    flex: 5,
                  },
                  {
                    title: risk.owner,
                    flex: 2,
                  },
                ],
          details: (
            <>
              <RiskDetails
                projectId={projectId}
                programmeId={programmeId}
                releaseId={releaseId}
                productId={productId}
                risk={risk}
                onEdit={() =>
                  setIsEditingRisk((prev) => ({ ...prev, [risk.id]: true }))
                }
                isOpen={risk.id === riskId}
                index={i}
              />
              {isEditingRisk[risk.id] && (
                <RiskForm
                  t={t}
                  risk={risk}
                  onClose={() =>
                    setIsEditingRisk((prev) => ({
                      ...prev,
                      [risk.id]: false,
                    }))
                  }
                  closeForm={() => handleOpenRisk(risk.id)}
                  onSave={async (data) => {
                    await editRisk.mutate({
                      id: initiativeId,
                      riskId: risk?.id,
                      data: {
                        ...risk,
                        ...data,
                      },
                    });
                  }}
                  isHighlighted={highlightedRisk === risk.id}
                  setIsHighlighted={() => highlightRisk("")}
                  isOpen={risk.id === riskId}
                  index={i}
                />
              )}
            </>
          ),
        }))}
      />
      <RiskForm
        isOpen={showNewItemForm}
        onClose={() => setShowNewItemForm(false)}
        t={t}
        onSave={async (data) => {
          await createRisk.mutate({
            id: initiativeId,
            data: {
              ...data,
              id: uuidv4(),
            },
          });
        }}
      />
    </Stack>
  );
};
