import React, { useState } from "react";
import { useQ } from "../../../hooks/useQ";
import { project } from "../../../queries";
import { useT } from "../../../hooks/useT";
import { isEmpty } from "ramda";
import { DisplayContent } from "../../../base/content/DisplayContent";
import { QMAInformation } from "./QMAInformation";
import { QualityApproachForm } from "../forms/QualityApproachForm";
import { Button, Paper, Stack, Typography, Box } from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

const Guide = ({ enableEdit }) => {
  const { t } = useT();
  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={2}>
        <Typography>{t("project.quality.approach.noContent")}</Typography>
        <Button
          onClick={() => enableEdit((v) => !v)}
          color="secondary"
          endIcon={<ArrowCircleRightOutlinedIcon />}
        >
          {t("project.quality.approach.actions.createNew")}
        </Button>
      </Stack>
    </Paper>
  );
};

export const QualityManagementApproach = ({ projectId }) => {
  const { t } = useT();
  const {
    isLoading,
    data = {},
    refetch,
  } = useQ(`project-${projectId}-quality-approach`, () =>
    project.quality.approach({ projectId }),
  );
  const [displayForm, showForm] = useState(false);

  return (
    <>
      <QMAInformation />
      {displayForm ? (
        <QualityApproachForm
          projectId={projectId}
          data={data}
          onClose={() => showForm(false)}
          onUpdate={refetch}
        />
      ) : (
        <>
          {isEmpty(data) ? (
            <Guide projectId={projectId} enableEdit={showForm} />
          ) : (
            <Stack spacing={2}>
              <Button color="secondary" onClick={() => showForm(true)}>
                {t("generic.button.edit")}
              </Button>
              <DisplayContent
                area="quality.approach.fields"
                data={data}
                fields={[
                  "introduction",
                  "process",
                  "tools",
                  "records",
                  "reporting",
                  "timing",
                  "roles",
                ]}
              />
            </Stack>
          )}
        </>
      )}
    </>
  );
};
